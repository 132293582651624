export enum AnnexedInvoiceStatuses {
  DRAFT = 'draft',
  SENT = 'sent',
  PAID = 'paid',
  PARTIALLY_PAID = 'partially_paid',
  VOID = 'void',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  APPROVED_TO_SEND = 'approved_to_send',
  APPROVAL_REQUESTED = 'approval_requested'
}

import repositories from '@/repositories'
import { ref } from 'vue'
import { camelCase } from 'lodash'
import { BackgroundTaskSources } from '@/types/enums'

const exportModalRef = ref<any>(null)
const exportToastRef = ref<any>(null)
const exportContent = ref()

export interface TemplateHeader {
  label: string,
  value: string
}

interface BulkWoReport {
  jobType: string,
  payload: {
    source: BackgroundTaskSources | null,
    query: string | null,
    ids:(string | number)[]
    reportType: string,
    workOrderTab: string,
    reportFormat: string,
    headers: string[],
    includePhotos: boolean,
    templateName: string,
    selectedColumns?: string[],
    fileName: string,
    headerWithLabel?: TemplateHeader[]
  }
}

const bulkWoReportRef = ref<BulkWoReport>({
  jobType: 'bulk_work_order_report_export',
  payload: {
    source: null,
    query: null,
    ids: [],
    reportType: 'standard',
    workOrderTab: 'outbound',
    reportFormat: 'csv',
    headers: [],
    includePhotos: true,
    templateName: '',
    selectedColumns: [],
    fileName: ''
  }
})

// {
//   "id": 0,
//   "name": "string",
//   "report_type": "string",
//   "custom_headers": [
//     "string"
//   ],
//   "tab": "string",
//   "company_id": 0,
//   "user_id": 0
// }

export const useExports = () => {
  const getTemplates = async (params: any) => {
    try {
      const res = await repositories.exports.getTemplates(params)
      return {
        data: res[`${camelCase(params.type)}ReportTemplates`],
        page: res.meta.currentPage,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }
  const createTemplate = async (type: string, body: any) => {
    try {
      const res = await repositories.exports.createTemplate(type, body)
      return res
    } catch (err) {
      console.log(err)
    }
  }

  return {
    getTemplates,
    exportModalRef,
    exportToastRef,
    createTemplate,
    exportContent,
    bulkWoReportRef
  }
}

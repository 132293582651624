import repositories from '@/repositories'
import { baseGetResponse } from '@/utils/api'
import { useApp } from './app'
import { useInboundWorkOrders } from './inbound-work-orders'
import { BROKER, CLIENT, VENDOR } from '@/constants/permissions'
import { ERROR, EXPORT } from '@/constants/icons'
import { useToasts } from './toasts'
import {
  EXTERNAL_CONNECTIONS_PROVIDER_INFO_MAPPINGS,
  createRequiredActionForWorkOrderAction,
  createWorkOrderInvoiceRequiredActionAction,
  createWorkOrderProposalRequiredActionAction,
  createWorkOrderPurchaseOrderRequiredActionAction,
  createWorkOrderTripRequiredActionAction, WORK_ORDER_BROKER_ACTION_DATA,
  WORK_ORDER_CLIENT_ACTION_DATA,
  WORK_ORDER_CONTRACTOR_ACTION_DATA,
  WORK_ORDER_INTERMEDIARY_BROKER_ACTION_DATA,
  WorkOrderActionData,
  workOrderResolveAction,
  WorkOrderResourceAction,
  WO_VIEW_INCLUDIBLE_RESOURCES
} from '@/constants/actions-by-resource/work-orders'
import { useListRow } from './list-rows'
import {
  Column,
  MenuAction,
  ResourceAction,
  Trip,
  WorkOrder,
  Location,
  NewExternalConnection,
  Invoice,
  AutocompletePayload,
  AutocompleteResponse,
  AutocompleteMappings,
  MappingObject
} from '@/types/interfaces'
import { WORK_ORDER_HEADERS } from '@/constants/headers/work-order'

import { computed, inject, ref, watch } from 'vue'
import { AutomatedStatus } from '@/types/enums/work-order/automated-status'
import { useTrips } from '@/use/trips'
import { RequiredAction } from '@/types/interfaces/api-v2/required-action'
import { RequiredActionResourceTypes } from '@/types/enums/required-action/resource-types'
import { PatchWorkOrderParams, PostPurchaseOrderAssignmentParams } from '@/types/interfaces/api-v2/work-order'
import { WorkOrderCompanyRole } from '@/types/enums/work-order/company_role'
import { get, filter, findIndex, cloneDeep, snakeCase, startCase, kebabCase, capitalize, difference, find } from 'lodash'
import { useSession } from '@/use/session'
import { useCompanySettings } from '@/use/company-settings'
import { TripPatchParams } from '@/types/interfaces/api-v2/trip'
import { PROPOSAL_RESOURCE_TYPE, TRIP, PURCHASE_ORDER_RESOURCE_TYPE } from '@/constants/resource-types'
import { useDetailsPanel } from '@/use/details-panel'
import { getPriorityParams } from '@/repositories/priorities'
import { useWorkOrderResolve } from './work-orders/resolve'
import { AttachmentWorkOrderCategory } from '@/types/enums/work-order/attachment'
import { BackgroundTaskSources, ExtendedStatus } from '@/types/enums'
import { RequiredActionActions } from '@/types/enums/required-action/actions'
import moment from 'moment'
import { statuses } from '@/constants/statuses/work-order-client-vendor-statuses.json'
import { MarginPercentageResponse } from '@/types/interfaces/api-v2/margin-percentage'
import { useTripsVerification } from './trips/verification'
import { AttachmentInfo } from '@/use/attachments'

interface WorkOrderAction {
  value: string
  requiresConfirmation: boolean
  actionFn?: (params: any) => void
  bulkConfig?: object
}

const internalStatuses: any = ref<any>([])

const { companyType } = useApp()

const workOrdersTable = ref<any>(null)

const resolveLoading = ref<boolean>(false)

const { TRIP_ACTIONS } = useTrips(false)

const workOrderLimitedTab = ref<any>(null)

export interface bulkEventData {
  source: string | null
  query?: string | null
  workOrderIds?: (string | number)[] | null
  tab?: string | null
}
export interface workOrderPhotosParams {
  include?: string
  q?: any
  page?: number
  perPage?: number
}

export interface GenerateBulkInvoices extends bulkEventData {
  receivable?: boolean
  payable?: boolean
}

const renderBulkCloseWoModalWOs = ref(false)
const bulkWoCloseEventDataWOs = ref<bulkEventData>({
  source: null,
  query: null,
  workOrderIds: null,
  tab: null
})

const renderBulkAddWoNotesModalWOs = ref(false)
const renderBulkDeleteWoModalWOs = ref<boolean>(false)
const bulkAddWoNotesEventDataWOs = ref<bulkEventData>({
  source: '',
  query: '',
  workOrderIds: null,
  tab: null
})
const bulkWoDeleteEventDataWOs = ref<bulkEventData>({
  source: '',
  query: '',
  workOrderIds: null,
  tab: null
})

const renderBulkGenerateINSReportModalWOs = ref(false)
const bulkWoGenerateINSEventDataWOs = ref<bulkEventData>({
  source: null,
  query: null,
  workOrderIds: null,
  tab: null
})

const renderBulkCancelWoModalWOs = ref(false)
const bulkWoCancelEventDataWOs = ref<bulkEventData>({
  source: null,
  query: null,
  workOrderIds: null,
  tab: null
})

const renderBulkStatusChangeWoModalWOs = ref<boolean>(false)
const bulkWoStatusChangeEventDataWOs = ref<bulkEventData>({
  source: null,
  query: null,
  workOrderIds: null
})

const renderBulkMarkWorkOrderReadyForBillingModalWOs = ref(false)
const bulkMarkWorkOrderReadyForBillingEventDataWOs = ref<bulkEventData>({
  source: null,
  query: null,
  workOrderIds: null,
  tab: null
})

const emptyBulkData = {
  source: null,
  query: null,
  workOrderIds: null,
  tab: null
}

const renderWosBatchInvoiceConfiguration = ref(false)
const wosBatchInvoiceConfigurationData = ref<GenerateBulkInvoices>({
  source: null,
  query: null,
  workOrderIds: null
})

watch(() => renderWosBatchInvoiceConfiguration, (newVal) => {
  if (!newVal) {
    wosBatchInvoiceConfigurationData.value.source = null
    wosBatchInvoiceConfigurationData.value.query = null
    wosBatchInvoiceConfigurationData.value.workOrderIds = null
    wosBatchInvoiceConfigurationData.value.receivable = false
    wosBatchInvoiceConfigurationData.value.payable = false
  }
})

export interface DefaultTypesParam {
  tradeServiceId: string | number
  locationId: string | number
}

export interface ContactTypeSelection {
  workOrderManager?: string,
  name: string,
  value: string,
  assigneeType?: string,
  tripId?: number,
  vendorId?: number,
  clientId?: number,
  teamMemberId?: number,
  regionTeamMemberId?: number,
  territoryId?: number
}
const showCheckInPhotosModal = ref(false)

type CheckInPhotoObject = {
  workOrderId: string | number
}
const checkInPhotoWOdetails = ref<CheckInPhotoObject>({
  workOrderId: 0
})
const wosBulkReportDownloadModal = ref(false)
const bulkReportDownloadDataWOs = ref<bulkEventData>({
  source: null,
  query: null,
  workOrderIds: null,
  tab: null
})

const workOrderPerformBulkAction = (workOrderIds: (number | string)[], action: string, query = '') => {
  let params
  if (query !== '') {
    params = { source: BackgroundTaskSources.QUERY, query }
  } else {
    params = { source: BackgroundTaskSources.IDS, workOrderIds }
  }
  switch (action) {
    case 'delete_wos': {
      bulkWoDeleteEventDataWOs.value = params
      renderBulkDeleteWoModalWOs.value = true
      return
    }
    case 'add_notes': {
      bulkAddWoNotesEventDataWOs.value = params
      renderBulkAddWoNotesModalWOs.value = true
      return
    }
    case 'close_wos': {
      bulkWoCloseEventDataWOs.value = params
      renderBulkCloseWoModalWOs.value = true
      return
    }
    case 'generate_ins_sheet': {
      bulkWoGenerateINSEventDataWOs.value = params
      renderBulkGenerateINSReportModalWOs.value = true
      return
    }
    case 'cancel_wos': {
      bulkWoCancelEventDataWOs.value = params
      renderBulkCancelWoModalWOs.value = true
      return
    }
    case 'custom_status_change': {
      bulkWoStatusChangeEventDataWOs.value = params
      renderBulkStatusChangeWoModalWOs.value = true
      return
    }
    case 'mark_as_ready_for_billing': {
      bulkMarkWorkOrderReadyForBillingEventDataWOs.value = params
      renderBulkMarkWorkOrderReadyForBillingModalWOs.value = true
      return
    }
    case 'download_wos_report': {
      bulkReportDownloadDataWOs.value = params
      wosBulkReportDownloadModal.value = true
      return
    }
    case 'generate_receivable': {
      renderWosBatchInvoiceConfiguration.value = true
      wosBatchInvoiceConfigurationData.value = params
      wosBatchInvoiceConfigurationData.value.receivable = true
      return
    }
    case 'generate_payable': {
      renderWosBatchInvoiceConfiguration.value = true
      wosBatchInvoiceConfigurationData.value = params
      wosBatchInvoiceConfigurationData.value.payable = true
      return
    }
    case 'generate_invoices': {
      renderWosBatchInvoiceConfiguration.value = true
      wosBatchInvoiceConfigurationData.value = params
      wosBatchInvoiceConfigurationData.value.receivable = true
      wosBatchInvoiceConfigurationData.value.payable = true
      return
    }
    default: {
      console.info('functionality under development', workOrderIds, action)
    }
  }
}

export interface GetCustomStatusParams {
  scope?: string
  include?: string
  q?: any
  page?: number
  perPage?: number,
}

export interface CancelationReason {
  cancellationReason: string
}

export interface UpdateWorkOrderParams {
  workOrderStatusId?: string | number,
  cancelationReasonAttributes?: CancelationReason,
  sendWoCancelationEmail?: boolean
}

export interface GetWorkOrderParams {
  workOrderId: string | number,
  scope?: string
  include?: string
  q?: any
  page?: number
  perPage?: number,
  scopeContext?: string
}

const sharedActionsForWorkOrder = [
  {
    value: 'work-order-change-custom-status',
    requiresConfirmation: true,
    advancedModal: 'work-orders/modals/custom-status'
  },
  {
    value: 'work-order-notes',
    requiresConfirmation: true,
    advancedModal: 'work-orders/details/notes/new'
  },
  {
    value: 'work-order-add-attachment',
    requiresConfirmation: true,
    advancedModal: 'work-orders/details/attachments/create/index',
    dynamicContentProps: {
      createdFromWorkOrderMenu: true,
      isTripAttachment: false,
      defaultAttachmentType: {
        type: 'all',
        name: AttachmentWorkOrderCategory.PHOTO_OR_DOCUMENT
      }
    }
  },
  {
    value: 'work-order-add-proposal',
    requiresConfirmation: true,
    advancedModal: 'work-orders/modals/add-proposal'
  },
  {
    value: 'work-order-add-material',
    requiresConfirmation: true,
    advancedModal: 'uc/work-order/modal/add-material'
  },
  {
    value: 'view',
    updateSelectedResource: false,
    actionFn: (resources: WorkOrder[], { router }: any) => {
      router.push(`/work-orders/${resources[0].id}`)
    },
    noSuccessEmit: true
  },
  {
    value: 'trip-generate-report',
    requiresConfirmation: true,
    advancedModal: 'uc/attachments/uc-workorder-details-modal',
    dynamicContentProps: {
      bulkTripReportFromWO: true,
      bulkTripReport: true
    }
  }
]

// to avoid compilation circular dependency problems
let allHeaderActions: any[] | null = null

// captureNewTrip - function to updated work Order after trip added in it
const captureNewTrip = async (event: Trip) => {
  if (!workOrdersTable.value) return
  const { getWorkOrderById } = useWorkOrders()
  const response = await getWorkOrderById({
    workOrderId: event.workOrderId,
    scopeContext: 'all',
    include: WO_VIEW_INCLUDIBLE_RESOURCES,
    includeAttributesForCustomStatus: true,
    includeMaterialAttribute: true,
    profileDetailsRequired: true
  })
  if (response.data) workOrdersTable.value.updateRow(response.data)
}

const clientHeaderActions = [
  {
    value: 'Download Report',
    requiresConfirmation: false,
    actionFn: (resources: WorkOrder[]) => {
      const workOrderIds = resources.map((workOrder: WorkOrder) => workOrder.id)
      workOrderPerformBulkAction(workOrderIds, 'download_wos_report')
    },
    bulkConfig: {
      type: 'primary'
    }
  },
  ...sharedActionsForWorkOrder
]

// Define the structure for bulk action configuration
interface BulkConfig {
  type: string;
}

// Define the structure for the action
interface Action {
  value: string;
  requiresConfirmation: boolean;
  actionFn: (resources: WorkOrder[], additionalParams: any) => void;
  bulkConfig: BulkConfig;
}
type HeaderActionKey = 'invoiceable' | 'billable' | 'invoiceableOrBillable';

const ACTION_FILTER_HEADER_ACTIONS: Record<HeaderActionKey, Action> = {
  invoiceable: {
    value: 'generate-receivable',
    requiresConfirmation: false,
    actionFn: (resources: WorkOrder[], additionalParams: any) => {
      const workOrderIds = resources.map((workOrder: WorkOrder) => workOrder.id)
      let query = ''
      if (additionalParams.bulkSelectionData?.isBulkExclusive) {
        query = JSON.stringify(additionalParams?.exportQuery())
      }
      workOrderPerformBulkAction(workOrderIds, 'generate_receivable', query)
    },
    bulkConfig: {
      type: 'menu'
    }
  },
  billable: {
    value: 'generate-payable',
    requiresConfirmation: false,
    actionFn: (resources: WorkOrder[], additionalParams: any) => {
      const workOrderIds = resources.map((workOrder: WorkOrder) => workOrder.id)
      let query = ''
      if (additionalParams.bulkSelectionData?.isBulkExclusive) {
        query = JSON.stringify(additionalParams?.exportQuery())
      }
      workOrderPerformBulkAction(workOrderIds, 'generate_payable', query)
    },
    bulkConfig: {
      type: 'menu'
    }
  },
  invoiceableOrBillable: {
    value: 'generate-invoices',
    requiresConfirmation: false,
    actionFn: (resources: WorkOrder[], additionalParams: any) => {
      const workOrderIds = resources.map((workOrder: WorkOrder) => workOrder.id)
      let query = ''
      if (additionalParams.bulkSelectionData?.isBulkExclusive) {
        query = JSON.stringify(additionalParams?.exportQuery())
      }
      workOrderPerformBulkAction(workOrderIds, 'generate_invoices', query)
    },
    bulkConfig: {
      type: 'menu'
    }
  }
}

const getActionFilterHeaderActions = (headerAction: HeaderActionKey) => ACTION_FILTER_HEADER_ACTIONS[headerAction]

const vendorHeaderActions = [
  TRIP_ACTIONS.accept,
  TRIP_ACTIONS.reject,
  TRIP_ACTIONS.verify,
  {
    value: 'action-required-resolve',
    requiresConfirmation: false,
    actionFn: async (resources: WorkOrder[]) => {
      const actions: RequiredAction[] = resources[0]?.ccRequiredActions || []
      useWorkOrderResolve().openResourceForResolve(resources[0], actions)
    }
  },
  ...clientHeaderActions
]

const getWorkOrderActions = () => {
  if (companyType.value === CLIENT) {
    return clientHeaderActions
  } else if (companyType.value === VENDOR) {
    return vendorHeaderActions
  } else {
    const { getAllHeaderActions } = useWorkOrders()
    const allHeaderAction = checkIfBulkActionAllowed(getAllHeaderActions())
    return addINSSheetGenOption(allHeaderAction.slice())
  }
}

const { settings } = useCompanySettings()
const addINSSheetGenOption = (options: object[]) => {
  if (!options) return []

  if (settings.value.data?.workOrderSetting?.autoGenerateInvoiceSheets) {
    if (!(options.find((option: any) => option.value === 'generate-ins-sheet'))) {
      const addNotesIndex = options.findIndex((option: any) => option.value === 'add-notes')
      options.splice(
        addNotesIndex, 0,
        {
          value: 'Generate INS',
          requiresConfirmation: false,
          actionFn: (resources: WorkOrder[], additionalParams: any) => {
            const workOrderIds = resources.map((workOrder: WorkOrder) => workOrder.id)
            let query = ''
            if (additionalParams.bulkSelectionData?.isBulkExclusive) {
              query = JSON.stringify(additionalParams?.exportQuery())
            }
            workOrderPerformBulkAction(workOrderIds, 'generate_ins_sheet', query)
          },
          bulkConfig: {
            type: 'menu'
          }
        }
      )
    }
    return options
  } else {
    return options
  }
}

const getTableHeaders = () => {
  const { session } = useSession()
  const headers = WORK_ORDER_HEADERS()
  headers.headers = headers.headers.filter((column: Column) => column.templateName !== 'location')
  headers.headers = headers.headers.filter((column: Column) => column.label !== startCase(session.currentCompanyType))
  if (session.currentCompanyType !== BROKER) {
    headers.headers = headers.headers.filter((column: Column) => column.templateName !== 'follow_up')
  }
  return headers
}

const checkIfBulkActionAllowed = (actions: WorkOrderAction[]) => {
  if (!actions.length) return []

  const { hasAccess } = useSession()
  if (hasAccess('create_work_orders')) return actions

  return actions.filter(
    (action: WorkOrderAction) => !['cancel-work-orders', 'custom-status-change'].includes(action.value)
  )
}
/**
  To get my site work order there are multiple ways to find.
  But the important thing is who is viewing the work order(currentCompany)
  If currentCompanyRole is not BROKER, no need to check anything
  For example:
    case - @param resourceType
      1. WORK_ORDER
        a. check if the client(companyId) and broker(vendorId) are both same and
          equals to currentCompany
         or
        b. check if the owner(companyId) of location is equals to the either
          broker(vendorId) or client(companyId) and currentCompany
      2. TRIP
        a. check if the vendor(trip have a vendor and company from workOrder) or company
          and owner of location is same which is currentCompany
         or
      3. LOCATION
        a. check if owner(companyId) and currentCompany is same
          (with respect to WorkOrder)
*/
export enum MY_SITE_RESOURCE_TYPES {
  WORK_ORDER = 'work-order',
  TRIP = 'trip',
  WORK_ORDER_LOCATION = 'location'
}
type resource = WorkOrder | Trip | Location
const isMySiteResource = (
  resourceType: MY_SITE_RESOURCE_TYPES,
  resource: resource,
  currentCompanyRole = ''
): boolean => {
  if (!resource) return false

  const companyRole = currentCompanyRole ||
    (resource as WorkOrder).currentCompanyRole ||
    (resource as Trip).currentCompanyRole
  if (!companyRole || companyRole !== BROKER) return false

  const { session } = useSession()
  if (resourceType === MY_SITE_RESOURCE_TYPES.WORK_ORDER) {
    return ((resource as WorkOrder).vendorId === (resource as WorkOrder).companyId)
  } else if (resourceType === MY_SITE_RESOURCE_TYPES.TRIP) {
    return ((resource as Trip).workOrder?.vendorId === (resource as Trip).workOrder?.companyId)
  } else if (resourceType === MY_SITE_RESOURCE_TYPES.WORK_ORDER_LOCATION) {
    return (session.currentCompany.id === (resource as Location).companyId)
  } else {
    return false
  }
}

export const useWorkOrders = () => {
  function getAllHeaderActions () {
    allHeaderActions ||= [
      TRIP_ACTIONS.accept,
      TRIP_ACTIONS.reject,
      // TRIP_ACTIONS.adjust,
      TRIP_ACTIONS.assign,
      // TRIP_ACTIONS.checkIn,
      TRIP_ACTIONS.dispatch,
      TRIP_ACTIONS.edit,
      // TRIP_ACTIONS.reassign,
      TRIP_ACTIONS.verify,
      TRIP_ACTIONS.skip,
      // INVOICE_WORK_ORDER_ACTIONS.edit,
      // INVOICE_WORK_ORDER_ACTIONS.pay,
      // INVOICE_WORK_ORDER_ACTIONS.receivePayment,
      // INVOICE_WORK_ORDER_ACTIONS.review,
      // INVOICE_WORK_ORDER_ACTIONS.send,
      // PROPOSAL_WORK_ORDER_ACTIONS.approve,
      // PROPOSAL_WORK_ORDER_ACTIONS.edit,
      // PROPOSAL_WORK_ORDER_ACTIONS.review,
      // PURCHASE_ORDER_WORK_ORDER_ACTIONS.approve,
      // PURCHASE_ORDER_WORK_ORDER_ACTIONS.edit,
      // PURCHASE_ORDER_WORK_ORDER_ACTIONS.generateInvoice,
      {
        value: 'edit',
        updateSelectedResource: false,
        actionFn: (resources: WorkOrder[], { localDetailsPanelComponentProps, router, emit }: any) => {
          // condition to to check localDetailsPanelComponentProps available
          // if localDetailsPanelComponentProps is available means we have details panel opened
          if (localDetailsPanelComponentProps) {
            localDetailsPanelComponentProps.canDoubleClickToEdit = false
            router.push(`/work-orders/${resources[0].id}/edit`)
          } else {
            // If we don't have details panel open (as we open details screen in rout work-order/wo-Id) then need to emit edit action to capture it in .vue in action listener file
            emit('edit')
          }
        }
      },
      {
        value: 'work-order-save-add-trip',
        requiresConfirmation: false
      },
      {
        value: 'work-order-save-reoccurring-trip',
        requiresConfirmation: false
      },
      // {
      //   value: 'work-order-save-draft-trip',
      //   requiresConfirmation: false
      // },
      {
        value: 'work-order-save',
        requiresConfirmation: false
      },
      {
        value: 'work-order-mark-ready-for-billing',
        requiresConfirmation: true,
        advancedModal: 'work-orders/modals/mark-ready-for-billing'
      },
      {
        value: 'work-order-reject-ready-for-billing',
        requiresConfirmation: true,
        advancedModal: 'work-orders/modals/mark-reject-not-ready-for-billing'
      },
      {
        value: 'work-order-add-trip',
        requiresConfirmation: false,
        actionFn: (resources: WorkOrder[]) => {
          const { getTripsActions } = useTrips()
          const { openGlobalDetailsPanel } = useDetailsPanel()
          const { tripsTableRef } = useTrips()
          openGlobalDetailsPanel('trips/details', {
            resourceType: TRIP,
            actions: getTripsActions(),
            isEditMode: true,
            isGlobalDetailsPanel: true
          }, 'enterCreateFlow', { prefillData: { workOrderId: resources[0].id.toString(), tableRef: tripsTableRef } }, { success: captureNewTrip })
        }
      },
      {
        value: 'work-order-add-purchase-order',
        requiresConfirmation: true,
        advancedModal: 'work-orders/modals/add-purchase-order'
      },
      {
        value: 'receivable-payable-invoice',
        requiresConfirmation: true,
        advancedModal: 'work-orders/modals/generate-invoice'
      },
      {
        value: 'action-required-resolve',
        requiresConfirmation: false,
        actionFn: async (resources: WorkOrder[]) => {
          const actions: RequiredAction[] = resources[0]?.ccRequiredActions || []
          useWorkOrderResolve().openResourceForResolve(resources[0], actions)
        }
      },
      {
        value: 'delete',
        requiresConfirmation: false,
        actionFn: (resources: WorkOrder[], additionalParams: any) => {
          const workOrderIds = resources.map((workOrder: WorkOrder) => workOrder.id)
          // console.log('additionalParams.fetchfn -> ', additionalParams.fetchFn)
          let query = ''
          if (additionalParams.bulkSelectionData?.isBulkExclusive) {
            query = JSON.stringify(additionalParams?.exportQuery())
          }
          workOrderPerformBulkAction(workOrderIds, 'delete_wos', query)
        },
        bulkConfig: {
          type: 'menu'
        }
      },
      {
        value: 'close-work-orders',
        requiresConfirmation: false,
        actionFn: (resources: WorkOrder[], additionalParams: any) => {
          const workOrderIds = resources.map((workOrder: WorkOrder) => workOrder.id)
          let query = ''
          if (additionalParams.bulkSelectionData?.isBulkExclusive) {
            query = JSON.stringify(additionalParams?.exportQuery())
          }
          workOrderPerformBulkAction(workOrderIds, 'close_wos', query)
        },
        bulkConfig: {
          type: 'menu'
        }
      },
      // {
      //   value: 'dispatch',
      //   requiresConfirmation: false,
      //   actionFn: (resources: WorkOrder[]) => {
      //     const workOrderIds = resources.map((workOrder: WorkOrder) => workOrder.id)
      //     workOrderPerformBulkAction(workOrderIds, 'dispatch_wos')
      //   },
      //   bulkConfig: {
      //     type: 'menu'
      //   }
      // },
      {
        value: 'custom-status-change',
        requiresConfirmation: false,
        actionFn: (resources: WorkOrder[], additionalParams: any) => {
          const workOrderIds = resources.map((workOrder: WorkOrder) => workOrder.id)
          let query = ''
          if (additionalParams.bulkSelectionData?.isBulkExclusive) {
            query = JSON.stringify(additionalParams?.exportQuery())
          }
          workOrderPerformBulkAction(workOrderIds, 'custom_status_change', query)
        },
        bulkConfig: {
          type: 'menu'
        }
      },
      {
        value: 'add-notes',
        requiresConfirmation: false,
        actionFn: (resources: WorkOrder[], additionalParams: any) => {
          const workOrderIds = resources.map((workOrder: WorkOrder) => workOrder.id)
          let query = ''
          if (additionalParams.bulkSelectionData?.isBulkExclusive) {
            query = JSON.stringify(additionalParams?.exportQuery())
          }
          workOrderPerformBulkAction(workOrderIds, 'add_notes', query)
        },
        bulkConfig: {
          type: 'menu'
        }
      },
      {
        value: 'mark-client-invoices-as-ready-for-billing',
        requiresConfirmation: false,
        actionFn: (resources: WorkOrder[], additionalParams: any) => {
          const workOrderIds = resources.map((workOrder: WorkOrder) => workOrder.id)
          let query = ''
          if (additionalParams.bulkSelectionData?.isBulkExclusive) {
            query = JSON.stringify(additionalParams?.exportQuery())
          }
          workOrderPerformBulkAction(workOrderIds, 'mark_as_ready_for_billing', query)
        },
        bulkConfig: {
          type: 'menu'
        }
      },
      {
        value: 'cancel-work-orders',
        requiresConfirmation: false,
        actionFn: (resources: WorkOrder[], additionalParams: any) => {
          const workOrderIds = resources.map((workOrder: WorkOrder) => workOrder.id)
          let query = ''
          if (additionalParams.bulkSelectionData?.isBulkExclusive) {
            query = JSON.stringify(additionalParams?.exportQuery())
          }
          workOrderPerformBulkAction(workOrderIds, 'cancel_wos', query)
        },
        bulkConfig: {
          type: 'menu'
        }
      },
      {
        value: 'download-work-order-report',
        requiresConfirmation: false,
        actionFn: (resources: WorkOrder[], additionalParams: any) => {
          const workOrderIds = resources.map((workOrder: WorkOrder) => workOrder.id)
          let query = ''
          if (additionalParams.bulkSelectionData?.isBulkExclusive) {
            query = JSON.stringify(additionalParams?.exportQuery())
          }
          workOrderPerformBulkAction(workOrderIds, 'download_wos_report', query)
        },
        bulkConfig: {
          type: 'icon',
          icon: EXPORT,
          text: 'Download Work Order Report'
        }
      },
      {
        value: 'trip-generate-invoice',
        requiresConfirmation: true,
        advancedModal: 'work-orders/modals/generate-invoice'
      },
      {
        value: 'invoice-receive-payment',
        requiresConfirmation: false,
        actionFn: (invoiceIds: Invoice[]) => {
          const invoiceId = invoiceIds
          // functionality to be added later
          console.log('Receive payment action for invoice', invoiceId)
        }
      },
      ...sharedActionsForWorkOrder
    ]
    return allHeaderActions
  }

  const getWorkOrders = async (params: any) => {
    try {
      const res = await repositories.workOrders.get(params)
      return {
        data: res.workOrders,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages,
        currentPage: res.meta.currentPage,
        nextPage: res.meta.nextPage
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getWorkOrderById = async (params: any) => {
    try {
      const res = await repositories.workOrders.getById(params)
      if (res?.clientVendorStatuses) {
        res.workOrder = { ...res.workOrder, clientVendorStatuses: res.clientVendorStatuses }
      }
      return {
        data: res.workOrder
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }
  const getWorkOrderTripsById = async (params: any) => {
    try {
      const res = await repositories.workOrders.getTripsById(params)
      return {
        data: res.trips,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }
  const getInvoiceableWorkOrdersByClientId = async (params: any) => {
    try {
      const res = await repositories.workOrders.getInvoiceablesByClientId(params)
      return {
        data: res.workOrders,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getBillableWorkOrdersByVendorId = async (params: any) => {
    try {
      const res = await repositories.workOrders.getBillablesByVendorId(params)
      return {
        data: res.workOrders,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  function generateRequiredActionMenuEntry (requiredAction: RequiredAction, includeIdentifier: boolean) {
    switch (requiredAction.resourceType) {
      case RequiredActionResourceTypes.TRIP:
        return createWorkOrderTripRequiredActionAction(requiredAction, includeIdentifier)
      case RequiredActionResourceTypes.PROPOSAL:
        return createWorkOrderProposalRequiredActionAction(requiredAction, includeIdentifier)
      case RequiredActionResourceTypes.PURCHASE_ORDER:
        return createWorkOrderPurchaseOrderRequiredActionAction(requiredAction, includeIdentifier)
      case RequiredActionResourceTypes.INVOICE:
        return createWorkOrderInvoiceRequiredActionAction(requiredAction, includeIdentifier)
      case RequiredActionResourceTypes.WORK_ORDER:
        return createRequiredActionForWorkOrderAction(requiredAction, includeIdentifier)
      default:
        throw new Error(`Unsupported resource type ${requiredAction.resourceType})`)
    }
  }

  const generateWorkOrderMenuActions = (workOrder: WorkOrder, isDetailsPanel = false): MenuAction[] => {
    const { getActionMenuData } = useListRow()
    const { hasAccess } = useSession()
    const isCreateMaterialAccessible = !workOrder.alreadyBilled && !workOrder.alreadyBilled && workOrder.accessibleMaterialTab

    if (!workOrder?.id) {
      if (WORK_ORDER_BROKER_ACTION_DATA.createActions) {
        return getActionMenuData('', [], [...WORK_ORDER_BROKER_ACTION_DATA.createActions], '', false, null)
      }
      return []
    }

    const workOrderStatus = workOrder.automatedStatusName
    let staticActions: WorkOrderActionData
    switch (workOrder.currentCompanyRole) {
      case WorkOrderCompanyRole.CLIENT:
        staticActions = WORK_ORDER_CLIENT_ACTION_DATA
        break
      case WorkOrderCompanyRole.INTERMEDIARY_BROKER:
        staticActions = WORK_ORDER_INTERMEDIARY_BROKER_ACTION_DATA
        break
      case WorkOrderCompanyRole.CONTRACTOR:
        staticActions = WORK_ORDER_CONTRACTOR_ACTION_DATA
        break
      case WorkOrderCompanyRole.BROKER:
        staticActions = WORK_ORDER_BROKER_ACTION_DATA
        break
      default:
        return []
    }

    let assembledActions: WorkOrderResourceAction[] | null = null

    if (workOrder.automatedStatusName === AutomatedStatus.ACTION_REQUIRED) {
      // Filter Create Trip required action since we will always have Add Trip static action in the action list
      interface RejectActions {
        Invoice: RequiredActionActions[]
        Proposal: RequiredActionActions[]
        PurchaseOrder: RequiredActionActions[]
        Trip: RequiredActionActions[]
        WorkOrder: RequiredActionActions[]
      }

      const rejectActions: RejectActions = {
        Invoice: [RequiredActionActions.RECEIVE_PAYMENT, RequiredActionActions.EDIT, RequiredActionActions.SEND, RequiredActionActions.PAY, RequiredActionActions.REVIEW],
        Proposal: [RequiredActionActions.REVIEW, RequiredActionActions.APPROVE, RequiredActionActions.EDIT],
        PurchaseOrder: [RequiredActionActions.EDIT, RequiredActionActions.APPROVE, RequiredActionActions.GENERATE_INVOICE],
        Trip: [RequiredActionActions.CHECK_IN, RequiredActionActions.ADJUST, RequiredActionActions.REASSIGN, RequiredActionActions.RECEIVE_INVOICE],
        WorkOrder: []
      }
      const requiredActions = cloneDeep(workOrder.ccRequiredActions?.filter((a) => {
        return (a.action !== 'create_trip') && (!rejectActions[a.resourceType] || !rejectActions[a.resourceType].includes(a.action))
      }))

      if (requiredActions && requiredActions.length === 1) {
        // when we have only one, the first action will be a no-identifier for the shortened button, and the 2nd will be for the first menu
        // entry with the identifier (a duplicate but a UX choice)
        assembledActions = [
          generateRequiredActionMenuEntry(requiredActions[0], true),
          ...staticActions.preRequiredActions,
          ...staticActions.postRequiredActions
        ].filter(assembledAction => assembledAction.name.length)
      } else if (requiredActions && requiredActions.length > 1) {
        // with > 1, the main action is always resolve, and the rest are just identifier-containing menu entries
        let requiredActionsResourceTypes: string[] = []
        const requiredActionsActions = requiredActions.map((requiredAction): ResourceAction => {
          requiredActionsResourceTypes.push(requiredAction.resourceType)
          return generateRequiredActionMenuEntry(requiredAction, true)
        }).filter(assembledAction => assembledAction.name.length)
        assembledActions = [
          workOrderResolveAction,
          ...staticActions.preRequiredActions,
          ...requiredActionsActions,
          ...staticActions.postRequiredActions
        ]
        requiredActionsResourceTypes = [...new Set(requiredActionsResourceTypes)]
        const requiredActionResourcePermissions = requiredActionsResourceTypes.map(resourceType => {
          const formattedResourceType = resourceType === RequiredActionResourceTypes.TRIP
            ? 'work_orders'
            : snakeCase(resourceType)
          return hasAccess(formattedResourceType)
        })
        const noPermission = requiredActionResourcePermissions.every(value => !value)
        // If user don't have permission to any of the required action then removing the resolve action
        if (noPermission) {
          assembledActions = assembledActions.filter(assembledAction => assembledAction.name !== workOrderResolveAction.name)
        }
      }
    }
    if (!assembledActions) {
      assembledActions = [
        ...staticActions.preRequiredActions,
        ...staticActions.postRequiredActions
      ]
    }

    assembledActions = assembledActions.filter(
      (assembledAction: WorkOrderResourceAction) => {
        return (!assembledAction.onlyNonCanceled || workOrderStatus !== AutomatedStatus.CANCELED) &&
          (!assembledAction.requiredSetting || get(workOrder.vendor, assembledAction.requiredSetting)) &&
          (!assembledAction.requiredFeature || hasAccess(assembledAction.requiredFeature.subFeature || assembledAction.requiredFeature.feature)) &&
          (assembledAction.specialLogic !== 'rejectReadyForBilling' || workOrder.clientStatusLabel === 'Ready for Billing') &&
          (assembledAction.specialLogic !== 'readyForBilling' || (workOrder.clientStatusLabel !== 'Ready for Billing' && workOrder.clientStatusLabel !== 'Invoice Void'))
      })

    // Remove '+ Trip' if the work order is received.
    if (workOrder.isReceived) {
      assembledActions = assembledActions.filter(assembledAction => assembledAction.name !== '+ Trip')
    }
    assembledActions = updateVerifyTripActions(workOrder, assembledActions)

    // Remove 'Add Material' if the createMaterial is not accessible.
    if (!isCreateMaterialAccessible) {
      assembledActions = filter(
        assembledActions, (action: WorkOrderResourceAction) => findIndex(assembledActions, () => (action.name === 'Add Material')) === -1
      )
    }
    if (!hasAccess(PROPOSAL_RESOURCE_TYPE)) {
      assembledActions = filter(
        assembledActions, (action: WorkOrderResourceAction) => findIndex(assembledActions, () => (action.name === '+ Proposal')) === -1)
    }
    if (!hasAccess(PURCHASE_ORDER_RESOURCE_TYPE)) {
      assembledActions = filter(
        assembledActions, (action: WorkOrderResourceAction) => findIndex(assembledActions, () => (action.name === '+ Purchase Order')) === -1)
    }
    assembledActions = assembledActions.map((action) => {
      if (action.extraData) {
        return { ...action, eventName: kebabCase(action.name), originalEventName: action.eventName }
      } else {
        return action
      }
    })
    // we're just building the list without any shinannighans of building out a per-status list,
    // the status doesn't matter since we didn't add a filter to the statuses themselves
    return getActionMenuData('', [], assembledActions, '', isDetailsPanel, null)
  }

  const updateVerifyTripActions = (workOrder: WorkOrder, assembledActions: WorkOrderResourceAction[]) => {
    if (workOrder.trips?.some((trip) => trip.extendedStatus === ExtendedStatus.COMPLETED)) {
      assembledActions = assembledActions.map((action) => {
        if (action.eventName === 'trip-verify') {
          const trip = workOrder.trips?.find((trip) => trip.extendedStatus === ExtendedStatus.COMPLETED && trip.id === action.extraData?.resourceId) as Trip
          const { updatedVerifyActionText } = useTripsVerification(trip)
          return updatedVerifyActionText(action, ((workOrder.trips?.length || 0) > 1))
        }
        return action
      })

      const resolveAction = find(assembledActions, { eventName: 'action-required-resolve' })
      const verifyAction = find(assembledActions, { eventName: 'trip-verify' })

      if (!resolveAction && verifyAction) {
        assembledActions = [verifyAction, ...difference(assembledActions, [verifyAction])]
      }
    }
    return assembledActions
  }

  const getWoTripReportsById = async (params: any) => {
    try {
      const res = await repositories.workOrders.getWoTripReports(params)
      return {
        data: res.tripReport.contentTypes,
        surveyAndSurveyTemplateData: res.surveyAndSurveyTemplateData
      }
    } catch (err) {
      console.log(err)
      return { data: [] }
    }
  }

  const getWorkOrderDataForGeneration = async (workOrderId: number | string) => {
    const { companyType } = useApp()
    const { addToast } = useToasts()
    const { getInboundWorkOrders } = useInboundWorkOrders()
    let workOrder: any = null
    const workOrderRes = companyType.value === BROKER ? await getWorkOrderById({ workOrderId, include: 'company,locations' }) : await getInboundWorkOrders({ q: { id_eq: workOrderId }, include: 'company,locations' })

    const errorToast = {
      timeout: 5000,
      color: 'error',
      message: 'Work Order not found',
      prependIcon: ERROR
    }

    // For broker to broker case we need to call inbound work order api
    if (companyType.value === BROKER && (!workOrderRes.data || !Object.keys(workOrderRes.data).length)) {
      const inboundWorkOrderRes: any = await getInboundWorkOrders({ q: { id_eq: workOrderId }, include: 'company,locations' })
      if (!inboundWorkOrderRes.data || !inboundWorkOrderRes.data.length) {
        addToast(errorToast)
        return
      }
      workOrder = inboundWorkOrderRes.data[0]
    } else if (companyType.value === BROKER && Object.keys(workOrderRes.data).length) {
      workOrder = workOrderRes.data
    } else if (companyType.value === VENDOR && Object.keys(workOrderRes.data).length) {
      workOrder = workOrderRes.data[0]
    } else {
      addToast(errorToast)
      return
    }
    return workOrder
  }
  const getWorkOrderStatus = async (params: any) => {
    try {
      const res = await repositories.workOrders.getWOStatus(params)
      return {
        data: res.woExtendedStatusesStates,
        totalCount: res.length,
        totalPages: 1
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getTripStatus = async (params: any) => {
    try {
      const res = await repositories.workOrders.getWOStatus(params)
      return {
        data: res.tripExtendedStatuses,
        totalCount: res.length,
        totalPages: 1
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getClientStatus = async (params: any) => {
    try {
      const res = await repositories.workOrders.getWOStatus(params)
      return {
        data: res.woClientStatuses,
        totalCount: res.length,
        totalPages: 1
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getVendorStatus = async (params: any) => {
    try {
      const res = await repositories.workOrders.getWOStatus(params)
      return {
        data: res.woVendorStatuses,
        totalCount: res.length,
        totalPages: 1
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const WorkOrdersAutoComplete = async (params: AutocompletePayload) => {
    try {
      const res = await repositories.workOrders.WorkOrdersAutoComplete(params)
      return {
        data: res.results,
        nextPage: res.nextPage
      } as { data: AutocompleteResponse[], nextPage: number }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const genWoInternalStatusChipProps = ({ workOrder }: { workOrder: WorkOrder }) => {
    const appendedText = ''
    const text = workOrder.internalStatus
    const prependIcon = workOrder.internalStatusIcon === 'empty' ? '' : `${workOrder.internalStatusIcon} fa-fw`
    const backgroundColor = workOrder.internalStatusColor
    return {
      appendedText,
      backgroundColor,
      prependIcon,
      text,
      id: workOrder.workOrderStatus?.id
    }
  }

  const internalStatusChipProps = ref<any>({
    state: '',
    text: '',
    prependIcon: '',
    appendedText: ''
  })

  const downloadSampleUploadExcel = async () => {
    try {
      const res = await repositories.workOrders.downloadSampleUploadExcel()
      return {
        data: res
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const workOrdersSearch = async (params: any) => {
    try {
      const res = await repositories.workOrders.workOrdersSearch(params)
      return {
        data: res.workOrders,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages,
        currentPage: res.meta.currentPage
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const downloadFilteredExcel = async () => {
    try {
      const res = await repositories.workOrders.downloadFilteredExcel()
      return {
        data: res
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getInternalWorkOrderStatuses = async (params: GetCustomStatusParams) => {
    try {
      const res = await repositories.workOrderStatuses.getCustomStatuses(params)
      return {
        data: res.workOrderStatuses,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
    }
  }

  const patch = async (workOrderId: number | string, include: string, params: UpdateWorkOrderParams) => {
    try {
      const response = await repositories.workOrders.patch(workOrderId, include, params)
      return response
    } catch (err) {
      return err
    }
  }

  const getReasonsIfNonCancellable = async (workOrderId: number | string) => {
    try {
      const response = await repositories.workOrders.getReasonsIfNonCancellable(workOrderId)
      return response
    } catch (err) {
      return err
    }
  }

  const activeCustomStatuses = async (params: GetWorkOrderParams) => {
    try {
      const response = await repositories.workOrders.activeCustomStatuses(params)
      return {
        data: response.customStatuses,
        totalCount: response.meta.totalCount,
        totalPages: response.meta.totalPages
      }
    } catch (err) {
      return err
    }
  }

  const getWorkOrderActivities = async (params: GetWorkOrderParams) => {
    try {
      const res = await repositories.workOrders.getWoActivities(params)
      return {
        data: res.workOrderActivities,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getWorkOrderNotes = async (params: GetWorkOrderParams) => {
    try {
      const res = await repositories.workOrders.getWorkOrderNotes(params)
      return {
        data: res.notes,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getWOPriorities = async (param: getPriorityParams) => {
    try {
      const res = await repositories.workOrders.getWOPriorities(param)
      return {
        data: res.priorities,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount

      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getProblemType = async (params: GetWorkOrderParams) => {
    try {
      const res = await repositories.workOrders.getProblemType(params)
      return {
        data: res.categories,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const deleteWorkOrderNote = async (params: GetWorkOrderParams) => {
    try {
      const res = await repositories.workOrders.deleteWorkOrderNote(params)
      return res
    } catch (err) {
      console.error(err)
      return err
    }
  }

  const getWorkOrderCompanies = async (params: GetWorkOrderParams): Promise<{ data: { name: string, id: number }[] | boolean, totalPages?: number, totalCount?: number }> => {
    try {
      const res = await repositories.workOrders.getWorkOrderCompanies(params) as { actorList: { name: string, id: number }[] }
      return {
        data: res.actorList,
        totalPages: 1,
        totalCount: res.actorList.length
      }
    } catch (err) {
      console.error(err)
      return {
        data: false
      }
    }
  }

  const patchWorkOrder = async (params: PatchWorkOrderParams) => {
    try {
      const res = await repositories.workOrders.patchWorkOrder(params)
      return {
        data: res.workOrder
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const updateTrip = async (workOrderId: number | string, tripId: number | string, params: TripPatchParams, include?: string, assigneeDetailsRequired?: boolean, includeCompliance?: boolean, includeCanApproveNonCompliantVendor?: boolean) => {
    try {
      const res = await repositories.workOrders.updateTrip(workOrderId, tripId, params, include, assigneeDetailsRequired, includeCompliance, includeCanApproveNonCompliantVendor)
      return {
        data: res.trip
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getWOTradesServices = async (params: GetWorkOrderParams) => {
    try {
      const res = await repositories.workOrders.getWOTradesServices(params)
      return {
        data: res.workOrderTrades,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getWOTrades = async (params: GetWorkOrderParams) => {
    try {
      const res = await repositories.workOrders.getWOTrades(params)
      return {
        data: res.workOrderTrades,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const transformWoExternalReferences = (workOrder: WorkOrder) => {
    const consideredProviders: NewExternalConnection[] = []
    let extCons = workOrder.externalConnections
    workOrder.trips?.forEach((trip: any) => extCons?.push(trip.externalConnections))
    extCons = extCons?.flat(Infinity)
    if (extCons?.length) {
      extCons.forEach((con) => {
        const conProvider = EXTERNAL_CONNECTIONS_PROVIDER_INFO_MAPPINGS.find((extCon) => extCon.provider === con.provider)
        if (conProvider) {
          if (!(consideredProviders.find((prov) => prov.provider === conProvider.provider))) {
            consideredProviders.push({
              provider: conProvider.provider,
              title: conProvider.title,
              prefix: conProvider.prefix,
              externalId: con.externalId
            })
          }
        }
      })
    }
    return consideredProviders
  }

  const createWorkOrderNote = async (workOrderId: number, params: FormData, include = '') => {
    try {
      const res = await repositories.workOrders.createWorkOrderNote(workOrderId, params, include)
      return res
    } catch (err) {
      console.error(err)
      return err
    }
  }

  const patchWoCustomStatus = async (workOrderId: number | string, include: string, params: UpdateWorkOrderParams, includeAttributesForCustomStatus: boolean) => {
    try {
      const response = await repositories.workOrders.patchWoCustomStatus(workOrderId, include, params, includeAttributesForCustomStatus)
      return response
    } catch (err) {
      return err
    }
  }

  const getRecentWorkOrdersForLocation = async (params: any) => {
    try {
      const res = await repositories.workOrders.getRecentWorkOrdersForLocation(params)
      return {
        data: res.workOrders,
        totalCount: res.meta?.totalCount,
        totalPages: res.meta?.totalPages
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const createWorkOrder = async (params: any) => {
    try {
      const res = await repositories.workOrders.createWorkOrder(params)
      return res
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const isTradeRequired = computed(() => {
    return settings?.value?.data?.workOrderSetting?.tradeAssociatedForWorkOrder
  })

  const isServiceRequired = computed(() => {
    return settings?.value?.data?.workOrderSetting?.serviceAssociatedForWorkOrder
  })

  const isTaskRequired = computed(() => {
    return settings?.value?.data?.workOrderSetting?.taskAssociatedForWorkOrder
  })

  const uploadBulkAttachment = async (workOrderId: number | string, params: FormData) => {
    try {
      const response = await repositories.workOrders.uploadBulkAttachment(workOrderId, params)
      return response
    } catch (err) {
      return err
    }
  }
  // #TODO: Remove this API & use updateTrip Api
  const addTripAttachment = async (workOrderId: number, tripId: number | string, params: FormData) => {
    try {
      const response = await repositories.workOrders.addTripAttachment(workOrderId, tripId, params)
      return response
    } catch (err) {
      return err
    }
  }

  const bulkCreatePurchaseOrderAssignments = async (workOrderId: string | number, params: PostPurchaseOrderAssignmentParams) => {
    try {
      const res = await repositories.workOrders.bulkCreatePurchaseOrderAssignments(workOrderId, params)
      return { data: res, success: true }
    } catch (err: any) {
      return { data: false }
    }
  }

  const patchWorkOrderForProposals = async (workOrderId: string | number, proposalIds: (string | number)[], type: string) => {
    try {
      const res = await repositories.workOrders.patchWorkOrderForProposals(workOrderId, proposalIds, type)
      return { data: res, success: true }
    } catch (err: any) {
      return { data: false }
    }
  }

  const getWorkOrderManager = async (params: GetWorkOrderParams) => {
    try {
      const res = await repositories.workOrders.getWorkOrderManager(params)
      return {
        data: res.users,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      return { data: false }
    }
  }

  const getWorkOrderDefaultTypes = async (params: DefaultTypesParam) => {
    try {
      const res = await repositories.workOrders.getWorkOrderDefaultTypes(params)
      return res
    } catch (err) {
      return { data: false }
    }
  }

  /**
 * Determines if a resource can be created based on the provided details and company type.
 *
 * @param {WorkOrder} [resourceDetails] - Optional details of the work order resource.
 * @param {boolean} [onlyBroker=true] - Flag indicating if only brokers are considered.
 * @returns {boolean} - Returns true if the resource can be created, false otherwise.
 */
  const canCreateResource = (resourceDetails?: WorkOrder | null, onlyBroker = true) => {
    // Do not allow creating any resource(Invoice | Proposal | PO) if top level selected resource is WorkOrder and it's cancelled
    if (selectedWorkOrderIsCanceled.value) return false

    // Get the work order ID if a top-level work order resource is selected
    const injectedWorkOrder = inject('filterResource', ref<WorkOrder | null>(null))

    // Get the current company type is a broker
    const isBrokerType = companyType.value === BROKER

    // If only brokers are considered
    if (onlyBroker) {
    // Check if a work order is selected and the current company's role is BROKER
    // or if no work order is selected and the company type is BROKER
      return (injectedWorkOrder.value?.id && resourceDetails?.currentCompanyRole === WorkOrderCompanyRole.BROKER) ||
           (!injectedWorkOrder.value?.id && isBrokerType)
    }

    // If brokers, contractors, or intermediary brokers are considered
    const isRelevantRole = resourceDetails
      ? [WorkOrderCompanyRole.BROKER, WorkOrderCompanyRole.CONTRACTOR, WorkOrderCompanyRole.INTERMEDIARY_BROKER].includes(resourceDetails.currentCompanyRole)
      : false

    const isClientType = companyType.value === CLIENT

    // Check if a work order is selected and the current company's role is relevant
    // or if no work order is selected and the company type is BROKER/VENDOR
    return (injectedWorkOrder.value?.id && isRelevantRole) || (!injectedWorkOrder.value?.id && !isClientType)
  }

  const selectedWorkOrderIsCanceled = computed(() => {
    const injectedWorkOrder = inject('filterResource', ref<WorkOrder | null>(null))
    // This will only return true if top level selected resource is WorkOrder and it's cancelled
    return injectedWorkOrder.value && injectedWorkOrder.value.automatedStatusName === AutomatedStatus.CANCELED
  })

  const getWorkOrdersCount = async (filterParams: any) => {
    try {
      const res = await repositories.workOrders.getWorkOrdersCount(filterParams)
      return {
        count: res.count
      }
    } catch (err) {
      console.log(err)
      return { count: 0 }
    }
  }

  const getWorkOrderRequiredActions = async (workOrderId: number) => {
    try {
      const res = await repositories.workOrders.getWorkOrderRequiredActions(workOrderId)
      return {
        data: res.requiredActions
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const workOrderAge = (createdDate: string) => {
    if (createdDate) {
      const date = moment(createdDate)
      return `${capitalize(moment.duration(moment().diff(date)).humanize().replace(/^an?\b/, '1'))}`
    } else {
      return 'N/A'
    }
  }

  const getWorkOrdersPhotos = async (woId: number, params: workOrderPhotosParams) => {
    try {
      const res = await repositories.workOrders.getWorkOrdersPhotos(woId, params)
      return {
        data: res.workOrdersPhotos,
        currentPage: res.meta?.currentPage,
        nextPage: res.meta?.nextPage,
        prevPage: res.meta?.prevPage,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getWorkOrdersPhotosCount = async (workOrderId: number) => {
    try {
      const res = await repositories.workOrders.getWorkOrdersPhotosCount(workOrderId)
      return {
        data: res.count
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getProfitLossDetail = async (param: any) => {
    try {
      let res = await repositories.workOrders.getProfitLossDetail(param)
      if (res?.profitLossDetail) {
        res = res.profitLossDetail.map((item: any, index:number) => {
          return {
            id: index,
            ...item
          }
        })
      }
      return {
        data: res,
        totalPages: 1,
        totalCount: 1
      } as any
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  function fieldMappingForAutocomplete (
    field: keyof AutocompleteMappings
  ): MappingObject[] {
    return statuses[field] || []
  }

  interface ResourceCount {
    activitieCount: number
    attachmentsCount: number
    notesCount: number
    contactsCount: number
  }

  const getWorkOrderResourcesCount = async (workOrderId: number): Promise<{ resourceCount: ResourceCount } | undefined> => {
    const resourceCount = await repositories.workOrders.getWorkOrderResourcesCount(workOrderId)
    return { resourceCount }
  }
  const marginPercentage = async (params: { ids: number[], scopeContext: string }) => {
    try {
      const res: { invoices: MarginPercentageResponse[] } = await repositories.workOrders.marginPercentage(params)
      return res
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getTrips = async (params: { workOrderId: number, perPage: number, excludeDefaultInclude?: boolean, sort?: string }) => {
    try {
      const res = await repositories.workOrders.getTripsById(params)
      return {
        data: res.trips
      }
    } catch (err) {
      return { data: false }
    }
  }

  const photoPreview = async (params: { ids: number[], scopeContext: string }) => {
    try {
      const res: { workOrders: AttachmentInfo[] } = await repositories.workOrders.photoPreview(params)
      return res
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const additionalDataDetails = [{ fetchFn: marginPercentage, responseKey: 'work_orders', dataKey: 'marginPercentage', fetchParams: { scopeContext: 'all' } }, { fetchFn: photoPreview, responseKey: 'workOrders', dataKey: 'attachmentInfo', fetchParams: { scopeContext: 'all' } }]

  return {
    getWorkOrders,
    getWorkOrderById,
    getInvoiceableWorkOrdersByClientId,
    getWorkOrderTripsById,
    getBillableWorkOrdersByVendorId,
    generateWorkOrderMenuActions,
    getWoTripReportsById,
    getWorkOrderDataForGeneration,
    getInternalWorkOrderStatuses,
    getWorkOrderStatus,
    getTripStatus,
    getClientStatus,
    getVendorStatus,
    WorkOrdersAutoComplete,
    workOrdersSearch,
    genWoInternalStatusChipProps,
    internalStatuses,
    internalStatusChipProps,
    downloadSampleUploadExcel,
    downloadFilteredExcel,
    getTableHeaders,
    getWorkOrderActions,
    patch,
    getReasonsIfNonCancellable,
    activeCustomStatuses,
    isMySiteResource,
    getWorkOrderActivities,
    getWorkOrderNotes,
    deleteWorkOrderNote,
    getWorkOrderCompanies,
    getWOPriorities,
    getProblemType,
    patchWorkOrder,
    getWOTradesServices,
    getWOTrades,
    transformWoExternalReferences,
    createWorkOrderNote,
    patchWoCustomStatus,
    updateTrip,
    getRecentWorkOrdersForLocation,
    createWorkOrder,
    isTradeRequired,
    isServiceRequired,
    isTaskRequired,
    renderBulkCloseWoModalWOs,
    bulkWoCloseEventDataWOs,
    renderBulkAddWoNotesModalWOs,
    bulkAddWoNotesEventDataWOs,
    renderBulkGenerateINSReportModalWOs,
    bulkWoGenerateINSEventDataWOs,
    bulkWoDeleteEventDataWOs,
    renderBulkDeleteWoModalWOs,
    renderBulkCancelWoModalWOs,
    bulkWoCancelEventDataWOs,
    renderBulkMarkWorkOrderReadyForBillingModalWOs,
    bulkMarkWorkOrderReadyForBillingEventDataWOs,
    uploadBulkAttachment,
    bulkCreatePurchaseOrderAssignments,
    patchWorkOrderForProposals,
    bulkWoStatusChangeEventDataWOs,
    renderBulkStatusChangeWoModalWOs,
    getWorkOrderManager,
    getWorkOrderDefaultTypes,
    canCreateResource,
    selectedWorkOrderIsCanceled,
    workOrdersTable,
    addTripAttachment,
    emptyBulkData,
    resolveLoading,
    wosBulkReportDownloadModal,
    bulkReportDownloadDataWOs,
    getAllHeaderActions,
    getWorkOrdersCount,
    workOrderAge,
    getWorkOrdersPhotos,
    getWorkOrdersPhotosCount,
    getProfitLossDetail,
    fieldMappingForAutocomplete,
    workOrderLimitedTab,
    showCheckInPhotosModal,
    checkInPhotoWOdetails,
    getWorkOrderRequiredActions,
    getWorkOrderResourcesCount,
    marginPercentage,
    additionalDataDetails,
    getTrips,
    getActionFilterHeaderActions,
    renderWosBatchInvoiceConfiguration,
    wosBatchInvoiceConfigurationData
  }
}

import { VDataIterator } from 'vuetify/lib/components/VDataIterator';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-flex flex-column overflow-hidden"
  }, [_vm.columns.headers && _vm.columns.headers.length && _vm.data.length && !_vm.hideHeader && _vm.size !== 'card' ? _c('uc-rows-headers', {
    staticClass: "mb-2",
    attrs: {
      "font-size": _vm.columns.fontSize,
      "border": _vm.columns.border,
      "headers": _vm.columns.headers,
      "size": _vm.size,
      "can-expand": _vm.columns.canExpand,
      "show-actions": _vm.columns.showActions,
      "can-select": _vm.columns.canSelect
    }
  }) : _vm._e(), _vm.columns.headers && _vm.columns.headers.length && _vm.data.length && !_vm.hideHeader && _vm.size === 'card' ? _c('uc-rows-card-headers', {
    staticClass: "mb-2",
    attrs: {
      "headers": _vm.columns.headers,
      "can-expand": _vm.columns.canExpand,
      "show-actions": _vm.columns.showActions,
      "can-select": _vm.columns.canSelect
    }
  }) : _vm._e(), _c(VDataIterator, {
    ref: "dataIteratorRef",
    class: `uc-rows fill-height ${!_vm.data.length ? 'overflow-hidden no-data' : 'overflow-auto'}`,
    attrs: {
      "items": _vm.data,
      "disable-filtering": true,
      "disable-pagination": true,
      "disable-sort": "",
      "hide-default-footer": ""
    },
    on: {
      "item-selected": function ($event) {
        return _vm.$emit('item-selected', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function () {
        return [_vm.totalPages == -1 ? _c('div', [_vm.size === 'card' ? _c('uc-list-row-card-loading') : _c('uc-list-row-loading', {
          attrs: {
            "first-row": true,
            "last-row": true,
            "size": _vm.size,
            "column-defs": _vm.columns
          }
        })], 1) : _vm.$slots['empty-state'] ? _vm._t("empty-state") : _c('div', {
          staticClass: "d-flex fill-width fill-height flex-row align-center justify-center"
        }, [_c('uc-rows-empty-state', {
          attrs: {
            "text": "No Data Found",
            "icon": _vm.GENERIC_EMPTY_STATE
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (resource) {
        return [_c('uc-list-row-resource', _vm._g(_vm._b({
          key: `${resource.item.id}`,
          ref: 'listRow' + resource.index,
          attrs: {
            "id": resource.item.id,
            "type": resource.item.type,
            "column-defs": _vm.columns,
            "item": resource.item,
            "size": _vm.size
          },
          on: {
            "toggle-select": function ($event) {
              resource.select(!resource.isSelected);
              _vm.emitSelectedResources();
            },
            "row-click": function ($event) {
              return _vm.$emit('row-click', {
                index: resource.index,
                id: resource.item.id,
                type: resource.item.type,
                details: resource.item
              });
            },
            "row-dblclick": function ($event) {
              return _vm.$emit('row-dblclick', {
                index: resource.index,
                type: resource.item.type,
                id: resource.item.id,
                details: resource.item
              });
            },
            "row:updated": function ($event) {
              return _vm.$emit('row:updated', $event);
            }
          }
        }, 'uc-list-row-resource', {
          ...resource.item.props,
          firstRow: resource.index === 0,
          lastRow: resource.index === _vm.data.length - 1 && !_vm.hasNextPage,
          isSelected: resource.isSelected,
          additionalData: _vm.additionalData[resource.item.id],
          backgroundColor: _vm.selectedId === resource.item.id ? 'layer-active' : 'layer'
        }, false), _vm.listenerObj))];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_vm.hasNextPage && _vm.size !== 'card' ? _c('div', [_c('uc-list-row-loading', {
          attrs: {
            "first-row": false,
            "last-row": false,
            "size": _vm.size,
            "column-defs": _vm.columns
          }
        }), _c('uc-list-row-loading', {
          attrs: {
            "first-row": false,
            "last-row": true,
            "size": _vm.size,
            "column-defs": _vm.columns
          }
        })], 1) : _vm._e(), _vm.hasNextPage && _vm.size === 'card' ? _c('uc-list-row-card-loading') : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.localSelectedResources,
      callback: function ($$v) {
        _vm.localSelectedResources = $$v;
      },
      expression: "localSelectedResources"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
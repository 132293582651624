export const ATTACHMENTS_HEADERS = () => {
  return {
    fontSize: 'lg',
    border: false,
    headers: [
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'content',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-12 v-col-sm-12 v-col-md-12 v-col-lg-12'
      }
    ]
  }
}

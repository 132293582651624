import { PluginObject } from 'vue'
import { HUBSPOT_APP_ID } from '@/constants/env'

interface HubspotChatPluginOptions {
  hubspotAppId?: string;
}

declare global {
  interface Window {
    HubSpotConversations: any
    hsConversationsSettings: any
    hsConversationsOnReady?: (() => void)[];
  }
}

const HubspotChatPlugin: PluginObject<HubspotChatPluginOptions> = {
  install (Vue, options) {
    const { hubspotAppId } = options || {}
    const appId = HUBSPOT_APP_ID || hubspotAppId

    if (!appId) {
      console.info('HubSpot App ID is required.')
      return
    }

    if (window !== window.top) {
      console.info('Hubspot Chat App can only be mounted on top window')
      return
    }

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = 'hs-script-loader' // ID is required
    script.async = true
    script.defer = true
    script.src = `https://js.hs-scripts.com/${appId}.js`
    document.head.appendChild(script)

    window.hsConversationsSettings = { loadImmediately: false, widgetOpened: false }

    let fnExecutionInProgress = false
    const onConversationsAPIReady = ():void => {
      if (window.HubSpotConversations && !fnExecutionInProgress) {
        fnExecutionInProgress = true
        window.HubSpotConversations.clear()
        window.HubSpotConversations.widget.load()
      }
    }

    if (window.HubSpotConversations) {
      onConversationsAPIReady()
    } else {
      window.hsConversationsOnReady = [onConversationsAPIReady]
    }

    Vue.prototype.$hubspot = {
      loadWidget: onConversationsAPIReady
    }
  }
}

export default HubspotChatPlugin

import { api } from '@/api'
import { WeatherEventPatchParam, WeatherWorkEventParams } from '@/use/weather-works'
import qs from 'qs'

export default {
  get: ({ include, q, page, perPage, withNonExistingClients }: WeatherWorkEventParams) => {
    return api.$get('/storm_detail_trips', {
      params: {
        include,
        q,
        page,
        per_page: perPage,
        with_non_existing_clients: withNonExistingClients
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  patch: (stormId: number|string, params: WeatherEventPatchParam) => {
    return api.$patch(`/storm_detail_trips/${stormId}`, params)
  },
  disassociate: (stormId: number|string, params: WeatherEventPatchParam) => {
    return api.$patch(`/storm_detail_trips/${stormId}/disassociate`, params)
  },
  createTripStormOverrides: (tripId: number, params: { totalAccumulation: number|string }) => {
    return api.$post(`/trips/${tripId}/storm_overrides`, params)
  },
  deleteTripStormOverrides: (stormId: number|string, tripId: number) => {
    return api.$delete(`/trips/${tripId}/storm_overrides/${stormId}`)
  }
}

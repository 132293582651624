const escapeRegExp = (search: string) => {
  // regex pattern that matches special characters and replaces them with their escaped versions
  return search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

const processMatches = (source: string, searchTerms: any[], bgModifier: any) => {
  for (const term of searchTerms) {
    const results = []
    const escapedTerm = escapeRegExp(term)
    const regexp = new RegExp(escapedTerm, 'ig')
    let start = 0

    for (const match of source.matchAll(regexp)) {
      const matchStart = match.index || 0

      if (start < matchStart) {
        results.push({
          text: source.substring(start, matchStart),
          match: false
        })
      }

      results.push({
        text: source.substring(matchStart, matchStart + term.length),
        match: true
      })

      start = matchStart + term.length
    }

    if (start < source.length) results.push({ text: source.substring(start), match: false })

    if (results.some(result => result.match)) {
      return results.map(result => {
        if (result.match) {
          return bgModifier
            ? `<span style="background-color: yellow">${result.text}</span>`
            : `<strong>${result.text}</strong>`
        }
        return result.text
      }).join('')
    }
  }
  return source
}

const updateContent = (el: any, bindings: any) => {
  const source = el.innerText
  const search = bindings.value

  const searchTerms = (Array.isArray(search) ? search : [search]).filter(item => item !== '')
  if (searchTerms.length === 0) return

  const bgModifier = bindings?.modifiers?.bg
  const output = processMatches(source, searchTerms, bgModifier)

  el.innerHTML = output
}
export default {
  bind: (el: any, bindings: any) => updateContent(el, bindings),
  componentUpdated: (el: any, bindings: { oldValue: any, value: any }) => {
    if (bindings.oldValue !== bindings.value) updateContent(el, bindings)
  }
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', _vm._g({
    ref: "tileRef",
    class: `uc-tile-container ${!_vm.noPadding && _vm.padding} ${_vm.noMargin && 'ma-0'}`,
    style: {
      backgroundColor: _vm.backgroundColor !== 'transparent' && `var(${_vm.backgroundColor})`,
      border: `1px solid var(${_vm.borderColor})`,
      boxShadow: !_vm.noElevation ? `0px 8px 16px 0px #4C5C7414` : ''
    }
  }, _vm.$listeners), [_vm._t("default", null, null, {
    transitioning: _vm.transitioning
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };
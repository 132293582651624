var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.type === 'headline' && _vm.size === 1 ? _c('h1', {
    class: _vm.classes,
    style: {
      ..._vm.styleObject,
      fontWeight: _vm.boldedFontWeight
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " "), _vm._t("default")], 2) : _vm.type === 'headline' && _vm.size === 2 ? _c('h2', {
    class: _vm.classes,
    style: {
      ..._vm.styleObject,
      fontWeight: _vm.boldedFontWeight
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " "), _vm._t("default")], 2) : _vm.type === 'headline' && _vm.size === 3 ? _c('h3', {
    class: _vm.classes,
    style: {
      ..._vm.styleObject,
      fontWeight: _vm.boldedFontWeight
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " "), _vm._t("default")], 2) : _c('span', {
    class: _vm.classes,
    style: {
      ..._vm.styleObject,
      fontWeight: _vm.boldedFontWeight
    },
    on: {
      "mouseenter": function ($event) {
        return _vm.$emit('mouseenter');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " "), _vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import { ref, computed } from 'vue'
import { Trip } from '@/types/interfaces'
import { TripResourceAction } from '@/constants/actions-by-resource/trips'
import { useCompanySettings } from '@/use/company-settings'
import { useSession } from '@/use/session'
import moment from 'moment'
import repositories from '@/repositories'

enum VerificationState {
  VALID = 'valid',
  EXCEPTION = 'exception'
}

export const useTripsVerification = (trip: Trip) => {
  const { settings } = useCompanySettings()
  const { session } = useSession()

  const serviceAdjustmentHours = trip.serviceAdjustmentHours || 9999

  const infiniteHours = [0, 9999].includes(serviceAdjustmentHours)

  const isCurrentUserExceptionManager = settings.value.data?.serviceAdjustmentSetting?.exceptionManagerIds.includes(session.loggedUser.id) || false

  const verificaionWindowMissed = !infiniteHours && (moment().diff(moment(trip.endDate), 'hours')) >= serviceAdjustmentHours

  const minutesSinceTripEnded = moment().diff(moment(trip.endDate), 'minutes') // get the difference between now and trip end date in minutes to get granularity we need for button

  const minutesLeftToVerifyTrip = ref((serviceAdjustmentHours * 60) - minutesSinceTripEnded) // convert serviceAdjustmentHours to minutes and subtract amount of minutes already elapsed

  const tripVerificationState = computed(() => {
    if (serviceAdjustmentHours === -1 && isCurrentUserExceptionManager) {
      return VerificationState.VALID
    } else if (serviceAdjustmentHours === -1 && !isCurrentUserExceptionManager) {
      return VerificationState.EXCEPTION
    } else if (infiniteHours) {
      return VerificationState.VALID
    } else if (minutesLeftToVerifyTrip.value > 0) {
      return VerificationState.VALID
    } else if (minutesLeftToVerifyTrip.value <= 0) {
      if (isCurrentUserExceptionManager) {
        return VerificationState.VALID
      } else {
        return VerificationState.EXCEPTION
      }
    } else {
      return VerificationState.VALID
    }
  })

  const showVerificationTimeLeft = (tripVerificationState.value === VerificationState.VALID) && !infiniteHours && (minutesLeftToVerifyTrip.value > 0)

  const updatedVerifyActionText = (verifyAction: TripResourceAction, showTripPosition?: boolean) => {
    minutesLeftToVerifyTrip.value ||= (serviceAdjustmentHours * 60) - minutesSinceTripEnded // convert serviceAdjustmentHours to minutes and subtract amount of minutes already elapsed // skip if this in case of interval is running

    if (tripVerificationState.value === VerificationState.EXCEPTION) {
      return { ...verifyAction, ...{ name: `Exception Note ${showTripPosition ? `Trip ${trip.position}` : ''}` } }
    } else if (showVerificationTimeLeft) {
      const remainingHours = Math.floor(minutesLeftToVerifyTrip.value / 60) // divide by 60 get convert minutes to hours then round down
      const remainingMinutes = minutesLeftToVerifyTrip.value % 60 // get remainder of 60 which gives minutes
      return { ...verifyAction, ...{ name: `Verify ${showTripPosition ? `Trip ${trip.position} -` : ''} ${remainingHours > 23 ? remainingHours : moment(remainingHours, 'HH').format('HH')}:${moment(remainingMinutes, 'mm').format('mm')}` } }
    } else {
      return verifyAction
    }
  }

  const verifyTrip = async (params?: { exceptionManager: boolean }) => {
    try {
      const response = await repositories.trips.verifyTrip(trip.workOrderId as number, trip.id as number, params)
      return { data: response }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const unverifyTrip = async (params?: { exceptionManager: boolean, unverifyServices: boolean }) => {
    try {
      const response = await repositories.trips.unverifyTrip(trip.workOrderId as number, trip.id as number, params)
      return { data: response }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  return {
    isCurrentUserExceptionManager,
    verificaionWindowMissed,
    serviceAdjustmentHours,
    minutesSinceTripEnded,
    minutesLeftToVerifyTrip,
    tripVerificationState,
    updatedVerifyActionText,
    VerificationState,
    verifyTrip,
    unverifyTrip
  }
}

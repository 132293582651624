import { EQUAL, BETWEEN, LESS_THAN, GREATER_THAN, DATE_BETWEEN, DATE_EQ, DATE_GT, DATE_LT, NOT_IN } from '@/constants/ransack'
import Vue, { ref, computed, Ref, provide, inject } from 'vue'
import { allFilters } from '@/constants/filters/index'

import { cloneDeep, kebabCase, flatten, map } from 'lodash'
import repositories from '@/repositories'
import { Filter, FiltersParams } from '@/types/interfaces'
import { generateConditional, getRansackKey } from '@/utils/filter-tools'
import { FilterResources } from '@/types/enums/filter-types'
import { baseGetResponse } from '@/utils/api'

const currentRansack = ref<{ g: { [key: number]: { c: any, m: string } } }>(
  { g: { 0: { c: {}, m: '' } } }
)
const filtersForTab = ref<[]>([])

export interface TabFilter {
  field: string,
  selected: any[],
  inject: any[],
  name: string,
  operator: string,
  chip: {
    resource: FilterResources | null,
    canSelect: boolean,
    pinned: boolean,
    isPrimary: boolean,
    text: string,
    popoverProps: any
  }
}

export interface AdvancedFilter {
  resource: { text: string, value: any },
  field: { text: string, value: any },
  comparator: { text: string, value: any },
  value: { text: string, value: any },
  values: Array<any>
}

export interface FilterTemplate extends Omit<baseGetResponse, 'totalCount' | 'data'> {
  data: Filter[]
  page: number
  currentPage: number
}

const MAX_SELECT_COUNT = 10000

// shared filters between different tab levels
const globalFilter: { [key: string]: any } = ref({ vendors: <any> [], clients: <any> [], sites: <any> [] })

const filterPanelRadioButtons = [
  {
    color: 'primary',
    value: EQUAL,
    label: 'Equals'
  },
  {
    type: 'date',
    color: 'primary',
    value: DATE_EQ,
    label: 'Equals'
  },
  {
    color: 'primary',
    value: GREATER_THAN,
    label: 'Greater Than'
  },
  {
    color: 'primary',
    value: LESS_THAN,
    label: 'Less Than'
  },
  {
    color: 'primary',
    value: BETWEEN,
    label: 'Between'
  },
  {
    type: 'date',
    color: 'primary',
    value: DATE_GT,
    label: 'Greater than'
  },
  {
    type: 'date',
    color: 'primary',
    value: DATE_LT,
    label: 'Less Than'
  },
  {
    type: 'date',
    color: 'primary',
    value: DATE_BETWEEN,
    label: 'Between'
  },
  {
    type: 'string',
    color: 'primary',
    value: EQUAL,
    label: 'Is'
  },
  {
    type: 'string',
    color: 'primary',
    value: NOT_IN,
    label: 'Is Not'
  },
  {
    type: 'number',
    color: 'primary',
    value: EQUAL,
    label: 'Equals'
  },
  {
    type: 'number',
    color: 'primary',
    value: GREATER_THAN,
    label: 'Greater Than'
  },
  {
    type: 'number',
    color: 'primary',
    value: LESS_THAN,
    label: 'Less Than'
  },
  {
    type: 'number-picker',
    color: 'primary',
    value: EQUAL,
    label: 'Equals'
  },
  {
    type: 'number-picker',
    color: 'primary',
    value: GREATER_THAN,
    label: 'Greater Than'
  },
  {
    type: 'number-picker',
    color: 'primary',
    value: LESS_THAN,
    label: 'Less Than'
  },
  {
    type: 'number-picker',
    color: 'primary',
    value: BETWEEN,
    label: 'Between'
  }
]

interface FilterByTabObject {
  [key: string | number]: {filters: any, sortBy: any, fieldMapping: any}
}

interface RansackableScopeFilters {
  [key: string]: {
    predicate: string
    values: string[]
  }[]
}

export const useFilters = (tabId: Ref<number> | null = null): any => {
  // init temp object so that we can init reactive variable with correct keys. you cannot add keys to reactive / ref object after instantiation

  const selectedFilterResourcesByTab = ref<FilterByTabObject>({})

  const initSelectedFilterResourcesByTab = (f: any) => {
    const keys = Object.keys(f)
    keys.forEach((key: string) => {
      if (f[key].filters?.length) {
        const chips: any[] = f[key].filters // get the chips for the scenario
        f[key].filters = {} // convert array to object
        chips.forEach((chip: any) => {
          const text = chip?.popoverProps?.headerText || chip.text

          // each filter will have an operator, selected values, an internal name (what it maps to in db), and the field we should use (id, text, etc)
          const operator = f[key].fieldMapping?.[kebabCase(text)]?.operator || 'eq'
          let defaultObj = { operator, selected: [], name: '', field: '', inject: [] }
          // for each chip there is a potential array of selected resources to filter by
          defaultObj = {
            ...defaultObj,
            ...f[key].fieldMapping?.[kebabCase(text)],
            chip
          }
          f[key].filters[kebabCase(text)] = cloneDeep(defaultObj)
        })
      } else {
        if (typeof f === 'object' && key in f) initSelectedFilterResourcesByTab(f[key]) // recurse until child of node is an array
      }
    })
    return f
  }
  if (tabId) {
    selectedFilterResourcesByTab.value = initSelectedFilterResourcesByTab(allFilters(tabId.value))
  }

  const getNewFilterObject = () => {
    if (!tabId) return
    selectedFilterResourcesByTab.value = initSelectedFilterResourcesByTab(allFilters(tabId.value))
  }

  const templateNames = computed(() => {
    return filterTemplates.value?.data?.map((template: any) => {
      return template.templateName
    }) || []
  })

  const actionFilters = computed(() => {
    if (filterTemplates.value.data.length) {
      return filterTemplates.value.data.map((filter: Filter) => filter.isAction)
    } else {
      return []
    }
  })

  const pinnedFilters = ref<any[]>([])
  const filterTemplates = ref<FilterTemplate>(
    { data: [], page: 1, totalPages: -1, currentPage: 1 }
  )
  /*
  ======== INITALIZING FILTER OBJECT AND ASSOCIATED VARIABLES ========
  */

  // TODO: OR and AND clauses, grouping, etc
  const createRansackFilterObject = (filterSelections: any) => {
    if (!filterSelections) return {}
    let keys:any = []
    if (filterSelections.filters) {
      keys = Object.keys(filterSelections.filters)
    }
    const groupings = { } as any
    const ransack = { } as any
    const ransackableScopeAttrs: RansackableScopeFilters = {}
    let index = 0
    keys.forEach((key: string) => {
      const filterObj = filterSelections.filters[key]
      if (filterObj.selected && filterObj.selected.length) {
        const condition = {
          a: {
            0: { name: '', ransacker_args: {} }
          },
          p: '',
          v: ''
        }
        let values = filterObj.selected
        if (Array.isArray(filterObj.selected) && (<any>Object).hasOwn(filterObj.selected[0], 'id')) {
          // added flatten for case when the value for the seleved filed has mutiple possible values
          // e.g. vendor status -> pending -> ['pending', 'vendor_rejected_invite']
          values = flatten(filterObj.selected.map((val: any) => {
            if (filterObj.field === 'id' || filterObj.field === undefined) {
              return val.id
            }
            let newVal = val.props[val.type][filterObj.field]
            if (typeof newVal === 'string' && filterObj.transform) {
              newVal = filterObj.transform(newVal)
            }
            return newVal
          }))
        }

        values = Array.isArray(values) ? values : [values]
        const ransackAttrKey = filterObj.ransackKey || filterObj.name || key
        if ([BETWEEN, DATE_BETWEEN].includes(filterObj.operator)) {
          ransack[index] = generateConditional({ ...condition }, [values[0]], filterObj.operator.includes('date') ? DATE_GT : GREATER_THAN, ransackAttrKey)
          index++
          ransack[index] = generateConditional({ ...condition }, [values[1]], filterObj.operator.includes('date') ? DATE_LT : LESS_THAN, ransackAttrKey)
        } else if (Array.isArray(ransackAttrKey)) {
          const groupRansack = {} as any
          (ransackAttrKey).forEach((keyName: string, subIndex: number) => {
            const condition = {
              a: {
                [subIndex]: { name: '', ransacker_args: {} }
              },
              p: '',
              v: ''
            }
            groupRansack[subIndex] = generateConditional({ ...condition }, values, filterObj.operator, keyName, subIndex)
          })
          groupings[Object.keys(groupings).length + 1] = { c: groupRansack, m: 'or' }
        } else {
          ransack[index] = generateConditional({ ...condition }, values, filterObj.operator, ransackAttrKey)
        }
        // add in addl ransack inject obj if it exists after all the ransack[index] is it.
        if (filterObj.inject && filterObj.inject.length) {
          filterObj.inject.forEach((obj: any) => {
            let values = obj.values || []
            if (!values.length && obj.field) {
              values = filterObj.selected.map((val:any) => {
                return val.props[val.type][obj.field]
              })
            }
            const appendedRansackObj = generateConditional({
              a: {
                0: { name: '', ransacker_args: {} }
              },
              p: '',
              v: ''
            }, values, obj.operator, obj.name)
            index += 1
            ransack[index] = { ...appendedRansackObj }
          })
        }

        // Including as params in ransack for attribute (s) supporting ransackable_scopes
        if (filterObj.ransackableScope || filterObj.chip.ransackableScope) {
          const predicate = getRansackKey(filterObj.operator, values)
          ransackableScopeAttrs[filterObj.name] = [{ predicate, values }]
        }
        index++
      }
    })

    Vue.set(groupings, 0, { c: ransack, m: 'and' })
    let sortBy = {}
    if (filterSelections.sortBy) {
      sortBy = `${filterSelections.sortBy.value} ${filterSelections.sortBy.direction.value}`
    }
    Vue.set(currentRansack.value, 'g', groupings)
    return {
      g: groupings,
      s: sortBy,
      ...ransackableScopeAttrs
    }
  }

  const currentFilterSelections: any = computed(() => {
    if (!tabId) return
    return selectedFilterResourcesByTab.value?.[tabId.value].filters
  })

  /*
  ======== FILTER TEMPLATE FUNCTIONS ========
  */

  const transformFilterObjectForDatabase = (name: string, tabId: number, filterObject: any = null, companySpecific?: boolean) => {
    // clonedeep to break the reference so the deletes below don't impact application
    const filtersObjectToSave = cloneDeep(filterObject || selectedFilterResourcesByTab.value[tabId])
    // deleting the filter config which are not selected
    for (const key in filtersObjectToSave.filters) {
      if (filtersObjectToSave.filters[key].selected.length === 0 && !filtersObjectToSave.filters[key].chip.pinned) {
        delete filtersObjectToSave.filters[key]
      }
    }
    // delete mappings and configurations
    // this is important incase we end up changing a mapping (field name changes, bug, etc)
    // we need to grab the latest
    // similarly delete sort by items so that if we add new items users will inherit it
    if (filtersObjectToSave.fieldMapping) delete filtersObjectToSave.fieldMapping
    if (filtersObjectToSave?.sortBy?.items) delete filtersObjectToSave.sortBy.items

    const template = {
      templateName: name,
      filterType: 'applied',
      path: tabId,
      filterConfig: filtersObjectToSave,
      query: {},
      filterConfigOrder: {},
      companySpecific
    }
    return template
  }

  const setFilterTemplate = (filterObject: Filter, tabId: number) => {
    // TODO - fix camelCase returned by API

    if (filterObject.filterConfig?.sortBy && selectedFilterResourcesByTab.value[tabId]) {
      selectedFilterResourcesByTab.value[tabId].sortBy.value = filterObject.filterConfig.sortBy.value
      selectedFilterResourcesByTab.value[tabId].sortBy.direction = filterObject.filterConfig.sortBy.direction
      if (!selectedFilterResourcesByTab.value[tabId].sortBy.value) {
        selectedFilterResourcesByTab.value[tabId].sortBy.value = selectedFilterResourcesByTab.value[tabId].sortBy.items[0].value
      }
    }

    if (!filterObject.filterConfig?.filters || !Object.keys(filterObject.filterConfig?.filters || {}).length) return

    // remove old selected filter if user have selected/changed Filter Template
    if (filterObject.templateName && !filterObject.isAction) {
      Object.keys(selectedFilterResourcesByTab.value[tabId].filters).forEach((key: string) => {
        selectedFilterResourcesByTab.value[tabId].filters[key].selected = []
      })
    }
    Object.keys(filterObject.filterConfig.filters).forEach((filterNameKey: string) => {
      const filterConfig = selectedFilterResourcesByTab.value[tabId].filters[kebabCase(filterNameKey)]
      selectedFilterResourcesByTab.value[tabId].filters[kebabCase(filterNameKey)] = {
        ...filterConfig,
        ...filterObject.filterConfig.filters[filterNameKey],
        // take chip from local since that has dynamic info like search key, fetch fn, etc that wont be saved in the db
        // use for pinned status from db
        chip: {
          ...filterConfig.chip,
          pinned: filterObject.isAction
            ? filterConfig.chip?.pinned
            : filterObject.filterConfig.filters[filterNameKey]?.chip?.pinned
        },
        ransackKey: filterConfig.ransackKey || filterConfig.name
      }
    })
  }

  /**
   * removeFilters can be used to remove an action filter or user defined templates
   */
  const removeFilters = (filter: Filter, tabId: number): void => {
    if (!tabId || !filter) return

    const filtersConfig = filter.filterConfig.filters
    if (!filtersConfig || !Object.keys(filtersConfig).length) return

    Object.keys(filtersConfig).forEach((key: string) => {
      const filterKey = kebabCase(key)
      const currentFilters = selectedFilterResourcesByTab.value[tabId].filters[filterKey]
      const filterObj = cloneDeep(currentFilters)
      if (filterObj) {
        const selectedIdsToRemove = new Set(map(filtersConfig[key].selected, 'id'))
        const selected = filterObj.selected.filter((item: any) => !selectedIdsToRemove.has(item.id))

        selectedFilterResourcesByTab.value[tabId].filters[filterKey] = {
          ...filterObj,
          selected
        }
      }
    })
  }

  /*
  ======== TEMPLATE API CALLS ========
  */

  const saveFilterTemplate = async (name: string, tab: number, filterObject: any, templateId: number | null = null, companySpecific: boolean) => {
    const template = transformFilterObjectForDatabase(name, tab, filterObject, companySpecific)
    if (templateId) {
      return await updateFilterTemplate(templateId as number, template)
    } else {
      return await createFilterTemplate(template)
    }
  }

  const saveDefaultFilter = async (name: string, tabId: number) => {
    const template = transformFilterObjectForDatabase(name, tabId)
    return await createOrUpdateDefaultFilter(template)
  }

  const getFilterTemplates = async (params: FiltersParams = {}) => {
    try {
      return await repositories.filterTemplates.getFilterTemplates(params)
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const getAndSetDefaultFilterByTab = async (tabId: number) => {
    try {
      const defaultFilter: { filter: Filter } = await repositories.filterTemplates.getAndSetDefaultFilterByTab(String(tabId))
      setFilterTemplate(defaultFilter.filter, tabId)
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const createFilterTemplate = async (template: any, params: FiltersParams = {}) => {
    try {
      return await repositories.filterTemplates.createFilterTemplate(template, params)
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const createOrUpdateDefaultFilter = async (template: any, params: FiltersParams = {}) => {
    try {
      return await repositories.filterTemplates.createDefaultFilter(template, params)
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const updateFilterTemplate = async (templateId: number, template: any, params: FiltersParams = {}) => {
    try {
      return await repositories.filterTemplates.updateFilterTemplate(templateId, template, params)
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const deleteFilterTemplate = async (templateId: number, params: FiltersParams = {}) => {
    try {
      return await repositories.filterTemplates.deleteFilterTemplate(templateId, params)
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const loadFilterTemplates = async () => {
    if (!tabId) return
    const { filters, meta } = await getFilterTemplates({ q: { path_eq: tabId.value, s: 'template_name asc' }, page: filterTemplates.value.page, perPage: 10 })
    filterTemplates.value.data.push(...filters)
    filterTemplates.value.totalPages = meta.totalPages
    filterTemplates.value.page++
    filterTemplates.value.currentPage = meta.currentPage
  }

  const resetFilterTemplates = () => {
    filterTemplates.value.data = []
    filterTemplates.value.totalPages = -1
    filterTemplates.value.page = 1
    filterTemplates.value.currentPage = 1
  }

  const getActionFilters = async (params: FiltersParams = {}) => {
    return getFilterTemplates({ ...params, actionFilter: true })
  }

  const filtersLoaded = ref(false)

  const provideFiltersLoaded = () => provide('filtersLoaded', filtersLoaded)

  const injectFiltersLoaded = (defaultValue = false) => {
    const filtersLoaded = inject('filtersLoaded', { value: defaultValue }) as Ref<boolean>

    return { filtersLoaded }
  }

  return {
    filterPanelRadioButtons,
    currentFilterSelections,
    createRansackFilterObject,
    selectedFilterResourcesByTab,
    currentRansack,
    getRansackKey,
    generateConditional,
    globalFilter,
    saveFilterTemplate,
    setFilterTemplate,
    MAX_SELECT_COUNT,
    getFilterTemplates,
    createFilterTemplate,
    updateFilterTemplate,
    deleteFilterTemplate,
    filterTemplates,
    loadFilterTemplates,
    resetFilterTemplates,
    filtersForTab,
    pinnedFilters,
    createOrUpdateDefaultFilter,
    getAndSetDefaultFilterByTab,
    saveDefaultFilter,
    templateNames,
    filtersLoaded,
    getNewFilterObject,
    provideFiltersLoaded,
    injectFiltersLoaded,
    actionFilters,
    getActionFilters,
    removeFilters
  }
}

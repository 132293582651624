import { defineComponent, nextTick, onMounted, ref, watch } from 'vue';
import { formatNumber } from '@/utils/formatting';
import { DRAWER_CLOSED, DRAWER_OPENED } from '@/constants/icons';
export default defineComponent({
  props: {
    max: {
      type: Number,
      default: () => Number.MAX_VALUE
    },
    min: {
      type: Number,
      default: () => Number.MIN_VALUE
    },
    decimal: {
      type: Number,
      default: null
    },
    value: {
      type: [String, Number],
      required: true
    },
    showControls: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tabIndex: {
      type: [String, Number],
      default: null
    },
    readModeEmptyState: {
      type: String,
      default: null
    },
    defaultBlank: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, {
    emit,
    attrs
  }) => {
    // BEFORE CHANGES PLEASE TEST RECEIVE PAYMENTS AND EDIT INVOICE. OR ANYTHING ELSE THAT USES THIS.
    // those flows have a lot of logic so please ask someone for help testing if you're not very familiar with all the functionality :)
    const curInputId = ref('');
    const localValue = ref(props.value);
    const localTabIndex = ref(props.tabIndex);
    onMounted(() => {
      var _numberInputRef$value;
      curInputId.value = ((_numberInputRef$value = numberInputRef.value.$el.querySelector('input')) === null || _numberInputRef$value === void 0 ? void 0 : _numberInputRef$value.getAttribute('id')) || '';
      init(localValue.value);
    });
    const formatNumbers = (value, increment = 0, direction = 0) => {
      var _numberInputRef$value2;
      if (attrs.disabled) return;
      if (props.disabled || props.readonly) return;
      const val = (numberInputRef === null || numberInputRef === void 0 ? void 0 : (_numberInputRef$value2 = numberInputRef.value.$el.querySelector('input')) === null || _numberInputRef$value2 === void 0 ? void 0 : _numberInputRef$value2.value) || attrs.value || (attrs.value === 0 ? '0' : false) || (props.defaultBlank ? '' : 0);
      const emitPrice = formatNumber(Math.max(Math.min(props.max, parseFloat(val) + direction * increment), props.min), {
        decimals: props.decimal
      });
      localValue.value = convertToFloat(emitPrice, props.decimal);
      nextTick(() => {
        emit('input', convertToFloat(emitPrice, props.decimal));
      });
    };
    const numberInputRef = ref(null);
    const init = val => {
      const emitPrice = formatNumber(Math.max(Math.min(props.max, parseFloat(val)), props.min), {
        decimals: props.decimal
      });
      localValue.value = convertToFloat(emitPrice, props.decimal);
      nextTick(() => {
        emit('input', convertToFloat(emitPrice, props.decimal));
      });
    };
    const convertToFloat = (value, decimal) => {
      return parseFloat(value).toFixed(decimal);
    };
    watch(() => props.value, () => {
      localValue.value = props.value || (props.value === 0 ? '0' : false) || (props.defaultBlank ? '' : 0);
      init(localValue.value);
    });
    watch(() => props.tabIndex, () => {
      localTabIndex.value = props.tabIndex;
    });
    // // when we force load/change numberInputRef.value.value - we need to call formatNumbers and emit up the new value in decimal formatting
    // watch(() => numberInputRef?.value?.value, () => {
    //   if (numberInputRef.value && numberInputRef.value?.value) {
    //     formatNumbers(numberInputRef.value?.value)
    //   }
    // })
    return {
      formatNumbers,
      numberInputRef,
      formatNumber,
      DRAWER_CLOSED,
      DRAWER_OPENED,
      localValue,
      localTabIndex
    };
  }
});
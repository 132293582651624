export const tripsSorting = {
  value: 'created_at',
  direction: {
    text: 'Descending',
    value: 'desc'
  },
  items: [
    {
      label: 'Creation Date',
      value: 'created_at'
    },
    {
      label: 'Status',
      value: 'extended_status'
    },
    {
      label: 'Expiration Date',
      value: 'expiration_date'
    },
    {
      label: 'Work Order #',
      value: 'work_order_id'
    }
  ]
}

import { api } from '@/api'
import qs from 'qs'

export default {
  bulkUpdateTaxExempt: (params: { clientId: number|string }) => {
    const { clientId } = params
    return api.$patch(`/clients/${clientId}/locations/bulk_update_tax_exempt`, {
      params: {},
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  }
}

import { INFO_CIRCLE } from '@/constants/icons'
export const TRIP_INVOICE_GENERATION_HEADERS = (showNTE = true, showWoId = false) => {
  return {
    fontSize: 'lg',
    border: false,
    headers: [
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'id',
        resourceField: 'estimate_number',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-8 v-col-sm-8 v-col-md-4 v-col-lg-2'
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'description',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only hidden-sm-only hidden-md-only v-col-lg-1'
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'work-order-id',
        resourceField: 'estimate_number',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'center'
        },
        visibility: 'v-col-4 v-col-sm-4 v-col-md-2 v-col-lg-1'
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'billing-method',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only hidden-sm-only hidden-md-only v-col-lg-1'
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'nte',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only hidden-sm-only v-col-md-2 v-col-lg-2'
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'eta',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only hidden-sm-only v-col-md-2 v-col-lg-2'
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'asignee',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only hidden-sm-only v-col-md-1 v-col-lg-1'
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'check-in',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only hidden-sm-only v-col-md-1 v-col-lg-2'
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'photos',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only hidden-sm-only v-col-md-1 v-col-lg-1'
      }
    ].filter((column) => {
      if (['nte', 'work-order-id'].includes(column.templateName)) {
        if (column.templateName === 'nte' && showNTE) {
          return true
        } else if (column.templateName === 'work-order-id' && showWoId) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    })
  }
}

export const TRIPS_TABLE_HEADERS = (canSelect: boolean, isWithinWorkOrder = false) => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect,
    showActions: true,
    canExpand: true,
    headers: [
      {
        label: 'Trip Status | Description',
        hideTextOnMobile: false,
        templateName: 'id',
        resourceField: 'estimate_number',
        prependIcon: '',
        appendIcon: '',
        row: 1,
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: isWithinWorkOrder ? 'v-col-2 v-col-sm-2 v-col-md-2 v-col-lg-2' : 'v-col-2 v-col-sm-2 v-col-md-2 v-col-lg-2'
      },
      {
        label: 'For',
        hideTextOnMobile: false,
        templateName: 'for-details',
        resourceField: 'company_id',
        prependIcon: '',
        appendIcon: '',
        row: 3,
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: isWithinWorkOrder ? 'hidden-xs-only hidden-sm-only hidden-lg-only hidden-md-only' : 'v-col-sm-2 v-col-md-1 v-col-lg-2'
      },
      {
        label: 'Assignee',
        hideTextOnMobile: false,
        templateName: 'assignee',
        resourceField: 'estimate_number',
        prependIcon: '',
        appendIcon: '',
        row: 2,
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: isWithinWorkOrder ? 'v-col-1 v-col-sm-1 v-col-md-2 v-col-lg-1' : 'v-col-1 v-col-sm-1 v-col-md-1 v-col-lg-1'
      },
      {
        label: 'Services | Tasks',
        hideTextOnMobile: false,
        templateName: 'services-tasks',
        resourceField: 'estimate_number',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: isWithinWorkOrder ? 'hidden-xs-only hidden-sm-only hidden-lg-only hidden-md-only' : 'v-col-1 v-col-sm-1 v-col-md-1 v-col-lg-1'
      },
      {
        label: 'Scheduled & Actual',
        hideTextOnMobile: false,
        templateName: 'scheduled-actual',
        resourceField: 'estimate_number',
        prependIcon: '',
        appendIcon: INFO_CIRCLE,
        appendIconTooltip: "<div class='trpHdrTltpCntnt'><span style='font-weight: bolder;'>Open</span><span style='display: block;'>This is the time and date that represents the start of the service window, and reflects the moment the Work Order trip will be available for reporting via the mobile application.</span><span style='font-weight: bolder;'>Estimated Service Start Date</span><span style='display: block;'>The estimated service start time is the expected start time an assignee shares through a mobile app, indicating when a particular service or task should begin.</span><span style='font-weight: bolder;'>Latest ETA</span><span style='display: block;'>This is the time and date that we expect the Work Order to have begun (Check In). If work has not begun by this time and date the Work Order trip status will turn to late (Red Pin). Please contact the assignee to ensure checkin of the Work Order prior to its close date and time.</span><span style='font-weight: bolder;'>Latest ETC</span><span style='display: block;'>This is the time and date that we estimated the Work Order to have been complete (processed via app) If work has not been complete by this time and date the Work Order trip status will remain late (Red Pin). Please contact the assignee to ensure check out of the Work Order trip prior to its close date and time.</span><span style='font-weight: bolder;'>Close</span><span style='display: block;'>At this time and date the Work Order will no longer be available in the mobile device.</span></div>",
        appendIconTooltipPlacement: 'bottom',
        row: 3,
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: isWithinWorkOrder ? 'v-col-md-3 v-col-lg-2' : 'v-col-md-2 v-col-lg-2'
      },
      {
        label: 'Trip Attributes & Data',
        hideTextOnMobile: false,
        templateName: 'attributes-data',
        resourceField: 'estimate_number',
        prependIcon: '',
        appendIcon: '',
        row: 2,
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: isWithinWorkOrder ? 'hidden-xs-only hidden-sm-only v-col-md-auto v-col-lg-auto' : 'hidden-xs-only hidden-sm-only v-col-md-auto v-col-lg-auto'
      }
    ]
  }
}

export const TRIPS_ASSIGNEE_TABLE_HEADERS = () => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect: true,
    showActions: false,
    canExpand: false,
    headers: [
      {
        label: 'Assignee',
        hideTextOnMobile: false,
        templateName: 'assignee',
        resourceField: 'estimate_number',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: ''
      },
      {
        label: 'Services | Tasks',
        hideTextOnMobile: false,
        templateName: 'services-tasks',
        resourceField: 'estimate_number',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: ''
      },
      {
        label: 'Scheduled & Actual',
        hideTextOnMobile: false,
        templateName: 'scheduled-actual',
        resourceField: 'estimate_number',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: ''
      }
    ]
  }
}

export const TRIP_ADJUSTMENT_HISTORY_HEADERS = () => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect: false,
    showActions: true,
    canExpand: true,
    headers: [
      {
        label: 'Adjusted By',
        hideTextOnMobile: false,
        templateName: 'adjusted-by',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-2 v-col-sm-2 v-col-md-2 v-col-lg-2'
      },
      {
        label: 'Adjustment Date',
        hideTextOnMobile: false,
        templateName: 'adjustment-date',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-3 v-col-sm-3 v-col-md-3 v-col-lg-3'
      },
      {
        label: 'Change Reason',
        hideTextOnMobile: false,
        templateName: 'change-reason',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-3 v-col-sm-3 v-col-md-3 v-col-lg-3'
      },
      {
        label: 'Hours Description',
        hideTextOnMobile: false,
        templateName: 'hours-description',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-2 v-col-sm-2 v-col-md-2 v-col-lg-2'
      }
    ]
  }
}

export const TIMECARD_ARCHIVE_HEADERS = () => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect: false,
    showActions: false,
    canExpand: true,
    headers: [
      {
        label: 'Name',
        hideTextOnMobile: false,
        templateName: 'name',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        row: 1,
        styleObj: {
          align: 'center',
          justify: 'start'
        },
        visibility: 'v-col-3 v-col-sm-3 v-col-md-3 v-col-lg-3'
      },
      {
        label: 'Schedule',
        hideTextOnMobile: false,
        templateName: 'schedule',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        row: 2,
        styleObj: {
          align: 'center',
          justify: 'start'
        },
        visibility: 'v-col-4 v-col-sm-4 v-col-md-4 v-col-lg-4'
      },
      {
        label: 'Archived',
        hideTextOnMobile: false,
        templateName: 'archived',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        row: 2,
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-4 v-col-sm-4 v-col-md-4 v-col-lg-4'
      }
    ]
  }
}

export const TIMECARD_ARCHIVE_SERVICE_HEADERS = () => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect: false,
    showActions: false,
    canExpand: false,
    headers: [
      {
        label: 'Position',
        hideTextOnMobile: false,
        templateName: 'position',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        row: 2,
        styleObj: {
          align: 'center',
          justify: 'start'
        },
        visibility: 'v-col-1 v-col-sm-1 v-col-md-2 v-col-lg-2'
      },
      {
        label: 'Schedule',
        hideTextOnMobile: false,
        templateName: 'schedule',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        row: 2,
        styleObj: {
          align: 'center',
          justify: 'start'
        },
        visibility: 'v-col-4 v-col-sm-4 v-col-md-4 v-col-lg-4'
      },
      {
        label: 'Service Task',
        hideTextOnMobile: false,
        templateName: 'service-task',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        row: 3,
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-4 v-col-sm-4 v-col-md-4 v-col-lg-4'
      },
      {
        label: 'Adjustments',
        hideTextOnMobile: false,
        templateName: 'adjustments',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        row: 2,
        styleObj: {
          align: 'end',
          justify: 'end'
        },
        visibility: 'v-col-1 v-col-sm-1 v-col-md-1 v-col-lg-1'
      }
    ]
  }
}

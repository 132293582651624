import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    buttonTitle: {
      type: String,
      default: null
    }
  }
});
import { BatchInvoice } from '@/types/interfaces'
import { DATE_EQ, DATE_GT, DATE_LT } from '@/constants/ransack'
import { cloneDeep } from 'lodash'

export interface baseGetResponse {
  data: Record<any, any>[],
  totalPages: number,
  totalCount: number
}

export interface baseGetParams {
  page?: number,
  perPage?: number,
  q?: Record<string, string | number>,
  include?: string,
  scope?: string,
  withoutCount?: boolean
}

export interface InvoiceParams extends baseGetParams {
  objectScope?: 'both' | 'single' | 'multiple'
  includeGrouped?: boolean
  workOrderId?: number,
  includeMarginPercentage?: boolean
  includeVerified?: boolean
}

export interface BatchInvoiceUpdateParams {
  invoiceId: string,
  include?: string,
  updateContent: BatchInvoice,
  objectScope?: 'both' | 'single' | 'multiple'
}

export interface getBillPaymentsParams extends baseGetParams {
  scope?: 'without_deleted' | 'with_deleted' | 'only_deleted'
}

export interface getBillableTradeServices extends baseGetParams {
  scope?: 'without_deleted' | 'with_deleted' | 'only_deleted'
}

export interface getCallTypes extends baseGetParams {
  scope?: 'without_deleted' | 'with_deleted' | 'only_deleted'
}

export interface getListByParentIdParams extends baseGetParams {
  parentId: number | string,
}

export interface getResourceByIdWithParentIdParams extends getListByParentIdParams {
  id: number | string
}

export interface getCompaniesParams extends baseGetParams {
  skipExistingClients?: boolean,
  skipExistingVendors?: boolean,
  skipUserCompanies?: boolean
}

export interface getCompanyClassificationsParams extends baseGetParams {
  packageId: number
}

export interface getAppVersionsParams {
  after?: string
  platform?: 'ios' | 'android'
  appId?: string
}

export interface getCompanyByToken extends baseGetParams {
  token: string
}

interface FieldMap {
  queryField: string | RegExp, // field from ransack query ex: q[created_at_date_eq]
  replaceWith?: string, // optional parent field ex: q_trips
  key: string // key to use while generating endpoint query prameter ex: created_at_date_eq
  valueIndex?: number
  noPredicate?: boolean
}

// helper method to move ransack query params to api query parameter
export const transformRansackToEndpointQuery = (
  query: any,
  fieldMap: FieldMap[],
  deleteFromQuery = true
) => {
  const transformedParams: any = {}
  if (!query) return transformedParams
  fieldMap.forEach((item: any) => {
    const matchedParams = Object.keys(query).filter((key: any) => key.match(item.queryField)) || ''
    matchedParams.forEach((matchedParam: string) => {
      const keyWithPredicate = `${item.key + (item.noPredicate ? '' : matchedParam?.match(item.queryField)?.at(1) || '')}`
      if (item?.replaceWith) {
        if (!transformedParams[item.replaceWith]) transformedParams[item.replaceWith] = {}
        transformedParams[item.replaceWith][keyWithPredicate] = translateValue(
          cloneDeep(query), matchedParam
        )
        if (deleteFromQuery) delete query[matchedParam || item.queryField]
      } else {
        transformedParams[keyWithPredicate] = translateValue(
          cloneDeep(query), matchedParam
        )
        if (deleteFromQuery) delete query[matchedParam]
      }

      if (Array.isArray(transformedParams[keyWithPredicate]) && item?.valueIndex >= 0) {
        transformedParams[keyWithPredicate] = transformedParams[keyWithPredicate][item.valueIndex]
      }
    })
  })

  return transformedParams
}

const translateValue = (query: any, param: string): string => {
  const suffixes = [DATE_EQ, DATE_GT, DATE_LT]
  const endsWithAny = suffixes.some((suffix: string) => param.endsWith(suffix))
  if (endsWithAny && Array.isArray(query[param])) {
    return query[param].shift()
  } else {
    return query[param]
  }
}

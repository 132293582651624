import { useApp } from '@/use/app'
import { BROKER } from '@/constants/permissions'
import { useUsersCompanies } from '@/use/users-companies'

export const WORK_ORDER_GENERATE_HEADERS = (showNTE = true) => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect: true,
    showActions: true,
    canExpand: true,
    headers: [
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'id',
        resourceField: 'estimate_number',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-4 v-col-sm-4 v-col-md-2 v-col-lg-2'
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'priority',
        resourceField: 'priority',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only hidden-sm-only v-col-md-2 v-col-lg-2'
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'description',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only hidden-sm-only v-col-md-2 v-col-lg-2'
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'proposal-po-details',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'center'
        },
        visibility: 'hidden-xs-only hidden-sm-only v-col-md-1 v-col-lg-1'
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'nte',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only hidden-sm-only v-col-md-1 v-col-lg-1'
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'age',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only hidden-sm-only hidden-md-only v-col-lg-2'
      }
    ].filter((column) => showNTE || column.templateName !== 'nte')
  }
}

export const CONTACTS_HEADERS = () => {
  return {
    fontSize: 'lg',
    border: false,
    canExpand: false,
    showActions: false,
    headers: [
      {
        label: 'Contact',
        hideTextOnMobile: true,
        templateName: 'profile',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'start',
          justify: 'start'
        },
        visibility: 'v-col-10 v-col-sm-10 v-col-md-10 v-col-lg-10'
      }
    ]
  }
}

export const WORK_ORDER_HEADERS = () => {
  const { companyType } = useApp()
  const { isPaidCompany } = useUsersCompanies()
  const isVisible = (companyType.value === BROKER && isPaidCompany.value)
  return {
    fontSize: 'lg',
    border: false,
    canSelect: true,
    showActions: true,
    canExpand: false,
    headers: [
      {
        label: 'WO #',
        hideTextOnMobile: false,
        templateName: 'id',
        resourceField: 'estimate_number',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only v-col-1 v-col-sm-1 v-col-md-1 v-col-lg-1'
      },
      {
        label: 'For',
        hideTextOnMobile: false,
        templateName: 'for-details',
        resourceField: 'company_id',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only v-col-sm-2 v-col-md-1 v-col-lg-1'
      },
      {
        label: 'Trips',
        hideTextOnMobile: false,
        templateName: 'trips',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'center'
        },
        visibility: 'hidden-xs-only v-col-sm-1 v-col-md-1 v-col-lg-1'
      },
      {
        label: 'Contacts',
        hideTextOnMobile: false,
        templateName: 'contacts',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only hidden-sm-only v-col-md-2 v-col-lg-1'
      },
      {
        label: 'Site',
        hideTextOnMobile: false,
        templateName: 'location',
        resourceField: 'location_id',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only v-col-sm-1 v-col-md-1 v-col-lg-1'
      },
      {
        label: 'Services | Description',
        hideTextOnMobile: false,
        templateName: 'description',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        row: 3,
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only hidden-sm-only v-col-md-1 v-col-lg-1 overflow-hidden'
      },
      {
        label: 'Priority & Schedule',
        hideTextOnMobile: false,
        templateName: 'schedule',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only hidden-sm-only v-col-md-1 v-col-lg-1'
      },
      {
        label: 'Follow Up',
        hideTextOnMobile: false,
        templateName: 'follow_up',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only hidden-sm-only v-col-md-1 v-col-lg-1'
      },
      {
        label: 'Client',
        hideTextOnMobile: false,
        templateName: 'client_status',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only hidden-sm-only v-col-md-1 v-col-lg-1'
      },
      {
        label: 'Vendor',
        hideTextOnMobile: false,
        templateName: 'vendor_status',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only hidden-sm-only v-col-md-1 v-col-lg-1'
      },
      {
        label: (isVisible ? 'Margin' : ''),
        hideTextOnMobile: false,
        templateName: (isVisible ? 'margin' : ''),
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: (isVisible ? 'hidden-xs-only hidden-sm-only v-col-md-1 v-col-lg-1' : 'hidden-xs-only hidden-sm-only hidden-md-only hidden-lg-only')
      },
      {
        label: 'Age',
        hideTextOnMobile: false,
        templateName: 'age',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'center'
        },
        visibility: 'hidden-xs-only hidden-sm-only v-col-md-1 v-col-lg-1'
      },
      {
        label: 'Status | Notes',
        hideTextOnMobile: false,
        templateName: 'statuses',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only v-col-sm-2 v-col-md-auto v-col-lg-auto'
      },
      {
        label: 'WO #',
        hideTextOnMobile: false,
        templateName: 'id',
        resourceField: 'estimate_number',
        prependIcon: '',
        appendIcon: '',
        row: 1,
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-sm-only hidden-md-only hidden-lg-only hidden-xl-only'
      },
      {
        label: 'Priority',
        hideTextOnMobile: false,
        templateName: 'priority',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        row: 1,
        styleObj: {
          align: 'start',
          justify: 'end'
        },
        visibility: 'hidden-sm-only hidden-md-only hidden-lg-only hidden-xl-only'
      },
      {
        label: 'Trips',
        hideTextOnMobile: false,
        templateName: 'trips-card',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        row: 2,
        styleObj: {
          align: 'end',
          justify: 'center'
        },
        visibility: 'hidden-sm-only hidden-md-only hidden-lg-only hidden-xl-only'
      },
      {
        label: 'Age',
        hideTextOnMobile: false,
        templateName: 'age-card',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        row: 2,
        styleObj: {
          align: 'end',
          justify: 'center'
        },
        visibility: 'hidden-sm-only hidden-md-only hidden-lg-only hidden-xl-only'
      },
      {
        label: 'Client',
        hideTextOnMobile: false,
        templateName: 'client_status',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        row: 2,
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-sm-only hidden-md-only hidden-lg-only hidden-xl-only'
      },
      {
        label: 'Vendor',
        hideTextOnMobile: false,
        templateName: 'vendor_status',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        row: 2,
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-sm-only hidden-md-only hidden-lg-only hidden-xl-only'
      },
      {
        label: 'Status | Notes',
        hideTextOnMobile: false,
        templateName: 'statuses-card',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        row: 2,
        styleObj: {
          align: 'center',
          justify: 'end'
        },
        visibility: 'hidden-sm-only hidden-md-only hidden-lg-only hidden-xl-only'
      },
      {
        label: 'For',
        hideTextOnMobile: false,
        templateName: 'for-details',
        resourceField: 'company_id',
        prependIcon: '',
        appendIcon: '',
        row: 3,
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-sm-only hidden-md-only hidden-lg-only hidden-xl-only'
      }
    ]
  }
}
export const WORK_ORDER_INVOICE_HEADERS = () => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect: false,
    showActions: false,
    canExpand: true,
    headers: [
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'id',
        resourceField: 'estimate_number',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-5 v-col-sm-5 v-col-md-5 v-col-lg-5'
      },
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'billing-method',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-5 v-col-sm-5 v-col-md-5 v-col-lg-5'
      }
    ]
  }
}

export const WORK_ORDER_NOTES = () => {
  return {
    fontSize: 'lg',
    border: false,
    headers: [
      {
        label: '',
        hideTextOnMobile: false,
        templateName: 'notes',
        resourceField: 'notes',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: ''
      }
    ]
  }
}

export const RECENT_WORK_ORDER_HEADERS = () => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect: false,
    canExpand: false,
    showActions: false,
    headers: [
      {
        label: 'WO# and Name',
        hideTextOnMobile: false,
        templateName: 'wo_and_name',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-2 v-col-sm-2 v-col-md-2 v-col-lg-2'
      },
      {
        label: 'Service and Trade',
        hideTextOnMobile: false,
        templateName: 'service_and_trade',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-4 v-col-sm-4 v-col-md-4 v-col-lg-4'
      },
      {
        label: 'Type',
        hideTextOnMobile: false,
        templateName: 'type',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only v-col-sm-2 v-col-md-2 v-col-lg-2'
      },
      {
        label: 'Creation',
        hideTextOnMobile: false,
        templateName: 'creation',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only v-col-sm-2 v-col-md-2 v-col-lg-2'
      },
      {
        label: 'Description',
        hideTextOnMobile: false,
        templateName: 'description',
        resourceField: '',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only v-col-sm-2 v-col-md-2 v-col-lg-2'
      }
    ]
  }
}

export const WORK_ORDER_MARGIN_LIST_HEADERS = () => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect: false,
    canExpand: false,
    showActions: false,
    headers: [
      {
        label: 'Item',
        hideTextOnMobile: false,
        templateName: 'margin_name',
        resourceField: 'margin_name',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-5 v-col-sm-5 v-col-md-5 v-col-lg-5'
      },
      {
        label: 'Amount',
        hideTextOnMobile: false,
        templateName: 'margin_amount',
        resourceField: 'margin_amount',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-7 v-col-sm-7 v-col-md-7 v-col-lg-7'
      }
    ]
  }
}

export const WORK_ORDER_SCHEDULER_MARGIN_LIST_HEADERS = () => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect: false,
    canExpand: true,
    showActions: false,
    headers: [
      {
        label: 'Item',
        hideTextOnMobile: false,
        templateName: 'name',
        resourceField: 'name',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-4 v-col-sm-4 v-col-md-4 v-col-lg-4'
      },
      {
        label: 'Date',
        hideTextOnMobile: false,
        templateName: 'date',
        resourceField: 'date',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-4 v-col-sm-4 v-col-md-4 v-col-lg-4'
      },
      {
        label: 'Amount',
        hideTextOnMobile: false,
        templateName: 'total',
        resourceField: 'total',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'end'
        },
        visibility: 'v-col-3 v-col-sm-3 v-col-md-3 v-col-lg-3'
      }
    ]
  }
}

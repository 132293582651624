import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.isEditMode ? _c('div', {
    staticClass: "d-flex uc-input flex-column",
    class: _vm.labelVariant ? 'py-1' : '',
    style: {
      maxWidth: _vm.maxWidth,
      minWidth: _vm.minWidth
    }
  }, [!_vm.labelVariant && _vm.label ? _c('uc-text', {
    staticClass: "ma-1",
    attrs: {
      "size": 2,
      "text": `${_vm.label}${_vm.required ? '*' : ''}`,
      "type": 'body',
      "bold": false
    }
  }) : _vm._e(), _c(VTextField, _vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showField,
      expression: "showField"
    }],
    ref: "inputRef",
    class: [{
      'number': _vm.type === 'number'
    }, {
      'uc-input--dense': _vm.dense
    }, {
      'dark': _vm.$vuetify.theme.dark
    }, ..._vm.classes],
    attrs: {
      "color": "text-disabled",
      "loader-height": '4px',
      "autofocus": _vm.autofocus,
      "hide-spin-buttons": _vm.type === 'number',
      "label": `${_vm.labelVariant && _vm.label ? _vm.label : ''}${_vm.labelVariant && _vm.label && _vm.required ? '*' : ''}`,
      "placeholder": _vm.placeholder,
      "height": _vm.height,
      "rules": _vm.localRules,
      "disabled": _vm.disabled,
      "dense": "",
      "outlined": "",
      "hint": _vm.hint,
      "persistent-hint": _vm.persistentHint,
      "prefix": _vm.prefix ? _vm.prefix : null,
      "clearable": _vm.clearable,
      "validate-on-blur": _vm.validateOnBlur,
      "append-icon": _vm.appendInputIcon,
      "prepend-inner-icon": _vm.prependIcon,
      "loading": _vm.loading,
      "readonly": _vm.readonly,
      "hide-details": _vm.hideDetails,
      "error-messages": _vm.errorMessages,
      "type": _vm.inputType,
      "value": _vm.value,
      "max": _vm.max,
      "counter": _vm.maxLength,
      "suffix": _vm.suffix
    },
    on: {
      "paste": function ($event) {
        $event.stopPropagation();
        return _vm.$emit('paste', $event);
      },
      "blur": function ($event) {
        _vm.$emit('blur', _vm.getEmitValue($event));
        _vm.hovering = false;
        _vm.canDoubleClickToEdit && _vm.toggleEditMode();
      },
      "input": function ($event) {
        return _vm.$emit('input', $event);
      },
      "click": function ($event) {
        return _vm.$emit('click');
      },
      "click:append": function ($event) {
        return _vm.clickAppendIcon();
      },
      "click:clear": function ($event) {
        return _vm.$emit('clear');
      },
      "focus": function ($event) {
        return _vm.$emit('focus');
      },
      "change": function ($event) {
        return _vm.$emit('change', $event);
      },
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        _vm.$emit('keydown:enter');
        _vm.canDoubleClickToEdit && _vm.toggleEditMode();
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")) return null;
        return _vm.$emit('keydown:tab');
      }, function ($event) {
        $event.stopPropagation();
      }]
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function () {
        return [_vm._t("prepend")];
      },
      proxy: true
    }, _vm.type === 'number' || _vm.$slots.append ? {
      key: "append",
      fn: function () {
        return [_vm._t("append")];
      },
      proxy: true
    } : null], null, true)
  }, 'v-text-field', {
    autocomplete: _vm.autofill ? 'on' : 'new-password'
  }, false))], 1) : _c('div', {
    staticClass: "no-select d-flex flex-column justify-center align-start overflow-hidden pa-1",
    class: _vm.hovering ? 'editable-hover' : '',
    style: {
      maxWidth: _vm.maxWidth,
      minWidth: _vm.minWidth
    },
    on: {
      "mouseenter": function ($event) {
        _vm.canDoubleClickToEdit && !_vm.disabled ? _vm.hovering = true : _vm.hovering = false;
      },
      "mouseleave": function ($event) {
        _vm.hovering = false;
      },
      "dblclick": function ($event) {
        $event.stopPropagation();
        _vm.canDoubleClickToEdit && !_vm.disabled && _vm.toggleEditMode();
      }
    }
  }, [_vm.nonEditModeComponent === 'text' ? [_vm.label !== null ? _c('uc-text', {
    attrs: {
      "size": 3,
      "type": 'body',
      "darken": true,
      "text": _vm.label
    }
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex align-center justify-space-between fill-width"
  }, [_c('uc-text', {
    staticClass: "my-1",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "size": _vm.doubleClickProps && _vm.doubleClickProps.text && _vm.doubleClickProps.text.size ? _vm.doubleClickProps.text.size : 2,
      "type": _vm.doubleClickProps && _vm.doubleClickProps.type ? _vm.doubleClickProps.type : 'body',
      "bold": _vm.doubleClickProps && _vm.doubleClickProps.bold ? _vm.doubleClickProps.bold : true,
      "ellipses": true,
      "text": _vm.readOnlyText
    }
  }), _vm.showCopyIcon ? _c('uc-copy-button', {
    attrs: {
      "value": _vm.value
    }
  }) : _vm._e()], 1)] : _vm.nonEditModeComponent === 'chip' ? [_c('div', {
    staticClass: "d-flex flex-column align-start fill-width"
  }, [_vm.label !== null ? _c('uc-text', {
    staticClass: "my-1",
    attrs: {
      "size": 3,
      "type": 'body',
      "darken": true,
      "text": _vm.label
    }
  }) : _vm._e(), _c('uc-chip', {
    style: {
      cursor: _vm.disabled ? '' : 'pointer'
    },
    attrs: {
      "show-quantity": _vm.doubleClickProps.chip.showQuantity,
      "text": _vm.doubleClickProps.chip.showText ? _vm.value || `${_vm.label ? `No ${_vm.startCase(_vm.label)}` : ''}` : '',
      "quantity": _vm.doubleClickProps.chip.showQuantity ? _vm.formatNumberByPrefix() || 0.00 : '',
      "state": 'layer-secondary',
      "size": 'sm',
      "can-select": !_vm.disabled,
      "tooltip-text": `${_vm.startCase(_vm.label)}: ${_vm.doubleClickProps.chip.showQuantity ? _vm.formatNumberByPrefix() || 0.00 : _vm.value}`,
      "tooltip-position": "top"
    }
  })], 1)] : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };
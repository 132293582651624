import { reactive, computed, ref } from 'vue'
import repositories from '@/repositories'
import router from '@/router'
import {
  PROTOCOL,
  PORT,
  DOMAIN,
  RAILS_SESSION_COOKIE,
  CURRENT_SUBDOMAIN,
  IS_RAEL_APP,
  HUBSPOT_APP_ID
} from '@/constants/env'
import { isMobile } from '@/utils/user-agent'
import { useSegment } from '@/use/segment'
import { getCookieValue } from '@/utils/cookies'
import events from '@/events'
import { useOnboarding } from '@/use/onboarding'
import { useCompanyUsers } from '@/use/company_users'
import { useAttendances } from '@/use/attendances'
import { ADMIN_WITH_COMPANY_SETTINGS } from '@/constants/permissions'
import { useBackgroundTasks } from './background-tasks'
import _ from 'lodash'
import { useCompanies } from './companies'
import { useToasts } from './toasts'
import { ERROR } from '@/constants/icons'
import { EMAIL_PHONE_ALREADY_REGISTERED_ERROR, INVALID_CREDENTIALS_ERROR } from '@/constants/authorization'
import { findCompanyType } from '@/utils/company'
import { Feature } from '@/types/interfaces/api-v2/feature'
import { useApp } from './app'
import { useHubspotChat, HubspotInfo } from './hubspot-chat'

interface userCreds {
  login: string
  password: string
  remember?: boolean
  source?: string
}
// interface tokens {
//   accessToken: string
//   refreshToken: string
// }
interface userEmail {
  email: string
}
interface passwords {
  password: string
  // eslint-disable-next-line camelcase
  password_confirmation: string
  // eslint-disable-next-line camelcase
  reset_password_token: string
}
interface session {
  currentUser?: null
  hasCompanies?: boolean
  token?: string,
  currentCompanyType: string,
  response: {
    status: number
    errorMessage: string
  }
}
interface userEmailOrPhone {
  emailOrPhone: string
  recaptchaToken: string
}

interface User {
  newPassword?: string
  confirmNewPassword?: string
  mode?: string
  otpPin?: number
  value?: string
}

const { loadCompanyUsers } = useCompanyUsers()
const { getCompanyProfile } = useCompanies()
const { addToast } = useToasts()
// cookie sometimes gets initialized as an object when there is no cookie so forced to empty string if thats the case
let session: {
  currentCompanyId?: any
  currentUser: any
  currentCompany: any
  hasCompanies: boolean
  token: any
  response: any
  loggedUser: any
  logInMethod: string,
  role: any
  loggedIn: boolean,
  currentCompanyType: string
} = reactive({
  currentCompanyId: typeof getCookieValue('company-id') === 'string' ? getCookieValue('company-id') : '',
  currentUser: null,
  hasCompanies: true,
  currentCompany: {},
  token: getCookieValue(RAILS_SESSION_COOKIE) || '',
  response: { status: 0, error_message: '' },
  loggedUser: {},
  logInMethod: 'password',
  loggedIn: false,
  role: null,
  currentCompanyType: ''
})

const companySetValues = ref<any>(null)

const { identifyUser } = useSegment()

export const useSession = () => {
  const login = async (user: userCreds, token?: string, isSSO = false) => {
    try {
      let data: any = {}
      if (!isSSO) {
        data = await repositories.session.signInViaRails(user)
        session.currentUser = data?.user
      }
      session.token = getCookieValue(RAILS_SESSION_COOKIE)
      session.response.status = 200
      session.loggedIn = true

      await getUserProfile()
      if (data?.redirectPath) {
        router.push(data.redirectPath)
      } else {
        const { getCurrentOnboardingState, validateFreeBroker } = useOnboarding()
        const redirect = await getCurrentOnboardingState(token)
        if (redirect) {
          const isValidBroker = await validateFreeBroker('/approval/pending')
          if (isValidBroker) {
            if (CURRENT_SUBDOMAIN === redirect) {
              router.push(`${session.loggedUser.landing_page}${isMobile() ? '?login_modal=true' : ''}`)
            } else {
              window.location.replace(createSubdomainURL(redirect, `${session.loggedUser.landing_page}${isMobile() ? '?login_modal=true' : ''}`))
            }
          }
        }
      }
    } catch (err:any) {
      session.response.error_message = err?.data?.message === INVALID_CREDENTIALS_ERROR
        ? INVALID_CREDENTIALS_ERROR
        : (err?.data?.message || INVALID_CREDENTIALS_ERROR)
      session.response.status = 401
      return { error: session.response.error_message }
    }
  }

  const ssoAuth = async (provider: string, userObj: {accessToken: string, uuid: string | number, subDomain: string}) => {
    try {
      const res = await repositories.session.ssoAuth(provider, userObj)
      return res
    } catch (err) {
      console.log('err', err)
      return err
    }
  }

  const createSubdomainURL = (subdomain: string | undefined, path: string) => {
    const protocol = `${PROTOCOL}://`
    const baseUrl = `${subdomain || ''}${subdomain ? DOMAIN : DOMAIN?.substr(1, DOMAIN.length - 1)}`
    const port = process.env.NODE_ENV === 'development' ? `:${PORT}` : ''
    const url = `${protocol}${baseUrl}${port}${path}`
    return url
  }

  const getUserProfile = async () => {
    try {
      const { totalUnreadCount } = useApp()
      const currentCompany = await getCompanyProfile({ include: 'billing_address,contacts,lock_detail, email_setting/email_template', payment_status_required: true })
      session.currentCompany = currentCompany.data
      session.currentCompanyType = findCompanyType(currentCompany.data.roles)
      const res = await repositories.session.userProfile({
        include: 'accessible_features,active_checkin,image,unread_received_messages',
        visitorIdentification: true
      })
      // if my response is empty, that means I'm not logged in
      // set session.loggedIn to false and return true (any subdomain is valid when not logged in)
      if (!res) {
        session.loggedIn = false
        return true
      }

      session.loggedIn = true

      totalUnreadCount.value = res.user.unreadReceivedMessages.length

      const loggedUser = {
        company_id:
            res?.user?.lastSignInCompanyId || session.currentCompanyId,
        fullName: res?.user?.fullName,
        email: res?.user?.email,
        phone: res?.user?.primary_phone_number,
        imageUrl: {
          icon: res?.user?.image?.versions?.icon,
          thumb: res?.user?.image?.versions?.thumb
        },
        id: res?.user?.id,
        subdomain: CURRENT_SUBDOMAIN,
        createdAt: res?.user?.dateJoined,
        accessibleFeatures: res?.user?.accessibleFeatures?.features || [],
        attendance: res.user?.activeCheckin,
        landing_page: res?.user.landingPath || '/',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        hsChatInfo: res.user.hsChatInfo
      }
      session.loggedUser = loggedUser
      identifyUser()

      if (!session.role) {
        await loadUserRole()
      }
      session.loggedUser = loggedUser
      return !!session?.loggedUser?.accessibleFeatures.length
    } catch (error: any) {
      identifyUser()
      console.error(error)
      session.loggedIn = false
      session.loggedUser = {}
      return false
    }
  }
  const { patchAttendanceCheckOut } = useAttendances()
  const { cleanSetInterval } = useBackgroundTasks()
  const { unbindEvents, loadDefaultConfigs, setVisitorAndReladWidget } = useHubspotChat()

  const logout = async (is401Unauthenticated = false, domainURL = '') => {
    try {
      const attendance = session.loggedUser.attendance
      // User automatically checks out when logging out
      if (attendance && (typeof attendance.checkInTime === 'string') && !attendance.checkOutTime) {
        await patchAttendanceCheckOut({
          id: attendance.id,
          note: ''
        })
      }
      const domain = DOMAIN ? DOMAIN.split(':')[0] : ''
      document.cookie = `uc-web-access-token=; domain=${domain}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
      document.cookie = `uc-web-refresh-token=; domain=${domain}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
      document.cookie = `user-details${RAILS_SESSION_COOKIE}=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
      document.cookie = `${RAILS_SESSION_COOKIE}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
      await repositories.session.signOutViaRails()
      session.loggedIn = false
      const allCookies = document.cookie.split(';')
      for (let i = 0; i < allCookies.length; i++) {
        document.cookie = allCookies[i].split('=')[0] + '= ; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      }
      session.currentUser = null
      session.loggedUser = {}
      session.token = ''
      localStorage.clear()
      cleanSetInterval(true)
      if (HUBSPOT_APP_ID) {
        unbindEvents()
        loadDefaultConfigs()
        setVisitorAndReladWidget({} as HubspotInfo, true)
      }

      if (domainURL) {
        window.location.replace(domainURL)
      } else {
        router.push('/login')
      }
    } catch (err) {
      console.error('Failed to logout', err)
    }
  }

  const forgot = async (user: userEmail): Promise<void> => {
    try {
      await repositories.session.forgot(user)
      session.response.status = 200
    } catch (err: any) {
      session.response.error_message = 'Please enter a valid email address'
      session.response.status = 400
    }
  }

  const updatePassword = async (passwords: passwords) => {
    try {
      const response = await repositories.session.updatePassword(passwords)
      if (response?.errors) {
        session.response.error_message = response?.errors[0]
        session.response.status = 400
      } else {
        session.response.status = 200
      }
    } catch (err: any) {
      session.response.error_message =
        'Token might expired. Please try again with new token'
      session.response.status = 422
    }
  }

  const updateLoginMethod = (loginMethod: string) => {
    session.logInMethod = loginMethod
  }

  const checkContactDetails = async (user:userEmailOrPhone) => {
    try {
      const res = await repositories.session.checkContactDetails(user)
      return res
    } catch (err: any) {
      return err
    }
  }

  const getLayoutType = computed(() => {
    return session.loggedIn ? 'default' : 'public'
  })

  const resetPassword = async (user:User, recaptchaToken:string) => {
    try {
      const response = await repositories.session.resetPassword(
        user,
        recaptchaToken
      )
      return response
    } catch (err: any) {
      console.error(err)
      return err
    }
  }

  const checkContactExisting = async (
    emailOrPhone: string,
    recaptchaToken: string,
    element: any,
    typeOfInput: string,
    isSignUp = false
  ) => {
    const params = {
      emailOrPhone,
      recaptchaToken
    }
    const res = await checkContactDetails(params)
    const { currentRoute: route } = router
    if (res.data) {
      if (res?.data?.user?.invitationPending && !isSignUp) {
        element.isError = true
        element.errorText = 'You have a pending invitation, accept it to finish creating your account'
        return true
      } else if (res.data.user && res.data.user.emailPresent) {
        if (isSignUp) {
          element.isError = true
          element.errorText =
          EMAIL_PHONE_ALREADY_REGISTERED_ERROR
          addToast({
            color: 'error',
            prependIcon: ERROR,
            message: EMAIL_PHONE_ALREADY_REGISTERED_ERROR
          })
        }
        // Need to go to login screen if email already signup
        router.push({
          name: 'login',
          query: { token: route.query.token }
        })
        return false
      } else if (
        res.data.user &&
        res.data.user.phoneNumberPresent &&
        isSignUp
      ) {
        if (isSignUp) {
          element.isError = true
          element.errorText =
          EMAIL_PHONE_ALREADY_REGISTERED_ERROR
          addToast({
            color: 'error',
            prependIcon: ERROR,
            message: EMAIL_PHONE_ALREADY_REGISTERED_ERROR
          })
        }
        // Need to go to login screen if phone number already signup
        router.push({
          name: 'login',
          query: { token: route.query.token }
        })
        return false
      } else if (res.data.error) {
        if (res.data.error === 'Record Not Found') {
          if (!isSignUp) {
            element.isError = true
            if (element.errorText === '') {
              element.errorText = `This ${typeOfInput} is not registered on ${IS_RAEL_APP === 'true' ? 'Rael' : 'UtilizeCore'}`
            }
          }
          return true
        }
      }
    }
  }
  const updateSessionData = (data: any) => {
    session = { ...session, ...data }
  }

  const hasAccess = (featureName: string): boolean => {
    const features = session.loggedUser?.accessibleFeatures
    const subFeatures = session.loggedUser?.accessibleFeatures.map(
      (feature: Feature) => feature.subFeatures
    ).flat(2)
    if (!features.length || !subFeatures.length) return false

    return (!!features.find((feature: Feature) => feature.name === featureName) ||
      !!subFeatures?.find((feature: Feature) => feature.name === featureName))
  }

  const hasCompanySettingsAccess = async () => {
    if (!session.role) {
      await loadUserRole()
    }
    return session?.role && session.role?.name === ADMIN_WITH_COMPANY_SETTINGS
  }

  const loadUserRole = async () => {
    if (!session.role) {
      const resp = await loadCompanyUsers({
        page: 1,
        perPage: 20,
        active: true,
        q: {
          user_id_eq: session.loggedUser.id
        }
      })
      session.role = resp && resp.companyUsers && resp.companyUsers[0] && resp.companyUsers[0].role
    }
  }

  const hasStoreAccess = () => {
    const storeAccess = session.role && session.role.name && session.role.name.trim() === ADMIN_WITH_COMPANY_SETTINGS
    return storeAccess
  }
  const saveInviteTeamMemberDetails = async (userData: any) => {
    try {
      const response = await repositories.session.saveInviteTeamMemberDetails(userData)
      return response
    } catch (err) {
      return err
    }
  }

  const getInviteTeamMemberDetails = async (token: any) => {
    try {
      const response = await repositories.session.getInvitedMemberDetails(token)
      return response
    } catch (err) {
      return err
    }
  }

  const isUtilizeCoreUser = computed(() => {
    return session?.loggedUser?.email?.includes('@utilizecore.com')
  })

  const getCompanySetValues = async () => {
    if (!companySetValues.value) {
      const res = await repositories.companySettings.getCompanySetValues(session.loggedUser.company_id)
      companySetValues.value = res.companysetvalues[0]
    }

    return companySetValues.value
  }

  return {
    session,
    getInviteTeamMemberDetails,
    createSubdomainURL,
    login,
    logout,
    forgot,
    updatePassword,
    getUserProfile,
    getCookieValue,
    updateLoginMethod,
    getLayoutType,
    checkContactDetails,
    resetPassword,
    checkContactExisting,
    updateSessionData,
    hasAccess,
    hasStoreAccess,
    loadUserRole,
    saveInviteTeamMemberDetails,
    hasCompanySettingsAccess,
    ssoAuth,
    isUtilizeCoreUser,
    getCompanySetValues,
    companySetValues
  }
}

import { computed, ref } from 'vue'
import { useUsers } from './users'
import { useSession } from './session'
import { UpdateUserParams } from '@/repositories/users'
import moment from 'moment'

declare global {
  interface Window {
    hsConversationsSettings: any
    HubSpotConversations: any
  }
}

export interface HubspotInfo {
  email: string
  visitorToken: string
  nextRefreshAt: Date
}

const iFrame = ref<HTMLIFrameElement>()
const tokenCookieName = 'hubspotutk'
const divContainerSelector = 'hubspot-messages-iframe-container'
const frameIdSelector = 'hubspot-conversations-iframe'
const capturedEvents = ['widgetLoaded', 'widgetClosed', 'conversationStarted']
const bannerDivClass = 'hs-web-interactives-top-banner-open'

const widgetOpened = ref<boolean>(window.hsConversationsSettings?.widgetOpened || false)
const widgetLoaded = ref<boolean>(false)
const wrapperContainer = computed(() => iFrame.value ? document.getElementById(divContainerSelector) : undefined)
const publicPage = ref<boolean>(false)

const { update } = useUsers()

const loadDefaultConfigs = ():void => {
  try {
    window.HubSpotConversations.clear()
    window.hsConversationsSettings = {
      loadImmediately: false,
      widgetOpened: false
    }
    iFrame.value = undefined
  } catch (err) {
    iFrame.value = undefined
    console.error('Failed to loadWidget - Hubspot chat', err)
  }
}

const loadWidget = ():void => {
  try {
    window.HubSpotConversations.widget.load()
    bindEvents()
  } catch (err) {
    console.error('Failed to loadWidget - Hubspot chat', err)
  }
}

const openWidget = ():void => {
  try {
    if (window.HubSpotConversations.widget.status().loaded) {
      window.HubSpotConversations.widget.open()
    } else {
      loadWidget()
      window.HubSpotConversations.widget.open()
    }
    widgetOpened.value = true
    window.hsConversationsSettings.widgetOpened = true
    wrapperContainer.value?.style.removeProperty('visibility')
  } catch (err) {
    console.error('Failed to openWidget - Hubspot chat', err)
  }
}

const closeWidget = ():void => {
  try {
    widgetOpened.value = false
    window.hsConversationsSettings.widgetOpened = false
    window.HubSpotConversations.widget.close()
  } catch (err) {
    console.error('Failed to closeWidget - Hubspot chat', err)
  }
}

const openOrCloseWidget = ():void => {
  try {
    if (widgetOpened.value) {
      closeWidget()
    } else {
      openWidget()
    }
  } catch (err) {
    console.error('Failed to openOrCloseWidget - Hubspot chat', err)
  }
}

const setVisitorAndReladWidget = (hsDetails: HubspotInfo, fromPublic = false): void => {
  window.hsConversationsSettings = {
    loadImmediately: false,
    identificationEmail: hsDetails.email,
    identificationToken: hsDetails.visitorToken || ''
  }
  publicPage.value = fromPublic
  resetAndReloadWidget()
}

const resetAndReloadWidget = (): void => {
  try {
    window.HubSpotConversations.resetAndReloadWidget()
    loadWidget()
  } catch (err) {
    console.error('Failed to resetAndReloadWidget - Hubspot chat', err)
    iFrame.value = document.getElementById(frameIdSelector) as HTMLIFrameElement
    if (!iFrame.value) return

    widgetLoaded.value = true
    hideWidget()
  }
}

const hideWidget = (): void => {
  if (widgetLoaded.value) {
    if (iFrame.value) {
      wrapperContainer.value?.style.setProperty('visibility', 'hidden')
      widgetOpened.value = false
      window.hsConversationsSettings.widgetOpened = false
    } else {
      window.HubSpotConversations.off('widgetLoaded', () => {})
    }
  }
}

const updateUser = (): void => {
  const { session } = useSession()
  const hsSettings = window.hsConversationsSettings
  const currentUser = session.loggedUser || { email: '' }
  if (
    hsSettings.identificationToken &&
        currentUser.email === hsSettings.identificationEmail
  ) {
    try {
      const apiBody: UpdateUserParams = {
        id: currentUser.id,
        hubspotChatClientAttributes: { lastLoginAt: moment().toISOString() },
        visitorIdentification: true
      }
      update(apiBody)
    } catch (error: any) {
      console.error('API failed to update last_login_at for hs', error)
    }
  }
}

const bindEvents = (): void => {
  window.HubSpotConversations.on('widgetLoaded', (_event: any) => {
    widgetLoaded.value = true
    iFrame.value = document.getElementById(frameIdSelector) as HTMLIFrameElement
    if (!publicPage.value) hideWidget()
  })
  window.HubSpotConversations.on('widgetClosed', (_event: any) => {
    if (!publicPage.value) hideWidget()
  })
  window.HubSpotConversations.on('conversationStarted', (_event: any) => {
    updateUser()
  })
}

const unbindEvents = (): void => {
  capturedEvents.forEach((event: string) => {
    window.HubSpotConversations.off(event, () => {})
  })
}

export const useHubspotChat = () => {
  return {
    loadDefaultConfigs,
    loadWidget,
    openOrCloseWidget,
    setVisitorAndReladWidget,
    unbindEvents,
    resetAndReloadWidget
  }
}

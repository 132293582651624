import repositories from '@/repositories'
import { reactive } from 'vue'
import { usePurchaseOrders } from '.'
import { useTransactionResources } from '../transactional-resources'
import { OUTBOUND_PURCHASE_ORDER, PO_RESOURCE_TYPE } from '@/constants/resource-types'
import { outboundActionData } from '@/constants/actions-by-resource/purchase-orders/outbound'
import { BROKER } from '@/constants/permissions'
import { useApp } from '../app'
import { AutocompleteResponse, AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'
import { useDetailsPanel } from '../details-panel'
import { isMobile } from '@/utils/user-agent'
import { PurchaseOrder } from '@/types/interfaces'

interface PurchaseOrderUpdateParams {
  purchaseOrderId: string
  updateContent: any,
  include?: string
}

type PurchaseOrders = {
  loading: boolean,
  list: Array<any>,
  latest:any,
  meta: any
}
const purchaseOrders = reactive<PurchaseOrders>({
  loading: false,
  list: [],
  latest: [],
  meta: {
    currentPage: 1,
    nextPage: 2,
    prevPage: null,
    totalCount: 0,
    totalPages: 0
  }
})

interface LoadPurchaseOrderParams {
  page: number
  perPage: number
  q?: any
  isSent: boolean
}

export const useOutboundPurchaseOrders = () => {
  const { companyType } = useApp()
  const { purchaseOrderActionListenersAndFunctions, shared, purchaseOrderActionModalProps, SAVED_PURCHASE_ORDER_STATUSES } = usePurchaseOrders()
  const { basicOutboundActions, resourceModalConfirmationHandler } = useTransactionResources()

  const basicPurchaseOrderOutboundActions = () => {
    const purchaseOrderOutboundActions = [
      ...basicOutboundActions(purchaseOrderActionListenersAndFunctions),
      {
        value: 'resend',
        actionFn: async (purchaseOrders: any[], additionalParams: any) => {
          purchaseOrderActionListenersAndFunctions.resend(
            purchaseOrders,
            additionalParams,
            true
          )
        }
      },
      {
        value: 'save',
        bulkConfig: {
        }
      },
      {
        value: 'save-as-draft',
        bulkConfig: {
        }
      },
      {
        value: 'save-and-mark-sent',
        bulkConfig: {
        }
      },
      {
        value: 'save-and-send',
        bulkConfig: {
        }
      },
      ...shared(true)
    ]

    // display only for non-mobile displays
    if (!isMobile()) {
      purchaseOrderOutboundActions.push({
        value: 'change-template',
        requiresConfirmation: true,
        updateSelectedResource: false,
        advancedModal: 'uc/change-template',
        dynamicContentProps: {
          type: OUTBOUND_PURCHASE_ORDER
        }
      } as any)
    }

    return purchaseOrderOutboundActions
  }

  const purchaseOrderOutboundModalRequiredActions = [
    {
      value: 'close',
      requiresConfirmation: true,
      modal: purchaseOrderActionModalProps(true).close,
      actionFn: async (purchaseOrders: any[], additionalParams: any) => {
        await updatePOStatus({ purchaseOrderId: purchaseOrders[0].id, updateContent: { status: 'closed' } })
      }
    },
    {
      value: 'approve',
      requiresConfirmation: true,
      modal: purchaseOrderActionModalProps(true).approve,
      actionFn: async (purchaseOrders: any[], additionalParams: any) => {
        await updatePOStatus({ purchaseOrderId: purchaseOrders[0].id, updateContent: { status: 'approved', note: additionalParams?.additionalDataFromActionModal?.note } })
      }
    },
    {
      value: 'evaluate',
      requiresConfirmation: true,
      modal: purchaseOrderActionModalProps(true).evaluate,
      actionFn: async (purchaseOrders: any[], additionalParams: any) => {
        await updatePOStatus({ purchaseOrderId: purchaseOrders[0].id, updateContent: { status: 'evalute', note: additionalParams?.additionalDataFromActionModal?.note } })
      }

    },
    {
      value: 'reject',
      requiresConfirmation: true,
      modal: purchaseOrderActionModalProps(true).reject,
      actionFn: async (purchaseOrders: any[], additionalParams: any) => {
        await updatePOStatus({ purchaseOrderId: purchaseOrders[0].id, updateContent: { status: 'rejected', note: additionalParams?.additionalDataFromActionModal?.note } })
      }
    },
    {
      value: 'delete',
      requiresConfirmation: true,
      modal: purchaseOrderActionModalProps(true).delete,
      actionFn: async (purchaseOrders: PurchaseOrder[]) => {
        await deletePurchaseOrder(String(purchaseOrders[0].id))
      }
    },
    {
      value: 'assign-department',
      requiresConfirmation: true,
      canUndo: true,
      modal: purchaseOrderActionModalProps(true).assignDepartment,
      actionFn: async (purchaseOrders: any[], additionalParams: any) => {
        purchaseOrders = purchaseOrders.map((purchaseOrder:any) => {
          if (purchaseOrder.props) {
            return purchaseOrder.props.purchaseOrder
          } else return purchaseOrder
        })
        await resourceModalConfirmationHandler('assign-department', purchaseOrders, additionalParams, PO_RESOURCE_TYPE, updatePurchaseOrder, { directionalActionData: outboundActionData, statuses: SAVED_PURCHASE_ORDER_STATUSES }, true)
      }
    },
    {
      value: 'view',
      updateSelectedResource: true,
      actionFn: (purchaseOrders: any) => {
        const { openGlobalDetailsPanel } = useDetailsPanel()
        openGlobalDetailsPanel('uc/resource-details', {
          actions: [
            // Deleting bulkConfig for hiding bulk action for the purchase order
            ...basicPurchaseOrderOutboundActions().map((item: any) => {
              const temp = Object.assign({}, item)
              delete temp.bulkConfig
              return temp
            }),
            ...purchaseOrderOutboundModalRequiredActions
          ],
          selectedResources: purchaseOrders,
          resourceType: PO_RESOURCE_TYPE,
          canDoubleClickToEdit: true,
          outbound: true
        }, '', { }, { })
      },
      noSuccessEmit: true
    }

  ].filter((action:any) => {
    return companyType.value !== BROKER ? !['evaluate', 'approve', 'reject'].includes(action.value) : action
  })

  const getPurchaseOrders = async (params: any) => {
    try {
      const res = await repositories.purchaseOrders.get(params)
      return {
        data: res.purchaseOrders,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const getPurchaseOrderById = async (id: number, params: any) => {
    try {
      const res = await repositories.purchaseOrders.getPurchaseOrderById(id, { include: params })
      return { data: res.purchaseOrder }
    } catch (err: any) {
      console.error(err)
      return { data: false }
    }
  }

  const postPurchaseOrders = async (params: any, requestBody: any) => {
    try {
      const res = await repositories.purchaseOrders.post(params, requestBody)
      return res.purchaseOrder
    } catch (err: any) {
      console.error(err)
    }
  }

  const deletePurchaseOrder = async (purchaseOrderId: string) => {
    try {
      const res = await repositories.purchaseOrders.delete(purchaseOrderId)
      return { data: res }
    } catch (err) {
      return false
    }
  }

  const bulkDeletePurchaseOrders = async (purchaseOrderIds: any) => {
    try {
      const res = await repositories.purchaseOrders.bulkDelete(purchaseOrderIds)
      return res
    } catch (err) {
      return false
    }
  }

  const updatePurchaseOrder = async ({ purchaseOrderId, updateContent, include }: PurchaseOrderUpdateParams) => {
    try {
      const res = await repositories.purchaseOrders.update({ purchaseOrderId, updateContent, include })
      return res
    } catch (err) {
      console.error(err)
      return false
    }
  }
  const getPurchaseOrderPreviewPdfById = async (purchaseOrderId: string | number, resourceType: string) => {
    try {
      const res = await repositories.purchaseOrders.getPreviewPdfByResource(purchaseOrderId, resourceType)
      return res
    } catch (err: any) {
      console.error(err)
      return false
    }
  }

  const postPurchaseOrdersSendEmail = async (body: any) => {
    try {
      // if (!ids) throw new Error('Id array required in params')
      const res = await repositories.purchaseOrders.postSendEmail(body)
      // if (purchaseOrderEmail.backgroundTaskId) {
      //   addBackgroundTask(purchaseOrderEmail.backgroundTaskId)
      // } else {
      //   console.error('Background Task ID not received  from send email response, unable to create background task')
      // }
      return {
        data: res.purchaseOrderEmail.success || false
      }
    } catch (err: any) {
      console.error(err)
      return null
    }
  }

  const loadPurchaseOrders = async ({ page, perPage, q, isSent }: LoadPurchaseOrderParams) => {
    try {
      purchaseOrders.loading = true
      const res = isSent ? await repositories.purchaseOrders.get({ page, perPage, q }) : await repositories.inboundPurchaseOrders.get({ page, perPage, q })
      if (page === 1) {
        purchaseOrders.list = res.purchaseOrders.length === 0 ? [] : [...res.purchaseOrders]
      } else {
        purchaseOrders.list = [...purchaseOrders.list, ...res.purchaseOrders]
      }
      purchaseOrders.latest = res.purchaseOrders
      purchaseOrders.meta = { ...res.meta }
    } catch (err) {
      console.error(err)
      return false
    } finally {
      purchaseOrders.loading = false
    }
  }
  const updatePurchaseOrderWithStatus = async (id: number, isSent: boolean, status: string) => {
    try {
      const purchaseOrderToUpdate = isSent
        ? await repositories.purchaseOrders.getPurchaseOrderById(id, {})
        : await repositories.inboundPurchaseOrders.getById(id, {})
      purchaseOrderToUpdate.purchaseOrder.status = status
      const response = isSent
        ? await repositories.purchaseOrders.updatePurchaseOrderById(id, purchaseOrderToUpdate.purchaseOrder)
        : await repositories.inboundPurchaseOrders.update(id, purchaseOrderToUpdate.purchaseOrder)
      return response.purchaseOrder
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const getPDFLinkById = async (purchaseOrderId: string, isSent: boolean) => {
    try {
      const res = isSent
        ? await repositories.purchaseOrders.getPreviewPdfById(purchaseOrderId)
        : await repositories.inboundPurchaseOrders.getPreviewPdfById(purchaseOrderId)
      return res
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const loadPurchaseOrdersWithoutSaving = async ({ page, perPage, q, isSent }: LoadPurchaseOrderParams) => {
    try {
      const response = isSent
        ? await repositories.purchaseOrders.get({ page, perPage, q })
        : await repositories.inboundPurchaseOrders.get({ page, perPage, q })
      return response
    } catch (err) {
      console.error(err)
      return {
        purchaseOrders: [],
        meta: {}
      }
    }
  }
  const updatePurchaseOrderStatus = async (id: number, updatedStatus: string) => {
    try {
      const res = await repositories.purchaseOrders.updateStatus(id, updatedStatus)
      return res
    } catch (err: any) {
      console.error(err)
      return false
    }
  }
  const updatePOStatus = async (params: any) => {
    const { purchaseOrderId, updateContent } = params
    try {
      const res = await repositories.purchaseOrders.updateStatus(purchaseOrderId, updateContent.status === 'approved' ? 'accepted' : updateContent.status)
      return res
    } catch (err: any) {
      console.error(err)
      return false
    }
  }
  const getPOPrefix = async (params: any) => {
    try {
      const res = await repositories.purchaseOrders.getPOPrefix(params)
      return {
        data: res.poPrefixes,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const postPOPrefix = async (params: any, requestBody: any) => {
    try {
      const res = await repositories.purchaseOrders.postPOPrefix(params, requestBody)
      return {
        data: res,
        success: true
      }
    } catch (response: any) {
      console.error('res', response)
      const errors = response?.data?.errors
      errors.message = 'Failed to add Prefix.'
      if (errors.label?.length > 0) {
        if (errors.label[0].error === 'taken') {
          errors.message = 'Prefix "' + errors.label[0].value + '" is already taken.'
        }
      }
      return {
        data: errors.message,
        success: false
      }
    }
  }

  const purchaseOrdersAutoComplete = async (params: AutocompletePayload) => {
    try {
      const res = await repositories.purchaseOrders.purchaseOrdersAutoComplete(params)
      return {
        data: res.results,
        nextPage: res.nextPage
      } as { data: AutocompleteResponse[], nextPage: number }
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const purchaseOrdersSearch = async (params: any) => {
    try {
      const res = await repositories.purchaseOrders.purchaseOrdersSearch(params)
      return {
        data: res.purchaseOrders,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const statuses = async (as = 'both') => {
    try {
      const res = await repositories.purchaseOrders.purchaseOrderStatuses(as)
      return {
        data: res.statuses
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  return {
    purchaseOrders,
    getPurchaseOrders,
    deletePurchaseOrder,
    updatePurchaseOrder,
    getPurchaseOrderPreviewPdfById,
    postPurchaseOrdersSendEmail,
    loadPurchaseOrders,
    updatePurchaseOrderWithStatus,
    getPDFLinkById,
    loadPurchaseOrdersWithoutSaving,
    updatePurchaseOrderStatus,
    updatePOStatus,
    bulkDeletePurchaseOrders,
    getPurchaseOrderById,
    postPurchaseOrders,
    purchaseOrderOutboundModalRequiredActions,
    basicPurchaseOrderOutboundActions,
    getPOPrefix,
    postPOPrefix,
    purchaseOrdersAutoComplete,
    purchaseOrdersSearch,
    statuses
  }
}

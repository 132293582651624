import { api } from '@/api'
import qs from 'qs'

interface Params {
  scope?: string
  include?: string
  q?: any
  page?: number
  perPage?: number,
  id?: string | number,
  itemTypeId?: string | number,
  objType?: string,
  resourceId?: string | number,
  resourceType?: string,
  currentCompanyRole?: string,
  selectedAddressId?: string | number,
  companyId?: string | number,
  withNonExistingClients?: boolean,
  vendorId?: string | number,
  locationId?: string | number,
  tripIds?: Array<string|number>
  orderByRates?: boolean
  outbound?: boolean
}

export default {
  new: (params:Params) => api.$get('/items/new', {
    params,
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  get: (params:any) => {
    const parameters:Params = params
    const { scope, include, q, page, perPage } = parameters
    return api.$get('/items', {
      params: {
        scope,
        include,
        q,
        page,
        per_page: perPage,
        include_applicable_tax_rate: true
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  getDerivedItemsByItemType: (params: any) => {
    const parameters:Params = params
    const { scope, include, q, page, perPage, itemTypeId, objType, companyId, withNonExistingClients, locationId, tripIds, resourceId, resourceType, currentCompanyRole, selectedAddressId, vendorId, orderByRates, outbound } = parameters
    return api.$get(`/item_types/${itemTypeId}/derived_items`, {
      params: {
        obj_type: objType,
        company_id: companyId,
        resource_id: resourceId,
        resource_type: resourceType,
        location_id: locationId,
        trip_ids: tripIds,
        current_company_role: currentCompanyRole,
        address_id: selectedAddressId,
        with_non_existing_clients: withNonExistingClients,
        vendor_id: vendorId,
        scope,
        include,
        q,
        page,
        per_page: perPage,
        include_applicable_tax_rate: true,
        orderByRates,
        outbound
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  createItem: (item:FormData, include:string) => {
    return api.$post('/items', { item, include }, {
      headers: {
        content_type: 'multipart/form-data'
      }
    })
  },
  getCompanyMaterials: (params: any) => {
    const { scope, include, q, page, perPage } = params
    return api.$get('/company_materials', {
      params: {
        scope,
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  }
}

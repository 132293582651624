import { ResourceAction } from '@/types/interfaces'
import { RequiredAction } from '@/types/interfaces/api-v2/required-action'
import { RequiredActionActions } from '@/types/enums/required-action/actions'
import { kebabCase } from 'lodash'
import { DetailsPanelButtonState } from '@/types/enums'
import { RequiredActionResourceTypes } from '@/types/enums/required-action/resource-types'

export const workOrderResolveAction: ResourceAction = {
  name: 'Resolve',
  eventName: 'action-required-resolve',
  validStatuses: true,
  primaryAction: [],
  extraData: {
    resourceType: RequiredActionResourceTypes.WORK_ORDER
  }

}

type TranslateActionToNameFn = (action: RequiredActionActions) => string
type TranslateActionToNameWithHumanIdentifierFn = (action: RequiredActionActions, humanIdentifier: string) => string

function selectNameFormatter (withoutIdentifier: TranslateActionToNameFn, withIdentifier: TranslateActionToNameWithHumanIdentifierFn, useIdentifier: boolean) {
  return useIdentifier ? withIdentifier : withoutIdentifier as TranslateActionToNameWithHumanIdentifierFn
}

const tripActionToName: TranslateActionToNameFn = (action: RequiredActionActions) => {
  // This should be an exhaustive list, but it is always possible we miss something, that's what the undefined is for.
  switch (action) {
    case RequiredActionActions.ACCEPT:
      return 'Accept Trip'
    case RequiredActionActions.REJECT:
      return 'Reject Trip'
    case RequiredActionActions.ADJUST:
      return 'Adjust Trip'
    case RequiredActionActions.CHECK_IN:
      return 'Check In to Trip'
    case RequiredActionActions.DISPATCH:
      return 'Dispatch Trip'
    case RequiredActionActions.EDIT:
      return 'Edit Trip'
    case RequiredActionActions.ASSIGN:
      return 'Assign Trip'
    case RequiredActionActions.REASSIGN:
      return 'Reassign Trip'
    case RequiredActionActions.VERIFY:
      return 'Verify Trip'
    case RequiredActionActions.GENERATE_INVOICE:
      return 'Generate Inv'
    default:
      return 'Undefined'
  }
}

const tripActionToNameWithIdentifier: TranslateActionToNameWithHumanIdentifierFn = (action: RequiredActionActions, humanIdentifier: string) => {
  if (action === RequiredActionActions.GENERATE_INVOICE) {
    return ''
  } else {
    return `${tripActionToName(action)} ${humanIdentifier}`
  }
}

const invoiceActionToName: TranslateActionToNameFn = (action: RequiredActionActions) => {
  // This should be an exhaustive list, but it is always possible we miss something, that's what the undefined is for.
  switch (action) {
    case RequiredActionActions.RECEIVE_PAYMENT:
      return 'Receive Payment'
    case RequiredActionActions.EDIT:
      return 'Edit Inv'
    case RequiredActionActions.SEND:
      return 'Send Inv'
    case RequiredActionActions.PAY:
      return 'Pay Inv'
    case RequiredActionActions.REVIEW:
      return 'Review Inv'
    default:
      return 'Undefined'
  }
}

const invoiceActionToNameWithIdentifier: TranslateActionToNameWithHumanIdentifierFn = (action: RequiredActionActions, humanIdentifier: string) => {
  if (action === RequiredActionActions.RECEIVE_PAYMENT) {
    return `${invoiceActionToName(action)} for Inv ${humanIdentifier}`
  } else {
    return `${invoiceActionToName(action)} ${humanIdentifier}`
  }
}

const proposalActionToName: TranslateActionToNameFn = (action: RequiredActionActions) => {
  // This should be an exhaustive list, but it is always possible we miss something, that's what the undefined is for.
  switch (action) {
    case RequiredActionActions.REVIEW:
      return 'Review Pro'
    case RequiredActionActions.APPROVE:
      return 'Approve Pro'
    case RequiredActionActions.EDIT:
      return 'Edit Pro'
    case RequiredActionActions.SEND:
      return 'Send Pro'
    case RequiredActionActions.DOWNLOAD:
      return 'Download Pro'
    default:
      return 'Undefined'
  }
}

const proposalActionToNameWithIdentifier: TranslateActionToNameWithHumanIdentifierFn = (action: RequiredActionActions, humanIdentifier: string) => {
  return `${proposalActionToName(action)} ${humanIdentifier}`
}

const purchaseOrderActionToName: TranslateActionToNameFn = (action: RequiredActionActions) => {
  // This should be an exhaustive list, but it is always possible we miss something, that's what the undefined is for.
  switch (action) {
    case RequiredActionActions.GENERATE_INVOICE:
      return 'Gen Invoice for P.O.'
    case RequiredActionActions.APPROVE:
      return 'Approve P.O.'
    case RequiredActionActions.EDIT:
      return 'Edit P.O.'
    default:
      return 'Undefined'
  }
}

const purchaseOrderActionToNameWithIdentifier: TranslateActionToNameWithHumanIdentifierFn = (action: RequiredActionActions, humanIdentifier: string) => {
  return `${purchaseOrderActionToName(action)} ${humanIdentifier}`
}

const workOrderActionToName: TranslateActionToNameFn = (action: RequiredActionActions) => {
  // This should be an exhaustive list, but it is always possible we miss something, that's what the undefined is for.
  switch (action) {
    case RequiredActionActions.CREATE_TRIP:
      return 'Create Trip'
    default:
      return 'Undefined'
  }
}

const createWorkOrderRequiredActionAction = (eventNamePrefix: string, translateActionToName: TranslateActionToNameWithHumanIdentifierFn, action: RequiredActionActions, resourceId: string | number, resourceType: string, humanIdentifier: string): ResourceAction => {
  // Generate Invoice requires the work order object
  const requiredAction: any = {
    eventName: kebabCase(`${eventNamePrefix}-${action}`),
    name: translateActionToName(action, humanIdentifier),
    validStatuses: true,
    primaryAction: []
  }
  if (action === RequiredActionActions.GENERATE_INVOICE) {
    return requiredAction
  } else {
    requiredAction.extraData = {
      resourceId,
      resourceType
    }
    return requiredAction
  }
}

export const createWorkOrderTripRequiredActionAction = (action: RequiredAction, includeIdentifier: boolean): ResourceAction => {
  return createWorkOrderRequiredActionAction(
    'trip',
    selectNameFormatter(tripActionToName, tripActionToNameWithIdentifier, includeIdentifier),
    action.action,
    action.resourceId,
    action.resourceType,
    action.resourceHumanIdentifier
  )
}

export const createWorkOrderInvoiceRequiredActionAction = (action: RequiredAction, includeIdentifier: boolean): ResourceAction => {
  return createWorkOrderRequiredActionAction(
    'invoice',
    selectNameFormatter(invoiceActionToName, invoiceActionToNameWithIdentifier, includeIdentifier),
    action.action,
    action.resourceId,
    action.resourceType,
    action.resourceHumanIdentifier
  )
}
export const createWorkOrderProposalRequiredActionAction = (action: RequiredAction, includeIdentifier: boolean): ResourceAction => {
  return createWorkOrderRequiredActionAction(
    'proposal',
    selectNameFormatter(proposalActionToName, proposalActionToNameWithIdentifier, includeIdentifier),
    action.action,
    action.resourceId,
    action.resourceType,
    action.resourceHumanIdentifier
  )
}
export const createWorkOrderPurchaseOrderRequiredActionAction = (action: RequiredAction, includeIdentifier: boolean): ResourceAction => {
  return createWorkOrderRequiredActionAction(
    'purchase-order',
    selectNameFormatter(purchaseOrderActionToName, purchaseOrderActionToNameWithIdentifier, includeIdentifier),
    action.action,
    action.resourceId,
    action.resourceType,
    action.resourceHumanIdentifier
  )
}

export const createRequiredActionForWorkOrderAction = (action: RequiredAction, includeIdentifier: boolean): ResourceAction => {
  return createWorkOrderRequiredActionAction(
    'work-order',
    workOrderActionToName, // we don't care about identifiers here
    action.action,
    action.resourceId,
    action.resourceType,
    action.resourceHumanIdentifier
  )
}

export interface WorkOrderResourceAction extends ResourceAction {
  onlyNonCanceled?: boolean
  requiredSetting?: string // this is a setting relationship path like vendorSetting.foo
  requiredFeature?: {feature: string, subFeature: string}
  // reject ready for billing status, a.k.a. rejected, not ready for billing, a.k.a. we're no longer ready for billing
  specialLogic?: 'readyForBilling' | 'rejectReadyForBilling'
}

export interface WorkOrderActionData {
  createActions?: WorkOrderResourceAction[],
  preRequiredActions: WorkOrderResourceAction[],
  postRequiredActions: WorkOrderResourceAction[]
}

export const WORK_ORDER_BROKER_ACTION_DATA: WorkOrderActionData = {
  createActions: [
    {
      name: 'Save & Add Trip',
      eventName: 'work-order-save-add-trip',
      validStatuses: true,
      primaryAction: []
    },
    // {
    //   name: 'Save & Add Draft Trip',
    //   eventName: 'work-order-save-draft-trip',
    //   validStatuses: true,
    //   primaryAction: []
    // },
    {
      name: 'Save',
      eventName: 'work-order-save',
      validStatuses: true,
      primaryAction: []
    }
  ],
  preRequiredActions: [
    {
      name: 'Edit',
      eventName: 'edit',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true
    },
    {
      name: 'View',
      eventName: 'view',
      validStatuses: true,
      primaryAction: [],
      detailsPanel: DetailsPanelButtonState.Hide
    },
    {
      name: 'Generate Report',
      eventName: 'trip-generate-report',
      validStatuses: true,
      primaryAction: []
    },
    {
      name: '+ Notes',
      eventName: 'work-order-notes',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true
    }
  ],
  postRequiredActions: [
    {
      name: 'Mark as Ready for Billing',
      eventName: 'work-order-mark-ready-for-billing',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true,
      specialLogic: 'readyForBilling',
      requiredFeature: { feature: 'invoice', subFeature: 'can_change_to_ready_for_billing_status' },
      requiredSetting: 'workOrderSetting.invoiceReadyForBilling'
    },
    {
      name: 'Rejected, Not Ready for Billing',
      eventName: 'work-order-reject-ready-for-billing',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true,
      specialLogic: 'rejectReadyForBilling',
      requiredFeature: { feature: 'invoice', subFeature: 'can_change_to_rejected_not_ready_for_billing_status' },
      requiredSetting: 'workOrderSetting.invoiceRejectNotReadyForBilling'
    },
    {
      name: 'Change Custom Status',
      eventName: 'work-order-change-custom-status',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true
    },
    {
      name: '+ Trip',
      eventName: 'work-order-add-trip',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true
    },
    {
      name: '+ Attachment',
      eventName: 'work-order-add-attachment',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true
    },
    {
      name: '+ Material',
      eventName: 'work-order-add-material',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true
    },
    {
      name: '+ Purchase Order',
      eventName: 'work-order-add-purchase-order',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true
    },
    {
      name: '+ Proposal',
      eventName: 'work-order-add-proposal',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true
    },
    {
      name: 'Skip',
      validStatuses: true,
      primaryAction: [],
      textColor: 'error',
      detailsPanel: DetailsPanelButtonState.Only
    }
  ]
}

export const WORK_ORDER_CONTRACTOR_ACTION_DATA: WorkOrderActionData = {
  preRequiredActions: [
    {
      name: 'View',
      eventName: 'view',
      validStatuses: true,
      primaryAction: [],
      detailsPanel: DetailsPanelButtonState.Hide
    },
    {
      name: 'Generate Report',
      eventName: 'trip-generate-report',
      validStatuses: true,
      primaryAction: []
    },
    {
      name: '+ Notes',
      eventName: 'work-order-notes',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true
    }
  ],
  postRequiredActions: [
    {
      name: 'Change Custom Status',
      eventName: 'work-order-change-custom-status',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true,
      requiredSetting: 'vendorSetting.allowVendorsToUpdateWoStatus'
    },
    {
      name: '+ Trip',
      eventName: 'work-order-add-trip',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true
    },
    {
      name: '+ Attachment',
      eventName: 'work-order-add-attachment',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true
    },
    {
      name: '+ Material',
      eventName: 'work-order-add-material',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true,
      requiredSetting: 'vendorSetting.workOrderMaterialEnabled'
    },
    {
      name: '+ Proposal',
      eventName: 'work-order-add-proposal',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true,
      requiredSetting: 'vendorSetting.workOrderProposalsEnabled'
    }
  ]
}

export const WORK_ORDER_INTERMEDIARY_BROKER_ACTION_DATA: WorkOrderActionData = {
  preRequiredActions: [
    {
      name: 'View',
      eventName: 'view',
      validStatuses: true,
      primaryAction: [],
      detailsPanel: DetailsPanelButtonState.Hide
    },
    {
      name: 'Generate Report',
      eventName: 'trip-generate-report',
      validStatuses: true,
      primaryAction: []
    },
    {
      name: '+ Notes',
      eventName: 'work-order-notes',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true
    }
  ],
  postRequiredActions: [
    {
      name: 'Change Custom Status',
      eventName: 'work-order-change-custom-status',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true,
      requiredSetting: 'vendorSetting.allowVendorsToUpdateWoStatus'
    },
    {
      name: '+ Trip',
      eventName: 'work-order-add-trip',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true
    },
    {
      name: '+ Attachment',
      eventName: 'work-order-add-attachment',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true
    },
    {
      name: '+ Material',
      eventName: 'work-order-add-material',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true,
      requiredSetting: 'vendorSetting.workOrderMaterialEnabled'
    },
    {
      name: '+ Proposal',
      eventName: 'work-order-add-proposal',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true,
      requiredSetting: 'vendorSetting.workOrderProposalsEnabled'
    }
  ]
}

export const WORK_ORDER_CLIENT_ACTION_DATA: WorkOrderActionData = {
  preRequiredActions: [
    {
      name: 'View',
      eventName: 'view',
      validStatuses: true,
      primaryAction: [],
      detailsPanel: DetailsPanelButtonState.Hide
    },
    {
      name: 'Generate Report',
      eventName: 'trip-generate-report',
      validStatuses: true,
      primaryAction: []
    },
    {
      name: '+ Notes',
      eventName: 'work-order-notes',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true
    }
  ],
  postRequiredActions: [
    {
      name: '+ Attachment',
      eventName: 'work-order-add-attachment',
      validStatuses: true,
      primaryAction: [],
      onlyNonCanceled: true
    }
  ]
}
export const WO_VIEW_INCLUDIBLE_RESOURCES = 'trips, company, company/logo, vendor, vendor/logo, vendor/client_setting, vendor/work_order_setting, vendor/vendor_setting, locations/billing_address, trade_services, trade_services/trade, trades, tasks, work_order_trade_services, external_connections, trips/external_connections, cc_trips, cc_trip_clients, cc_trip_clients/logo, cc_required_actions, work_order_status, work_order_materials, vendors_status,clients_status, unread_notes/actor'

export const WO_INCLUDIBLE_RESOURCES = 'company, company/logo, vendor, vendor/logo, vendor/client_setting, vendor/work_order_setting, vendor/vendor_setting, locations/billing_address, trade_services, trade_services/trade, cc_trip_clients, cc_trip_clients/logo, cc_required_actions, work_order_status, vendors_status, clients_status'
export const EXTERNAL_CONNECTIONS_PROVIDER_INFO_MAPPINGS = [
  { provider: 'ServiceChannel', title: 'Service Channel', prefix: 'SC' },
  { provider: 'Qsifacilities', title: 'Qsi Facilities', prefix: 'C&W' },
  { provider: 'ServiceNow', title: 'Service Now', prefix: 'SN' },
  { provider: 'Apg', title: 'Apg', prefix: 'APG' },
  { provider: 'Corrigo', title: 'Corrigo', prefix: 'CO' }
]

import { api } from '@/api'

export default {
  post: (userIds: number[]) => {
    return api.$post('/chat/conversations', { chat_conversation: { user_ids: userIds } })
  },
  getJWT: (chatId: number) => {
    return api.$post(`/chat/conversations/${chatId}/meet`, {
    })
  }
}

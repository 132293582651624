import { api } from '@/api'
import qs from 'qs'

export default {
  get: () => {
    return api.$get('settings/work_orders/default_vendor_priorities')
  },
  update: (params: any) => {
    return api.$patch('settings/work_orders/default_vendor_priorities', { settings_work_orders: params },
      {
        paramsSerializer: (params: any) => {
          return qs.stringify(params)
        }
      }
    )
  }
}

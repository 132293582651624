import { FilterResources } from '@/types/enums/filter-types'
import { useWeatherEvents } from '@/use/weather-events'
const { getWeatherEvents } = useWeatherEvents()

const WEATHER_EVENT = (config = {}) => {
  return {
    text: 'WW ID',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'weather-event-ww-id-filter',
      headerText: 'Weather Event WW ID',
      size: 'lg',
      contentType: 'number',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async (params: any) => {
        const { data, totalPages, totalCount } = await getWeatherEvents({ ...params }) as any
        if (!data) {
          return {
            data: [],
            totalCount: 0,
            totalPages: 0
          }
        }
        return {
          data: data.map((item: any) => {
            return {
              id: item.weatherWorksStormId,
              text: `${item.weatherWorksStormId} | ${item.text}`,
              value: item.weatherWorksStormId
            }
          }),
          totalCount,
          totalPages
        }
      },
      params: { include: 'storm', q: { s: 'weather_works_storm_id asc' } },
      searchKeys: ['weather_works_storm_id'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WEATHER_EVENT,
    ...config
  }
}

const WEATHER_EVENT_START_DATE = (config = {}) => {
  return {
    text: 'Start Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'weather-event-start-date-filter',
      headerText: 'Weather Event Start Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WEATHER_EVENT,
    ...config
  }
}

const WEATHER_EVENT_END_DATE = (config = {}) => {
  return {
    text: 'Start End',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'weather-event-end-date-filter',
      headerText: 'Weather Event End Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WEATHER_EVENT,
    ...config
  }
}

export {
  WEATHER_EVENT,
  WEATHER_EVENT_START_DATE,
  WEATHER_EVENT_END_DATE
}

import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VCard, {
    staticClass: "uc-modal-card",
    class: _vm.background,
    attrs: {
      "width": _setup.getWidth()
    }
  }, [_c(VCardTitle, {
    staticClass: "uc-modal-card__title px-2 mx-1"
  }, [_vm.headerIcon || _vm.headerAvatar || _vm.headerAvatarText ? [_vm.headerIconIsButton ? _c('uc-button', {
    attrs: {
      "icon-name": `mdi-${_vm.headerIcon}`,
      "icon": true,
      "type": "text",
      "state": "text",
      "has-hover": true
    },
    on: {
      "click": function ($event) {
        return _setup.emit('click:prepend');
      }
    }
  }) : _vm.headerAvatar || _vm.headerAvatarText ? _c('uc-avatar', {
    attrs: {
      "avatar": _vm.headerAvatar,
      "name": _vm.headerAvatarText,
      "size": "30",
      "name-format-type": "first-second-name"
    }
  }) : _c(VIcon, {
    attrs: {
      "color": _vm.iconColor
    }
  }, [_vm._v(" " + _vm._s('mdi-' + _vm.headerIcon) + " ")])] : _vm._e(), _vm.headerImageSrc !== '' ? _c(_setup.StoreImageDisplay, {
    attrs: {
      "size": 32,
      "image": _vm.headerImageSrc
    }
  }) : _vm._e(), _c('div', {
    class: ['ml-2', 'flex-1', 'overflow-hidden']
  }, [_c('uc-tooltip', _vm._b({}, 'uc-tooltip', _vm.headerTooltipProps, false), [_c('uc-text', {
    attrs: {
      "size": 3,
      "ellipses": true,
      "text": _vm.headerText,
      "type": 'title',
      "bold": _vm.headerTextBold
    }
  })], 1)], 1), _vm.counterChip !== null && _vm.counterChip !== '' ? _c('uc-chip', {
    attrs: {
      "can-select": false,
      "state": _vm.counterChipState,
      "text": _vm.counterChip,
      "size": "sm"
    }
  }) : _vm._e(), _vm._t("header-actions"), _vm.appendHeaderIcon !== null && _vm.appendHeaderIcon !== '' ? _c('uc-button', {
    attrs: {
      "icon-name": `mdi-${_vm.appendHeaderIcon}`,
      "icon": true,
      "type": "text",
      "state": "text",
      "has-hover": true
    },
    on: {
      "click": function ($event) {
        return _setup.emit('click:append');
      }
    }
  }) : _vm._e(), _vm.closable ? _c('uc-button', {
    attrs: {
      "icon-name": _setup.EXIT,
      "icon": "",
      "type": "text",
      "state": "text",
      "has-hover": "",
      "data-cy": "close-modal",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        return _setup.emit('close-card');
      }
    }
  }) : _vm._e()], 2), _c(VDivider), _c(VCardText, {
    staticClass: "uc-modal-card__content",
    style: {
      height: _vm.scrollableContent.contentHeight,
      overflow: 'auto'
    }
  }, [_vm._t("content")], 2), _setup.validateFooterContent(_vm.$scopedSlots.footer) ? _c(VDivider) : _vm._e(), _setup.validateFooterContent(_vm.$scopedSlots.footer) ? _c(VCardActions, {
    staticClass: "uc-modal-card__footer"
  }, [_vm._t("footer")], 2) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
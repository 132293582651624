import { VCard } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VCard, _vm._b({
    staticClass: "uc-list-card",
    attrs: {
      "color": `var(${_vm.backgroundColor})`,
      "elevation": "0",
      "width": _vm.width,
      "height": _vm.fillType === 'fill' ? '100%' : ''
    }
  }, 'v-card', _vm.$attrs, false), [_c(VList, {
    staticClass: "uc-list-card__list py-0",
    attrs: {
      "max-height": _vm.height ? _vm.height : '',
      "color": `var(${_vm.backgroundColor})`
    }
  }, [_vm._t("header"), !_vm.infiniteScroll ? _vm._t("rows") : _c('div', {
    staticClass: "uc-list-card__infinite-scroller"
  }, [_vm._t("no-data"), _vm._t("rows"), _vm.hasNextPage ? [_c('uc-list-row-skeleton', {
    attrs: {
      "intersect": true
    },
    on: {
      "intersect": function ($event) {
        return _vm.$emit('load');
      }
    }
  }), _c('uc-list-row-skeleton')] : _vm._e()], 2)], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import repositories from '@/repositories/index'
import { cloneDeep, snakeCase, startCase } from 'lodash'
import { useBackgroundTasks } from '@/use/background-tasks'
import { BackgroundTaskSources, BackgroundTaskZipFileTypes, BackgroundTaskFormats, BackgroundTaskReportTypes, DetailsPanelButtonState } from '@/types/enums'
import { BROKER, CLIENT, VENDOR } from '@/constants/permissions'
import { useListRow } from '../list-rows'
import { useApp } from '../app'
import moment from 'moment-timezone'
import { inboundActionData } from '@/constants/actions-by-resource/proposals/inbound'
import { outboundActionData } from '@/constants/actions-by-resource/proposals/outbound'
import { useTransactionResources } from '../transactional-resources'
import { CLOSE, DATE_EXPIRED, DRAFT, ERROR, EXPORT, HELP, PDF_DOWNLOAD, PRINT, RECEIVED, REJECTED_ALERT, SEND, SHARE, SUCCESS } from '@/constants/icons'
import { useToasts } from '../toasts'
import { INVOICE_RESOURCE_TYPE, PROPOSAL_RESOURCE_TYPE, WO_FETCH_PARAM_TYPE } from '@/constants/resource-types'
import { useSession } from '../session'
import { useUsersCompanies } from '../users-companies'
import { MenuAction, ResourceAction, ResourceActionMap } from '@/types/interfaces'
import { DirectionalResourceAction } from '@/types/interfaces/global/resource-actions'
import { reactive, ref } from 'vue'
import { Proposal } from '@/types/interfaces/api-v2/proposal'
import { findCompanyType } from '@/utils/company'
import { useInboundProposals } from './inbound'
import { useOutboundProposals } from './outbound'
import { useDetailsPanel } from '../details-panel'

const PROPOSAL_STATUSES = ['unsaved', 'sent', 'draft', 'rejected', 'rejected_closed', 'accepted', 'accepted_wo', 'accepted_invoiced', 'evaluating', 'ready_for_review']
const SAVED_PROPOSAL_STATUSES = PROPOSAL_STATUSES.filter((status: string) => status !== 'unsaved')

const ACCEPTED_WO_STATUS = 'accepted_wo'

const MODAL_CONTENT_NOTE_COMPONENT = 'uc/resource/modal-content/note'

const statusTransformationMap = (outbound = true) => {
  return {
    accepted: { text: 'Approved', state: 'success', prependIcon: SUCCESS },
    accepted_wo: { text: 'Approved (Work Order)', state: 'success', prependIcon: SUCCESS },
    accepted_invoice: { text: 'Approved (Invoice)', state: 'success', prependIcon: SUCCESS },
    rejected_closed: { text: 'Rejected (Closed)', state: 'error', prependIcon: REJECTED_ALERT },
    sent: { text: outbound ? 'Sent' : 'Received', state: 'warning', prependIcon: outbound ? SEND : RECEIVED },
    rejected: { text: 'Rejected', state: 'error', prependIcon: REJECTED_ALERT },
    evaluating: { text: 'Evaluating', state: 'warning', prependIcon: HELP },
    expired: { text: 'Expired', state: 'error', prependIcon: DATE_EXPIRED },
    draft: { text: 'Draft', state: 'secondary-base', prependIcon: DRAFT },
    ready_for_review: { text: 'Ready For Review', state: 'secondary-base', prependIcon: DRAFT },
    closed: { text: 'Closed', state: 'error', prependIcon: CLOSE },
    unsaved: { text: 'Unsaved', state: 'warning', prependIcon: '' },
    approved: { text: 'Approved', state: 'success', prependIcon: SUCCESS },
    declined: { text: 'Declined', state: 'error', prependIcon: REJECTED_ALERT }
  } as any
}

const universalProposalActions = [
  // export action should not show on any list row menus so leave all config empty.
  // however, its a bulk action so we must generate a listener for it by adding the config here.
  {
    name: 'Download',
    primaryAction: {
      outbound: [
      ],
      inbound: []
    },
    validStatuses: {
      outbound: SAVED_PROPOSAL_STATUSES,
      inbound: SAVED_PROPOSAL_STATUSES
    },
    detailsPanel: DetailsPanelButtonState.Icon,
    buttonIcon: PDF_DOWNLOAD

  },
  {
    name: 'Print',
    primaryAction: {
      outbound: [
        'accepted_invoiced'
      ],
      inbound: [
        'accepted_invoiced'
      ]
    },
    validStatuses: {
      outbound: SAVED_PROPOSAL_STATUSES,
      inbound: SAVED_PROPOSAL_STATUSES
    },
    detailsPanel: DetailsPanelButtonState.Icon,
    buttonIcon: PRINT
  },
  {
    name: 'View',
    primaryAction: {
      outbound: ['accepted_wo'],
      inbound: ['accepted_wo']
    },
    validStatuses: {
      outbound: SAVED_PROPOSAL_STATUSES,
      inbound: SAVED_PROPOSAL_STATUSES
    },
    detailsPanel: DetailsPanelButtonState.Hide
  },
  {
    name: 'Share',
    primaryAction: {
      outbound: [],
      inbound: []
    },
    validStatuses: {
      outbound: SAVED_PROPOSAL_STATUSES,
      inbound: SAVED_PROPOSAL_STATUSES
    },
    detailsPanel: DetailsPanelButtonState.Icon,
    buttonIcon: SHARE
  }
] as DirectionalResourceAction[]

const proposalMenuActionsMap = {
  outbound: {},
  inbound: {}
} as Record<string, Record<string, MenuAction[]>>

export const PROPOSAL_WORK_ORDER_ACTIONS = {
  review: {
    value: 'proposal-review',
    requiresConfirmation: false,
    actionFn: (proposalIds: (string | number)[]) => {
      const proposalId = proposalIds[0]
      // functionality to be added later
      console.log('Review action for proposal', proposalId)
    }
  },
  approve: {
    value: 'proposal-approve',
    requiresConfirmation: false,
    actionFn: (proposalIds: (string | number)[]) => {
      const proposalId = proposalIds[0]
      // functionality to be added later
      console.log('Approve action for proposal', proposalId)
    }
  },
  edit: {
    value: 'proposal-edit',
    requiresConfirmation: false,
    actionFn: (proposalIds: (string | number)[]) => {
      const proposalId = proposalIds[0]
      // functionality to be added later
      console.log('Edit action for proposal', proposalId)
    }
  }
}

export const useProposals = () => {
  const proposalsTable = ref<any>(null)
  const {
    configureDetailsPanel,
    sendResources,
    sharedBasicActions
  } = useTransactionResources()
  const { addToast } = useToasts()
  const { createSubdomainURL } = useSession()
  const { createBackgroundTask } = useBackgroundTasks()
  const { companyType, optionalDynamicComponentProps, setBottomHeight } = useApp()
  const { currentUsersCompany } = useUsersCompanies()

  const proposals:{ loading:boolean,
    list: any,
    latest: any,
    meta:any,
    createdProposal: any,
    createdError: boolean,
    sentError: boolean,
    emailResponse: any,
  } = reactive({
    loading: false,
    list: [],
    latest: [],
    meta: {
      currentPage: 1,
      nextPage: 2,
      prevPage: null,
      totalCount: 0,
      totalPages: 0
    },
    createdProposal: {
      content: {},
      type: ''
    },
    createdError: false,
    sentError: false,
    emailResponse: {}
  })

  const proposalActionModalProps = (outbound = true) => {
    const actionData = outbound ? outboundActionData : inboundActionData
    return {
      delete: {
        dynamicContentProps: {
          allowedStatuses: actionData.find((action: ResourceAction) => action.name === 'Delete')?.validStatuses || [],
          resultingStatus: '',
          numberIdKey: 'estimateNumber'
        },
        modalCardProps: {
          headerText: 'Delete',
          headerIcon: 'delete',
          size: 'md'
        },
        modalContentComponent: null,
        confirmButtonProps: {
          text: 'DELETE',
          state: 'error'
        }
      },
      close: {
        dynamicContentProps: {
          allowedStatuses: actionData.find((action: ResourceAction) => action.name === 'Close')?.validStatuses || [],
          resultingStatus: '',
          numberIdKey: 'estimateNumber'
        },
        modalCardProps: {
          headerText: 'Close',
          headerIcon: CLOSE.split('mdi-')[1],
          size: 'md'
        },
        modalContentComponent: MODAL_CONTENT_NOTE_COMPONENT,
        confirmButtonProps: {
          text: 'CLOSE',
          state: 'error',
          disabled: false
        }
      },
      draft: {
        dynamicContentProps: {
          allowedStatuses: ['ready_for_review'],
          resultingStatus: '',
          numberIdKey: 'estimateNumber'
        },
        modalCardProps: {
          headerText: 'Draft',
          headerIcon: DRAFT.split('mdi-')[1],
          size: 'md'
        },
        modalContentComponent: null,
        confirmButtonProps: {
          text: 'Draft',
          state: 'success',
          disabled: false
        }
      },
      'ready-for-review': {
        dynamicContentProps: {
          allowedStatuses: ['draft'],
          resultingStatus: '',
          numberIdKey: 'estimateNumber'
        },
        modalCardProps: {
          headerText: 'Ready For Review',
          headerIcon: DRAFT.split('mdi-')[1],
          size: 'md'
        },
        modalContentComponent: null,
        confirmButtonProps: {
          text: 'Ready For Review',
          state: 'success',
          disabled: false
        }
      },
      reject: {
        dynamicContentProps: {
          allowedStatuses: actionData.find((action: ResourceAction) => action.name === 'Reject')?.validStatuses || [],
          numberIdKey: 'estimateNumber'
        },
        modalCardProps: {
          headerText: 'Reject Proposal',
          headerIcon: REJECTED_ALERT.split('mdi-')[1],
          size: 'md'
        },
        modalContentComponent: MODAL_CONTENT_NOTE_COMPONENT,
        confirmButtonProps: {
          text: 'REJECT',
          state: 'error',
          disabled: false
        }
      },
      approve: {
        dynamicContentProps: {
          allowedStatuses: actionData.find((action: ResourceAction) => action.name === 'Approve')?.validStatuses || [],
          numberIdKey: 'estimateNumber'
        },
        modalCardProps: {
          headerText: 'Approve Proposal',
          headerIcon: SUCCESS.split('mdi-')[1],
          size: 'md'
        },
        modalContentComponent: MODAL_CONTENT_NOTE_COMPONENT,
        modalContentComponentProps: {
          isApprove: true,
          outbound
        },
        confirmButtonProps: {
          text: 'APPROVE',
          state: 'success',
          disabled: false
        }
      },
      evaluate: {
        dynamicContentProps: {
          allowedStatuses: actionData.find((action: any) => action.name === 'Evaluate')?.validStatuses,
          numberIdKey: 'estimateNumber'
        },
        modalCardProps: {
          headerText: 'Evaluate Proposal',
          headerIcon: HELP.split('mdi-')[1],
          size: 'md'
        },
        modalContentComponent: MODAL_CONTENT_NOTE_COMPONENT,
        confirmButtonProps: {
          text: 'EVALUATE',
          state: 'primary',
          disabled: false
        }
      },
      'create-work-order': {
        dynamicContentProps: {
          allowedStatuses: actionData.find((action: ResourceAction) => action.name === 'Create Work Order')?.validStatuses,
          numberIdKey: 'estimateNumber'
        },
        modalCardProps: {
          headerText: 'Create Work Order',
          headerIcon: HELP.split('mdi-')[1],
          size: 'md'
        },
        modalContentComponent: null,
        confirmButtonProps: {
          text: 'YES',
          state: 'primary',
          disabled: false
        }
      }
    }
  }

  const getPDFLink = async (proposalId : string | number, resourceType: string) => {
    try {
      const response = await repositories.proposals.getProposalPDFLink(proposalId, resourceType)
      return response
    } catch (error) {
      console.log(error)
    }
  }

  const generateProposalMenuActions = (proposal: Proposal, isDetailsPanel = false) => {
    const { generateMenuActions } = useListRow()
    const filteredUniversalActions = universalProposalActions.map((action:any) => {
      if (companyType.value === CLIENT && action.name === 'View') {
        action.primaryAction.outbound = ['accepted', 'accepted_wo']
        action.primaryAction.inbound = ['accepted', 'accepted_wo']
      }
      return action
    })
    let outboundActionDataCopy = cloneDeep(outboundActionData)
    let inboundActionDataCopy = cloneDeep(inboundActionData)
    if (companyType.value === VENDOR) {
      const actionsToRemove = ['Reject', 'Approve', 'Evaluate', 'Change Template']
      outboundActionDataCopy = outboundActionDataCopy.filter(item => !actionsToRemove.includes(item.name))
    }
    if (companyType.value !== BROKER) {
      outboundActionDataCopy = outboundActionDataCopy.filter(item => item.name !== 'Create Work Order')
    }
    if (companyType.value === CLIENT) {
      inboundActionDataCopy = inboundActionDataCopy.filter(item => item.name !== 'Edit')
    }
    proposalMenuActionsMap.outbound = generateMenuActions(PROPOSAL_STATUSES, proposalMenuActionsMap.outbound, filteredUniversalActions, outboundActionDataCopy, 'outbound', isDetailsPanel, findCompanyType(proposal?.company?.roles || []))
    proposalMenuActionsMap.inbound = generateMenuActions(PROPOSAL_STATUSES, proposalMenuActionsMap.inbound, filteredUniversalActions, inboundActionDataCopy, 'inbound', isDetailsPanel, null)
  }

  const proposalActionListenersAndFunctions: any = {
    review: (
      proposals: any[],
      { toggleFullScreenView, setSelectedResources, localDetailsPanelComponentProps }:any
    ) => {
      if (localDetailsPanelComponentProps) {
        localDetailsPanelComponentProps.resourceType = PROPOSAL_RESOURCE_TYPE
      }
      configureDetailsPanel(proposals, toggleFullScreenView, setSelectedResources)
    },
    draft: (
      proposals: Proposal[],
      { toggleFullScreenView, setSelectedResources, localDetailsPanelComponentProps }:any
    ) => {
      if (localDetailsPanelComponentProps) {
        localDetailsPanelComponentProps.resourceType = PROPOSAL_RESOURCE_TYPE
      }
      configureDetailsPanel(proposals, toggleFullScreenView, setSelectedResources)
    },
    'ready-for-review': (
      proposals: Proposal[],
      { toggleFullScreenView, setSelectedResources, localDetailsPanelComponentProps }:any
    ) => {
      if (localDetailsPanelComponentProps) {
        localDetailsPanelComponentProps.resourceType = PROPOSAL_RESOURCE_TYPE
      }
      configureDetailsPanel(proposals, toggleFullScreenView, setSelectedResources)
    },
    send: (proposals: any[], additionalParams: any) => {
      proposals = !proposals[0].props ? proposals : proposals.map((proposal: any) => proposal.props.proposal)
      let params = null
      if (additionalParams.bulkSelectionData?.isBulkExclusive) {
        params = additionalParams.selectAllParams
      }
      sendResources(proposals, PROPOSAL_RESOURCE_TYPE, params ? additionalParams.fetchFn : null, params)
      additionalParams.hidePanel()
    },
    resend: (proposals: any[], { hidePanel }: any) => {
      !proposals[0].props ? sendResources(proposals, PROPOSAL_RESOURCE_TYPE) : sendResources(proposals.map((proposal: any) => proposal.props.proposal), PROPOSAL_RESOURCE_TYPE)
      hidePanel()
    },
    edit: (proposals: any, { emit, rowProps }:any, outbound: boolean) => {
      if (emit) {
        emit('edit')
      } else {
        const { openGlobalDetailsPanel } = useDetailsPanel()
        let actions
        if (outbound) {
          const { basicProposalOutboundActions, proposalOutboundModalRequiredActions } = useOutboundProposals()
          actions = [
            // Deleting bulkConfig for hiding bulk action for the proposal
            ...basicProposalOutboundActions(companyType.value).map((item: any) => {
              const temp = Object.assign({}, item)
              delete temp.bulkConfig
              return temp
            }),
            ...proposalOutboundModalRequiredActions
          ]
        } else {
          const { proposalInboundModalRequiredActions, basicProposalInboundActions } = useInboundProposals()

          actions = [
            // Deleting bulkConfig for hiding bulk action for the proposal
            ...basicProposalInboundActions.map((item: any) => {
              const temp = Object.assign({}, item)
              delete temp.bulkConfig
              return temp
            }),
            ...proposalInboundModalRequiredActions]
        }
        openGlobalDetailsPanel('uc/resource-details', {
          actions,
          selectedResources: proposals,
          resourceType: PROPOSAL_RESOURCE_TYPE,
          canDoubleClickToEdit: false,
          outbound
        }, '', { }, { })
      }
    },
    'generate-invoice': (proposals: any, { detailsRef, localDetailsPanelComponentProps }:any, outbound = true) => {
      if (proposals[0].workOrderId) {
        if (localDetailsPanelComponentProps) {
          localDetailsPanelComponentProps.resourceType = INVOICE_RESOURCE_TYPE
        }
        const resource:any = {
          resources: [
            {
              resource_id: proposals[0].workOrderId,
              resource_type: WO_FETCH_PARAM_TYPE,
              trip_ids: [],
              location_id: proposals[0].locationId
            }

          ]
        }
        outbound ? resource.clientId = proposals[0].companyId : resource.vendorId = proposals[0].vendorId

        detailsRef.enterCreateResourceFlow(resource, 1, INVOICE_RESOURCE_TYPE)
      } else {
        console.error('TO DO: you need a workorder id to generate an invoice')
      }
    },
    print: async (
      proposals: any[],
      additionalParams: any,
      outbound = true
    ) => {
      if (proposals.length === 1) {
        additionalParams.pdfViewerRef.setLoading(true)
        const url = await getPDFLink(proposals[0].id, outbound ? 'proposals' : 'inbound_proposals')
        additionalParams.pdfViewerRef.setLoading(false)
        if (!url) {
          addToast({ timeout: 3000, color: 'error', message: 'Failed to print PDF', prependIcon: ERROR })
          return
        }
        additionalParams.pdfViewerRef.show(url)
      } else if (proposals.length > 1) {
        const defaultQ = additionalParams?.exportQuery()
        const handleQuery = () => {
          const idPKey = additionalParams.bulkSelectionData.isBulkExclusive ? 'id_not_in' : 'id_in'
          const q = {
            ...defaultQ,
            [idPKey]: additionalParams.trackedResourceIds
          }
          return q
        }
        await createBackgroundTask({
          jobType: 'proposals',
          source: BackgroundTaskSources.QUERY,
          query: JSON.stringify(handleQuery()),
          format: BackgroundTaskFormats.PDF,
          reportType: outbound ? BackgroundTaskReportTypes.OUTBOUND : BackgroundTaskReportTypes.INBOUND,
          zipFileType: BackgroundTaskZipFileTypes.MULTIPLE_PDF
        })
        addToast({ timeout: 3000, color: 'primary', message: 'Your PDFs are being processed', prependIcon: PDF_DOWNLOAD })
      }
    },
    download: async (
      proposals: any[],
      additionalParams: any,
      outbound = true
    ) => {
      // we can generate PDF for a single proposal at a time
      if (proposals.length === 1) {
        additionalParams.pdfViewerRef.setLoading(true)
        const url = await getPDFLink(proposals[0].id, outbound ? 'proposals' : 'inbound_proposals')
        additionalParams.pdfViewerRef.setLoading(false)
        if (!url) {
          addToast({ timeout: 3000, color: 'error', message: 'Failed to print PDF', prependIcon: ERROR })
          additionalParams.pdfViewerRef.setLoading(false)
          return
        }
        const blob = new Blob([new Uint8Array(url)], {
          type: 'application/pdf'
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${proposals[0].estimateNumber}_${outbound ? 'proposals' : 'inbound_proposals'}.pdf`
        link.click()

        addToast({
          timeout: 3000,
          color: 'primary',
          message: 'Your PDF is being downloaded',
          prependIcon: PDF_DOWNLOAD
        })
      }
    },
    share: (proposals: any[], { rowProps }:any, outbound: boolean) => {
      const url = createSubdomainURL(currentUsersCompany.value.subdomain, `/proposals/${proposals[0].id}?${outbound ? 'outbound=true' : 'outbound=false'}`)
      navigator.clipboard.writeText(url).then(function () {
        addToast({
          message: 'Link copied to clipboard',
          timeout: 5000,
          prependIcon: SHARE,
          color: 'primary'
        })
      })
    },
    view: (
      proposals: any[],
      { toggleFullScreenView, localDetailsPanelComponentProps }:any,
      outbound = true
    ) => {
      localDetailsPanelComponentProps.resourceType = PROPOSAL_RESOURCE_TYPE
      if (!localDetailsPanelComponentProps.canDoubleClickToEdit) {
        localDetailsPanelComponentProps.canDoubleClickToEdit = true
      }
      toggleFullScreenView()
      setBottomHeight('lg')
    }
  }

  const shared = (outbound = true) => {
    return [
      ...sharedBasicActions(proposalActionListenersAndFunctions, outbound).filter((action:any) => action.value !== 'export'),
      {
        value: 'download',
        bulkConfig: {
          type: 'icon',
          icon: PDF_DOWNLOAD,
          text: 'Download PDF'
        },
        actionFn: (proposals: any[], additionalParams: any) => {
          proposalActionListenersAndFunctions.download(
            proposals,
            additionalParams,
            additionalParams?.rowProps?.outbound || !!additionalParams.detailsPanelComponentProps?.outbound
          )
        },
        noSuccessEmit: true
      },
      {
        value: 'review',
        bulkConfig: {
          type: 'primary'
        },
        updateSelectedResource: true,
        actionFn: (proposals: any[], additionalParams: any) => {
          proposalActionListenersAndFunctions.review(
            proposals,
            additionalParams,
            outbound || additionalParams?.rowProps?.outbound || !!additionalParams.detailsPanelComponentProps?.outbound
          )
        },
        noSuccessEmit: true
      },
      {
        value: 'edit',
        updateSelectedResource: true,
        bulkConfig: {
        },
        actionFn: (proposals: any[], additionalParams: any) => {
          proposalActionListenersAndFunctions.edit(
            proposals,
            additionalParams,
            outbound || additionalParams?.rowProps?.outbound || !!additionalParams.detailsPanelComponentProps?.outbound
          )
        },
        noSuccessEmit: true
      },
      {
        value: 'skip'
      }
    ]
  }

  const genProposalChipProps = ({ proposal, outbound = true }: { proposal: any, outbound: boolean | undefined }) => {
    let appendedText = ''
    const { proposalDate, status } = proposal
    const statusMap = statusTransformationMap(outbound)
    let { text, state, prependIcon } = statusMap[snakeCase(status)]

    if (proposalDate) {
      // set these dates to start of day so we dont get partial days and rounding errors
      const dueDateMoment = moment(proposalDate).startOf('day')
      const today = moment().startOf('day')
      const diff = dueDateMoment.diff(today, 'days')
      const validStatuses = ['accepted_wo', 'accepted', 'accepted_invoiced', 'rejected', 'rejected_closed']
      // we only want to add date countdowns or show 'Expired' for statuses: draft, sent, evaluating
      if (validStatuses.includes(status)) {
        appendedText = ''
      } else if (diff < 0) {
      // Expiration
        statusMap.expired.text = startCase(text) + ' ' + statusMap.expired.text
        return {
          ...statusMap.expired
        }
      } else if (diff > 0) {
        appendedText += ` Due In ${diff} days`
        if (text === 'Draft' && diff <= 7) state = 'warning'
      } else {
        appendedText += ' Due Today'
        state = 'warning'
      }
    }
    return {
      appendedText,
      state,
      prependIcon,
      text
    }
  }

  const allProposalActions = (outbound = false) => {
    if (!outbound) {
      const { proposalInboundModalRequiredActions, basicProposalInboundActions } = useInboundProposals()
      return [
      // Deleting bulkConfig for hiding bulk action for the proposal
        ...basicProposalInboundActions.map((item: any) => {
          const temp = Object.assign({}, item)
          delete temp.bulkConfig
          return temp
        }),
        ...proposalInboundModalRequiredActions
      ]
    } else {
      const { proposalOutboundModalRequiredActions, basicProposalOutboundActions } = useOutboundProposals()
      return [
        ...basicProposalOutboundActions(companyType.value).map((item: any) => {
          const temp = Object.assign({}, item)
          delete temp.bulkConfig
          return temp
        }),
        ...proposalOutboundModalRequiredActions
      ]
    }
  }

  return {
    proposalMenuActionsMap,
    generateProposalMenuActions,
    proposalActionListenersAndFunctions,
    genProposalChipProps,
    proposalActionModalProps,
    shared,
    SAVED_PROPOSAL_STATUSES,
    ACCEPTED_WO_STATUS,
    getPDFLink,
    proposals,
    allProposalActions,
    proposalsTable
  }
}

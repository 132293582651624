import repositories from '@/repositories'
import { User } from '@/types/interfaces/api-v2/user'
import { HubspotInfo } from './hubspot-chat'
import { UpdateUserParams } from '@/repositories/users'

export interface FetchUserParams {
  id: number
  include?: string
  q?: any
  page?: number
  perPage?: number
}

export interface UserResponse {
  data: {
    success: boolean,
    error?: string,
    user?: User
  }
}

export const useUsers = () => {
  const getUserById = async (params: FetchUserParams) => {
    try {
      const res = await repositories.users.show(params)
      return { data: { ...res, success: true } } as UserResponse
    } catch (err) {
      console.info(err)
      return { data: { success: false } }
    }
  }

  const getHubspotInfo = async (params: FetchUserParams) => {
    try {
      const res = await repositories.users.getHubspotInfo(params)
      return { data: res as HubspotInfo, success: true }
    } catch (err) {
      console.info(err)
      return { success: false }
    }
  }

  const update = async (params: UpdateUserParams) => {
    try {
      const res = await repositories.users.update(params)
      return { data: res as HubspotInfo, success: true }
    } catch (err) {
      console.info(err)
      return { success: false }
    }
  }

  return {
    getUserById,
    getHubspotInfo,
    update
  }
}

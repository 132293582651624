import { api } from '@/api'
import qs from 'qs'
import { NewVendorCompliancy } from '@/types/interfaces/api-v2/vendor-compliancy'

interface getVendorCompliancesParams {
  perPage?: number,
  page?: number,
  q?: any,
  s?: any,
  include?: any,
  vendorId?: number,
  includeComplianceStatus?: boolean
}

interface getVendorComplianceRequirementsParams {
  vendorComplianceId?: number,
  perPage?: number,
  page?: number,
  q?: any,
  s?: any,
  include?: any,
  vendorId?: number
  includeComplianceDocumentStatus?: boolean,
  onlyRequiredDocuments?: boolean
}

export default {
  getVendorCompliances: ({ perPage, page, include, q, s, includeComplianceStatus, vendorId }:getVendorCompliancesParams) => {
    return api.$get('/settings/vendor_compliances', {
      params: {
        include,
        q,
        page,
        perPage,
        s,
        includeComplianceStatus,
        vendorId
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  getVendorComplianceRequirements: ({ vendorComplianceId, perPage, page, include, q, s, vendorId, includeComplianceDocumentStatus, onlyRequiredDocuments }:getVendorComplianceRequirementsParams) => {
    return api.$get(`/settings/vendor_compliances/${vendorComplianceId}/compliance_documents`, {
      params: {
        include,
        q,
        page,
        perPage,
        s,
        vendorId,
        onlyRequiredDocuments,
        includeComplianceDocumentStatus
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  addVendorCompliance: (params: NewVendorCompliancy) => {
    return api.$post('/settings/vendor_compliances', { vendor_compliance: params },
      {
        paramsSerializer: (params: any) => {
          return qs.stringify(params)
        }
      })
  },
  updateVendorCompliance: (params: NewVendorCompliancy, id: number) => {
    return api.$patch(`/settings/vendor_compliances/${id}`, { vendor_compliance: params },
      {
        paramsSerializer: (params: any) => {
          return qs.stringify(params)
        }
      })
  },
  deleteVendorCompliance: (id: number) => {
    return api.$delete(`/settings/vendor_compliances/${id}`)
  },
  getVendorComplianceSetting: () => {
    return api.$get('/settings/compliances')
  },
  updateVendorComplianceStatus: (params: any) => {
    return api.$patch('/settings/compliances/create_or_update', { compliance_setting: params },
      {
        paramsSerializer: (params: any) => {
          return qs.stringify(params)
        }
      }
    )
  }
}

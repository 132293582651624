/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./base-card.vue?vue&type=template&id=5bbface2&scoped=true&"
import script from "./base-card.vue?vue&type=script&lang=ts&setup=true&"
export * from "./base-card.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./base-card.vue?vue&type=style&index=0&id=5bbface2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bbface2",
  null
  
)

export default component.exports
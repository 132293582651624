import { useInboundInvoices } from '@/use/invoices/inbound'
import { useOutboundInvoices } from '@/use/invoices/outbound'
import { cloneDeep } from 'lodash'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { ref } from 'vue'
import { FilterResources } from '@/types/enums/filter-types'
import { CompanyRoleTypes } from '@/types/enums'

const { inboundInvoicesAutoComplete } = useInboundInvoices()
const { invoicesAutoComplete, statuses } = useOutboundInvoices()

const isOutbound = ref(false)

// const invoiceStatuses: Record <string, string> = {
//   Draft: 'draft',
//   Sent: 'sent',
//   Received: 'sent',
//   Paid: 'paid',
//   'Partially Paid': 'partially_paid',
//   Void: 'void',
//   Approved: 'approved',
//   Rejected: 'rejected',
//   'Approved To Send': 'approved_to_send'
// }

interface CalculatedStaus {
  code: number;
  text: string;
}

interface InvoiceAutocompletePayload extends AutocompletePayload {
  finderParams?: {
    includeGrouped?: boolean,
    objectScope?: string
  }
}
const invoiceStatuses: {[CompanyRoleTypes.CLIENT]: CalculatedStaus[], [CompanyRoleTypes.VENDOR]: CalculatedStaus[], both: CalculatedStaus[] } = {
  client: [],
  vendor: [],
  both: []
}

const getInvoiceStatuses = async (as: CompanyRoleTypes | 'both') => {
  if (invoiceStatuses[as].length) return invoiceStatuses[as]

  const res = await statuses(as)

  if (res.data) {
    invoiceStatuses[as] = res.data
    return invoiceStatuses[as]
  } else {
    return []
  }
}

const invoiceAutoCompleteFetchFn = async (params: InvoiceAutocompletePayload) => {
  if (params.finderParams) {
    params.finderParams.includeGrouped = true
    params.finderParams.objectScope = 'both'
  } else {
    params.finderParams = { includeGrouped: true, objectScope: 'both' }
  }

  const { data, nextPage } = (isOutbound.value)
    ? await invoicesAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number }
    : await inboundInvoicesAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number }
  return {
    data: data.map((item: AutocompleteResponse, index: number) => {
      return {
        id: `${index}-${item.text}`,
        text: item.text,
        value: item.text
      }
    }),
    nextPage
  }
}

const INVOICE_NUMBER = (outbound: boolean, config = {}, params = { field: 'invoice_number' }, fetchFn: any = invoiceAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-number-filter',
      headerText: 'Invoice Number',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['invoice_number'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_CUSTOM_NUMBER = (outbound: boolean, config = {}, params = { field: 'custom_invoice_number' }, fetchFn: any = invoiceAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Custom Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-custom-number-filter',
      headerText: 'Invoice Custom Number',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['custom_invoice_number'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_STATUS = (as: CompanyRoleTypes | 'both', config = {}) => {
  return {
    text: 'Status',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-status-filter',
      headerText: 'Invoice Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async ({ terms }: { terms?: string }) => {
        const res = await getInvoiceStatuses(as)

        const data = cloneDeep(res).filter((calculatedStaus) => calculatedStaus.text.toLocaleLowerCase().includes(terms?.toLowerCase() || '')).map((calculatedStaus) => {
          return {
            id: calculatedStaus.code,
            text: calculatedStaus.text,
            value: calculatedStaus.code
          }
        })

        return {
          data,
          totalCount: data.length,
          totalPages: 1
        }
      },
      params: { q: { s: 'invoice_status asc' } },
      searchKeys: ['text']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_DATE_OF_ISSUE = (outbound: boolean, config = {}) => {
  return {
    text: 'Date of Issue',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-date-of-issue-filter',
      headerText: 'Invoice Date of Issue',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_DUE_DATE = (outbound: boolean, config = {}) => {
  return {
    text: 'Due Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-due-date-filter',
      headerText: 'Invoice Due Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_RECEIVED_DATE = (config = {}) => {
  return {
    text: 'Received Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-received-date-filter',
      headerText: 'Invoice Received Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_AGREEMENT_CONTRACT_NUMBER = (outbound: boolean, config = {}, params = { field: 'agreement_number' }, fetchFn: any = invoiceAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Schedule Service ID',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-schedule-service-filter',
      headerText: 'Schedule Service ID',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['agreement_number'],
      collectionPredicate: 'eq'
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_AGREEMENT_CUSTOM_NUMBER = (outbound: boolean, config = {}, params = { field: 'agreement_custom_no' }, fetchFn: any = invoiceAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Schedule Service Custom ID',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-schedule-service-custom-id-filter',
      headerText: 'Schedule Service Custom ID',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['agreement_custom_no']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_EXTERNAL_REGION = (outbound: boolean, config = {}, params = { field: 'inv_region' }, fetchFn: any = invoiceAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Invoice External Region',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-external-region-filter',
      headerText: 'Invoice External Region',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['inv_region']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_CLIENT_PO_NUMBER = (outbound: boolean, config = {}, params = { field: 'po_number' }, fetchFn: any = invoiceAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Client PO Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-client-po-number-filter',
      headerText: 'Invoice Client PO Number',
      size: 'lg',
      contentType: 'number',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['po_number']
    },
    pinned: false,
    isPrimary: false,
    ...config
  }
}

const INVOICE_AMOUNT = (outbound: boolean, config = {}, params = { field: 'total_in_dollar' }, fetchFn: any = invoiceAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Amount',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-total-in-dollar-filter',
      headerText: 'Invoice Amount',
      size: 'lg',
      contentType: 'number-picker',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['total_in_dollar'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    ...config
  }
}

export {
  INVOICE_NUMBER,
  INVOICE_CUSTOM_NUMBER,
  INVOICE_STATUS,
  INVOICE_DATE_OF_ISSUE,
  INVOICE_DUE_DATE,
  INVOICE_RECEIVED_DATE,
  INVOICE_AGREEMENT_CONTRACT_NUMBER,
  INVOICE_AGREEMENT_CUSTOM_NUMBER,
  INVOICE_EXTERNAL_REGION,
  INVOICE_CLIENT_PO_NUMBER,
  INVOICE_AMOUNT
}


import {
  PAYMENT_REFERENCE_NUMBER,
  PAYMENT_METHOD,
  PAYMENT_DEPOSIT_DATE,
  PAYMENT_STATUS
} from '@/constants/filters/resources/payments'

import {
  VENDOR_NAME
} from '@/constants/filters/resources/vendor'

import { useMakePayment } from '@/use/makePayment'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { WORK_ORDER_START_DATE, WORK_ORDER_EXPIRATION_DATE } from '../resources/workOrder'
import { TRIP_CREATION_DATE, TRIP_EXPIRATION_DATE } from '../resources/trip'

export const madePaymentFilters = () => {
  const { madePaymentsAutoComplete } = useMakePayment()

  const madePaymentsAutoCompleteFetchFn = async (params: AutocompletePayload) => {
    const { data, nextPage } = await madePaymentsAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number }
    return {
      data: data.map((item: AutocompleteResponse, index: number) => {
        return {
          id: `${index}-${item.text}`,
          text: item.text,
          value: item.text
        }
      }),
      nextPage
    }
  }

  return {
    filters: [
      PAYMENT_REFERENCE_NUMBER(false, { pinned: false, isPrimary: false }),
      PAYMENT_METHOD(false, { pinned: false, isPrimary: false }),
      PAYMENT_DEPOSIT_DATE(false, { pinned: true, isPrimary: true }),
      PAYMENT_STATUS(false, { pinned: false, isPrimary: false }),
      VENDOR_NAME({ pinned: true, isPrimary: true }, { field: 'vendor' }, madePaymentsAutoCompleteFetchFn),
      WORK_ORDER_START_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_EXPIRATION_DATE({ pinned: false, isPrimary: false }),
      TRIP_CREATION_DATE({ pinned: false, isPrimary: false }),
      TRIP_EXPIRATION_DATE({ pinned: false, isPrimary: false })
    ],
    sortBy: {
      value: 'payment_date',
      direction: {
        text: 'Descending',
        value: 'desc'
      },
      items: [
        {
          label: 'Payment Date',
          value: 'payment_date'
        },
        {
          label: 'Payment Id',
          value: 'id'
        }
      ]
    },
    fieldMapping: {
      'vendor-name': {
        name: 'vendor_name',
        field: 'value'
      },
      'payment-client-name': {
        name: 'company_name',
        field: 'value'
      },
      'reference-number': {
        name: 'reference_number',
        field: 'value'
      },
      'payment-method': {
        name: 'payment_method',
        field: 'value'
      },
      'deposit-account': {
        name: 'ledger_account_name',
        field: 'value'
      },
      'deposit-date': {
        name: 'payment_date',
        field: 'value'
      },
      amount: {
        name: 'amount_in_dollar',
        field: 'value'
      },
      status: {
        name: 'status',
        field: 'value'
      },
      'work-order-start-date': {
        name: 'invoices_work_order_created_at',
        field: 'value'
      },
      'work-order-expiration-date': {
        name: 'invoices_work_order_expiration_date',
        field: 'value'
      },
      'trip-creation-date': {
        name: 'invoices_trips_start_date',
        field: 'value'
      },
      'trip-expiration-date': {
        name: 'invoices_trips_expiration_date',
        field: 'value'
      }
    }
  }
}

import { ref } from 'vue'
import { RequiredActionResourceTypes } from '@/types/enums/required-action/resource-types'
import { INVOICE_RESOURCE_TYPE, PO_RESOURCE_TYPE, PROPOSAL_RESOURCE_TYPE, TRIP, WO_RESOURCE_TYPE } from '@/constants/resource-types'
import { useTrips } from '../trips'
import { useWorkOrders } from '../work-orders'
import { WorkOrder } from '@/types/interfaces'
import { RequiredAction } from '@/types/interfaces/api-v2/required-action'
import { useDetailsPanel } from '../details-panel'
import { useProposals } from '../proposals'
import { useInvoices } from '../invoices'
import { usePurchaseOrders } from '../purchase-orders'
import { useSession } from '../session'
import { findIndex } from 'lodash'

interface SelectedResourceProp {
  resourceType: string,
  workOrderId?: number,
  id: number
}

const DEFAULT_START_INDEX = 0
const currentResourceIndex = ref(DEFAULT_START_INDEX)
const previousResourceIndex = ref(currentResourceIndex.value)
const currentBulkActions = ref<RequiredAction[]>([])
const currentWorkOrder = ref<WorkOrder>()

export const useWorkOrderResolve = () => {
  const openResourceForResolve = async (
    workOrder: WorkOrder,
    requiredAction: RequiredAction[],
    currentIndex = DEFAULT_START_INDEX
  ) => {
    const { hasAccess } = useSession()
    let filteredActions = requiredAction
    if (!hasAccess('invoice')) {
      filteredActions = filteredActions.filter(action => action.resourceType !== RequiredActionResourceTypes.INVOICE)
    }
    if (!hasAccess('proposal')) {
      filteredActions = filteredActions.filter(action => action.resourceType !== RequiredActionResourceTypes.PROPOSAL)
    }
    if (!hasAccess('purchase_order')) {
      filteredActions = filteredActions.filter(action => action.resourceType !== RequiredActionResourceTypes.PURCHASE_ORDER)
    }
    if (!hasAccess('work_orders')) {
      filteredActions = filteredActions.filter(action => action.resourceType !== RequiredActionResourceTypes.TRIP)
    }
    currentResourceIndex.value = currentIndex
    currentWorkOrder.value = workOrder
    currentBulkActions.value = filteredActions
    const { getWorkOrderActions } = useWorkOrders()
    const { getTripsActions } = useTrips()
    const { allProposalActions } = useProposals()
    const { allInvoiceActions } = useInvoices()
    const { allOutboundPurchaseOrderActions } = usePurchaseOrders()
    const currentAction = filteredActions[currentResourceIndex.value]
    const isOutbound = !currentAction.resourceIsReceived

    const detailsPanelData: Record<string, any> = {
      WorkOrder: {
        actions: getWorkOrderActions(),
        detailsPanelPath: 'work-orders/details',
        localDetailsPanelComponentProps: { resourceType: WO_RESOURCE_TYPE }
      },
      Trip: {
        actions: getTripsActions(),
        detailsPanelPath: 'trips/details',
        localDetailsPanelComponentProps: { resourceType: TRIP }
      },
      Proposal: {
        actions: allProposalActions(isOutbound),
        detailsPanelPath: 'uc/resource-details',
        localDetailsPanelComponentProps: { resourceType: PROPOSAL_RESOURCE_TYPE }
      },
      PurchaseOrder: {
        actions: allOutboundPurchaseOrderActions(),
        detailsPanelPath: 'uc/resource-details',
        localDetailsPanelComponentProps: { resourceType: PO_RESOURCE_TYPE }
      },
      Invoice: {
        actions: allInvoiceActions(isOutbound),
        detailsPanelPath: 'uc/resource-details',
        localDetailsPanelComponentProps: { resourceType: INVOICE_RESOURCE_TYPE }
      }
    }
    goToNextResource(currentAction, detailsPanelData, filteredActions, workOrder)
  }

  const goToNextResource = async (currentAction: RequiredAction, detailsPanelData: Record<string, any>, actions: RequiredAction[], workOrder: WorkOrder) => {
    const { openGlobalDetailsPanel, updateGlobalDetailsPanel } = useDetailsPanel()
    const isOutbound = !currentAction.resourceIsReceived
    const resourceDetailsPanelData = detailsPanelData[currentAction.resourceType]
    const detailsProps = {
      totalResources: actions.length,
      inCreateResourceFlow: true,
      currentResourceIndex: currentResourceIndex.value,
      outbound: isOutbound,
      inBulkActionFlow: true,
      canDoubleClickToEdit: true,
      message: currentAction.message,
      resourceType: resourceDetailsPanelData.localDetailsPanelComponentProps.resourceType,
      actions: resourceDetailsPanelData.actions,
      isGlobalDetailsPanel: true
    }

    const selectedResourceProps: SelectedResourceProp = {
      resourceType: detailsProps.resourceType,
      id: currentAction.resourceId
    }
    if (detailsProps.resourceType === TRIP || detailsProps.resourceType === WO_RESOURCE_TYPE) {
      selectedResourceProps.workOrderId = Number(workOrder.id)
    }

    if (currentResourceIndex.value === DEFAULT_START_INDEX && previousResourceIndex.value === 0) {
      openGlobalDetailsPanel(
        resourceDetailsPanelData.detailsPanelPath,
        detailsProps,
        'setSelectedResources',
        [selectedResourceProps],
        { 'change-resource': goToNextTripResource })
    } else {
      updateGlobalDetailsPanel(detailsProps, 'setSelectedResources', [selectedResourceProps], resourceDetailsPanelData.detailsPanelPath)
    }
    previousResourceIndex.value = currentResourceIndex.value
  }

  /**
 * Moves to the next required action for the current work order.
 * It determines where to go next based on the current position and updates the list of actions.
 *
 * @param {number} index - Starting point for searching the next action (default is 0).
 */
  const goToNextTripResource = async (index = 0) => {
  // Check if we are not at the starting position
    if ((currentResourceIndex.value !== DEFAULT_START_INDEX || previousResourceIndex.value !== 0) && currentWorkOrder.value?.id) {
    // Get the list of required actions for the current work order
      const { getWorkOrderRequiredActions } = useWorkOrders()
      const response = await getWorkOrderRequiredActions(Number(currentWorkOrder.value.id))

      if (response.data) { // If the list of actions is available
        let actionsToCheck = []

        // Decide whether to move forward or backward in the list
        if (index > currentResourceIndex.value) {
        // Moving forward: Get actions after the current position
          actionsToCheck = currentBulkActions.value.slice(currentResourceIndex.value + 1)
        } else {
        // Moving backward: Get actions before the current position (in reverse order)
          actionsToCheck = currentBulkActions.value.slice(0, currentResourceIndex.value).reverse()
        }

        // Find the next valid action in the list
        for (const action of actionsToCheck) {
          const nextIndex = findIndex(response.data, action)
          if (nextIndex !== -1) { // If a valid action is found
            index = nextIndex
            break
          }
        }
      }

      // Update the current list of actions with the new data
      currentBulkActions.value = response.data
    }

    // Update the current position
    currentResourceIndex.value = index

    // Open the next resource for resolving actions
    openResourceForResolve(currentWorkOrder.value as WorkOrder, currentBulkActions.value, index)
  }
  return {
    openResourceForResolve
  }
}

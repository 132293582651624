import repositories from '@/repositories'
import { ref } from 'vue'
import { User } from '@/types/interfaces/api-v2/user'
import { Company } from '@/types/interfaces/api-v2/company'
import { ExtendedStatus } from '@/types/enums'
import { AttachmentTripCategory, AttachmentWorkOrderCategory } from '@/types/enums/work-order/attachment'
import { Attachment } from '@/types/interfaces/api-v2/attachment'

export interface AttachedDocument {
  id: number | string
  attachmentUrl: string
  name: string
  downloadUrl?: string,
  user?: User,
  company?: Company
  formattedFileName?: string
  canDelete?: boolean
}

export interface AttachmentCategoryType {
  name: AttachmentWorkOrderCategory | AttachmentTripCategory
}

export interface AttachmentType {
  id?: number | string
  name: string
  type: string
  tripId?: number
}

export interface AttachmentResourceType {
  name: string
  tripId?: number | string
  categories?: AttachmentCategoryType[]
}

export interface AttachmentSelectedResourceInfo {
  type: AttachmentResourceType
  category: AttachmentCategoryType
}

export interface CreateWorkOrderAttachment {
  id?: number | string
  name: string
  description: string
  makeFileAvailableToClient?: boolean
  makeFileAvailableToVendor?: boolean
  remoteAttachmentUrl?: string
  attachment?: AttachedDocument[]
  attachedFile?: any
  attachmentType?: AttachmentType
  user?: User
  attachmentUrl?: string
  formattedFileName?: string
  downloadUrl?: string
  canSyncToSc?: boolean
  isSyncedToSc?: boolean
  syncedToSc?: boolean
  documents?: AttachedDocument[]
  resourceType?: string
  createdAt?: Date | string
  status?: string
  totalCents?: number
  resourceTypeInfo?: AttachmentSelectedResourceInfo
  canDelete?: boolean
  sendToSc?: boolean
}

export interface WorkOrderAttachment extends CreateWorkOrderAttachment {
  company?: Company
  isAttachmentFromWO: boolean
  isEdit: boolean
  fileType: string
}

export interface WorkOrderAnnexedAttachment extends CreateWorkOrderAttachment {
  id: number | string
  resourceType: string
  customId?: string
  estimateNumber?: string
  poNumber?: string
  invoiceDocuments?: WorkOrderAttachment[]
  proposalDocuments?: WorkOrderAttachment[]
  purchaseOrderDocuments?: WorkOrderAttachment[]
}

export interface AttachmentInfo {
  count: number
  attachments: Attachment[]
}

const tripAttachmentsTable = ref<any>(null)

export const useAttachments = () => {
  const getAttachments = async (params: any) => {
    try {
      const res = await repositories.attachments.get(params)
      return {
        data: res.attachments,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const postAttachments = async (params: any) => {
    try {
      const res = await repositories.attachments.post(params)
      return {
        data: res[params.responseKey]
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const patchAttachment = async (params: any) => {
    try {
      const res = await repositories.attachments.patch(params)
      return {
        data: res[params.responseKey]
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const deleteAttachments = async (params:any) => {
    try {
      return await repositories.attachments.delete(params)
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const syncAttachment = async (params: any) => {
    try {
      return await repositories.attachments.syncAttachment(params)
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getWorkOrderTradeAttachment = async (params: any) => {
    try {
      const res = await repositories.attachments.getWorkOrderTradeAttachment(params)
      return {
        data: res.workOrderTradeDocuments,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const tripExtendedStatusIsVerifiedOrCancelled = (trip: { extendedStatus: ExtendedStatus }) => {
    return (trip?.extendedStatus === ExtendedStatus.TRIP_VERIFIED || trip?.extendedStatus === ExtendedStatus.CANCELED)
  }

  return {
    getAttachments,
    postAttachments,
    deleteAttachments,
    patchAttachment,
    syncAttachment,
    getWorkOrderTradeAttachment,
    tripAttachmentsTable,
    tripExtendedStatusIsVerifiedOrCancelled
  }
}

export const attachmentRequestResponseKeys: {
  [key: string]: {
    requestPayloadKey: string;
    responseKey: string;
  };
} = {
  invoices: { requestPayloadKey: 'attachment', responseKey: 'invoiceAttachment' },
  proposals: { requestPayloadKey: 'proposal_document', responseKey: 'proposalDocument' },
  work_orders: { requestPayloadKey: 'work_order_attachment', responseKey: 'workOrderAttachment' }
}

export const formatAttachmentDetails = (attachments: { attachment: string; name: string }[]): { url: string; name: string }[] => {
  return attachments.map((item) => ({
    url: item.attachment,
    name: item.name
  }))
}

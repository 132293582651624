import { useSession } from '@/use/session'
import { WO_ENABLED_SUBDOMAINS, WO_ENABLED_USERS_EMAILS } from '@/constants/env'
import { CLIENT } from '@/constants/permissions'

export const useFeatureFlags = () => {
  const { session } = useSession()

  const hideVueWorkOrder =
    process.env.VUE_APP_MODE === 'preproduction' &&
      !(
        session.currentCompanyType === CLIENT ||
          WO_ENABLED_SUBDOMAINS?.includes(session.currentCompany.applicableSubdomain) ||
            WO_ENABLED_USERS_EMAILS?.includes(session.loggedUser.email)
      )
  return { hideVueWorkOrder }
}

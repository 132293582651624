import { api } from '@/api'
import qs from 'qs'

export default {
  getPackages: ({ page, perPage, include, q }: any) =>
    api.$get('/packages', {
      params: {
        include,
        page,
        perPage,
        q
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    }),
  getPackageAddons: (id: any) => api.$get(`/packages/${id}/addons`),
  getPackageAddonByPriceID: (id: any) =>
    api.$get(`/packages/addons/${id}/pricing`, {
      params: {
        item_price_id_sent: true
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    }),
  getPackageAddonsPricing: ({ id, periodUnit, period }: any) =>
    api.$get(`/packages/addons/${id}/pricing`, {
      params: {
        period_unit: periodUnit,
        period
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    }),
  getPackageAddonsByPriceID: (ids: any) =>
    api.$get('/packages/addons/pricing', {
      params: {
        item_price_id_sent: true,
        ids
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
}

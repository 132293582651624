import { defineComponent, computed, ref } from 'vue';
import { validate } from '@/utils';
import { PIN } from '@/constants/icons';
export default defineComponent({
  props: {
    size: {
      type: String,
      default: 'md',
      validator: val => {
        return ['xs', 'sm', 'md', 'lg'].includes(val.toLowerCase());
      }
    },
    state: {
      type: String,
      default: 'background',
      validator: val => {
        return ['primary', 'warning', 'secondary', 'secondary-base', 'background', 'background-secondary', 'layer', 'layer-selected', 'layer-secondary', 'error', 'success', 'text', 'orange', '#ffd601'].includes(val.toLowerCase());
      }
    },
    closeable: {
      type: Boolean,
      default: false
    },
    quantity: {
      type: [String, Number],
      default: ''
    },
    text: {
      type: [String, Number],
      default: ''
    },
    prependIcon: {
      type: String,
      default: ''
    },
    img: {
      type: String,
      default: ''
    },
    appendIcon: {
      type: String,
      default: ''
    },
    outlined: {
      type: Boolean,
      default: false
    },
    appendedText: {
      type: String,
      default: ''
    },
    clickable: {
      type: Boolean,
      default: false
    },
    noBorderRadius: {
      type: Boolean,
      default: false
    },
    styleObj: {
      type: Object,
      default: () => ({})
    },
    canSelect: {
      type: Boolean,
      default: true
    },
    hideTextOnMobile: {
      type: Boolean,
      default: false
    },
    tooltipPosition: {
      type: String,
      default: 'none'
    },
    showQuantity: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: ''
    },
    paletteColor: {
      type: String,
      default: ''
    },
    hasHoverStyle: {
      type: Boolean,
      default: true
    },
    pinned: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    borderRadius: {
      type: String,
      default: ''
    }
  },
  setup: (props, {
    emit
  }) => {
    const getFontSize = size => {
      if (size === 'xs') return 3;
      if (size === 'sm') return 3;
      if (size === 'md') return 2;
      if (size === 'lg') return 1;
    };
    const textColorClass = computed(() => {
      const classArr = props.state.split(' ');
      if (classArr.length === 1) {
        return props.state + '--text';
      } else {
        return classArr[0] + '--text' + ' ' + 'text--' + classArr[1];
      }
    });
    const hover = ref(false);
    const selected = ref(false);
    const textRef = ref();
    const chipState = computed(() => {
      if (selected.value && hover.value) {
        return props.state + '-active';
      } else if (selected.value) {
        return props.state + '-selected';
      } else if (hover.value && props.hasHoverStyle) {
        return props.state + '-hover';
      } else {
        return props.state;
      }
    });
    const toggleSelected = () => {
      selected.value = !selected.value;
      emit('selected', selected.value);
    };
    const {
      isEllipses
    } = validate;
    return {
      textRef,
      isEllipses,
      getFontSize,
      textColorClass,
      selected,
      hover,
      chipState,
      toggleSelected,
      PIN
    };
  }
});
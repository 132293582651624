import { EVENT_FETCH_PARAM_TYPE, PROPOSAL_RESOURCE_TYPE, WO_FETCH_PARAM_TYPE } from '@/constants/resource-types'
import { VueResourceLineItem } from '@/utils/resource-details'
import { useInvoices } from './invoices'
import { ref } from 'vue'
import { formatNumber, roundFloor, roundToDecimal } from '@/utils/formatting'
import { BROKER, CLIENT } from '@/constants/permissions'
import { cloneDeep, sum } from 'lodash'
import { findCompanyType } from '@/utils/company'
import { NewInvoice, NewInvoiceResource, NewProposal, Trip, WorkOrder, NewTrip } from '@/types/interfaces'
import { Invoice } from '@/types/interfaces/api-v2/invoice'
import { Proposal } from '@/types/interfaces/api-v2/proposal'
import { useTransactionResources } from './transactional-resources'
import { NewPurchaseOrder, PurchaseOrder } from '@/types/interfaces/api-v2/purchase-order'
import { MarkupTypes } from '@/types/enums/markup-types'

interface NTEMap {
  [k:string]: {exceed: boolean, childrenExceed: number, nte: number, total: string}
}

interface WorkOrderLineItems extends WorkOrder {
  rowType: string
  resourceType: string
  generatedDescriptionForResource?: string
  expanded: boolean
  lineItems: VueResourceLineItem[]
  removedLineItems: VueResourceLineItem[]
  peopleOnSiteCount?: number
  tripIds: number[]
}

const vueConfigLineItem:VueResourceLineItem = {
  id: null,
  type: '',
  quantity: 1,
  price: 0,
  originalPrice: 0,
  tax: 0,
  baseTax: 0,
  description: '',
  markup: 0,
  multiplier: 0,
  resourceType: '',
  resourceId: null,
  tripIds: [],
  location: '',
  teamMembersCount: 1,
  measureUnit: {
    id: null,
    name: ''
  },
  derivedItem: null,
  derivedItemId: null,
  itemId: null,
  itemTypeId: null,
  itemTypeName: '',
  itemName: '',
  item: {
    id: null,
    itemType: null
  },
  transformed: true,
  position: 0
}
const lineItemTree = ref<any>([])

export const useLineItems = () => {
  const siteNTE = ref<NTEMap>({})
  const workOrderNTE = ref<NTEMap>({})
  const tripNTE = ref<NTEMap>({})
  const eventNTE = ref<NTEMap>({})
  const lineItemNesting = {} as Record<string, any>
  const generatingLineItems = ref(true)

  const linkedWorkOrders = { data: [] } as {data: WorkOrder[]}
  const linkedEvents = { data: [] } as {data: any[]}

  const perLineItemMarkupsAndTaxMetaData = ref<any>({})

  // Only Labor and Equipment Task T&M line items are impacted by team member count
  const determineTeamMemberCount = (item: {itemTypeName: string, teamMembersCount: number}) => {
    return item.itemTypeName === 'Equipment Task T&M' ? item.teamMembersCount : 1
  }

  const findEventLocation = (resource: NewInvoice, eventId:number) => {
    const locIds = resource.invoiceResources?.filter((invoiceResource: NewInvoiceResource) => invoiceResource.resourceType === EVENT_FETCH_PARAM_TYPE && invoiceResource.resourceId === eventId).map((invoiceResource: NewInvoiceResource) => invoiceResource.locationId)
    return locIds?.[0] || ''
  }

  const fetchEventAndWorkOrderData = async (resource: NewInvoice | Invoice, outbound: boolean) => {
    const { getInvoiceResourcesFetchDataByWorkFlow } = useInvoices()
    const woIds:number[] = resource.invoiceResources?.filter?.((resource: {resourceType: string}) => resource.resourceType === WO_FETCH_PARAM_TYPE)?.map?.((resource: {resourceId: number}) => resource.resourceId) || []
    const eventIds:number[] = resource.invoiceResources?.filter?.((resource: {resourceType: string}) => resource.resourceType === EVENT_FETCH_PARAM_TYPE)?.map?.((resource: {resourceId: number}) => resource.resourceId) || []

    let woFetchFn = async (params: any) => Promise.resolve(params)
    let eventFetchFn = async (params: any) => Promise.resolve(params)

    if (woIds.length) {
      woFetchFn = await getInvoiceResourcesFetchDataByWorkFlow(resource, outbound, false)
    }
    if (eventIds.length) {
      eventFetchFn = await getInvoiceResourcesFetchDataByWorkFlow(resource, outbound, true)
    }
    linkedWorkOrders.data = []
    linkedEvents.data = []

    if (woIds.length) {
      let hasNextPage = true
      let page = 1

      while (hasNextPage) {
        const res = await woFetchFn({ q: { id_in: woIds }, include: 'trips', perPage: 100, page })
        hasNextPage = res.totalPages > page && res.totalPages
        if (hasNextPage) page++
        if (res?.data?.length) {
          linkedWorkOrders.data = [...linkedWorkOrders.data, ...res?.data]
        }
      }
    }
    if (eventIds.length) {
      let hasNextPage = true
      let page = 1
      while (hasNextPage) {
        const res = await eventFetchFn({ q: { id_in: eventIds }, include: 'uc_locations', perPage: 100, page })
        hasNextPage = res.totalPages > page && res.totalPages
        if (hasNextPage) page++
        if (res?.data?.length) {
          linkedEvents.data = [...linkedEvents.data, ...res?.data]
        }
      }
      linkedEvents.data.forEach((e:any) => {
        e.ucLocations = e.ucLocations.filter((loc: {id: number}) => loc.id === findEventLocation(resource, e.id))
      })
    }
  }

  const transformLineItemsForVue = (currLineItems:any[], markupType: MarkupTypes, resourceType: string, outbound: boolean, resourceMultiplierType: MarkupTypes) => {
    if (!currLineItems || !currLineItems.length) return []
    const newLineItems = currLineItems.map((oldObj: any) => {
      if (oldObj.type === 'custom' || oldObj.transformed) return oldObj
      const newObj = {
        ...cloneDeep(vueConfigLineItem),
        ...oldObj
      } as VueResourceLineItem
      const targetKeys = Object.keys(newObj)
      targetKeys.forEach((key:string) => {
        if (key === 'measureUnit') {
          if (oldObj.unit) {
            newObj[key].name = oldObj.unit
          } else if (oldObj.unitOfMeasure) {
            newObj[key].name = oldObj.unitOfMeasure
          } else if (oldObj.measureUnit) {
            newObj[key] = oldObj.measureUnit
          }
        } else if (key === 'price' && Object.keys(oldObj).includes('baseTax')) {
          newObj[key] = newObj.originalPrice = oldObj[key] + (oldObj[key] * parseFloat(oldObj.baseTax) / 100)
        }
      })
      if (resourceType === PROPOSAL_RESOURCE_TYPE && resourceMultiplierType === MarkupTypes.MARKUP) {
        newObj.multiplier = (100 * ((newObj.multiplier || 1) - 1)) / (newObj.multiplier || 1)
      }
      if (!outbound) {
        newObj.price = getMarkupOrMarginPrice(newObj, markupType)
        delete newObj[markupType]
      }
      return newObj
    })

    return newLineItems
  }

  const getMarkupOrMarginPrice = (item:any, markupOrMargin: MarkupTypes) => {
    let priceTimesMarkupOrMargin = 0
    const price = parseFloat(item.price)

    if (item[markupOrMargin] > 0) {
      if (markupOrMargin === MarkupTypes.MARKUP) {
        priceTimesMarkupOrMargin = price * item[markupOrMargin]
      } else {
        priceTimesMarkupOrMargin = price / (1 - (item[markupOrMargin] / 100))
      }
    } else {
      priceTimesMarkupOrMargin = item.price
    }
    return roundFloor(priceTimesMarkupOrMargin)
  }

  const resetNTE = () => {
    workOrderNTE.value = {}
    tripNTE.value = {}
    siteNTE.value = {}
  }
  /*
  Calculate totals given:
  1. A list of line items
  2. If its inbound or outbound (this impacts if markup is included)
  3. A discount (by default zero which means do not calc a discount)
  4. Is it the grand total - this impacts if tax and discount are included
  */
  const calcTotal = (lineItems:any, discount = 0, isGrandTotal = false, markupOrMargin: MarkupTypes, version?: 'current' | 'old', withoutTax = false) => {
    return formatNumber(lineItems?.reduce((sum:number, item:any) => {
      const price = getMarkupOrMarginPrice(item, markupOrMargin)

      // only use team member count for Labor and Equipment T&M Item Types
      const teamMemberCount = determineTeamMemberCount(item)
      const priceTimesQtyTimesPeople = roundToDecimal((parseFloat(item.quantity) * (price)) * teamMemberCount, 2)
      if (isGrandTotal) {
        const totalWithoutTax = priceTimesQtyTimesPeople
        if (!version || version === 'current') {
          const discountAmount = parseFloat(formatNumber((discount > 0 ? (priceTimesQtyTimesPeople * discount / 100) : 0), { decimals: 2 }))
          const total = totalWithoutTax - discountAmount
          if (withoutTax) {
            return roundToDecimal(sum + total, 2)
          } else {
            // calculate tax after discount is applied
            let totalAfterTax = 0
            if (item.lineItemTaxes?.length > 0) {
              totalAfterTax = getTaxRate(total, item.lineItemTaxes)
            } else {
              totalAfterTax = roundToDecimal((parseFloat(item.tax) / 100) * total, 2)
            }
            return sum + (total + totalAfterTax)
          }
        } else {
          const discountPrice = discount > 0 ? totalWithoutTax * (discount / 100) : 0
          if (withoutTax) {
            return sum + (totalWithoutTax - discountPrice)
          } else {
            const totalAfterTax = totalWithoutTax + ((parseFloat(item.tax) / 100) * totalWithoutTax)
            return sum + (totalAfterTax - discountPrice)
          }
        }
      } else {
        return sum + (priceTimesQtyTimesPeople)
      }
    }, 0))
  }

  const getTaxRate = (total: number, itemTaxRates: [{ id: number, tax: number, name: string }]) => {
    return sum(itemTaxRates.map((itemTaxRate: { id: number, tax: number, name: string }) => roundToDecimal((itemTaxRate.tax / 100) * total, 2)))
  }

  /*
  Create the nested object that matches what batch would be
  1. Sites
  2. Work Orders/Events
  Each Work Order/Event will have a list of line items and trips used in other calculations
  */
  const createLineItemTree = (resource: Proposal | NewProposal | Invoice | NewInvoice | PurchaseOrder | NewPurchaseOrder) => {
    const temp = cloneDeep(resource.locations)
    // loop through each location
    temp?.forEach((location:any, index: number) => {
      location.expanded = true
      if (!perLineItemMarkupsAndTaxMetaData.value[location.id]) {
        perLineItemMarkupsAndTaxMetaData.value[location.id] = { lineItemMetaData: { taxExempt: false, fetchParams: {}, defaultLocationTax: [{ tax: 0, name: '', amount: 0 }] } }
      }

      if (index === 0) {
        location.taxExempt = perLineItemMarkupsAndTaxMetaData.value[location.id].lineItemMetaData.taxExempt
      }
      perLineItemMarkupsAndTaxMetaData.value = Object.assign({}, perLineItemMarkupsAndTaxMetaData.value)
      // get WO data for all work orders at that location
      const atWOLocation = linkedWorkOrders.data?.filter?.((wo:WorkOrder) => wo.locationIds?.[0] === location.id) || []
      // add WO data to the location
      location.resources = [...atWOLocation.map((wo: WorkOrder) => {
        const obj = wo as WorkOrderLineItems
        obj.rowType = 'work-order'
        obj.resourceType = WO_FETCH_PARAM_TYPE
        obj.expanded = true
        obj.lineItems = []
        obj.removedLineItems = []
        obj.tripIds = []
        const filteredRecord = (resource as Invoice).invoiceResources?.filter?.((resource: { resourceId: number, resourceType: string}) => resource.resourceType === WO_FETCH_PARAM_TYPE && resource.resourceId === obj.id)?.[0]
        if (filteredRecord) {
          obj.generatedDescriptionForResource = filteredRecord.generatedDescriptionForResource
          obj.peopleOnSiteCount = filteredRecord.peopleOnSiteCount
        }
        return obj
      })]
      // get Event data for all work orders at that location
      const atEventLocation = linkedEvents.data?.filter?.((event:any) => {
        return event.ucLocations?.[0]?.id === location.id
      }) || []
      // add event data to the location
      location.resources = [...location.resources, ...atEventLocation.map((event: any) => {
        event.rowType = 'weather-event'
        event.resourceType = EVENT_FETCH_PARAM_TYPE
        event.lineItems = []
        event.removedLineItems = []
        event.expanded = true
        event.tripIds = []
        return event
      })]
      // no resource associated with the line item (aka no work order for invoice)
      location.resources.push({
        rowType: 'none',
        id: 'none',
        lineItems: [],
        removedLineItems: [],
        expanded: true,
        tripIds: []
      })
    })
    lineItemTree.value = temp
  }

  /*
  For each resource in the line item tree, add all the associated line items
  */
  const getLineItemsPerResource = (lineItems: VueResourceLineItem[]) => {
    lineItemTree.value?.forEach((location:any) => {
      location?.resources?.forEach((resource:any) => {
        resource.lineItems = lineItems.filter((lineItem:VueResourceLineItem) => {
          return (lineItem.resourceType === resource.resourceType && lineItem.resourceId === resource.id) || (lineItem.resourceId === 'none' && resource.id === 'none')
        })

        resource.tripIds = resource.lineItems.reduce((arr: number[], lineItem: VueResourceLineItem) => arr.concat(lineItem.tripIds), [])
      })
    })
  }

  /*
  Get color of text when there is an NTE.
  If the NTE is not exceeded (check if me or my children have) return '' -- defaults to normal text color
  Else I have exceed, if that is not allowed, return error else just warning
  Potentially no NTE information (no line items for that WO/location) so null or undefined could be passed
  */
  const getNTEColor = (resourceNTEDetails: {exceed: boolean, childrenExceed: number} | null | undefined, canExceed: boolean) => {
    if (!resourceNTEDetails?.exceed && !resourceNTEDetails?.childrenExceed) return ''
    else {
      if (canExceed) return 'warning'
      else return 'error'
    }
  }

  /*
For each level of nesting (site, resource, trips) calculate the values needed:
1. childrenExceeded: This is how many nested children exceed.  For example, at the site level how many WOs exceed their NTE
2. nte: the net for that level of nesting.  Sites have no NTE.  WOs only have NTE if its an invoice to a client, else NTE is on the trip level
3. exceed: does this resource exceed its NTE
4. total: what is the total for this resource. For example, at a site add up the total for all line items across all WOs at that site

These results are stored in a unique object per "level" -- sites, work orders and events, and trips -- this makes it really easy to get the alerts since we just need to fetch the correct object and then its index by that resource's id

if a singleLineItem was updated, optional params for location and resourceId are passed so we only need to check those branches of the tree
*/
  const getExceedByResource = (lineItems: VueResourceLineItem[], clientRole: string, outbound: boolean, canExceed: boolean, siteId = '' as string | number, linkedLineItems: VueResourceLineItem[] = [], discount = 0, markupOrMargin: MarkupTypes) => {
    const { reasonsForDisable } = useTransactionResources()
    const sites = siteId ? [siteId] : Object.keys(lineItemNesting)
    if (!siteId) {
      tripNTE.value = {}
      siteNTE.value = {}
      workOrderNTE.value = {}
    }

    sites.forEach((site: number | string) => {
      if (!lineItemNesting[site]) return

      Object.keys(lineItemNesting[site]).forEach((orderOrEventId: number | string) => {
        Object.keys(lineItemNesting[site][orderOrEventId]).forEach((trip: number | string) => {
          // starting with trips of all the WOs/Events
          // keep NTE as -1 in the beginning to handle the scenario where NTE is blank/undefined/not present
          let localTripNte = -1
          if (clientRole === BROKER && linkedWorkOrders.data) {
            const workOrder = linkedWorkOrders.data.find((wo: WorkOrder) => wo.id === Number(orderOrEventId))

            // find nte for the trip in each loop
            const tripNte: any = workOrder?.trips?.find((t:Trip | NewTrip) => t.id === Number(trip))?.nte // Its already in dollors
            if (tripNte !== null) {
              localTripNte = tripNte
            }
          }
          if (clientRole !== CLIENT) {
            const tripNteExceeded = getTotalAndCheckIfExceedsNTE(
              lineItems, localTripNte, trip, 'tripIds', outbound, [], discount, markupOrMargin
            )
            tripNTE.value[trip] = {
              ...tripNteExceeded,
              childrenExceed: 0,
              nte: localTripNte
            }
          }
        })

        // then for WOs/Events
        // keep NTE as -1 in the beginning to handle the scenario where NTE is blank/undefined/not present
        let localOrderOrEventNte = -1
        if (clientRole === CLIENT && linkedWorkOrders.data) {
          const worderOrEventNte: any = (linkedWorkOrders.data.find((wo: WorkOrder) => wo.workOrderType !== 'Per Event' && wo.id === Number(orderOrEventId))?.nte?.cents)
          if (worderOrEventNte >= 0) {
            localOrderOrEventNte = worderOrEventNte / 100
          }
        }
        const orderOrEventNteExceeded = getTotalAndCheckIfExceedsNTE(
          lineItems, localOrderOrEventNte, orderOrEventId, 'resourceId', outbound, linkedLineItems, discount, markupOrMargin
        )
        workOrderNTE.value[orderOrEventId] = {
          ...orderOrEventNteExceeded,
          childrenExceed: Object.keys(lineItemNesting[site][orderOrEventId]).reduce((count: number, t: string | number) => {
            count = count + (tripNTE.value[t]?.exceed ? 1 : 0)
            return count
          }, 0),
          nte: localOrderOrEventNte
        }
      })

      const siteNteExceeded = getTotalAndCheckIfExceedsNTE(lineItems, -1, site, 'location', outbound, [], discount, markupOrMargin)
      siteNTE.value[site] = {
        ...siteNteExceeded,
        childrenExceed: Object.keys(lineItemNesting[site]).reduce((count: number, orderOrEventId: string | number) => {
          count = count + ((workOrderNTE.value?.[orderOrEventId].exceed || workOrderNTE.value?.[orderOrEventId]?.childrenExceed) ? 1 : 0)
          return count
        }, 0),
        nte: 0
      }
    })

    // check if any site has children that has exceeded the NTE
    reasonsForDisable.nteExceeded.value = Object.values(siteNTE.value).reduce((exceed: boolean, val: {childrenExceed: number}) => {
      return exceed || !!val.childrenExceed
    }, false) && !canExceed
  }

  /*
  This generates the line items used by the vue application.  It takes a resource (used to fetch meta data), the list of current line items, if its outbound, and if we should "reload".  We want to reload when the client, vendor, location, work orders or trips change since that impacts nesting. If a line item is added, removed or updated we should not force loading.

  This function
  1. Fetches the role of the client of the invoice (always broker if inbound)
  2. creates a nesting object of sites, work orders/events, and trips so that all resources on the associated to line items
  3. Creates a tree structure to match the nesting used in batch
  4. Add the line items onto each resource (Wo or Event) of the tree
  5. calculate totals and NTE data per level
  */
  const generateLineItemsObj = async (resource: NewInvoice | Invoice, lineItems: VueResourceLineItem[], outbound: boolean, canExceed: boolean, showLoading = true, linkedLineItems:VueResourceLineItem[] = [], markupOrMargin: MarkupTypes) => {
    generatingLineItems.value = showLoading
    if (!resource?.company?.roles && outbound) return
    const clientRole = !outbound ? BROKER : findCompanyType(resource?.company?.roles || [])
    if (showLoading) {
      await fetchEventAndWorkOrderData(resource, outbound)
    }
    lineItems.forEach((item: any) => {
      if (item.resourceType === WO_FETCH_PARAM_TYPE) {
        item.location = linkedWorkOrders.data.find((wo: any) => wo.id === item.resourceId)?.locationIds[0]
        if (!lineItemNesting[item.location]) lineItemNesting[item.location] = {}
        if (!lineItemNesting[item.location][item.resourceId]) lineItemNesting[item.location][item.resourceId] = {}
      } else if (item.resourceType === EVENT_FETCH_PARAM_TYPE) {
        item.location = linkedEvents.data.find((ev: any) => ev.id === item.resourceId)?.ucLocations?.[0]?.id
        if (!lineItemNesting[item.location]) lineItemNesting[item.location] = {}
        if (!lineItemNesting[item.location][item.resourceId]) lineItemNesting[item.location][item.resourceId] = {}
      } else if (resource?.locationIds?.length) {
        item.location = resource.locationIds[0]
        if (!lineItemNesting[item.location]) lineItemNesting[item.location] = {}
        if (!lineItemNesting[item.location].none) lineItemNesting[item.location].none = {}
        lineItemNesting[item.location].none.none = {}
      }
      if (item.tripIds?.length && lineItemNesting[item.location][item.resourceId]) {
        item.tripIds.forEach((tripId: number) => {
          if (!lineItemNesting[item.location][item.resourceId][tripId]) lineItemNesting[item.location][item.resourceId][tripId] = {}
        })
      }
    })

    createLineItemTree(resource)
    getLineItemsPerResource(lineItems)
    getExceedByResource(lineItems, clientRole, outbound, canExceed, '', linkedLineItems, 0, markupOrMargin === MarkupTypes.MARKUP ? MarkupTypes.MARKUP : MarkupTypes.MULTIPLIER)

    generatingLineItems.value = false
  }

  /*
   This takes a list of lineItems, the NTE, a resourceId, and the key to check against that resource Id.  Trips is an array of tripIds, the rest are single ids.

   This returns total of the line items whose resourceId matches the id at the provided key and if that total exceeds the NTE provided.
  */
  const getTotalAndCheckIfExceedsNTE = (lineItems: VueResourceLineItem[], nte: number, resourceId: number | string, key: 'location' | 'resourceId' | 'tripIds', outbound: boolean, otherLineItems: VueResourceLineItem[] = [], discount = 0, markupOrMargin: MarkupTypes) => {
    const otherFilteredItems = otherLineItems.filter((item: VueResourceLineItem) => key === 'tripIds' ? item[key].includes(Number(resourceId)) : item[key] === Number(resourceId))
    const filteredItems = lineItems.filter((item: VueResourceLineItem) => key === 'tripIds' ? item[key].includes(Number(resourceId)) : item[key] === Number(resourceId))
    const total = calcTotal([...filteredItems, ...otherFilteredItems], discount, true, markupOrMargin) || '0'
    // NTE: 0, total 0 --> true, true --> false
    // NTE: 0, total 20 --> true, true --> true
    // NTE: -1, total 20 --> false, true --> false
    // NTE: -1, total 0 --> false, true --> false
    // NTE: 10, total 20 --> false, true --> false
    // NTE: 30, total 20 --> false, false --> false
    // NTE: -1, then NTE not set in WO or Trip
    // NTE: 0 is a valid NTE
    let exceed = false
    if (nte === -1 || (nte === 0 && parseFloat(total) === 0)) {
      exceed = false
    } else if (nte === 0 && parseFloat(total) > 0) {
      exceed = true
    } else {
      exceed = parseFloat(total) > nte
    }

    return { exceed, total }
  }

  return {
    resetNTE,
    siteNTE,
    workOrderNTE,
    tripNTE,
    eventNTE,
    generateLineItemsObj,
    generatingLineItems,
    calcTotal,
    lineItemTree,
    determineTeamMemberCount,
    getNTEColor,
    perLineItemMarkupsAndTaxMetaData,
    getExceedByResource,
    getMarkupOrMarginPrice,
    transformLineItemsForVue,
    vueConfigLineItem,
    createLineItemTree
  }
}

import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('span', {
    staticClass: "uc-avatar"
  }, [_c(VAvatar, {
    style: _vm.avatarStyle,
    attrs: {
      "size": _vm.size,
      "rounded": _vm.rounded,
      "color": _vm.avatar && !_setup.isErrorImage ? '#b9b6b6' : `var(${_vm.backgroundColor})`
    }
  }, [_vm.avatar && !_setup.isErrorImage ? _c(VImg, {
    attrs: {
      "src": _vm.avatar,
      "alt": _setup.concatFirstAndLastNameInitials,
      "lazy-src": _vm.lazySrc ? require('@images/person-placeholder.png') : ''
    },
    on: {
      "error": _setup.errorImage
    }
  }) : _c('uc-text', {
    staticClass: "text-uppercase",
    attrs: {
      "size": _vm.largeFontSize ? 1 : 2,
      "type": 'body',
      "line-height": 'normal',
      "text": _setup.concatFirstAndLastNameInitials
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
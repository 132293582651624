import { intersection } from 'lodash'

export const DEFAULT_SCROLLABLE_ATTACH_IDENTIFIER = '.v-main'
enum ScrollStyles {
  AUTO = 'auto',
  SCROLL = 'scroll'
}
const SCROLL_CLASSES = ['overflow-auto', 'overflow-scroll']

export const scrollHelper = () => {
  /**
 * Recursively finds the closest scrollable parent element.
 * It checks the element's overflow properties and specific scroll-related classes
 * to determine if it is scrollable. If the element is not scrollable,
 * the function continues searching through its parent elements.
 *
 * @param {HTMLElement} el - The element to start the search from.
 * @returns {HTMLElement | null} - The nearest scrollable parent or null if none is found.
 */
  function getClosestParent (el: HTMLElement): HTMLElement | null {
    if (!el) return null

    const style = window.getComputedStyle(el)

    const isOverflowStyle = (
      style.overflowY === ScrollStyles.AUTO ||
        style.overflowY === ScrollStyles.SCROLL ||
        style.overflowX === ScrollStyles.AUTO ||
        style.overflowX === ScrollStyles.SCROLL
    )

    const isOverflowClass = intersection(Array.from(el.classList), SCROLL_CLASSES)

    // Check if the element has scrollable content
    const isScrollableContent = el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth

    if ((isOverflowStyle || isOverflowClass) && isScrollableContent) {
      return el
    }

    return getClosestParent(el.parentElement as HTMLElement)
  }

  return { getClosestParent }
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.listRowComponent, _vm._g(_vm._b({
    tag: "component",
    attrs: {
      "size": _vm.size,
      "search-value": _vm.searchValue
    },
    on: {
      "row:updated": function ($event) {
        return _vm.$emit('row:change', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "drawer-content",
      fn: function () {
        return [_vm._t("drawer-content")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_vm._t("actions")];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_vm._t("footer")];
      },
      proxy: true
    }], null, true)
  }, 'component', {
    key: _vm.item.id + _vm.item.type,
    isSelected: _vm.item.selected,
    isExpanded: _vm.item.expanded,
    firstRow: _vm.item.firstRow,
    lastRow: _vm.item.lastRow,
    hasBorder: _vm.item.props.hasBorder || false,
    columnDefs: _vm.columnDefs,
    showFooter: _vm.item.props.showFooter || false,
    ..._vm.item.props,
    ..._vm.$attrs,
    additionalData: _vm.additionalData
  }, false), _vm.$listeners));
};
var staticRenderFns = [];
export { render, staticRenderFns };
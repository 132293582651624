import { api, apis } from '@/api'
import qs from 'qs'
import { InvoiceParams } from '@/utils/api'
import { InvoiceEmailParams } from '@/types/interfaces/api-v2/invoice'
import { AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'

interface InvoiceUpdateParams {
  invoiceId: string,
  include?: string,
  updateContent: any,
  objectScope?: 'both' | 'single' | 'multiple'
}

interface invoiceAmountStartEndDate {
  draft_sent_at_date_gteq?: [],
  startEndDate?: {
    date_of_issue_gteq: string
    date_of_issue_lteq: string
  }
}

const clientInvoiceInclude = [
  'company',
  'locations',
  'attachments',
  'invoice_payments',
  'invoice_items/item/item_type',
  'invoice_items/item/department',
  'department'
]

export default {
  getClientInvoice: (invoiceId: string) => api.$get(`/invoices/${invoiceId}`, {
    params: {
      include: clientInvoiceInclude.join(',')
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  deleteOutboundInvoice: (invoiceId: string, objectScope = '') => {
    return api.$delete(`/invoices/${invoiceId}`, {
      params: {
        objectScope,
        include_grouped: true
      }
    })
  },
  update: ({ invoiceId, updateContent, include, objectScope }:InvoiceUpdateParams) => {
    return api.$patch(`/invoices/${invoiceId}`, { invoice: updateContent }, {
      params: {
        objectScope,
        include,
        include_grouped: true
      },
      paramsSerializer: (params:any) => {
        return qs.stringify(params)
      }
    })
  },
  deleteOutboundInvoiceWithId: (invoiceId: string) => {
    return api.$delete(`/invoices/${invoiceId}`)
  },
  deleteInboundInvoiceWithId: (invoiceId: string) => {
    return api.$delete(`/inbound_invoices/${invoiceId}`)
  },
  getPreviewPdfById: (invoiceId: string | number, resourceType: string, objectScope:string) => {
    return api.$getBuffer(`/${resourceType}/${invoiceId}/preview_pdf`, {
      params: {
        download: true,
        objectScope,
        include_grouped: true
      },
      responseType: 'arraybuffer',
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  get: ({ include, objectScope, q, page, perPage }:InvoiceParams) => api.$get('/invoices', {
    params: {
      include,
      object_scope: objectScope,
      q,
      page,
      per_page: perPage,
      include_grouped: true
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  totalAmount: (startEndDate: invoiceAmountStartEndDate) => api.$get('/invoices/total_amount', {
    params: {
      q: startEndDate
    },
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false })
    }
  }),
  patchBulkUpdate: ({ field, value, query }: { field: string, value: any, query: any }) => apis.$patch('/invoices/bulk_update', {}, {
    params: {
      field,
      value,
      query
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  getOutboundInvoiceNotes: (invoiceId: string | number) => {
    return api.$get(`/invoices/${invoiceId}/notes`, {
      params: {
        include: clientInvoiceInclude.join(',')
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  postSendBulkEmail: (body: any, objectScope: 'both' | 'multiple' | 'single') => apis.$post('/invoices/send_bulk_email', body, {
    params: {
      objectScope
    }
  }),
  sendEmail: (invoiceId: number | string, invoiceEmail: InvoiceEmailParams, objectScope: string) => {
    return apis.$post(`invoices/${invoiceId}/send_email`, {
      invoice_email: invoiceEmail,
      object_scope: objectScope,
      include_grouped: true
    })
  },
  new: (params: any) => apis.$get('/invoices/new_v2', {
    params,
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  newBatch: (params: any, requestBody:any) => api.$post('/invoices/new_v3', {
    ...requestBody
  }, {

    params,
    headers: {
      'Content-Type': 'application/json'
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  post: (params: any, requestBody: any) => {
    return api.$post('/invoices', {
      ...requestBody
    }, {
      params,
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  getById: (id: number, { include, objectScope }:InvoiceParams) => apis.$get(`/invoices/${id}`, {
    params: {
      include,
      objectScope
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  forwardInvoice: (invoiceId: number, markup: number, addMarkupToInvoice: boolean, include = '') => apis.$get('/invoices/forward_invoice', {
    params: {
      forward_invoice_id: invoiceId,
      add_markup_to_invoice: addMarkupToInvoice,
      markup_multiplier: markup,
      include
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  getInvoiceStatusInstructions: ({ include }:InvoiceParams) => api.$get('/invoice_status_instructions', {
    params: {
      include
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  invoicesAutoComplete: (params: AutocompletePayload) => {
    const { field, terms, predicate, page, perPage, finderParams } = params
    return apis.$post('/invoices/autocomplete', {
      field,
      terms,
      predicate,
      page,
      perPage,
      finderParams
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  invoicesSearch: ({ include, objectScope, q, page, perPage, workOrderId, withoutCount, includeVerified }:InvoiceParams) => {
    return api.$post('/invoices/search', {
      include,
      object_scope: objectScope,
      q,
      page,
      per_page: perPage,
      include_grouped: true,
      work_order_id: workOrderId,
      withoutCount,
      includeVerified
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  actionableInvoices: (params: { ids?: number[] | string[], q?: any[], status: string }) => {
    const { ids, q, status } = params
    return apis.$get('/invoices/actionables', {
      params: {
        ids,
        status,
        query: JSON.stringify(q)
      },
      paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'brackets' })
    })
  },
  getProfitLossDetail: (param: any) => {
    return api.$get(`/invoices/${param.id}/profit_loss_detail`, {
      params: {
        object_scope: param.objectScope,
        include_drouped: param.includeGrouped
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getSchedulerProfitLossDetail: (param: any) => {
    return api.$get(`/invoices/${param.id}/scheduler_profit_loss_detail`, {
      params: {
        object_scope: param.objectScope,
        include_drouped: param.includeGrouped
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getAnnexedInvoiceStatuses: () => apis.$get('/annexed_invoices/statuses', {}),
  marginPercentage: (params: { ids: number[], objectScope: string }) => {
    return apis.$get('/invoices/margin_percentage', {
      params: {
        includeGrouped: true,
        withoutCount: true,
        ...params
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  count: (params: InvoiceParams) => {
    return api.$post('/invoices/count', {
      ...params,
      paramsSerializer: (params: InvoiceParams) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  invoiceStatuses: (as = 'both') => apis.$get('/invoices/statuses', { params: { as } })
}

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VBtn, _vm._g({
    class: ['uc-button overflow-hidden', {
      'uc-button--no-hover': !_vm.hasHover
    }, _vm.icon ? 'rounded' : '', _vm.text === '' ? 'px-0' : 'px-2'],
    attrs: {
      "large": _vm.size === 'lg',
      "x-large": _vm.size === 'xl',
      "small": _vm.size === 'sm',
      "x-small": _vm.size === 'xs',
      "color": _vm.state,
      "elevation": 0,
      "readonly": "",
      "width": _vm.width,
      "disabled": _vm.disabled,
      "text": _setup.buttonType === 'text',
      "outlined": _setup.buttonType === 'outlined',
      "href": _vm.href,
      "max-width": _vm.maxWidth,
      "loading": _vm.loading
    },
    on: {
      "click": function ($event) {
        !_vm.stopPropogation && _vm.$emit('click', $event);
      }
    }
  }, _vm.$listeners), [_c('div', {
    staticClass: "d-flex align-center overflow-hidden"
  }, [_vm.iconName ? _c(VIcon, {
    class: _setup.getIconMargin,
    attrs: {
      "size": _setup.iconSize
    }
  }, [_vm._v(" " + _vm._s(_vm.iconName) + " ")]) : _vm._e(), _c('uc-text', {
    style: _vm.disabled ? 'opacity: .6;' : '',
    attrs: {
      "size": _vm.size === 'md' || _vm.size === 'lg' ? 2 : 3,
      "type": 'body',
      "text": _vm.text,
      "bold": true,
      "palette-color": _vm.type === 'primary' ? 'white' : _vm.state,
      "ellipses": _vm.ellipses
    }
  }), _vm.appendIcon ? _c(VIcon, {
    attrs: {
      "size": _setup.iconSize
    }
  }, [_vm._v(" " + _vm._s(_vm.appendIcon) + " ")]) : _vm._e()], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
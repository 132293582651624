// UNIVERSAL COL TYPES
interface Visibility {
  card?: string,
  xs: string,
  sm: string,
  md: string,
  lg: string
}

interface StyleObj {
  align: string,
  justify: string,
  overflow?: string
}

export interface Column {
  label: string,
  hideTextOnMobile: boolean,
  templateName: string,
  resourceField: string | null,
  prependIcon: string,
  appendIcon: string,
  styleObj: StyleObj,
  visibility: Visibility | string,
  row?: number
}

export interface ColumnDefs {
  headers?: Column[],
  fontSize?: string,
  border?: boolean,
  canSelect?: boolean,
  canExpand?: boolean,
  drawer?: Column[],
  showActions?: boolean,
  footer?: Column[]
}

export const CHECKBOX_COL : Column = {
  label: '',
  hideTextOnMobile: false,
  templateName: 'checkbox',
  resourceField: null,
  prependIcon: '',
  appendIcon: '',
  styleObj: {
    align: 'end',
    justify: 'start',
    overflow: 'overflow-visible'
  },
  visibility: {
    xs: '36',
    sm: '36',
    md: '36',
    lg: '36'

  }

}

export const DRAWER_CONTENT_EXPANDER_COL : Column = {
  label: '',
  hideTextOnMobile: false,
  templateName: 'drawer-content-expander',
  resourceField: null,
  prependIcon: '',
  appendIcon: '',
  styleObj: {
    align: 'end',
    justify: 'start',
    overflow: 'overflow-visible'
  },
  visibility: {
    xs: '24',
    sm: '24',
    md: '24',
    lg: '24'

  }
}

export const ACTIONS_COL : Column = {
  label: 'Actions',
  hideTextOnMobile: false,
  templateName: 'actions',
  resourceField: null,
  prependIcon: '',
  appendIcon: '',
  styleObj: {
    align: 'center',
    justify: 'end'
  },
  visibility: {
    xs: '60',
    sm: '60',
    md: 'flex',
    lg: 'flex'

  }
}

export const DRAWER_CONTENT : Column = {
  label: '',
  hideTextOnMobile: false,
  templateName: 'drawer-content',
  resourceField: null,
  prependIcon: '',
  appendIcon: '',
  styleObj: {
    align: 'end',
    justify: 'start'
  },
  visibility: {
    xs: 'flex',
    sm: 'flex',
    md: 'flex',
    lg: 'flex'

  }
}

export const REMOVE_COL : Column = {
  label: '',
  hideTextOnMobile: false,
  templateName: 'remove',
  resourceField: null,
  prependIcon: '',
  appendIcon: '',
  styleObj: {
    align: 'end',
    justify: 'start'
  },
  visibility: {
    xs: '36',
    sm: '36',
    md: '36',
    lg: '36'

  }
}

export const ADD_COL : Column = {
  label: '',
  hideTextOnMobile: false,
  templateName: 'add',
  resourceField: null,
  prependIcon: '',
  appendIcon: '',
  styleObj: {
    align: 'end',
    justify: 'start'
  },
  visibility: {
    xs: '36',
    sm: '36',
    md: '36',
    lg: '36'

  }
}

import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('uc-tooltip', {
    attrs: {
      "disabled": _vm.tooltipPosition === 'none',
      "position": _vm.tooltipPosition,
      "text": _vm.tooltipText,
      "overflow-hidden": false
    }
  }, [_vm.fontAwesomeIcon ? _c('div', [_c('font-awesome-icon', {
    class: _vm.classes,
    attrs: {
      "icon": _vm.value,
      "color": _vm.state,
      "size": _vm.size,
      "fixed-width": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  })], 1) : _c('div', [_c(VIcon, {
    attrs: {
      "x-large": _vm.size === 'xl',
      "large": _vm.size === 'lg',
      "small": _vm.size === 'sm',
      "x-small": _vm.size === 'xs',
      "color": _vm.state,
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.value) + " ")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VMenu, _vm._b({
    attrs: {
      "close-on-content-click": _vm.closeOnContentClick,
      "content-class": `rounded-lg uc-menu elevation-14 ${_vm.$vuetify.theme.dark && 'uc-menu--dark'} ${_vm.contentClass}`,
      "max-width": "100%",
      "eager": _vm.eager,
      "left": _vm.alignment === 'left',
      "right": _vm.alignment === 'right'
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_vm._t("activator", null, null, {
          on,
          attrs
        })];
      }
    }], null, true),
    model: {
      value: _vm.isMenuOpen,
      callback: function ($$v) {
        _vm.isMenuOpen = $$v;
      },
      expression: "isMenuOpen"
    }
  }, 'v-menu', _vm.$attrs, false), [_vm.showList ? _c('uc-list', _vm._g({
    attrs: {
      "data-cy": _vm.dataCy,
      "data": _vm.rowData,
      "content-width": _vm.contentWidth,
      "background-color": _vm.backgroundColor
    },
    on: {
      "click:row": function ($event) {
        return _vm.handleRowClick($event);
      }
    }
  }, _vm.listenerObject)) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
const validateEmail = (email:string) => {
  return /^\w+([.-/+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

const validateMultiEmail = (email:string) => {
  return /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/.test(email)
}

const isEllipses = () => {
  return false
  // if (!textRef) return
  // const element: any = textRef?.$el
  // return element?.offsetWidth >= element?.scrollWidth
}

const emptyFileName = (val:any) => (!!val && val[0] !== '.') || 'Filename cannot be blank'

const containsInvalidCharacter = (val:any) => {
  const matchedRegex = val.match(/[,\\'"%`/{}[\]|]/g)
  return matchedRegex === null || `Filename cannot contain the following characters: ${matchedRegex.join(', ')}`
}

const moreThanOnePeriod = (val:any) => !(/.*\..*\./g).test(val) || 'Filename cannot contain more than one period'

const isNumeric = (value: any) => {
  return /^\d+$/.test(value)
}

const validateURL = (url: string) => {
  return /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(url)
}

export default {
  isEllipses,
  validateEmail,
  validateMultiEmail,
  emptyFileName,
  containsInvalidCharacter,
  moreThanOnePeriod,
  isNumeric,
  validateURL
}

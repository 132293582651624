import _ from 'lodash';
import { defineComponent, computed } from 'vue';
export default defineComponent({
  props: {
    buttonProps: {
      type: Object,
      required: true
    },
    activatorButtonProps: {
      type: Object,
      required: true
    },
    menuData: {
      type: Object,
      required: true
    },
    menuProps: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideButtonOnMobile: {
      type: Boolean,
      default: false
    },
    contentWidth: {
      type: String,
      default: '180px'
    }
  },
  setup: (props, {
    emit
  }) => {
    const getEmitName = buttonText => {
      emit(_.kebabCase(buttonText));
    };
    const convertedButtonText = computed(() => {
      var _props$buttonProps$te;
      return (_props$buttonProps$te = props.buttonProps.text) === null || _props$buttonProps$te === void 0 ? void 0 : _props$buttonProps$te.split(' ').map(word => _.capitalize(word)).join(' ');
    });
    const menuActions = computed(() => {
      if (!props.hideButtonOnMobile || props.buttonProps.text === null) {
        return props.menuData.rows;
      } else if (convertedButtonText.value) {
        return [{
          text: convertedButtonText.value,
          eventName: props.buttonProps.eventName
        }, ...props.menuData.rows];
      }
      return props.menuData.rows;
    });
    return {
      getEmitName,
      convertedButtonText,
      menuActions
    };
  }
});
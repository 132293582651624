import { api, apis } from '@/api'
import { baseGetParams } from '@/utils/api'
import qs from 'qs'
import { snakeCase } from 'lodash'
import { AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'
interface GetProposalsListParams {
  perPage?: number
  page: number
  q?: any
  s?: any,
  include: any,
  proposalId?: number | string,
  updateContent?: any,
  withNonExistingClient?: boolean,
  tab?: string
}

interface updateProposalStatusParams {
  action:string,
  proposalId: number | string,
  reason: string,
  annexedPoNumber?: string,
  include?: any
}
export default {
  get: (params : any) => {
    const { page, perPage, q, include, tab, withNonExistingClient }: GetProposalsListParams = params
    return api.$get('/proposals', {
      params: {
        include,
        page,
        per_page: perPage,
        with_non_existing_client: withNonExistingClient,
        tab,
        q,
        onlyDiscountPercent: true
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  post: (params: any, requestBody: any) => {
    return api.$post('/proposals', {
      ...requestBody
    }, {
      params,
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  newV2: (params: any, requestBody:any) => api.$post('/proposals/new_v2', {
    ...requestBody
  }, {
    params,
    headers: {
      'Content-Type': 'application/json'
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  sendEmail: (body:any) => {
    return api.$post(`/proposals/${body.proposal_email.ids[0]}/send_email`, body, {})
  },
  postSendBulkEmail: (body: any) => apis.$post('/proposals/send_bulk_email', body),
  patch: (params : any) => {
    const { proposalId, include, perPage, q, page, updateContent }: GetProposalsListParams = params
    return api.$patch(`/proposals/${proposalId}`, { proposal: updateContent }, {
      params: {
        id: proposalId,
        include,
        page,
        per_page: perPage,
        q,
        onlyDiscountPercent: true
      },
      paramsSerializer: (params:any) => {
        return qs.stringify(params)
      }
    })
  },
  getById: (id: number, { include }: baseGetParams) => api.$get(`/proposals/${id}`, {
    params: {
      include,
      onlyDiscountPercent: true
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  updateProposalStatus: ({ action, proposalId, reason, include, annexedPoNumber }:updateProposalStatusParams) => {
    return api.$patch(`proposals/${proposalId}/${snakeCase(action)}`, {
      id: proposalId,
      include,
      reason,
      annexedPoNumber,
      onlyDiscountPercent: true
    })
  },
  // deprecated
  addNewProposal: (proposal:FormData) => {
    return api.$post('/proposals', proposal, {
      headers: {
        content_type: 'multipart/form-data'
      }
    })
  },
  getProposalPDFLink: (proposalId: string | number, resourceType: string) => {
    return api.$getBuffer(`${resourceType}/${proposalId}/preview_pdf`, {
      params: {
        download: true
      },
      responseType: 'arraybuffer',
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  bulkDestroy: ({ query }: { query: any }) => {
    return api.$delete('proposals/bulk_destroy', {
      params: {
        query
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  deleteOutboundProposal: (proposalId: string, objectScope = '') => {
    return api.$delete(`/proposals/${proposalId}`, {
      params: {
        objectScope,
        include_grouped: true
      }
    })
  },
  // patchBulkUpdate: ({ field, value, query }: { field: string, value: any, query: any }) => apis.$patch('/proposals/bulk_update', {}, {
  //   params: {
  //     field,
  //     value,
  //     query
  //   },
  //   paramsSerializer: (params: any) => {
  //     return qs.stringify(params, { arrayFormat: 'brackets' })
  //   }
  // })

  proposalsAutoComplete: (params: AutocompletePayload) => {
    const { field, terms, predicate, page, finderParams, perPage } = params
    return apis.$post('/proposals/autocomplete', {
      field,
      terms,
      predicate,
      page,
      finderParams,
      perPage
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getAnnexedProposalStatuses: () => apis.$get('/annexed_proposals/statuses', {}),
  forwardProposal: (proposalId: number, markupMultiplier: number, addMarkupToProposal: string, include = '') => apis.$get('/proposals/forward_proposal', {
    params: {
      forwardProposalId: proposalId,
      markupMultiplier,
      addMarkupToProposal,
      include
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  proposalsSearch: (params : any) => {
    const { page, perPage, q, include }: GetProposalsListParams = params
    return api.$post('/proposals/search', {
      include,
      page,
      per_page: perPage,
      q
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  proposalStatuses: (as = 'both') => apis.$get('/proposals/statuses', { params: { as } })
}

import "core-js/modules/es.array.push.js";
import { defineComponent, ref, onMounted, computed, watch } from 'vue';
import { cloneDeep } from 'lodash';
export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true
    },
    contentWidth: {
      type: [Number, String],
      required: true
    },
    fillType: {
      type: String,
      default: 'dynamic'
    },
    dataCy: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: '--v-background-base'
    }
  },
  setup: (props, {
    emit
  }) => {
    const searchValue = ref('');
    const searchRef = ref(null);
    const currentHeader = ref('');
    const currentRows = ref([]);
    const currentPage = ref(0);
    const levels = ref([]);
    const hasFetch = ref(false);
    const currentData = ref(props.data);
    const menuCard = ref(null);
    watch(() => cloneDeep(props.data), () => {
      currentData.value = props.data;
      currentRows.value = props.data.rows;
    });
    watch(currentRows, () => {
      if (!currentData.value.height) {
        height.value = menuCard.value.offsetHeight;
      }
    });
    const hasNextPage = ref(false);
    const alternator = ref(false);
    const firstTransition = ref(true);
    const previousRows = ref(null);
    const previousHeader = ref('');
    const setCurrentLevel = async () => {
      previousRows.value = currentRows.value;
      previousHeader.value = currentHeader.value;
      alternator.value = !alternator.value;
      currentRows.value = [];
      let tempData = props.data || {};
      levels.value.forEach(level => {
        var _tempData$rows$level;
        tempData = (_tempData$rows$level = tempData.rows[level]) === null || _tempData$rows$level === void 0 ? void 0 : _tempData$rows$level.children;
      });
      if ('rows' in tempData) {
        currentData.value = tempData;
        currentHeader.value = tempData.header;
        currentRows.value = tempData.rows;
        hasFetch.value = false;
        hasNextPage.value = false;
        emit('show-create-join-company-buttons', currentHeader.value === 'My Companies');
      } else {
        currentData.value = tempData;
        currentHeader.value = tempData.header;
        getRowData('', true).then(() => {
          emit('show-create-join-company-buttons', currentHeader.value === 'My Companies');
        });
      }
    };
    const getRowData = (search = '', newSearch = false) => {
      return currentData.value.getRowData(search, newSearch).then(fetchData => {
        if (currentData.value.hasSearch && searchRef.value) {
          var _searchRef$value$;
          (_searchRef$value$ = searchRef.value[0]) === null || _searchRef$value$ === void 0 ? void 0 : _searchRef$value$.setLoading(false);
        }
        currentRows.value = fetchData.data.map(d => {
          return {
            text: currentData.value.textFn(d),
            subText: currentData.value.subtextFn(d),
            click: currentData.value.clickFn(d),
            rightClickFn: currentData.value.rightClickFn(d),
            key: currentData.value.keyFn ? currentData.value.keyFn(d) : d.id
          };
        });
        hasFetch.value = true;
        hasNextPage.value = !!fetchData.nextPage;
      });
    };
    // menu navigation functions: the transition type is based on which of the following you call
    const transitionType = ref('fade');
    const goForward = index => {
      transitionType.value = 'fast-slide-fade-forward';
      levels.value.push(index);
      setCurrentLevel();
    };
    const goBack = () => {
      transitionType.value = 'fast-slide-fade-backward';
      levels.value.pop();
      setCurrentLevel();
    };
    const rowClick = (row, index) => {
      if (row.type === 'nested') {
        goForward(index);
      } else if (row.click) {
        row.click();
      }
    };
    const height = ref(0);
    const calcHeight = node => {
      if (currentData.value.height) {
        height.value = currentData.value.height;
      } else if (node.offsetHeight) {
        height.value = node.offsetHeight;
      }
    };
    const styleObj = computed(() => ({
      maxHeight: `${height.value ? height.value : ''}px`,
      minHeight: `${height.value ? height.value : ''}px`,
      height: `${height.value ? height.value : ''}px`
    }));
    onMounted(() => {
      setCurrentLevel();
    });
    return {
      currentHeader,
      currentRows,
      currentPage,
      levels,
      currentData,
      goBack,
      goForward,
      hasFetch,
      rowClick,
      getRowData,
      hasNextPage,
      alternator,
      height,
      firstTransition,
      transitionType,
      calcHeight,
      styleObj,
      menuCard,
      searchValue,
      searchRef,
      previousRows,
      previousHeader
    };
  }
});
import { defineComponent, ref, watch, computed } from 'vue';
import { cloneDeep, kebabCase } from 'lodash';
import { useFilters } from '@/use/filters';
import { useInfiniteScroll } from '@vueuse/core';
import { GENERIC_EMPTY_STATE } from '@/constants/icons';
export default defineComponent({
  props: {
    data: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Object,
      default: () => {}
    },
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    },
    nextPage: {
      type: Number,
      required: true
    },
    selectedResources: {
      type: Array,
      default: () => []
    },
    selectedId: {
      type: Number,
      default: -1
    },
    listeners: {
      type: Array,
      default: () => []
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    allowCards: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      required: true
    },
    additionalData: {
      type: Object,
      default: () => {}
    }
  },
  setup: (props, {
    emit
  }) => {
    const localSelectedResources = ref(cloneDeep(props.selectedResources));
    watch(() => props.selectedResources, () => {
      localSelectedResources.value = cloneDeep(props.selectedResources);
    });
    const init = () => {
      return props.listeners.reduce((acc, text) => {
        acc[kebabCase(text)] = event => {
          emit(`${kebabCase(text)}`, event);
        };
        return acc;
      }, {});
    };
    const listenerObj = ref(init());
    const emitSelectedResources = () => {
      setTimeout(() => {
        emit('update:selected-resources', localSelectedResources.value);
      });
    };
    const {
      currentRansack
    } = useFilters();
    const dataIteratorRef = ref(null);
    const previousDataCount = ref(props.data.length);
    const hasNextPage = computed(() => {
      return props.data.length && (props.nextPage || props.currentPage < props.totalPages && props.totalPages !== -1);
    });
    const {
      reset
    } = useInfiniteScroll(dataIteratorRef, () => {
      loadMore();
    }, {
      canLoadMore: () => hasNextPage.value || props.totalPages === -1
    });
    const loadMore = () => {
      /*
        if i have a next page of data and
        my locally stored count of rows does != recently passed data length
        load more data
        if they are the same that means I am still waiting for more data
        and I shouldnt fire load more until I get my next page of data
      */
      if (hasNextPage.value && (previousDataCount.value !== props.data.length || props.previousDataCount === 0)) {
        previousDataCount.value = props.data.length;
        emit('load-more');
      }
    };
    watch(() => props.data, () => {
      if (!props.data.length) {
        reset();
        previousDataCount.value = props.data.length;
      }
    });
    return {
      listenerObj,
      localSelectedResources,
      emitSelectedResources,
      currentRansack,
      dataIteratorRef,
      hasNextPage,
      GENERIC_EMPTY_STATE
    };
  }
});
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-flex align-start justify-start flex-column"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('uc-text', {
    attrs: {
      "size": 1,
      "type": 'body',
      "text": _vm.title,
      "bold": true
    }
  }), _c('uc-text', {
    staticClass: "my-2",
    staticStyle: {
      "line-height": "1.2"
    },
    attrs: {
      "size": 3,
      "type": 'body',
      "text": _vm.description,
      "bold": false
    }
  })], 1), _vm.buttonTitle ? _c('uc-button', {
    staticClass: "mt-2 px-4 align-self-end",
    attrs: {
      "size": 'sm',
      "icon": false,
      "state": 'primary',
      "text": _vm.buttonTitle
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click-action');
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
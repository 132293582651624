import { ColumnDefs } from '@/types/interfaces'

export const INTEGRATIONS_HEADER = () => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect: true,
    showActions: false,
    canExpand: false,
    headers: [
      {
        label: 'Integration Partner',
        hideTextOnMobile: false,
        templateName: 'app_name',
        resourceField: 'name',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-10 v-col-sm-10 v-col-md-2 v-col-lg-2'
      },
      {
        label: 'Description',
        hideTextOnMobile: false,
        templateName: 'app_description',
        resourceField: 'description',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only hidden-sm-only v-col-md-9 v-col-lg-9'
      }
    ]
  } as ColumnDefs
}

export const ADDONS_HEADER = () => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect: true,
    showActions: false,
    canExpand: false,
    headers: [
      {
        label: 'Module Addons',
        hideTextOnMobile: false,
        templateName: 'app_name',
        resourceField: 'name',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-10 v-col-sm-4 v-col-md-2 v-col-lg-2'
      },
      {
        label: 'Description',
        hideTextOnMobile: false,
        templateName: 'app_description',
        resourceField: 'description',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only v-col-sm-6 v-col-md-9 v-col-lg-9'
      }
    ]
  } as ColumnDefs
}

export const SERVICES_HEADER = () => {
  return {
    fontSize: 'lg',
    border: false,
    canSelect: true,
    showActions: false,
    canExpand: false,
    headers: [
      {
        label: 'Services',
        hideTextOnMobile: false,
        templateName: 'app_name',
        resourceField: 'name',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'v-col-10 v-col-sm-4 v-col-md-2 v-col-lg-2'
      },
      {
        label: 'Description',
        hideTextOnMobile: false,
        templateName: 'app_description',
        resourceField: 'description',
        prependIcon: '',
        appendIcon: '',
        styleObj: {
          align: 'end',
          justify: 'start'
        },
        visibility: 'hidden-xs-only v-col-sm-6 v-col-md-9 v-col-lg-9'
      }
    ]
  } as ColumnDefs
}

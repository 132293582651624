import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VImg, _vm._b({
    on: {
      "click": function ($event) {
        return _setup.emit('click');
      },
      "mouseenter": function ($event) {
        return _setup.emit('mouse-enter');
      },
      "mouseleave": function ($event) {
        return _setup.emit('mouse-leave');
      }
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c(VProgressLinear, {
          attrs: {
            "indeterminate": "",
            "rounded": "",
            "height": "2"
          }
        })];
      },
      proxy: true
    }])
  }, 'v-img', _setup.imageBinding, false), [_vm.showVisibleIcon ? _c('uc-chip', {
    style: {
      position: 'absolute',
      borderRadius: '10%',
      top: '5px',
      right: '1px'
    },
    attrs: {
      "disabled": _vm.disableVisibleIcon,
      "tooltip-position": 'top',
      "tooltip-text": _vm.visible ? 'Hide' : 'Unhide',
      "can-select": false,
      "size": "md",
      "prepend-icon": _vm.visible ? _setup.EYE : _setup.EYE_OFF,
      "palette-color": 'white',
      "state": _vm.visible ? 'secondary' : 'error'
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _setup.emitVisibility.apply(null, arguments);
      }
    }
  }) : _vm._e(), _vm.showDownloadIcon && _vm.downloadUrl ? _c('uc-chip', {
    style: {
      position: 'absolute',
      borderRadius: '10%',
      top: '5px',
      left: '1px'
    },
    attrs: {
      "tooltip-position": 'top',
      "tooltip-text": 'Download',
      "can-select": false,
      "size": "md",
      "prepend-icon": _setup.EXPORT,
      "palette-color": 'white',
      "state": 'secondary'
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _setup.downloadImage.apply(null, arguments);
      }
    }
  }) : _vm._e(), _vm.showDetailsInfo ? _c('div', {
    staticClass: "d-flex flex-column justify-space-between",
    staticStyle: {
      "position": "absolute",
      "bottom": "0px",
      "left": "0px",
      "right": "0px",
      "background-color": "#00000044"
    }
  }, [_c('uc-text', {
    staticClass: "m-1",
    staticStyle: {
      "color": "#FFD700"
    },
    attrs: {
      "type": "body",
      "text": `Date: ${_vm.showDetailsInfo.capturedAt ? _setup.formatDate(_vm.showDetailsInfo.capturedAt, _setup.DEFAULT_DATE_TIME_FORMAT) : '-'}`,
      "size": 2
    }
  }), _c('uc-text', {
    staticClass: "m-1",
    staticStyle: {
      "color": "#FFD700"
    },
    attrs: {
      "type": "body",
      "text": `Lat: ${_vm.showDetailsInfo.lat}`,
      "size": 2
    }
  }), _c('uc-text', {
    staticClass: "m-1",
    staticStyle: {
      "color": "#FFD700"
    },
    attrs: {
      "type": "body",
      "text": `Long: ${_vm.showDetailsInfo.lng}`,
      "size": 2
    }
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
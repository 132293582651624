import { api } from '@/api'
import qs from 'qs'

import { ActionFilterResource, FiltersParams } from '@/types/interfaces'

export interface FilterResourceCount {
  ids: number[]
  resourceId?: string
}

export default {
  filterResourcesCount: ({ ids, resourceId }: FilterResourceCount) => {
    return api.$get('/filters/counts', {
      params: { filter: { ids, actionFilter: true, resourceId, scopeContext: 'all' } },
      paramsSerializer: (params: FiltersParams) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  appliedActions: (
    path: number, resourceType: string, parentTab?: number,
    attachedId?: number, attachedType?: string
  ) => {
    return api.$get('/action_filter_resources', {
      params: { filter: { path, resourceType, parentTab, attachedId, attachedType } },
      paramsSerializer: (params: FiltersParams) => {
        return qs.stringify(params)
      }
    })
  },
  createResource: (params: Omit<ActionFilterResource, 'resourceId'>) => {
    const { resourceType, actionFilterId, parentTab, attachedId, attachedType } = params
    return api.$post('/action_filter_resources', {
      filter: { resourceType, actionFilterId, parentTab, attachedId, attachedType }
    })
  },
  destroyResource: (params: Omit<ActionFilterResource, 'resourceId'>) => {
    const { resourceType, actionFilterId, parentTab, attachedId, attachedType } = params
    return api.$delete('/action_filter_resources', {
      params: {
        filter: { resourceType, actionFilterId, parentTab, attachedId, attachedType }
      },
      paramsSerializer: (params: FiltersParams) => {
        return qs.stringify(params)
      }
    })
  }
}

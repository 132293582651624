import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('uc-tile-container', {
    staticClass: "flex-column rounded-0",
    attrs: {
      "no-padding": "",
      "no-margin": "",
      "background-color": "--v-layer-base",
      "no-elevation": ""
    }
  }, [_c(VRow, {
    staticClass: "flex-nowrap"
  }, [_vm.canSelect ? _c(VCol, {
    staticClass: "v-col-sm-auto v-col-lg-auto v-col-md-auto v-col-xl-auto v-col-auto"
  }, [_c(VCheckbox, {
    staticClass: "hidden-visibility",
    staticStyle: {
      "margin-top": "0px",
      "padding-top": "0px"
    },
    attrs: {
      "disabled": "",
      "hide-details": ""
    }
  })], 1) : _vm._e(), _vm._l(_vm.headers, function (header, index) {
    return _c(VCol, {
      key: index,
      class: _setup.getHeaderClass(header)
    }, [_c('uc-tooltip', {
      attrs: {
        "disabled": _setup.isEllipses(),
        "text": header.prependIconToolTip || header.label,
        "position": header.prependIconToolTipPlacement || 'top'
      }
    }, [header.prependIcon ? _c('uc-icon', {
      staticClass: "mr-1",
      attrs: {
        "value": header.prependIcon
      }
    }) : _vm._e()], 1), _c('div', {
      staticClass: "d-flex align-center gap-sm"
    }, [_c('uc-tooltip', {
      attrs: {
        "disabled": _setup.isEllipses(),
        "text": header.label,
        "position": "top"
      }
    }, [header.label !== '' ? _c('uc-text', {
      attrs: {
        "size": _setup.getFontSize(_vm.fontSize),
        "type": "body",
        "ellipses": true,
        "text": header.label,
        "palette-color": header.paletteColor || '',
        "bold": true
      }
    }) : _c('div', {
      staticClass: "hidden-visibility"
    }, [_c('uc-icon', {
      staticClass: "hidden-xs-only hidden-sm-only",
      attrs: {
        "value": _setup.DATE_EXPIRED,
        "state": 'primary',
        "size": 'md'
      }
    })], 1)], 1), header.appendIcon !== '' ? _c('uc-icon', {
      staticClass: "pb-1",
      attrs: {
        "value": header.appendIcon,
        "size": header.appendIconSize || 'sm',
        "tooltip-position": 'bottom',
        "tooltip-text": header.appendIconTooltip || header.label
      }
    }) : _vm._e()], 1), _vm._t("append-icon-content")], 2);
  }), _vm.showActions ? _c(VCol, {
    staticClass: "v-col-xl v-col-sm v-col-lg v-col-md v-col"
  }, [_c('div', {
    staticClass: "d-flex justify-end"
  }, [_c('uc-tooltip', {
    attrs: {
      "disabled": _setup.isEllipses(),
      "text": _setup.ACTIONS_COL.label,
      "position": "top"
    }
  }, [_setup.ACTIONS_COL.prependIcon && _setup.ACTIONS_COL.prependIcon.startsWith('mdi') ? _c('uc-icon', {
    attrs: {
      "value": _setup.ACTIONS_COL.prependIcon
    }
  }) : _vm._e(), _setup.ACTIONS_COL.label !== '' ? _c('uc-text', {
    attrs: {
      "size": _setup.getFontSize(_vm.fontSize),
      "type": 'body',
      "ellipses": true,
      "text": _setup.ACTIONS_COL.label,
      "bold": true
    }
  }) : _vm._e(), _setup.ACTIONS_COL.appendIcon !== '' && _setup.ACTIONS_COL.appendIcon.startsWith('mdi') ? _c('uc-icon', {
    attrs: {
      "value": _setup.ACTIONS_COL.appendIcon
    }
  }) : _vm._e()], 1)], 1)]) : _vm._e(), _vm.canExpand ? _c(VCol, {
    staticClass: "v-col-sm-auto v-col-lg-auto v-col-md-auto v-col-xl-auto v-col-auto"
  }, [_c('div', {
    staticClass: "mr-2"
  }, [_vm._t("default")], 2)]) : _vm._e()], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import { FilterResources } from '@/types/enums/filter-types'
import { AutocompletePayload } from '@/types/interfaces'

export const ACTION_STATUS_FILTER = (
  config = {},
  fetchFn: any,
  params: AutocompletePayload,
  resource: FilterResources
) => {
  return {
    text: 'Action Status',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: `${resource}-action-status-filter`,
      headerText: 'Action Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['action']
    },
    pinned: false,
    isPrimary: false,
    resource,
    ...config
  }
}

import { api } from '@/api'
import { ArchivedTradesmenTripParams } from '@/use/tradesmen-trips'
import qs from 'qs'

export default {
  delete: (tripId: number, id: number) => api.$delete(`/trips/${tripId}/tradesmen_trips/${id}`),
  getArchivedTradesmenTrips: ({ tripId, perPage, page, q, include }: ArchivedTradesmenTripParams) => api.$get(`trips/${tripId}/tradesmen_trips/archived`, {
    params: {
      page,
      per_page: perPage,
      q,
      include
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  getTradesmenTripsArchivedServices: ({ tradesmenTripId, perPage, page, q, include }: ArchivedTradesmenTripParams) => api.$get(`tradesmen_trips/${tradesmenTripId}/services/archived`, {
    params: {
      page,
      per_page: perPage,
      q,
      include
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  })
}

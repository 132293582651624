import { EQUAL, IN } from '@/constants/ransack'

export const getRansackKey = (key: string, value: any) => {
  const type = typeof value
  const isArray = Array.isArray(value)
  if (type === 'string' || type === 'number') {
    return key
  } else if (isArray) {
    return key === EQUAL ? IN : key
  }
  return key
}

export const generateConditional = (
  condition: any,
  values: any,
  operator: string,
  key: string,
  index?: number
) => {
  condition.v = values
  condition.p = getRansackKey(operator, values)
  condition.a[index || 0].name = key
  return condition
}

import { api } from '@/api'
import qs from 'qs'
import { AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'
interface getBillPaymentParams {
  include: string,
  id: number
}

export default {
  createMakePayment: (params: any) => {
    const payload = {
      vendor_id: params.vendorId,
      payment_date: params.paymentDate,
      ref_no: params.refNo,
      amount: params.amount,
      memo: params.memo,
      resource_id: params.resourceId,
      resource_type: params.resourceType,
      items: params.items
    }
    return api.$post('/bill_payments', { bill_payment: payload })
  },
  getBillPayment: (params: any) => {
    const parameters: getBillPaymentParams = params
    const { include, id } = parameters
    return api.$get(`/bill_payments/${id}`, {
      params: {
        include
      }
    })
  },
  madePaymentsAutoComplete: (params: AutocompletePayload) => {
    const { field, terms, predicate, finderParams, page, perPage } = params
    return api.$post('/bill_payments/autocomplete', {
      field,
      terms,
      predicate,
      finderParams,
      page,
      perPage
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }

}

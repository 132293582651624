import { api, apis } from '@/api'
import qs from 'qs'
import { accountMargin } from '@/types/interfaces/api-v2/account-margin'

export default {
  getAccountMargin: () => {
    return api.$get('/settings/accounting/account_margins',
      {
        paramsSerializer: (params) => {
          return qs.stringify(params)
        }
      }
    )
  },
  addAccountMargin: (params: accountMargin) => {
    return api.$post('/settings/accounting/account_margins', { account_margin: params },
      {
        paramsSerializer: (params: any) => {
          return qs.stringify(params)
        }
      })
  },
  updateAccountMargin: (params: accountMargin, id: number) => {
    return api.$patch(`/settings/accounting/account_margins/${id}`, { account_margin: params },
      {
        paramsSerializer: (params: any) => {
          return qs.stringify(params)
        }
      }
    )
  }

}

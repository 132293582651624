import { ResourceAction } from '@/types/interfaces'
import { ExtendedStatus } from '@/types/enums'
import { without } from 'lodash'

const ALL_STATUSES = Object.values(ExtendedStatus)
export const ALLOWED_STATUS_TO_DELETE_SERVICE = [ExtendedStatus.NOT_CHECK_OUT, ExtendedStatus.IN_PROGRESS, ExtendedStatus.COMPLETED]

export const timeCardActionData: ResourceAction[] = [
  {
    name: 'Verify',
    eventName: 'verify',
    validStatuses: [ExtendedStatus.COMPLETED],
    primaryAction: [ExtendedStatus.COMPLETED]
  },
  {
    name: 'Verify with Trip',
    eventName: 'verify-with-trip',
    validStatuses: [ExtendedStatus.COMPLETED],
    primaryAction: []
  },
  {
    name: 'Edit Check In/Out',
    eventName: 'edit-check-in-out',
    validStatuses: without(ALL_STATUSES, ExtendedStatus.CANCELED, ExtendedStatus.CLOSED_INCOMPLETE, ExtendedStatus.TRIP_VERIFIED),
    primaryAction: []
  },
  {
    name: '+ Add Additional Check In/Out',
    eventName: 'add-additional-check-in-out',
    validStatuses: without(ALL_STATUSES, ExtendedStatus.CANCELED, ExtendedStatus.CLOSED_INCOMPLETE, ExtendedStatus.TRIP_VERIFIED),
    primaryAction: []
  },
  {
    name: 'View Adjustment History',
    eventName: 'view-adjustment-history',
    validStatuses: [
      ExtendedStatus.IN_PROGRESS, ExtendedStatus.NOT_CHECK_OUT, ExtendedStatus.TRIP_VERIFIED, ExtendedStatus.COMPLETED
    ],
    primaryAction: [ExtendedStatus.TRIP_VERIFIED]
  },
  {
    name: 'Send Text Message',
    eventName: 'send-text-message',
    validStatuses: without(ALL_STATUSES, ExtendedStatus.CANCELED),
    primaryAction: []
  },
  {
    name: 'Unverify',
    eventName: 'unverify',
    validStatuses: [ExtendedStatus.COMPLETED],
    primaryAction: [ExtendedStatus.COMPLETED]
  },
  {
    name: 'Archive',
    eventName: 'archive',
    textColor: 'error',
    validStatuses: without(ALL_STATUSES, ExtendedStatus.CANCELED, ExtendedStatus.NOT_CHECK_OUT, ExtendedStatus.IN_PROGRESS, ExtendedStatus.COMPLETED, ExtendedStatus.TRIP_VERIFIED),
    primaryAction: []
  },
  {
    name: 'Delete',
    eventName: 'delete',
    validStatuses: ALLOWED_STATUS_TO_DELETE_SERVICE,
    primaryAction: [],
    textColor: 'error'
  }
]

import { kebabCase } from 'lodash'

import {
  ANALYTICS,
  CART_OUTLINE,
  CLIPBOARD_ALERT,
  CREATE,
  CURRENCY,
  FILE_ACCOUNT_OUTLINE,
  FILE_ALERT_OUTLINE,
  FILE_DOCUMENT_MULTIPLE_OUTLINE,
  FILE_SIGN,
  ICON_CREW,
  ICON_PEOPLE,
  ICON_ROLE,
  ICON_TEAM_MEMBER,
  SEND,
  ICON_VENDOR,
  ICON_CLIENT,
  REVIEW,
  ICON_ACTIVE_VENDOR,
  SYNC,
  ICON_CANCELLED
} from './icons'
import { BROKER, CLIENT, VENDOR } from './permissions'
import { TopLevelTabs } from '@/types/enums/'
import { PageTab } from '@/constants/top-level-tabs'

// AR AP
export const generateInvoicesTab = 'Generate Invoices'
export const attentionInvoicesTab = 'Attention Invoices'
export const paidInvoicesTab = 'Paid Invoices'
export const generateVendorInvoicesTab = 'Generate Vendor Invoices'
export const bulkPayTab = 'Pay'
export const pendingApprovalTab = 'Pending Approval'
export const allInvoicesTab = 'All Invoices'
export const analyticsReceivableTab = 'Analytics'
export const analyticsPayableTab = 'Analytics'
export const allReceivableInvoicesTab = 'All Receivable Invoices'
export const allPayableInvoicesTab = 'All Payable Invoices'
// RECRUITMENT
export const brokerTemplatesTab = 'Application Templates'
export const vendorApplicationsTab = 'My Applications'
export const reviewApplicationsTab = 'Review Applications'
// EXPLORE
// Invoices
export const exploreInvoicesTab = 'Invoices'
export const exploreOutboundInvoicesTab = 'Receivable Invoices'
export const exploreInboundInvoicesTab = 'Payable Invoices'
export const exploreBillPaymentsTab = 'Made Payments'
// Payments
export const explorePaymentsTab = 'Payments'
export const exploreReceivedPaymentsTab = 'Received Payments'
export const paymentTab = 'Received Payments'
export const madePaymentTab = 'Sent Payments'
// Proposals
export const exploreSentProposalsTab = 'Sent Proposals'
export const exploreProposalsTab = 'Proposals'
export const exploreReceivedProposalsTab = 'Received Proposals'
// POs
export const explorePurchaseOrdersTab = 'Purchase Orders'
export const allPurchaseOrders = 'All Purchase Orders'
export const exploreOutboundPurchaseOrdersTab = 'Sent PO'
export const exploreInboundPurchaseOrdersTab = 'Received PO'
// SALES
export const proposalsTab = 'All Proposals'
export const toBeSentProposalsTab = 'To Be Sent'
export const requiresActionProposalsTab = 'Requires Action'
export const convertToWorkOrderTab = 'Convert To Work Order'
export const requiresActionInboundProposalsTab = 'Inbound Proposals Requiring Action'
export const requiresActionOutboundProposalsTab = 'Outbound Proposals Requiring Action'
export const allProposalsTab = 'All Proposals'
export const allProposalSentTab = 'All Sent Proposals'
export const allProposalReceivedTab = 'All Received Proposals'
// To Be Sent
export const proposalSent = 'Proposal Sent'
export const proposalReceived = 'Proposal Received'
// Action Required
export const proposalSentAction = 'Proposal Sent Action'
export const proposalReceivedAction = 'Proposal Received Action'
// Convert To Work Order
export const proposalSentConvertWo = 'Proposal Sent Convert Wo'
export const proposalReceivedConvertWo = 'Proposal Received Convert Wo'

// ORDERS
// OPERATIONS
export const allWorkOrdersTab = 'All Work Orders'
export const actionRequiredWorkOrdersTab = 'Action Required'
export const draftTripsWorkOrdersTab = 'Draft Trips'
export const myWosWorkOrdersTab = 'My Wos'

// Trips
export const allTripsTab = 'All Trips'
export const draftTripsTab = 'Draft Trips'
export const recurringTripsTab = 'Recurring Trips'
export const operationFailureTab = 'Operations Failure'
export const mustScheduleTab = 'Must Schedule'
export const verificationRequiredTab = 'Verification Required'
export const inProgressTab = 'In Progress'

// PURCHASING
export const purchaseOrdersTab = 'All Purchase Orders'
export const poTab = 'purchase-orders'
export const sentPurchaseOrdersTab = 'Sent Purchase Orders'
export const receivedPurchaseOrdersTab = 'Received Purchase Orders'
export const toBeSentPurchaseOrdersTab = 'To Be Sent PO'
export const requiresActionPurchaseOrdersTab = 'Requires Action PO'
export const poRequiresActionReceivedTab = 'Received Requires Action Purchase Order'
export const poDraftSentTab = 'Sent Draft Purchase Order'
export const poRequiresActionSentTab = 'Sent Requires Action Purchase Order'
export const purchaseOrderSent = 'Sent PO'
export const allPurchaseOrdersTab = 'All Purchase Orders'
export const allReceivedPurchaseOrdersTab = 'All Received Purchase Orders'
export const allSentPurchaseOrdersTab = 'All Sent Purchase Orders'
// NETWORK
export const peopleTab = 'People'
export const teamMembersTab = 'Employees'
export const crewTab = 'Crews'
export const rolesTab = 'Roles'
export const vendorsTab = 'Vendors'

export const clientsTab = 'Clients'
export const activeClientsTab = 'Active Clients'
export const pendingClientsTab = 'Pending Clients'
export const blockedClientsTab = 'Blocked Clients'

export const exploreNestedTabsByEnv = {
  development: [],
  prestaging: [],

  staging: [exploreSentProposalsTab, exploreReceivedProposalsTab, exploreOutboundInvoicesTab, exploreInboundInvoicesTab, explorePaymentsTab, exploreBillPaymentsTab, exploreInboundPurchaseOrdersTab, exploreOutboundPurchaseOrdersTab, toBeSentProposalsTab, requiresActionProposalsTab, convertToWorkOrderTab, toBeSentPurchaseOrdersTab, requiresActionPurchaseOrdersTab, allReceivableInvoicesTab, allPayableInvoicesTab, allPurchaseOrdersTab, allProposalsTab, sentPurchaseOrdersTab, receivedPurchaseOrdersTab],

  preproduction: [exploreOutboundInvoicesTab, exploreInboundInvoicesTab, explorePaymentsTab, exploreBillPaymentsTab, generateInvoicesTab, attentionInvoicesTab, generateVendorInvoicesTab, bulkPayTab, pendingApprovalTab, analyticsReceivableTab, analyticsPayableTab, peopleTab, teamMembersTab, crewTab, rolesTab, exploreSentProposalsTab, exploreReceivedProposalsTab, toBeSentProposalsTab, requiresActionProposalsTab, convertToWorkOrderTab, toBeSentPurchaseOrdersTab, requiresActionPurchaseOrdersTab, exploreInboundPurchaseOrdersTab, exploreOutboundPurchaseOrdersTab, vendorsTab, clientsTab, activeClientsTab, pendingClientsTab, blockedClientsTab, allReceivableInvoicesTab, allPayableInvoicesTab, allPurchaseOrdersTab, allProposalsTab, allTripsTab, draftTripsTab, recurringTripsTab, operationFailureTab, mustScheduleTab, verificationRequiredTab, inProgressTab, paymentTab, madePaymentTab, sentPurchaseOrdersTab, receivedPurchaseOrdersTab],

  production: [exploreOutboundInvoicesTab, exploreInboundInvoicesTab, explorePaymentsTab, exploreBillPaymentsTab, generateInvoicesTab, attentionInvoicesTab, generateVendorInvoicesTab, bulkPayTab, pendingApprovalTab, analyticsReceivableTab, analyticsPayableTab, peopleTab, teamMembersTab, crewTab, rolesTab, exploreSentProposalsTab, exploreReceivedProposalsTab, toBeSentProposalsTab, requiresActionProposalsTab, convertToWorkOrderTab, toBeSentPurchaseOrdersTab, requiresActionPurchaseOrdersTab, exploreInboundPurchaseOrdersTab, exploreOutboundPurchaseOrdersTab, vendorsTab, clientsTab, activeClientsTab, pendingClientsTab, blockedClientsTab, allReceivableInvoicesTab, allPayableInvoicesTab, allPurchaseOrdersTab, allProposalsTab, allTripsTab, draftTripsTab, recurringTripsTab, operationFailureTab, mustScheduleTab, verificationRequiredTab, inProgressTab, paymentTab, madePaymentTab, sentPurchaseOrdersTab, receivedPurchaseOrdersTab]
} as any

/*
  TRIP TABS
*/
export const ALL_TRIPS_TAB = {
  name: allTripsTab,
  prependIcon: FILE_ALERT_OUTLINE,
  companyTypes: [BROKER, VENDOR, CLIENT],
  featureAccess: [{ feature: 'work_orders', subFeature: '' }],
  quantity: '',
  tabFilter: {},
  workOrderFilter: {},
  id: 10
}
export const OPERATIONS_FAILURE_TAB = {
  name: operationFailureTab,
  prependIcon: FILE_DOCUMENT_MULTIPLE_OUTLINE,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'work_orders', subFeature: '' }],
  quantity: '',
  tabFilter: { extended_status_code_in: [3, 4, 5, 6, 10] },
  workOrderFilter: { trips_extended_status_code_in: [3, 4, 5, 6, 10] },
  id: 11
}
export const MUST_SCHEDULE_TAB = {
  name: mustScheduleTab,
  prependIcon: FILE_ALERT_OUTLINE,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'work_orders', subFeature: '' }],
  quantity: '',
  tabFilter: { extended_status_code_in: [0, 1, 2, 11] },
  workOrderFilter: { trips_extended_status_code_in: [0, 1, 2, 11] },
  id: 12
}
export const VERIFICATION_REQUIRED_TAB = {
  name: verificationRequiredTab,
  prependIcon: FILE_SIGN,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'work_orders', subFeature: '' }],
  quantity: '',
  tabFilter: { extended_status_code_in: [14] },
  workOrderFilter: { trips_extended_status_code_in: [14] },
  id: 13
}
export const IN_PROGRESS_TAB = {
  name: inProgressTab,
  prependIcon: FILE_DOCUMENT_MULTIPLE_OUTLINE,
  companyTypes: [BROKER, VENDOR, CLIENT],
  featureAccess: [{ feature: 'work_orders', subFeature: '' }],
  quantity: '',
  tabFilter: { extended_status_code_in: [7, 8, 9] },
  workOrderFilter: { trips_extended_status_code_in: [7, 8, 9] },
  id: 14
}
export const DRAFT_TRIPS_TAB = {
  name: draftTripsTab,
  prependIcon: FILE_SIGN,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'work_orders', subFeature: '' }],
  quantity: '',
  tabFilter: {},
  workOrderFilter: {},
  id: 15
}

/*
 RECEIVABLE TABS
*/
export const GENERATE_INVOICES_TAB = {
  name: generateInvoicesTab,
  prependIcon: CREATE,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'invoice', subFeature: '' }],
  id: 16
}
export const ALL_RECEIVABLE_INVOICES_TAB = {
  name: allReceivableInvoicesTab,
  prependIcon: CLIPBOARD_ALERT,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'invoice', subFeature: '' }],
  id: 17
}

export const ATTENTION_INVOICES_TAB = {
  name: attentionInvoicesTab,
  prependIcon: CLIPBOARD_ALERT,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'invoice', subFeature: '' }],
  tabFilter: { status_not_in: ['paid', 'void'] },
  workOrderFilter: { invoices_status_not_in: ['paid', 'void'] },
  id: 18
}
export const ANALYTICS_RECEIVABLE_TAB = {
  name: analyticsReceivableTab,
  prependIcon: ANALYTICS,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'dashboard', subFeature: '' }],
  id: 19
}

/*
  PAYABLE TABS
*/
export const GENERATE_VENDOR_INVOICES_TAB = {
  name: generateVendorInvoicesTab,
  prependIcon: CREATE,
  companyTypes: [BROKER],
  featureAccess: [{ feature: 'invoice', subFeature: '' }],
  id: 20
}
export const ALL_PAYABLE_INVOICES_TAB = {
  name: allPayableInvoicesTab,
  prependIcon: CLIPBOARD_ALERT,
  companyTypes: [BROKER, CLIENT],
  featureAccess: [{ feature: 'invoice', subFeature: '' }],
  id: 21
}
export const PENDING_APPROVAL_TAB = {
  name: pendingApprovalTab,
  prependIcon: CREATE,
  companyTypes: [BROKER, CLIENT],
  featureAccess: [{ feature: 'invoice', subFeature: '' }],
  tabFilter: { status_in: ['sent', 'approval_requested'] },
  workOrderFilter: { invoices_status_in: ['sent', 'approval_requested'] },
  id: 22
}
export const BULK_PAY_TAB = {
  name: bulkPayTab,
  prependIcon: CREATE,
  companyTypes: [BROKER, CLIENT],
  featureAccess: [{ feature: 'invoice', subFeature: '' }],
  tabFilter: { status_in: ['partially_paid', 'approved'] },
  workOrderFilter: { invoices_status_in: ['partially_paid', 'approved'] },
  id: 23
}
export const ANALYTICS_PAYABLE_TAB = {
  name: analyticsPayableTab,
  prependIcon: ANALYTICS,
  companyTypes: [BROKER],
  featureAccess: [{ feature: 'dashboard', subFeature: '' }],
  id: 24
}

/*
  PROPOSAL TABS
*/
export const ALL_PROPOSALS_TAB = {
  name: allProposalsTab,
  prependIcon: CURRENCY,
  companyTypes: [BROKER, VENDOR, CLIENT],
  featureAccess: [{ feature: 'proposal', subFeature: '' }],
  id: 25
}

export const TO_BE_SENT_PROPOSALS_TAB = {
  name: toBeSentProposalsTab,
  prependIcon: SEND,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'proposal', subFeature: '' }],
  tabFilter: { status_in: ['draft', 'ready_for_review'] },
  workOrderFilter: { proposals_status_in: ['draft', 'ready_for_review'] },
  id: 26
}
export const REQUIRES_ACTION_PROPOSALS_TAB = {
  name: requiresActionProposalsTab,
  prependIcon: CLIPBOARD_ALERT,
  companyTypes: [BROKER, VENDOR, CLIENT],
  featureAccess: [{ feature: 'proposal', subFeature: '' }],
  tabFilter: { status_in: ['sent', 'evaluating', 'rejected'] },
  workOrderFilter: { proposals_status_in: ['sent', 'evaluating', 'rejected'] },
  id: 27
}
export const CONVERT_TO_WORK_ORDER_TAB = {
  name: convertToWorkOrderTab,
  prependIcon: CLIPBOARD_ALERT,
  companyTypes: [BROKER],
  featureAccess: [{ feature: 'proposal', subFeature: '' }],
  tabFilter: { status_in: ['accepted'] },
  workOrderFilter: { proposals_status_in: ['accepted'] },
  id: 28
}

/*
  RECRUITMENT TABS
*/
export const VENDOR_APPLICATIONS_TAB = {
  name: vendorApplicationsTab,
  prependIcon: 'mdi-pencil-box',
  companyTypes: [VENDOR],
  featureAccess: [{ feature: 'vendors', subFeature: '' }],
  id: 29
}
export const BROKER_TEMPLATES_TAB = {
  name: brokerTemplatesTab,
  prependIcon: 'mdi-pencil-plus',
  companyTypes: [BROKER],
  featureAccess: [{ feature: 'vendors', subFeature: '' }],
  id: 30
}
export const REVIEW_APPLICATIONS_TAB = {
  name: reviewApplicationsTab,
  prependIcon: 'mdi-file-find',
  companyTypes: [BROKER],
  featureAccess: [{ feature: 'vendors', subFeature: '' }],
  id: 31
}

/*
  PURCHASE ORDER TABS
*/
export const ALL_PURCHASE_ORDERS_TAB = {
  name: allPurchaseOrdersTab,
  prependIcon: CART_OUTLINE,
  companyTypes: [BROKER],
  featureAccess: [{ feature: 'purchase_order', subFeature: '' }],
  id: 32
}
export const TO_BE_SENT_PURCHASE_ORDERS_TAB = {
  name: toBeSentPurchaseOrdersTab,
  prependIcon: CART_OUTLINE,
  companyTypes: [BROKER],
  featureAccess: [{ feature: 'purchase_order', subFeature: '' }],
  tabFilter: { status_in: ['draft'] },
  workOrderFilter: { purchase_orders_status_in: ['draft'] },
  id: 33
}
export const REQUIRES_ACTION_PURCHASE_ORDERS_TAB = {
  name: requiresActionPurchaseOrdersTab,
  prependIcon: CART_OUTLINE,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'purchase_order', subFeature: '' }],
  tabFilter: { status_in: ['sent', 'rejected'] },
  workOrderFilter: { purchase_orders_status_in: ['sent', 'rejected'] },
  id: 34
}

/*
  EXPLORE TAB
*/
export const EXPLORE_SENT_PROPOSALS_TAB = {
  name: exploreSentProposalsTab,
  prependIcon: CURRENCY,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'proposal', subFeature: '' }],
  id: 38
}
export const EXPLORE_RECEIVED_PROPOSALS_TAB = {
  name: exploreReceivedProposalsTab,
  prependIcon: CURRENCY,
  companyTypes: [BROKER, CLIENT],
  featureAccess: [{ feature: 'proposal', subFeature: '' }],
  id: 39
}
export const EXPLORE_OUTBOUND_INVOICES_TAB = {
  name: exploreOutboundInvoicesTab,
  prependIcon: CLIPBOARD_ALERT,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'invoice', subFeature: '' }],
  id: 40
}
export const EXPLORE_INBOUND_INVOICES_TAB = {
  name: exploreInboundInvoicesTab,
  prependIcon: CLIPBOARD_ALERT,
  companyTypes: [BROKER, CLIENT],
  featureAccess: [{ feature: 'invoice', subFeature: '' }],
  id: 41
}
export const EXPLORE_PAYMENTS_TAB = {
  name: explorePaymentsTab,
  prependIcon: 'mdi-cash-check',
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'payments', subFeature: '' }],
  id: 42
}
export const EXPLORE_BILL_PAYMENTS_TAB = {
  name: exploreBillPaymentsTab,
  prependIcon: 'mdi-cash-check',
  companyTypes: [BROKER, CLIENT],
  featureAccess: [{ feature: 'payments', subFeature: '' }],
  id: 43
}
export const EXPLORE_INBOUND_PURCHASE_ORDERS_TAB = {
  name: exploreInboundPurchaseOrdersTab,
  prependIcon: CURRENCY,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'purchase_order', subFeature: '' }],
  id: 44
}
export const EXPLORE_OUTBOUND_PURCHASE_ORDERS_TAB = {
  name: exploreOutboundPurchaseOrdersTab,
  prependIcon: CURRENCY,
  companyTypes: [BROKER],
  featureAccess: [{ feature: 'purchase_order', subFeature: '' }],
  id: 45
}

/*
  PEOPLE TABS
*/
export const TEAM_MEMBERS_TAB = {
  name: teamMembersTab,
  prependIcon: ICON_TEAM_MEMBER,
  companyTypes: [BROKER, VENDOR, CLIENT],
  featureAccess: [{ feature: 'team_members', subFeature: '' }],
  id: 46
}
export const CREW_TAB = {
  name: crewTab,
  prependIcon: ICON_CREW,
  companyTypes: [BROKER],
  featureAccess: [{ feature: 'crew', subFeature: '' }],
  id: 47
}

export const ROLES_TAB = {
  name: rolesTab,
  prependIcon: ICON_ROLE,
  companyTypes: [BROKER],
  featureAccess: [{ feature: 'roles', subFeature: '' }],
  id: 48
}

/*
  ALL PROPOSALS TABS
*/
export const ALL_PROPOSALS_SENT_TAB = {
  name: exploreSentProposalsTab,
  prependIcon: SEND,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'proposal', subFeature: '' }],
  id: 52
}
export const ALL_PROPOSALS_RECEIVED_TAB = {
  name: exploreReceivedProposalsTab,
  prependIcon: CURRENCY,
  companyTypes: [BROKER, CLIENT],
  featureAccess: [{ feature: 'proposal', subFeature: '' }],
  id: 53
}

/*
  TO BE SENT PROPOSALS TAB
*/

export const PROPOSALS_SENT_TAB = {
  name: proposalSent,
  prependIcon: SEND,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'proposal', subFeature: '' }],
  tabFilter: { status_in: ['draft', 'ready_for_review'] },
  workOrderFilter: { proposals_status_in: ['draft', 'ready_for_review'] },
  id: 54
}
export const PROPOSALS_RECEIVED_TAB = {
  name: proposalReceived,
  prependIcon: CLIPBOARD_ALERT,
  companyTypes: [BROKER],
  featureAccess: [{ feature: 'proposal', subFeature: '' }],
  tabFilter: { status_in: ['draft', 'ready_for_review'] },
  workOrderFilter: { proposals_status_in: ['draft', 'ready_for_review'] },
  id: 55
}

/*
  REQUIRES ACTION PROPOSAL TAB
*/
export const PROPOSAL_SENT_ACTION = {
  name: proposalSentAction,
  prependIcon: SEND,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'proposal', subFeature: '' }],
  tabFilter: { status_in: ['sent', 'evaluating', 'rejected'] },
  workOrderFilter: { proposals_status_in: ['sent', 'evaluating', 'rejected'] },
  id: 56
}
export const PROPOSAL_RECEIVED_ACTION = {
  name: proposalReceivedAction,
  prependIcon: CLIPBOARD_ALERT,
  companyTypes: [BROKER, CLIENT],
  featureAccess: [{ feature: 'proposal', subFeature: '' }],
  tabFilter: { status_in: ['sent', 'evaluating', 'rejected'] },
  workOrderFilter: { proposals_status_in: ['sent', 'evaluating', 'rejected'] },
  id: 57
}

/*
  CONVERT TO WORK ORDER PROPOSAL TAB
*/
export const PROPOSAL_SENT_CONVERT_WORK_ORDER = {
  name: proposalSentConvertWo,
  prependIcon: SEND,
  companyTypes: [BROKER],
  featureAccess: [{ feature: 'proposal', subFeature: '' }],
  tabFilter: { status_in: ['accepted'] },
  workOrderFilter: { proposals_status_in: ['accepted'] },
  id: 58
}
export const PROPOSAL_RECEIVED_CONVERT_WORK_ORDER = {
  name: proposalReceivedConvertWo,
  prependIcon: CLIPBOARD_ALERT,
  companyTypes: [BROKER],
  featureAccess: [{ feature: 'proposal', subFeature: '' }],
  tabFilter: { status_in: ['accepted'] },
  workOrderFilter: { proposals_status_in: ['accepted'] },
  id: 59
}

/*
  TO BE SENT PURCHASE ORDERS
*/
export const PURCHASE_ORDER_DRAFT_SENT_TAB = {
  name: poDraftSentTab,
  prependIcon: SEND,
  companyTypes: [BROKER],
  featureAccess: [{ feature: 'purchase_order', subFeature: '' }],
  tabFilter: { status_in: ['draft'] },
  workOrderFilter: { purchase_orders_status_in: ['draft'] },
  id: 60
}

/*
  ACTION REQUIERD PURCHASE ORDERS
*/
export const PURCHASE_ORDER_REQUIRES_ACTION_SENT_TAB = {
  name: poRequiresActionSentTab,
  prependIcon: SEND,
  companyTypes: [BROKER],
  featureAccess: [{ feature: 'purchase_order', subFeature: '' }],
  tabFilter: { status_in: ['sent', 'rejected'] },
  workOrderFilter: { purchase_orders_status_in: ['sent', 'rejected'] },
  id: 61
}
export const PURCHASE_ORDER_REQUIRES_ACTION_RECEIVED_TAB = {
  name: poRequiresActionReceivedTab,
  prependIcon: CLIPBOARD_ALERT,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'purchase_order', subFeature: '' }],
  tabFilter: { status_in: ['sent', 'rejected'] },
  workOrderFilter: { purchase_orders_status_in: ['sent', 'rejected'] },
  id: 62
}

/*
  ALL PURCHASE ORDER TABS
*/
export const ALL_SENT_PURCHASE_ORDERS_TAB = {
  name: sentPurchaseOrdersTab,
  prependIcon: SEND,
  companyTypes: [BROKER],
  featureAccess: [{ feature: 'purchase_order', subFeature: '' }],
  id: 63
}
export const ALL_RECEIVED_PURCHASE_ORDERS_TAB = {
  name: receivedPurchaseOrdersTab,
  prependIcon: CART_OUTLINE,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'purchase_order', subFeature: '' }],
  id: 64
}

/*
  CLIENTS TAB
*/
export const ACTIVE_CLIENTS_TAB = {
  name: activeClientsTab,
  prependIcon: ICON_ACTIVE_VENDOR,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'customers', subFeature: '' }],
  id: 65
}
export const PENDING_CLIENTS_TAB = {
  name: pendingClientsTab,
  prependIcon: SYNC,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'customers', subFeature: '' }],
  id: 66
}
export const BLOCKED_CLIENTS_TAB = {
  name: blockedClientsTab,
  prependIcon: ICON_CANCELLED,
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'customers', subFeature: '' }],
  id: 67
}

/*
  PAYMENTS TAB
*/
export const PAYMENTS_TAB = {
  name: paymentTab,
  prependIcon: 'mdi-cash-check',
  companyTypes: [BROKER, VENDOR],
  featureAccess: [{ feature: 'payments', subFeature: '' }],
  id: 70
}

export const MADE_PAYMENTS_TAB = {
  name: madePaymentTab,
  prependIcon: 'mdi-cash-check',
  companyTypes: [BROKER, CLIENT],
  featureAccess: [{ feature: 'payments', subFeature: '' }],
  id: 71
}

export const allNestedTabs = {
  [kebabCase(TopLevelTabs.TRIPS_TAB)]: [
    ALL_TRIPS_TAB,
    IN_PROGRESS_TAB,
    DRAFT_TRIPS_TAB
  ],
  [kebabCase(TopLevelTabs.RECEIVABLE_TAB)]: [
    GENERATE_INVOICES_TAB,
    ALL_RECEIVABLE_INVOICES_TAB,
    ANALYTICS_RECEIVABLE_TAB
  ],
  [kebabCase(TopLevelTabs.PAYABLE_TAB)]: [
    GENERATE_VENDOR_INVOICES_TAB,
    ALL_PAYABLE_INVOICES_TAB,
    ANALYTICS_PAYABLE_TAB
  ],
  [kebabCase(TopLevelTabs.SALES_TAB)]: [
    ALL_PROPOSALS_SENT_TAB,
    ALL_PROPOSALS_RECEIVED_TAB
  ],
  [kebabCase(TopLevelTabs.RECRUITMENT_TAB)]: [
    VENDOR_APPLICATIONS_TAB,
    BROKER_TEMPLATES_TAB,
    REVIEW_APPLICATIONS_TAB
  ],
  [kebabCase(TopLevelTabs.PURCHASING_TAB)]: [
    ALL_SENT_PURCHASE_ORDERS_TAB,
    ALL_RECEIVED_PURCHASE_ORDERS_TAB
  ],
  [kebabCase(clientsTab)]: [
    ACTIVE_CLIENTS_TAB,
    PENDING_CLIENTS_TAB,
    BLOCKED_CLIENTS_TAB
  ],
  [kebabCase(peopleTab)]: [
    TEAM_MEMBERS_TAB,
    CREW_TAB,
    ROLES_TAB
  ],
  // [kebabCase(TopLevelTabs.NETWORK_TAB)]: [
  //   PEOPLE_TAB,
  //   VENDORS_TAB,
  //   CLIENTS_TAB
  // ],
  [kebabCase(TopLevelTabs.PAYMENT_TAB)]: [
    PAYMENTS_TAB,
    MADE_PAYMENTS_TAB
  ],
  [kebabCase(TopLevelTabs.EXPLORE_TAB)]: [
    EXPLORE_SENT_PROPOSALS_TAB,
    EXPLORE_RECEIVED_PROPOSALS_TAB,
    EXPLORE_OUTBOUND_INVOICES_TAB,
    EXPLORE_INBOUND_INVOICES_TAB,
    EXPLORE_PAYMENTS_TAB,
    EXPLORE_BILL_PAYMENTS_TAB,
    EXPLORE_INBOUND_PURCHASE_ORDERS_TAB,
    EXPLORE_OUTBOUND_PURCHASE_ORDERS_TAB
  ]
} as Record<string, PageTab[]>

export const allSubNestedTabs = {
  [kebabCase(exploreSentProposalsTab)]: [
    ALL_PROPOSALS_SENT_TAB,
    ALL_PROPOSALS_RECEIVED_TAB
  ],
  [kebabCase(toBeSentProposalsTab)]: [
    PROPOSALS_SENT_TAB,
    PROPOSALS_RECEIVED_TAB
  ],
  [kebabCase(requiresActionProposalsTab)]: [
    PROPOSAL_SENT_ACTION,
    PROPOSAL_RECEIVED_ACTION
  ],
  [kebabCase(convertToWorkOrderTab)]: [
    PROPOSAL_SENT_CONVERT_WORK_ORDER,
    PROPOSAL_RECEIVED_CONVERT_WORK_ORDER
  ],
  [kebabCase(toBeSentPurchaseOrdersTab)]: [
    PURCHASE_ORDER_DRAFT_SENT_TAB
  ],
  [kebabCase(requiresActionPurchaseOrdersTab)]: [
    PURCHASE_ORDER_REQUIRES_ACTION_SENT_TAB,
    PURCHASE_ORDER_REQUIRES_ACTION_RECEIVED_TAB
  ],
  [kebabCase(sentPurchaseOrdersTab)]: [
    ALL_SENT_PURCHASE_ORDERS_TAB,
    ALL_RECEIVED_PURCHASE_ORDERS_TAB
  ]
} as Record<string, PageTab[]>

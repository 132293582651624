var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-flex flex-column align-center justify-center mt-10"
  }, [_c('div', {
    staticClass: "pb-3"
  }, [_vm.icon ? _c('div', {
    staticClass: "position-relative text-disabled pa-4 d-flex flex-row justify-center align-center rounded-pill",
    staticStyle: {
      "height": "60px",
      "width": "60px"
    }
  }, [_c('uc-icon', {
    attrs: {
      "size": "xl",
      "value": _vm.icon,
      "state": 'white',
      "font-awesome-icon": ""
    }
  }), _c('uc-img-tag', {
    staticClass: "otto",
    attrs: {
      "src": require(`@/assets/OTTO CIRCLE.svg`),
      "contain": "",
      "height": "24px"
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "d-flex flex-column flex-1 gap-md ml-2"
  }, [_vm.text ? _c('uc-text', {
    attrs: {
      "size": 3,
      "type": "title",
      "text": _vm.text
    }
  }) : _vm._e(), _vm.subText ? _c('uc-text', {
    class: 'text-center',
    attrs: {
      "size": 4,
      "type": "body",
      "text": _vm.subText
    }
  }) : _vm._e()], 1), _vm.buttonText ? _c('uc-button', {
    staticClass: "mt-3",
    attrs: {
      "size": "sm",
      "text": _vm.buttonText,
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _setup.emit('action');
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
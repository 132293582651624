import { VChip } from 'vuetify/lib/components/VChip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', _vm._g({
    staticClass: "uc-chip__wrapper overflow-hidden",
    class: {
      'uc-chip__wrapper--dark': _vm.$vuetify.theme.dark
    },
    on: {
      "mouseenter": function ($event) {
        _vm.canSelect || _vm.clickable && (_vm.hover = true);
      },
      "mouseleave": function ($event) {
        _vm.hover = false;
      },
      "click": function ($event) {
        _vm.canSelect && _vm.toggleSelected();
      }
    }
  }, _vm.$listeners), [_c('uc-tooltip', {
    staticClass: "overflow-hidden",
    attrs: {
      "disabled": (_vm.isEllipses() || _vm.tooltipPosition === 'none') && !_vm.tooltipText,
      "position": _vm.tooltipPosition,
      "text": _vm.tooltipText ? _vm.tooltipText : _vm.text + _vm.appendedText
    }
  }, [_c(VChip, _vm._b({
    class: [`uc-chip ${_vm.size} ${_vm.noBorderRadius ? 'rounded-0' : _vm.borderRadius}`, {
      'uc-chip-pinned': _vm.pinned
    }],
    style: {
      cursor: _vm.clickable || _vm.canSelect ? 'pointer' : '',
      ..._vm.styleObj
    },
    attrs: {
      "label": true,
      "close-icon": 'mdi-close',
      "outlined": _vm.outlined
    },
    on: {
      "click:close": function ($event) {
        return _vm.$emit('click:close');
      }
    }
  }, 'v-chip', {
    ..._vm.$attrs,
    color: _vm.backgroundColor || _vm.chipState,
    large: _vm.size === 'lg',
    small: _vm.size === 'sm',
    xSmall: _vm.size === 'xs',
    close: _vm.closeable
  }, false), [_vm.pinned ? _c('uc-icon', {
    attrs: {
      "size": "xs",
      "value": _vm.PIN
    }
  }) : _vm._e(), _vm.prependIcon ? _c('uc-icon', {
    attrs: {
      "value": _vm.prependIcon,
      "size": "sm"
    }
  }) : _vm._e(), _vm._t("prepend"), !_vm.hideTextOnMobile ? [_vm.text + _vm.appendedText !== '' ? _c('uc-text', {
    ref: "textRef",
    attrs: {
      "size": _vm.getFontSize(_vm.size),
      "type": "body",
      "text": _vm.text + _vm.appendedText,
      "palette-color": _vm.paletteColor,
      "ellipses": "",
      "bold": ""
    }
  }) : _vm._e(), _vm.quantity !== '' && _vm.showQuantity && parseInt(_vm.quantity) > 0 ? _c('uc-quantity', {
    attrs: {
      "quantity": _vm.quantity,
      "size": _vm.size,
      "background-color": _vm.state,
      "text-palette-color": _vm.paletteColor
    }
  }) : _vm._e()] : _vm._e(), _vm.appendIcon ? _c('uc-icon', {
    staticClass: "uc-chip__append-icon mx-2",
    attrs: {
      "value": _vm.appendIcon,
      "size": "sm"
    }
  }) : _vm._e()], 2)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import { defineComponent, computed, ref, watch } from 'vue';
import { kebabCase } from 'lodash';
export default defineComponent({
  props: {
    tabs: {
      type: Array,
      required: true
    },
    centered: {
      type: Boolean,
      default: false
    },
    showDivider: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    grow: {
      type: Boolean,
      default: false
    },
    isNested: {
      type: Boolean,
      default: false
    },
    noMargin: {
      type: Boolean,
      default: false
    },
    scroll: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: 0
    },
    contentBackground: {
      type: String,
      default: 'transparent'
    },
    contentDivider: {
      type: Boolean,
      default: false
    },
    hasChildrenTabs: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    },
    alignText: {
      type: String,
      default: 'center'
    },
    prerenderTabs: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup: props => {
    const tabsRef = ref();
    const tab = ref(props.value);
    const tabValues = ref(props.tabs);
    watch([() => props.value, () => props.tabs], () => {
      var _tabsRef$value;
      tab.value = props.value;
      tabValues.value = props.tabs;
      // hack, when our props change we may have different sized tabs due to rendering data,
      // so we must tell the tabs component to re-compute the slider
      (_tabsRef$value = tabsRef.value) === null || _tabsRef$value === void 0 ? void 0 : _tabsRef$value.callSlider();
    }, {
      deep: true
    });
    const lowerCaseTabNames = computed(() => {
      if (!Array.isArray(props.tabs)) return [];
      return props.tabs.map(({
        name
      }) => {
        // return name.split(' ').join('-').toLowerCase()
        // changed above code to kebabcase to align with the tab template key,
        // issue is happening when the tab name has more spaces or numeric values in that case tab
        // name is appended with more --- due to content is not gettting mapped with tab.
        return kebabCase(name);
      });
    });
    const calcHeight = () => {
      if (!props.vertical) return props.dense ? '38px' : null;else return '100%';
    };
    const transitionType = ref('fade');
    watch(tab, (newVal, oldVal) => {
      if (newVal < oldVal) {
        transitionType.value = 'medium-slide-fade-backward';
      } else if (newVal > oldVal) {
        transitionType.value = 'medium-slide-fade-forward';
      }
    });
    return {
      tab,
      lowerCaseTabNames,
      transitionType,
      calcHeight,
      tabValues,
      tabsRef
    };
  }
});
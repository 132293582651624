import { api } from '@/api'
import qs from 'qs'
import { FetchUserParams } from '@/use/users'

export interface UpdateUserParams {
  id: number
  hubspotChatClientAttributes?: {
    lastLoginAt: Date | string
  }
  visitorIdentification?: boolean
}

export default {
  show: ({ id, include, q, page, perPage }: FetchUserParams) => {
    return api.$get(`/users/${id}`, {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getHubspotInfo: ({ id }: FetchUserParams) => {
    return api.$get(`/users/${id}/hubspot_info`)
  },
  update: ({ id, ...restParams }: UpdateUserParams) => {
    return api.$patch(`/users/${id}`, restParams, {
      paramsSerializer: (restParams: any) => {
        return qs.stringify(restParams)
      }
    })
  }
}

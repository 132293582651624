export enum TopLevelTabs {
  PAYABLE_TAB = 'Payables',
  RECEIVABLE_TAB = 'Receivables',
  RECRUITMENT_TAB = 'Recruitment',
  SALES_TAB = 'Proposals',
  EXPLORE_TAB = 'Explore',
  NETWORK_TAB = 'Network',
  ORDERS_TAB = 'Work Orders',
  TRIPS_TAB = 'Trips',
  PURCHASING_TAB = 'Purchase Orders',
  MATERIALS_TAB = 'Materials',
  PAYMENT_TAB = 'Payments',
  CLIENTS = 'Clients',
  VENDORS = 'Vendors',
  PEOPLE = 'People',
  PROFILE = 'Profile',
  SERVICE_PHOTOS = 'Photos'

}

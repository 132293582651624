var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-flex fill-width rounded card-headers"
  }, [_c('uc-tile-container', {
    class: `row flex-row py-3 px-2 fill-width`,
    attrs: {
      "no-padding": true,
      "no-margin": true,
      "background-color": "--v-layer-base",
      "no-elevation": true
    }
  }, [_vm.canSelect ? _c('div', {
    staticClass: "column-select"
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex flex column-container pl-1"
  }, [_c('uc-tooltip', {
    attrs: {
      "disabled": _setup.isEllipses(),
      "text": _setup.columnOneNames.join(' | '),
      "position": "top"
    }
  }, [_c('uc-text', {
    staticClass: "column-one",
    attrs: {
      "type": "body",
      "size": 3,
      "text": _setup.columnOneNames.join(' | '),
      "bold": "",
      "ellipses": ""
    }
  })], 1), _c('uc-tooltip', {
    attrs: {
      "disabled": _setup.isEllipses(),
      "text": _setup.columnTwoNames.join(' | '),
      "position": "top"
    }
  }, [_c('uc-text', {
    staticClass: "column-two",
    attrs: {
      "type": "body",
      "size": 3,
      "text": _setup.columnTwoNames.join(' | '),
      "bold": "",
      "ellipses": ""
    }
  })], 1)], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import { INVOICE_RESOURCE_TYPE } from '@/constants/resource-types'
import { useApp } from '@/use/app'
import { useBackgroundTasks } from '@/use/background-tasks'
import { useExports } from '@/use/exports'
import { useSession } from '@/use/session'
import { snakeCase, uniq, uniqBy } from 'lodash'
import {
  useTransactionResources
} from '@/use/transactional-resources'
import { useUsersCompanies } from '@/use/users-companies'
import { CLOSE, CURRENCY, DATE_EXPIRED, DRAFT, ERROR, MAIL_BOX, PDF_DOWNLOAD, PRINT, RECEIVED, REJECTED_ALERT, SEND, SHARE, SUCCESS, FILE_PDF } from '@/constants/icons'
import moment from 'moment-timezone'
import { inboundActionData } from '@/constants/actions-by-resource/invoices/inbound'
import { outboundActionData } from '@/constants/actions-by-resource/invoices/outbound'
import repositories from '@/repositories'
import { useToasts } from '@/use/toasts'
import { useCompanySettings } from '@/use/company-settings'
import { BROKER, VENDOR, CLIENT } from '@/constants/permissions'
import { NewInvoice, Trip } from '@/types/interfaces'
import { useClients } from '@/use/clients'
import { useWorkOrders } from '@/use/work-orders'
import { LOCATION_INCLUDE } from '@/constants/include'
import { useWeatherEvents } from '@/use/weather-events'
import { useInboundWorkOrders } from '@/use/inbound-work-orders'
import { useLocations } from '@/use/locations'
import { getResourceClientOrVendorId } from '@/utils/resource-details'
import { baseGetResponse } from '@/utils/api'
import { BackgroundTaskSources, BackgroundTaskFormats, BackgroundTaskReportTypes, BackgroundTaskZipFileTypes, DetailsPanelButtonState } from '@/types/enums'
import { computed, Ref, ref } from 'vue'
import { useListRow } from '../list-rows'
import { findCompanyType } from '@/utils/company'
import { DirectionalResourceAction } from '@/types/interfaces/global/resource-actions'
import { isMobile } from '@/utils/user-agent'
import { useInboundInvoices } from './inbound'
import { useOutboundInvoices } from './outbound'
import { useDetailsPanel } from '../details-panel'

const INVOICE_STATUSES = ['unsaved', 'sent', 'draft', 'rejected', 'partially_paid', 'approved', 'approved_to_send', 'paid', 'void', 'approval_requested', 'disputed', 'on_hold', 'payment_processing', 'payment_pending']
const SAVED_INVOICE_STATUSES = INVOICE_STATUSES.filter((status: string) => status !== 'unsaved')

const MODAL_CONTENT_NOTE_COMPONENT = 'uc/resource/modal-content/note'
const MODAL_CONTENT_FORWARD_COMPONENT = 'uc/resource/modal-content/forward'
const workOrderReportModalConfig = ref<any>({})

const statusTransformationMap = (outbound = true) => {
  return {
    approved: { text: 'Approved', state: 'success', prependIcon: SUCCESS },
    approved_to_send: { text: 'Approved to Send', state: 'success', prependIcon: SUCCESS },
    sent: { text: outbound ? 'Sent' : 'Received', state: 'warning', prependIcon: outbound ? SEND : RECEIVED },
    rejected: { text: 'Rejected', state: 'error', prependIcon: REJECTED_ALERT },
    closed: { text: 'Closed', state: 'error', prependIcon: REJECTED_ALERT },
    partially_paid: { text: 'Partially Paid', state: 'warning', prependIcon: RECEIVED },
    overdue: { text: 'Overdue', state: 'error', prependIcon: DATE_EXPIRED },
    draft: { text: 'Draft', state: 'secondary-base', prependIcon: DRAFT },
    unsaved: { text: 'Unsaved', state: 'warning', prependIcon: '' },
    paid: { text: 'Paid', state: 'success', prependIcon: CURRENCY },
    void: { text: 'Void', state: 'error', prependIcon: CLOSE },
    approval_requested: { text: 'Approval Requested', state: 'warning', prependIcon: DRAFT },
    received: { text: 'Received', state: 'warning', prependIcon: RECEIVED },
    disputed: { text: 'Disputed', state: 'warning', prependIcon: '' },
    on_hold: { text: 'On Hold', state: 'warning', prependIcon: '' },
    payment_processing: { text: 'Payment Processing', state: 'warning', prependIcon: '' },
    payment_pending: { text: 'Payment Pending', state: 'warning', prependIcon: '' }
  } as any
}

let invoiceDueDate: any

const universalInvoiceActions = (isBatch: boolean) => {
  const invoiceActions = [
    {
      name: 'Download',
      primaryAction: {
        outbound: [
        ],
        inbound: []
      },
      validStatuses: {
        outbound: SAVED_INVOICE_STATUSES,
        inbound: SAVED_INVOICE_STATUSES
      },
      detailsPanel: DetailsPanelButtonState.Icon,
      buttonIcon: PDF_DOWNLOAD

    },
    {
      name: 'Print',
      primaryAction: {
        outbound: [
          'void',
          'rejected',
          'paid'
        ],
        inbound: []
      },
      validStatuses: {
        outbound: SAVED_INVOICE_STATUSES,
        inbound: SAVED_INVOICE_STATUSES
      },
      detailsPanel: DetailsPanelButtonState.Icon,
      buttonIcon: PRINT
    },
    {
      name: 'View',
      primaryAction: {
        outbound: [],
        inbound: []
      },
      validStatuses: {
        outbound: SAVED_INVOICE_STATUSES,
        inbound: SAVED_INVOICE_STATUSES
      },
      detailsPanel: DetailsPanelButtonState.Hide
    },
    {
      name: 'Share',
      primaryAction: {
        outbound: [],
        inbound: []
      },
      validStatuses: {
        outbound: SAVED_INVOICE_STATUSES,
        inbound: SAVED_INVOICE_STATUSES
      },
      detailsPanel: DetailsPanelButtonState.Icon,
      buttonIcon: SHARE
    }
  ] as DirectionalResourceAction[]
  // display only for non-mobile devices and individual(non-batch) invoices
  if (!isMobile() && !isBatch) {
    invoiceActions.push({
      name: 'Change Template',
      primaryAction: {
        outbound: [],
        inbound: []
      },
      validStatuses: {
        outbound: SAVED_INVOICE_STATUSES,
        inbound: SAVED_INVOICE_STATUSES
      },
      detailsPanel: DetailsPanelButtonState.Icon,
      buttonIcon: FILE_PDF
    })
  }
  return invoiceActions
}

export const INVOICE_WORK_ORDER_ACTIONS = {
  receivePayment: {
    value: 'invoice-receive-payment',
    requiresConfirmation: false,
    actionFn: (invoiceIds: (string | number)[]) => {
      const invoiceId = invoiceIds[0]
      // functionality to be added later
      console.log('Receive payment action for invoice', invoiceId)
    }
  },
  edit: {
    value: 'invoice-edit',
    requiresConfirmation: false,
    actionFn: (invoiceIds: (string | number)[]) => {
      const invoiceId = invoiceIds[0]
      // functionality to be added later
      console.log('Edit action for invoice', invoiceId)
    }
  },
  send: {
    value: 'invoice-send',
    requiresConfirmation: false,
    actionFn: (invoiceIds: (string | number)[]) => {
      const invoiceId = invoiceIds[0]
      // functionality to be added later
      console.log('Send action for invoice', invoiceId)
    }
  },
  pay: {
    value: 'invoice-pay',
    requiresConfirmation: false,
    actionFn: (invoiceIds: (string | number)[]) => {
      const invoiceId = invoiceIds[0]
      // functionality to be added later
      console.log('Pay action for invoice', invoiceId)
    }
  },
  review: {
    value: 'invoice-review',
    requiresConfirmation: false,
    actionFn: (invoiceIds: (string | number)[]) => {
      const invoiceId = invoiceIds[0]
      // functionality to be added later
      console.log('Review action for invoice', invoiceId)
    }
  }
}

export const useInvoices = () => {
  const { createBackgroundTask } = useBackgroundTasks()
  const { exportModalRef } = useExports()
  const { createSubdomainURL } = useSession()
  const { setWorkflow, optionalDynamicComponentProps } = useApp()
  const { addToast } = useToasts()
  const { currentUsersCompany } = useUsersCompanies()
  const {
    configureDetailsPanel,
    sendResources, receivePaymentForResources, makePaymentForResources, configureForReviewDetailsPanel
  } = useTransactionResources()

  const { generateMenuActions } = useListRow()

  const invoiceMenuActionsMap = {
    outbound: {},
    inbound: {}
  } as Record<string, Record<string, any>>

  const invoiceActionListenersAndFunctions: any = {
    pay: (invoices: any[], additionalParams: any) => {
      if (additionalParams.bulkSelectionData.isBulkExclusive) {
        if (additionalParams.selectAllParams.perPage > 1000) {
          additionalParams.selectAllParams.perPage = 1000
        }
        makePaymentForResources(additionalParams)
      } else {
        invoices = !invoices[0].props ? invoices : invoices.map((invoice: any) => invoice.props.invoice)
        optionalDynamicComponentProps.value.props = {
          invoices,
          receive: false
        }
        setWorkflow('invoice/receive-payments')
      }
    },
    review: (
      invoices: any[],
      {
        localDetailsPanelComponentProps, toggleFullScreenView, setSelectedResources, selectAllParams
        , fetchFn, rowProps
      }:any, isBulkSelected: boolean
    ) => {
      if (isBulkSelected) {
        if (localDetailsPanelComponentProps) {
          localDetailsPanelComponentProps.resourceType = INVOICE_RESOURCE_TYPE
        }
        if (selectAllParams.perPage > 1000) {
          selectAllParams.perPage = 1000
        }
        configureForReviewDetailsPanel(fetchFn, selectAllParams, toggleFullScreenView, setSelectedResources)
      } else {
        // configureDetailsPanel(invoices, toggleFullScreenView, setSelectedResources)
        const { openGlobalDetailsPanel } = useDetailsPanel()
        let actions
        if (rowProps.outbound) {
          const { outboundInvoiceModalRequiredActions, basicInvoiceOutboundActions } = useOutboundInvoices()
          actions = [...outboundInvoiceModalRequiredActions, ...basicInvoiceOutboundActions]
        } else {
          const { basicInvoiceInboundActions, getApproveRejectActionsForPayableTab, reviewInvoiceInboundAction } = useInboundInvoices()
          actions = [...getApproveRejectActionsForPayableTab(false), ...reviewInvoiceInboundAction, ...basicInvoiceInboundActions]
        }
        openGlobalDetailsPanel('uc/resource-details', {
          actions,
          selectedResources: invoices,
          resourceType: INVOICE_RESOURCE_TYPE,
          canDoubleClickToEdit: true,
          outbound: rowProps.outbound
        }, '', { }, { })
      }
    },
    send: (invoices: any[], additionalParams: any) => {
      invoices = !invoices[0].props ? invoices : invoices.map((invoice: any) => invoice.props.invoice)
      let params = null
      if (additionalParams.bulkSelectionData?.isBulkExclusive) {
        params = additionalParams.selectAllParams
      }
      sendResources(invoices, INVOICE_RESOURCE_TYPE, params ? additionalParams.fetchFn : null, params)
      additionalParams.hidePanel()
    },
    edit: (invoices: any, { emit, rowProps }:any, outbound: boolean) => {
      if (emit) {
        emit('edit')
      } else {
        const { openGlobalDetailsPanel } = useDetailsPanel()
        let actions
        if (outbound) {
          const { outboundInvoiceModalRequiredActions, basicInvoiceOutboundActions } = useOutboundInvoices()
          actions = [...outboundInvoiceModalRequiredActions, ...basicInvoiceOutboundActions]
        } else {
          const { basicInvoiceInboundActions, getApproveRejectActionsForPayableTab, reviewInvoiceInboundAction } = useInboundInvoices()
          actions = [...getApproveRejectActionsForPayableTab(false), ...reviewInvoiceInboundAction, ...basicInvoiceInboundActions]
        }
        openGlobalDetailsPanel('uc/resource-details', {
          actions,
          selectedResources: invoices,
          resourceType: INVOICE_RESOURCE_TYPE,
          canDoubleClickToEdit: false,
          outbound
        }, '', { }, { })
      }
    },
    print: async (
      invoices: any[],
      additionalParams: any,
      outbound = true
    ) => {
      if (invoices.length === 1) {
        additionalParams.pdfViewerRef.setLoading(true)
        const url = await getInvoicePreviewPdfById(invoices[0].id, outbound ? 'invoices' : 'inbound_invoices', 'both')
        additionalParams.pdfViewerRef.setLoading(false)
        if (!url) {
          addToast({ timeout: 3000, color: 'error', message: 'Failed to print PDF', prependIcon: ERROR })
          additionalParams.pdfViewerRef.setLoading(false)
          return
        }
        additionalParams.pdfViewerRef.show(url)
      } else if (invoices.length > 1) {
        const defaultQ = additionalParams?.exportQuery()
        const handleQuery = () => {
          const idPKey = additionalParams.bulkSelectionData.isBulkExclusive ? 'id_not_in' : 'id_in'
          const q = {
            ...defaultQ,
            [idPKey]: additionalParams.trackedResourceIds
          }
          return q
        }
        const formData = new FormData()
        formData.append('background_task[job_type]', 'invoices_export')
        formData.append('background_task[source]', BackgroundTaskSources.QUERY)
        formData.append('background_task[query]', JSON.stringify(handleQuery()))
        formData.append('background_task[format]', BackgroundTaskFormats.PDF)
        formData.append('background_task[report_type]', outbound ? BackgroundTaskReportTypes.OUTBOUND : BackgroundTaskReportTypes.INBOUND)
        formData.append('background_task[zip_file_type]', BackgroundTaskZipFileTypes.MULTIPLE_PDF)
        await createBackgroundTask(formData)
        addToast({ timeout: 3000, color: 'primary', message: 'Your PDFs are being processed', prependIcon: PDF_DOWNLOAD })
      }
    },
    'receive-payment': (invoices: any[], additionalParams: any) => {
      if (additionalParams.bulkSelectionData.isBulkExclusive) {
        if (additionalParams.selectAllParams.perPage > 1000) {
          additionalParams.selectAllParams.perPage = 1000
        }
        receivePaymentForResources(additionalParams)
      } else {
        invoices = invoices.map((invoice:any) => {
          if (invoice.props) {
            return invoice.props.invoice
          } else return invoice
        })
        optionalDynamicComponentProps.value.props = {
          invoices
        }
        setWorkflow('invoice/receive-payments')
      }
    },
    export: (
      invoices: any[],
      additionalParams: any,
      outbound = true
    ) => {
      const q = additionalParams?.exportQuery()
      exportModalRef.value.show({
        type: INVOICE_RESOURCE_TYPE,
        source: 'query',
        ids: additionalParams.trackedResourceIds,
        exportTab: outbound ? 'outbound' : 'inbound',
        query: q,
        bulkSelectionData: additionalParams.bulkSelectionData
      })
    },
    download: async (
      invoices: any[],
      additionalParams: any,
      outbound = true
    ) => {
      // we can generate PDF for a single invoice at a time
      if (invoices.length === 1) {
        additionalParams.pdfViewerRef.setLoading(true)
        const url = await getInvoicePreviewPdfById(invoices[0].id, outbound ? 'invoices' : 'inbound_invoices', 'both')
        additionalParams.pdfViewerRef.setLoading(false)
        if (!url) {
          addToast({ timeout: 3000, color: 'error', message: 'Failed to print PDF', prependIcon: ERROR })
          additionalParams.pdfViewerRef.setLoading(false)
          return
        }
        const blob = new Blob([new Uint8Array(url)], {
          type: 'application/pdf'
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${invoices[0].invoiceNumber}_${outbound ? 'invoices' : 'inbound_invoices'}.pdf`
        link.click()

        addToast({
          timeout: 3000,
          color: 'primary',
          message: 'Your PDF is being downloaded',
          prependIcon: PDF_DOWNLOAD
        })
      }
    },
    share: (
      invoices: any[],
      additionalParams: any,
      outbound = true
    ) => {
      const url = createSubdomainURL(currentUsersCompany.value.subdomain, `/${outbound ? 'receivables' : 'payables'}/${invoices[0].id}`)
      navigator.clipboard.writeText(url).then(function () {
        addToast({
          message: 'Link copied to clipboard',
          timeout: 5000,
          prependIcon: SHARE,
          color: 'primary'
        })
      })
    }
  }

  const invoiceActionModalProps: any = {
    delete: {
      dynamicContentProps: {
        allowedStatuses: outboundActionData.find((action: any) => action.name === 'Delete').validStatuses,
        resultingStatus: '',
        numberIdKey: 'invoiceNumber'
      },
      modalCardProps: {
        headerText: 'Delete',
        headerIcon: 'delete',
        size: 'md'
      },
      modalContentComponent: null,
      confirmButtonProps: {
        text: 'DELETE',
        state: 'error'
      }
    },
    void: {
      dynamicContentProps: {
        allowedStatuses: outboundActionData.find((action: any) => action.name === 'Void').validStatuses,
        resultingStatus: '',
        numberIdKey: 'invoiceNumber'
      },
      modalCardProps: {
        headerText: 'Void',
        headerIcon: 'delete',
        size: 'md'
      },
      modalContentComponent: null,
      confirmButtonProps: {
        text: 'VOID',
        state: 'error'
      }
    },
    markSent: {
      dynamicContentProps: {
        allowedStatuses: outboundActionData.find((action: any) => action.name === 'Mark Sent').validStatuses,
        resultingStatus: 'sent',
        numberIdKey: 'invoiceNumber'
      },
      modalCardProps: {
        headerText: 'Mark Sent',
        headerIcon: 'send',
        size: 'md'
      },
      modalContentComponent: null,
      confirmButtonProps: {
        text: 'MARK SENT',
        state: 'primary'
      }
    },
    assignDepartment: {
      dynamicContentProps: {
        allowedStatuses: true,
        resultingStatus: '',
        numberIdKey: 'invoiceNumber'
      },
      modalCardProps: {
        headerText: 'Assign Department',
        headerIcon: '',
        size: 'md'
      },
      modalContentComponent: 'uc/resource/modal-content/assign-department',
      confirmButtonProps: {
        text: 'ASSIGN DEPARTMENT',
        state: 'primary'
      }

    },
    approveToSend: {
      dynamicContentProps: {
        allowedStatuses: outboundActionData.find((action: any) => action.name === 'Approve To Send').validStatuses,
        resultingStatus: 'approved_to_send',
        numberIdKey: 'invoiceNumber'
      },
      modalCardProps: {
        headerText: 'Approve to Send',
        headerIcon: '',
        size: 'md'
      },
      modalContentComponent: '',
      confirmButtonProps: {
        text: 'APPROVE',
        state: 'primary'
      }
    },
    reject: {
      dynamicContentProps: {
        allowedStatuses: true,
        numberIdKey: 'invoiceNumber'
      },
      modalCardProps: {
        headerText: 'Reject',
        headerIcon: '',
        size: 'md'
      },
      modalContentComponent: MODAL_CONTENT_NOTE_COMPONENT,
      confirmButtonProps: {
        text: 'REJECT',
        state: 'error',
        disabled: true
      }
    },
    approve: {
      dynamicContentProps: {
        allowedStatuses: true,
        numberIdKey: 'invoiceNumber'
      },
      modalCardProps: {
        headerText: 'Approve',
        headerIcon: '',
        size: 'md'
      },
      modalContentComponent: MODAL_CONTENT_NOTE_COMPONENT,
      modalContentComponentProps: {
        isApprove: true,
        dueDate: () => invoiceDueDate
      },
      confirmButtonProps: {
        text: 'APPROVE',
        state: 'success'
      }
    },
    onlinePayment: {
      dynamicContentProps: {
        allowedStatuses: true,
        numberIdKey: 'invoiceNumber'
      },
      modalCardProps: {
        headerText: 'Make Online Payment',
        headerIcon: '',
        size: 'md'
      },
      modalContentComponent: null,
      confirmButtonProps: {
        text: 'PAY',
        state: 'primary'
      }
    },
    forwardInvoice: {
      dynamicContentProps: {
        allowedStatuses: inboundActionData.find((action: any) => action.name === 'Forward to Client').validStatuses,
        resultingStatus: '',
        numberIdKey: 'invoiceNumber'
      },
      modalCardProps: {
        headerText: 'Forward to Client',
        headerIcon: 'send',
        size: 'md'
      },
      modalContentComponentProps: {
        type: INVOICE_RESOURCE_TYPE
      },
      modalContentComponent: MODAL_CONTENT_FORWARD_COMPONENT,
      confirmButtonProps: {
        text: 'CREATE',
        state: 'primary',
        disabled: true,
        hidden: true
      }
    },
    vendorApprove: {
      dynamicContentProps: {
        allowedStatuses: outboundActionData.find((action: any) => action.name === 'Approve').validStatuses,
        resultingStatus: '',
        numberIdKey: 'invoiceNumber'
      },
      modalCardProps: {
        headerText: 'Approve',
        headerIcon: '',
        size: 'md'
      },
      modalContentComponent: null,
      confirmButtonProps: {
        text: 'APPROVE',
        state: 'success'
      }
    }
  }
  // CARLY TO DO: CREATE PROPOSAL BASED FUNCTION
  type FetchFnReturn = { data:boolean } | baseGetResponse
  const getInvoiceResourcesFetchDataByWorkFlow = async (resource:NewInvoice, outbound:boolean, isWeatherEvent:boolean) => {
    const { getWorkOrders } = useWorkOrders()
    const { getInboundWorkOrders } = useInboundWorkOrders()
    const { getWeatherEvents } = useWeatherEvents()
    const { getClientById } = useClients()
    const { companyType } = useApp()
    let fetchFn = getWorkOrders

    const getInboundOrOutboundWorkOrder = async (params:any) => {
      // transactional flow could be
      // 1. client - broker - broker -vendor
      // 2. broker - broker - vendor

      // call outbound work_orders first, if no results, call inbound
      try {
        const workOrder:FetchFnReturn = await getWorkOrders(params)
        if (Array.isArray(workOrder.data) && workOrder.data.length) {
          return workOrder
        } else {
          const inboundWorkOrder = await getInboundWorkOrders(params)
          if (Array.isArray(inboundWorkOrder.data) && inboundWorkOrder.data.length) {
            return inboundWorkOrder
          } else {
            return { data: false }
          }
        }
      } catch (err) {
        console.error(err)
        return { data: false }
      }
    }

    if (isWeatherEvent) {
      return getWeatherEvents
    }

    if (companyType.value && [CLIENT, VENDOR].includes(companyType.value)) {
    // companyType === CLIENT -> inbound_work_orders
      // companyType === VENDOR -> inbound_work_orders
      fetchFn = getInboundWorkOrders
    } else {
      try {
        if (outbound) {
          const clientId = getResourceClientOrVendorId(resource, outbound, 'client')
          const client = await getClientById({ id: clientId })
          if (client.data.roles.length === 1) {
          // 1. if we are outbound and our client is a pure client, call get-work-orders (we are sending inv)
            fetchFn = getWorkOrders
          } else {
          // 2. if we are outbound and client is both a client and a vendor (also a broker), we need to call both /work_orders and inbound_workorders (broker acting as a vendor)
            fetchFn = getInboundOrOutboundWorkOrder
          }
        } else {
        // 3. if we are inbound, the client is a broker, getWorkOrders
          fetchFn = getInboundOrOutboundWorkOrder
        }
      } catch (err) {
        console.error('Client is not found. Please ensure client is setup in your network', err)
      }
    }

    return fetchFn
  }

  // CARLY TO DO: CREATE PROPOSAL BASED FUNCTION
  const getInvoiceLocationsFetchDataByWorkFlow = (resource:any, outbound:boolean, locationId: any = -1) => {
    type LocationParams = {
      clientId?: string,
      vendorId?: string,
      q?: any,
      withExtendedStatus?: boolean,
      include:string,
      tab?:string,
      roles?:string,
      status?:string
    }
    let fetchFn:any
    const params:LocationParams = {
      withExtendedStatus: true,
      include: LOCATION_INCLUDE,
      q: {}
    }

    const metaData = {
      fetchFn,
      params
    }

    const { getLocations, getInvoiceableLocsByClient, getBillableLocations } = useLocations()
    const { companyType } = useApp()
    const clientId = getResourceClientOrVendorId(resource, outbound, 'client')
    const vendorId = getResourceClientOrVendorId(resource, outbound, 'vendor')

    if (!outbound) {
      metaData.fetchFn = getBillableLocations
      // only show me billable locations that I manage
      metaData.params.vendorId = vendorId
    } else if (companyType.value === VENDOR || (companyType.value === BROKER && resource.company?.roles?.includes(VENDOR))) {
      // if our client has both client and vendor in their roles arr, call locations/clients/clientid/invoiceable_locations bc we cant guarentee they are the owner of the site (could be broker client), otherwise, use /locations with company_id ransack because client owns location
      metaData.fetchFn = getInvoiceableLocsByClient
      metaData.params.clientId = clientId
    } else if (companyType.value === BROKER && resource.company?.roles?.length === 1) {
      metaData.fetchFn = getLocations
      metaData.params.q.company_id_eq = clientId
    }

    if (locationId !== -1 && locationId !== 'no-location') {
      metaData.params.q.id_eq = locationId
    }
    return metaData
  }

  const validateInvoiceUserPermission = (invoice:any): [boolean, Ref<boolean>] => {
    const { companyType } = useApp()
    const { settings } = useCompanySettings()
    const { hasAccess } = useSession()
    const validInvoiceableResources:boolean = !!invoice?.invoiceResources?.length &&
    invoice?.invoiceResources.every((r: {resourceType:string, resourceId:number | null}) => r.resourceType === 'WorkOrder' && r.resourceId) &&
    !!invoice.id
    const canForward = computed(() => settings.value?.data?.invoiceSetting?.addMarkupOrMarginForVendorsInvoice && companyType.value === BROKER && validInvoiceableResources)
    return [hasAccess('ability_to_void_invoices'), canForward]
  }

  const generateInvoiceMenuActions = (invoice:any, isDetailsPanel = false, isBatch = false) => {
    const { companyType } = useApp()
    const [canVoid, canForward] = validateInvoiceUserPermission?.(invoice)
    let filteredInboundData = []
    let filteredOutboundData = []
    filteredInboundData = inboundActionData.filter((action: { name: string }) => {
      switch (action.name) {
        case 'Forward to Client':
          return canForward.value
        case 'Pay':
          return companyType.value !== CLIENT
        default:
          return true
      }
    })

    filteredOutboundData = outboundActionData.filter((action: { name: string }) => {
      switch (action.name) {
        case 'Receive Payment':
          if (companyType.value === VENDOR || (companyType.value === BROKER && invoice.company?.roles?.includes(VENDOR))) {
            return invoice.trips?.filter((trip: Trip) => !trip.verified).length === 0
          } else {
            return companyType.value === BROKER
          }
        default:
          return true
      }
    })

    // code for filter menu actions

    filteredInboundData = filteredInboundData.filter((action: { name: string}) => invoice.allowEdit ? action : !['Edit', 'Save'].includes(action.name))
    invoiceMenuActionsMap.inbound = generateMenuActions(INVOICE_STATUSES, invoiceMenuActionsMap.inbound, universalInvoiceActions(isBatch), filteredInboundData, 'inbound', isDetailsPanel, null)

    const filteredOutboundActions = filteredOutboundData.filter((action: { name: string }) => !(canVoid && invoice.addedByCompanyId === currentUsersCompany.value.id) ? action.name !== 'Void' : (invoice.canAcceptOrReject ? action : !['Reject', 'Approve'].includes(action.name)))

    invoiceMenuActionsMap.outbound = generateMenuActions(INVOICE_STATUSES, invoiceMenuActionsMap.outbound, universalInvoiceActions(isBatch), filteredOutboundActions, 'outbound', isDetailsPanel, invoice?.company?.roles ? findCompanyType(invoice.company.roles) : null)
  }

  const genInvoiceChipProps = ({ invoice, outbound = true }: { invoice: any, outbound: boolean | undefined }) => {
    let appendedText = ''
    const { dueDate, status, statusTxt } = invoice
    const statusMap = statusTransformationMap(outbound)
    let { text, state, prependIcon } = statusMap[snakeCase(status)]
    if (!text) text = statusTxt
    if (dueDate) {
      // set these dates to start of day so we dont get partial days and rounding errors
      const dueDateMoment = moment(dueDate).startOf('day')
      const today = moment().startOf('day')
      const diff = dueDateMoment.diff(today, 'days')
      if (dueDate && status !== 'paid') {
        if (diff < 0) {
          return {
            ...statusMap.overdue,
            text,
            appendedText: ` Overdue By ${diff * -1} days`

          }
        } else if (diff > 0) {
          appendedText += ` Due In ${diff} days`
          if (text === 'Draft' && diff <= 7) state = 'warning'
        } else {
          appendedText += ' Due Today'
          state = 'warning'
        }
      }
    }
    return {
      appendedText,
      state,
      prependIcon,
      text
    }
  }

  const getInvoicePreviewPdfById = async (invoiceId: string | number, resourceType: string, objectScope: string) => {
    try {
      const res = await repositories.invoices.getPreviewPdfById(invoiceId, resourceType, objectScope)
      return res
    } catch (err: any) {
      console.error(err)
      return false
    }
  }

  const getInvoiceStatusInstructions = async (params: any) => {
    try {
      const res = await repositories.invoices.getInvoiceStatusInstructions(params)
      return {
        data: res,
        totalPages: 1,
        totalCount: res.length
      } as any
    } catch (err) {
      console.log(err)
    }
  }
  /**
  This function is used to destructure the batchInvoice and its subInvoices as required by Vue code vs the response returned by API
  */
  const flattenBatchInvoice = (res:any) => {
    const invoiceItems:any = []
    const invoiceResources:any = []
    let locations:any = []
    let tripIds:any = []
    const invoicePayments:any = []
    res.batchInvoice.subInvoices?.forEach((subInvoice:any) => {
      if (subInvoice.locations?.length > 0) locations.push(...subInvoice.locations)
      if (subInvoice.invoiceItems?.length > 0) invoiceItems.push(...subInvoice.invoiceItems)
      if (subInvoice.invoiceResources?.length > 0) invoiceResources.push(...subInvoice?.invoiceResources)
      if (subInvoice.tripIds?.length > 0) tripIds.push(...subInvoice?.tripIds)
      if (subInvoice.invoicePayments?.length > 0) invoicePayments.push(...subInvoice?.invoicePayments)
    })
    locations = uniqBy(locations, 'id')
    tripIds = uniq(tripIds)
    const locationIds:any = locations.map((loc:any) => loc.id)
    const flattenedInvoice = {
      invoiceItems,
      invoiceResources,
      locations,
      locationIds,
      tripIds,
      invoicePayments
    }

    return { ...res.batchInvoice, ...flattenedInvoice }
  }

  const allInvoiceActions = (outbound = false) => {
    const { companyType } = useApp()
    if (!outbound) {
      const {
        sharedActionsForPayableTab,
        basicInvoiceInboundActions
      } = useInboundInvoices()
      const invoiceActions = [...sharedActionsForPayableTab(companyType.value === BROKER), ...basicInvoiceInboundActions]
      return invoiceActions
    } else {
      const { outboundInvoiceModalRequiredActions, basicInvoiceOutboundActions } = useOutboundInvoices()
      return [
        ...basicInvoiceOutboundActions,
        ...outboundInvoiceModalRequiredActions
      ]
    }
  }

  const getProfitLossDetail = async (param: any) => {
    try {
      let res = await repositories.invoices.getProfitLossDetail(param)
      if (res?.profitLossDetail) {
        res = res.profitLossDetail.map((item: any, index:number) => {
          return {
            id: index,
            ...item
          }
        })
      }
      return {
        data: res,
        totalPages: 1,
        totalCount: 1
      } as any
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getSchedulerProfitLossDetail = async (param: any) => {
    try {
      let res = await repositories.invoices.getSchedulerProfitLossDetail(param)
      res = res.profitLossDetail.map((item: any, index:number) => {
        return {
          id: index,
          ...item
        }
      })
      return {
        data: res,
        totalPages: 1,
        totalCount: 1
      } as any
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  return {
    universalInvoiceActions,
    generateInvoiceMenuActions,
    invoiceMenuActionsMap,
    invoiceActionListenersAndFunctions,
    invoiceActionModalProps,
    SAVED_INVOICE_STATUSES,
    genInvoiceChipProps,
    getInvoicePreviewPdfById,
    validateInvoiceUserPermission,
    getInvoiceResourcesFetchDataByWorkFlow,
    getInvoiceLocationsFetchDataByWorkFlow,
    workOrderReportModalConfig,
    flattenBatchInvoice,
    getInvoiceStatusInstructions,
    allInvoiceActions,
    getProfitLossDetail,
    getSchedulerProfitLossDetail
  }
}

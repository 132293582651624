import { api } from '@/api'
import qs from 'qs'

interface getVendorInvoiceRejectionFormParams {
  perPage?: number,
  page?: number,
  q?: any,
  s?: any,
  include?: any
  clientId?: any
}
export default {
  getVendorInvoiceRejectionSetting: ({ perPage, page, include, q, s, clientId }:getVendorInvoiceRejectionFormParams) => {
    return api.$get('/settings/vendor_invoice_rejection_forms', {
      params: {
        include,
        q,
        page,
        perPage,
        s,
        clientId
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  updateVendorInvoiceRejectionSetting: (params: any) => {
    return api.$patch('/settings/vendor_invoice_rejection_forms', { vendor_invoice_rejection_form: params },
      {
        paramsSerializer: (params: any) => {
          return qs.stringify(params)
        }
      })
  },
  createVendorInvoiceRejection: (params: any, invoiceId: number) => {
    return api.$post(`/invoices/${invoiceId}/invoice_rejections`, { invoice_rejection: params },
      {
        paramsSerializer: (params: any) => {
          return qs.stringify(params)
        }
      })
  }
}

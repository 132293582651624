import Vue from 'vue'
import VueGtag from 'vue-gtag'
import { GOOGLE_ANALYTICS_ID } from '@/constants/env'
import { router, vuetify } from './plugins'
import App from './App.vue'
import './registerServiceWorker'
import Teleport from '@logue/vue2-helpers/teleport'
import highlight from '@/directives/highlight'
import HubspotChatPlugin from './plugins/hubspot-chat-plugin'

Vue.config.ignoredElements = [
  'trix-editor'
]

Vue.config.productionTip = false

Vue.directive('highlight', highlight)

Vue.use(
  VueGtag,
  {
    config: {
      id: GOOGLE_ANALYTICS_ID,
      params: {
        send_page_view: true
      }
    }
  },
  router
)

Vue.use(HubspotChatPlugin)

Vue.component('Teleport', Teleport)

export const vue = new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

import { companyRoles } from '@/constants/permissions'
import { DetailsPanelButtonState } from '@/types/enums'
import { MenuAction, ResourceAction, Column } from '@/types/interfaces'
import { DirectionalResourceAction } from '@/types/interfaces/global/resource-actions'
import { kebabCase, intersection } from 'lodash'
import { computed, Ref } from 'vue'

export const useListRow = (columnsToBeRendered?: Ref<Column[]>) => {
  const getActionMenuData = (status: string, universalActions:ResourceAction[] | DirectionalResourceAction[], actionData: ResourceAction[], key = '', isDetailsPanel = false, recipientCompanyType: null | companyRoles = null) => {
    const availableActions = Array.isArray(actionData) ? [...actionData] : [] as ResourceAction[]
    availableActions.push(...universalActions.map((action: ResourceAction | DirectionalResourceAction) => {
      let obj: ResourceAction

      if (key) {
        if (typeof action.validStatuses === 'boolean' || Array.isArray(action.validStatuses) || Array.isArray(action.primaryAction)) {
          return {
            name: action.name,
            validStatuses: [],
            primaryAction: [],
            textColor: action.textColor,
            detailsPanel: action.detailsPanel,
            buttonIcon: action.buttonIcon,
            originalEventName: action.originalEventName
          } as ResourceAction
        }
        obj = {
          name: action.name,
          validStatuses: action.validStatuses[key],
          primaryAction: action.primaryAction[key],
          textColor: action.textColor,
          detailsPanel: action.detailsPanel,
          buttonIcon: action.buttonIcon,
          originalEventName: action.originalEventName
        }
      } else {
        if ((typeof action.validStatuses !== 'boolean' && !Array.isArray(action.validStatuses)) || !Array.isArray(action.primaryAction)) {
          return {
            name: action.name,
            validStatuses: [],
            primaryAction: [],
            textColor: action.textColor,
            detailsPanel: action.detailsPanel,
            buttonIcon: action.buttonIcon,
            originalEventName: action.originalEventName
          } as ResourceAction
        }
        obj = {
          name: action.name,
          validStatuses: action.validStatuses,
          primaryAction: action.primaryAction,
          textColor: action.textColor,
          detailsPanel: action.detailsPanel,
          buttonIcon: action.buttonIcon,
          originalEventName: action.originalEventName
        }
      }
      return {
        ...obj
      }
    }))
    const returnActions: MenuAction[] = availableActions.reduce((acc: MenuAction[], action: ResourceAction) => {
      if ((action.detailsPanel === DetailsPanelButtonState.Only || action.detailsPanel === DetailsPanelButtonState.Never) && !isDetailsPanel) return acc
      if (action.recipientIs && recipientCompanyType && !action.recipientIs.includes(recipientCompanyType)) return acc
      const a:MenuAction = {
        text: action.name,
        buttonIcon: action.buttonIcon,
        textColor: action.textColor,
        detailsPanel: action.detailsPanel,
        eventName: action.eventName || kebabCase(action.name),
        extraData: action.extraData,
        originalEventName: action.originalEventName
      }

      if (action.primaryAction.includes(status)) {
        acc.unshift(a)
      } else if ((!Array.isArray(action.validStatuses) && action.validStatuses) || (action.validStatuses as string[]).includes(status)) {
        acc.push(a)
      }
      return acc
    }, [] as MenuAction[])
    return returnActions
  }

  /**
   * @param statuses - Possible statuses for the resource
   * @param actionMap - action and the associated primary status, valid statuses, detailsPanel, recipientIs, text color
   * @param universalActions - actions shared by all of the resources of this type (ignores inbound and outbound for example)
   * @param actionData - is the action data specific to this resource (inbound vs outbound)
   * @param key - is a string for specific information (inbound or outbound)
   * @param isDetailsPanel - is the list being rendered in the details panel or outside of it
   * @param recipientCompanyType - who is on the "other end" of this resource (null is no one) --> client or broker
   */
  const generateMenuActions = (statuses: string[], actionMap: Record<string, MenuAction[]>, universalActions: ResourceAction[] | DirectionalResourceAction[], actionData: ResourceAction[] | {[keys: string]: ResourceAction[]}, key = '', isDetailsPanel = false, recipientCompanyType: null | companyRoles = null) => {
    statuses.forEach((status: string) => {
      if (!Array.isArray(actionData)) return
      actionMap[status] = getActionMenuData(status, universalActions, actionData, key, isDetailsPanel, recipientCompanyType)
    })

    return actionMap
  }

  const initListenerObject = ({ menuActions, emit, getResource }: {menuActions: MenuAction[], emit: any, getResource: ((eventName: string, extraData: any | undefined | null) => any)}) => {
    return menuActions?.reduce((acc: any, action: MenuAction) => {
      acc[action.eventName] = async () => {
        const resources = [await Promise.resolve(getResource(action.eventName, action.extraData))]
        emit(action.originalEventName || action.eventName, resources)
      }
      return acc
    }, {}) || {}
  }

  const columnTemplatesToBeRendered = computed(() => {
    if (!columnsToBeRendered?.value) return []
    return columnsToBeRendered.value.map((col: Column) => col.templateName)
  })

  /*
  Checks if the list of columns to be rendered and a list of columns templateName to check against
  Returns true if any overlap, else false
  This should be used before functions to check if we need to run certain computed properties, API calls, etc to prevent unnecessary/breaking code from running when relevant columns are not rendered
  */

  const checkIfColumnIsRendered = (columnsThatNeedFunctionality: string[]) => {
    return !!intersection(columnsThatNeedFunctionality, columnTemplatesToBeRendered.value).length
  }

  return {
    generateMenuActions,
    getActionMenuData,
    initListenerObject,
    checkIfColumnIsRendered
  }
}

import { SEND, FILE_PDF } from '@/constants/icons'
import { CLIENT } from '@/constants/permissions'
import { DetailsPanelButtonState } from '@/types/enums'
import { ResourceAction } from '@/types/interfaces'
import { isMobile } from '@/utils/user-agent'

export const outboundActionData: ResourceAction[] = [
  {
    name: 'Send',
    validStatuses: [
      'draft',
      'ready_for_review'
    ],
    primaryAction: [
      'draft'
    ],
    detailsPanel: DetailsPanelButtonState.Icon,
    buttonIcon: SEND
  },
  {
    name: 'Draft',
    validStatuses: [
      'ready_for_review'
    ],
    primaryAction: [
      'ready_for_review'
    ]
  },
  {
    name: 'Ready For Review',
    validStatuses: [
      'draft'
    ],
    primaryAction: [
    ]
  },
  {
    name: 'Resend',
    validStatuses: [
      'sent', 'evaluating', 'rejected'
    ],
    primaryAction: [
    ]
  },
  {
    name: 'Edit',
    validStatuses: [
      'draft',
      'rejected',
      'ready_for_review'
    ],
    primaryAction: [
      'draft',
      'ready_for_review'
    ]
  },
  {
    name: 'Delete',
    textColor: 'error',
    validStatuses: [
      'draft',
      'rejected_closed',
      'ready_for_review'
    ],
    primaryAction: [
    ]
  },
  {
    name: 'Close',
    textColor: 'error',
    validStatuses: [
      'rejected'
    ],
    primaryAction: [
      'rejected'
    ]
  },
  {
    name: 'Review',
    validStatuses: [
      'evaluating',
      'sent'
    ],
    primaryAction: [
      'evaluating',
      'sent'
    ],
    detailsPanel: DetailsPanelButtonState.Hide,
    recipientIs: [CLIENT]
  },
  {
    name: 'Approve',
    textColor: 'success',
    validStatuses: [
      'sent',
      'evaluating'
    ],
    primaryAction: [
    ],
    detailsPanel: DetailsPanelButtonState.Expose,
    recipientIs: [CLIENT]
  },
  {
    name: 'Evaluate',
    textColor: 'primary',
    validStatuses: [
      'sent'
    ],
    primaryAction: [
    ],
    recipientIs: [CLIENT]
  },
  {
    name: 'Reject',
    textColor: 'error',
    validStatuses: [
      'evaluating',
      'sent'
    ],
    primaryAction: [
    ],
    detailsPanel: DetailsPanelButtonState.Expose,
    recipientIs: [CLIENT]
  },
  {
    name: 'Create Work Order',
    validStatuses: [
      'accepted'
    ],
    primaryAction: [
      'accepted'
    ],
    recipientIs: [CLIENT]
  },
  {
    name: 'Generate Invoice',
    validStatuses: [
    ],
    primaryAction: [
    ]
  },
  {
    name: 'Save And Send',
    validStatuses: ['unsaved'],
    primaryAction: [
    ]
  },
  {
    name: 'Save And Mark Sent',
    validStatuses: ['unsaved'],
    primaryAction: [
    ]
  },
  {
    name: 'Save As Draft',
    validStatuses: ['unsaved'],
    primaryAction: [
    ]
  },
  {
    name: 'Save',
    primaryAction: [
      'draft',
      'rejected',
      'ready_for_review'
    ],
    validStatuses: [],
    detailsPanel: DetailsPanelButtonState.Only
  },
  // display Print Preview in menu options for proposals
  {
    name: 'Change Template',
    validStatuses: [
      'draft',
      'ready_for_review',
      'sent',
      'accepted',
      'rejected',
      'evaluating',
      'rejected_closed',
      'accepted_invoiced',
      'accepted_wo'
    ],
    primaryAction: [
    ],
    // hide for mobile devices
    detailsPanel: isMobile() ? DetailsPanelButtonState.Never : DetailsPanelButtonState.Icon,
    buttonIcon: FILE_PDF
  },
  {
    name: 'Skip',
    primaryAction: [
      'skip'
    ],
    validStatuses: true,
    textColor: 'error',
    detailsPanel: DetailsPanelButtonState.Only
  }
]

import repositories from '@/repositories'
import { compact, isArray, sortBy } from 'lodash'
import { TechnicianTrip, Trip, TradeService, NewTripTradeTask, ServiceAdditionalTeamMember, ServiceAdjustment, ServiceException, Assignee, MenuAction, ServiceVerification, AssigneeDetails, WorkOrderService, Company, Task } from '@/types/interfaces'
import { ExtendedStatus, TripAssigneeType } from '@/types/enums'
import { useTripsVerification } from '@/use/trips/verification'
import { useSession } from '@/use/session'
import { useListRow } from '../list-rows'
import { ALL_STATUSES } from '@/constants/actions-by-resource/trips'
import { timeCardActionData } from '@/constants/actions-by-resource/timecard'
import { WorkOrderCompanyRole } from '@/types/enums/work-order/company_role'
import { TradesmanTrip } from '@/types/interfaces/api-v2/tradesman-trip'
import { computed, ref } from 'vue'

/*
  Timecard is an extended version of the WorkOrderService
  to include metadata that is not available on the WorkOrderService
  such as trip and tradesmen metadata like assignee, start time, status, etc
*/

export interface Timecard {
  id?: number | string // this is the Service#id
  tripId: number | string
  tradesmenTripId: number | string
  workOrderId: number | string
  assignee: Assignee | { fullName: string, email?: string, pictureUrl?: string }
  extendedStatus: ExtendedStatus
  extendedStatusText: string
  startDate: string | Date
  endDate: string | Date
  checkInTime: string | Date
  checkOutTime: string | Date
  billableDouble?: number
  billableOvertime?: number
  billableRegular?: number
  checkInLat?: number
  checkInLng?: number
  checkOutLat?: number
  checkOutLng?: number
  effectiveCheckInTime?: Date | string
  effectiveCheckOutTime?: Date | string
  effectiveDuration?: string
  lock?: boolean
  onBehalfOf?: string
  platform?: string
  position?: number
  reviewStatus?: string
  scheduledStartTime?: string | Date
  scheduledEndTime?: string | Date
  source?: string
  services?: TradeService[]
  tasks?: NewTripTradeTask[]
  parentService?: ServiceAdditionalTeamMember
  serviceException?: ServiceException
  recentAdjustment?: ServiceAdjustment
  vendorVerification?: ServiceVerification
  contractorVerifications?: ServiceVerification[]
  tradesmenTrip?: TradesmanTrip,
  assigneeType?: string,
  trip: Trip
}

export const timecardActions = [
  {
    value: 'verify',
    advancedModal: 'timecards/modals/verify',
    requiresConfirmation: true
  },
  {
    value: 'verify-with-trip',
    advancedModal: 'timecards/modals/verify',
    requiresConfirmation: true,
    dynamicContentProps: { verifyWithTrip: true }
  },
  {
    value: 'view-adjustment-history',
    requiresConfirmation: true,
    advancedModal: 'trips/modals/adjustment-history'
  },
  {
    value: 'edit-check-in-out',
    requiresConfirmation: true,
    advancedModal: 'trips/modals/timecard'
  },
  {
    value: 'add-additional-check-in-out',
    requiresConfirmation: true,
    advancedModal: 'trips/modals/timecard',
    dynamicContentProps: { newTimecard: true }
  },
  {
    value: 'send-text-message',
    requiresConfirmation: true,
    advancedModal: 'trips/modals/send-message',
    dynamicContentProps: { isTimeCard: true }
  },
  {
    value: 'unverify',
    requiresConfirmation: true,
    advancedModal: 'timecards/modals/unverify'
  },
  {
    value: 'archive',
    requiresConfirmation: true,
    advancedModal: 'timecards/modals/archive'
  },
  {
    value: 'delete',
    requiresConfirmation: true,
    advancedModal: 'timecards/modals/delete'
  }
]

const timecards = ref<Timecard[]>([]) // TODO: Should timecard and technicianTrip.services be merged? What is the correct naming convention

/*
  Timecard is an extended version of the WorkOrderService
  to include metadata that is not available on the WorkOrderService
  such as trip and tradesmen metadata like assignee, start time, status, etc
*/
export const useTripsTimecard = (trip: Trip) => {
  const generateTimecardsForTrip = () => {
    const timecards: Timecard[] = []
    if (trip.tradesmenTrips?.length) {
      trip.tradesmenTrips?.forEach((tradesmanTrip: TechnicianTrip) => {
        if (tradesmanTrip.services?.length) {
          const services = sortBy(tradesmanTrip.services, ['position'])
          const serviceToTimeCard: Timecard[] = services.map((service: WorkOrderService) => {
            return timecard(service, tradesmanTrip)
          }) || []
          timecards.push(...serviceToTimeCard)
        } else {
          const assignee = tradesmanTrip.assignee
          const formattedAssignee = {
            id: assignee.id,
            fullName: assignee.fullName,
            email: assignee.email,
            pictureUrl: trip.assigneeType === TripAssigneeType.VENDOR ? assignee.image : teamMemberPictureUrl(assignee)
          } as Assignee
          const timecard = unCheckedInTimecard(formattedAssignee, tradesmanTrip)
          timecards.push(timecard)
        }
      })
    } else {
      let assigneeDetails: AssigneeDetails[] = []
      if (trip.assigneeType === TripAssigneeType.REGION_TEAM_MEMBER) {
        const territoryName = trip.workOrder.locations[0].ccTerritory?.name
        assigneeDetails = [{ name: territoryName }] as AssigneeDetails[]
      } else if (isArray(trip.assigneeDetails)) {
        assigneeDetails = trip.assigneeDetails
      } else if (trip.assigneeDetails) {
        assigneeDetails = [trip.assigneeDetails]
      }

      assigneeDetails.forEach((assignee: AssigneeDetails | null | undefined) => {
        if (!assignee?.name) return

        const formattedAssignee = {
          id: assignee.userId,
          fullName: assignee.name,
          email: assignee.email,
          pictureUrl: trip.assigneeType === TripAssigneeType.VENDOR ? assignee.image : teamMemberPictureUrl(assignee)
        }
        const timecard = unCheckedInTimecard(formattedAssignee as Assignee)
        timecards.push(timecard)
      })
    }
    return timecards
  }

  const timecard = (service: WorkOrderService, tradesmanTrip: TechnicianTrip) => {
    return {
      ...service,
      tripId: trip.id,
      tradesmenTripId: tradesmanTrip.id,
      workOrderId: trip.workOrderId,
      extendedStatus: tradesmanTrip.extendedStatus,
      extendedStatusText: tradesmanTrip.extendedStatusText,
      assignee: timecardAssignee(tradesmanTrip),
      startDate: tradesmanTrip.startDate || '',
      endDate: tradesmanTrip.endDate || '',
      trip,
      services: compact(trip.tripTradeServices?.map((tripTradeService) => tripTradeService.tradeService)),
      tasks: mapTaskCheckIns(service, service.recentAdjustment?.tasks || service.tasks),
      assigneeType: trip.assigneeType
    }
  }

  const mapTaskCheckIns = (service: WorkOrderService, tasks: Task[] | undefined) => {
    const updatedTasks = tasks?.map(task => {
      const matchingService = service.recentAdjustment?.taskServices?.find(service => service.taskId === task.id)
      return { ...task, taskCheckIns: matchingService?.taskCheckIns || [] }
    })
    return updatedTasks
  }

  const unCheckedInTimecard = (assignee: Assignee, tradesmanTrip?: TechnicianTrip) => {
    return {
      id: -1,
      tripId: trip.id,
      tradesmenTripId: tradesmanTrip?.id || '',
      workOrderId: trip.workOrderId,
      assignee,
      checkInTime: trip.startDate || '',
      checkOutTime: trip.expirationDate || '',
      extendedStatus: (tradesmanTrip || trip).extendedStatus || '',
      extendedStatusText: (tradesmanTrip || trip).extendedStatusText || '',
      trip,
      services: [],
      tasks: [],
      startDate: trip.startDate || '',
      endDate: trip.expirationDate || '',
      assigneeType: trip.assigneeType
    }
  }

  const getTimecardMenuActions = (timecard: Timecard) => {
    const { generateMenuActions } = useListRow()
    const { session } = useSession()
    const { verificaionWindowMissed } = useTripsVerification(trip)

    let timeCardMenuActionsMap = {} as Record<string, MenuAction[]>

    timeCardMenuActionsMap = generateMenuActions(ALL_STATUSES, timeCardMenuActionsMap, timeCardActionData, [])
    let actions = timeCardMenuActionsMap[timecard.extendedStatus]

    const verifiedByCurrentCompany = () => {
      if (trip.currentCompanyRole === WorkOrderCompanyRole.BROKER && timecard.vendorVerification?.status === 'verified') {
        return true
      } else {
        return timecard.contractorVerifications?.map((verification) => verification.status === 'verified' && verification.companyId).includes(session.currentCompany?.id)
      }
    }

    if (trip.extendedStatus === 'completed' && verifiedByCurrentCompany()) {
      // we do not allow service verification if contractor has verified the trip and broker verification is pending
      actions = actions.filter((action) => !['verify-with-trip', 'verify'].includes(action.eventName))
    } else if (trip.extendedStatus === 'completed' && verificaionWindowMissed) {
      // we do not allow service verification post adjustment hours have passed
      actions = actions.filter((action) => !['verify-with-trip', 'verify'].includes(action.eventName))
    }

    const verifiedByAssignedCompany = [...(timecard.contractorVerifications || []), timecard.vendorVerification]
      .find(
        (verification) => verification && trip.ccTripClient && verification.companyId === trip.ccTripClient.id && verification.status === 'verified'
      )

    if (!verifiedByCurrentCompany() || verifiedByAssignedCompany) {
      actions = actions.filter((action) => !['unverify'].includes(action.eventName))
    }

    // Remove "Send Text Message" action when assignee is vendor
    if (trip.currentCompanyRole === WorkOrderCompanyRole.CLIENT || timecard.assigneeType !== TripAssigneeType.TEAM_MEMBER) {
      actions = actions.filter((action) => action.eventName !== 'send-text-message')
    }

    if (!timecard.recentAdjustment) {
      actions = actions.filter((action) => action.eventName !== 'view-adjustment-history')
    }

    if (!newCheckInAllowed.value) {
      actions = actions.filter((action) => action.eventName !== 'add-additional-check-in-out')
    }

    if (!canAdjustTimecard.value) {
      actions = actions.filter((action) => action.eventName !== 'edit-check-in-out')
    }

    if (trip.currentCompanyRole === WorkOrderCompanyRole.CLIENT || !trip.tradesmenTrips?.length) {
      actions = actions.filter((action) => action.eventName !== 'archive')
    }

    if (trip.currentCompanyRole !== WorkOrderCompanyRole.BROKER || timecard.id === -1) {
      actions = actions.filter((action) => action.eventName !== 'delete')
    }

    if (trip.currentCompanyRole === WorkOrderCompanyRole.CLIENT) {
      actions = actions.filter((action) => !['verify', 'verify-with-trip'].includes(action.eventName))
    }

    return actions
  }

  const verifyService = async (serviceId: number, verifyWithTrip = false) => {
    try {
      const res = await repositories.timecard.verifyService(Number(trip.id), Number(serviceId), verifyWithTrip)
      return {
        data: res
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const unverifyService = async (serviceId: number) => {
    try {
      const res = await repositories.timecard.unverifyService(Number(trip.id), Number(serviceId))
      return {
        data: res
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getServices = async (params: any) => {
    try {
      params.id = trip.id
      const res = await repositories.timecard.getServices(params)
      return {
        data: res.services,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages,
        currentPage: res.meta.currentPage
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const newCheckInAllowed = computed(() => {
    return (
      canAdjustTimecard.value &&
      (!trip.allowOneTeamMemberToCheckin || !trip.tradesmenTrips?.length) &&
      ![ExtendedStatus.CANCELED, ExtendedStatus.TRIP_VERIFIED].includes(trip.extendedStatus) &&
      ((trip.assigneeType !== TripAssigneeType.VENDOR) || trip.tradesmenTrips?.length)
    )
  })

  const canAdjustTimecard = computed(() => {
    const { hasAccess } = useSession()
    return (
      trip.canAdjust && (
        (
          trip.workOrder.currentCompanyRole === WorkOrderCompanyRole.BROKER &&
          hasAccess('adjust_time_stamp')
        ) ||
        (
          trip.workOrder.currentCompanyRole === WorkOrderCompanyRole.CONTRACTOR
        )
      )
    )
  })

  const generateTimecards = () => {
    timecards.value = generateTimecardsForTrip()
  }

  const timecardAssignee = (tradesmanTrip: TechnicianTrip) => {
    const isClient = (trip.currentCompanyRole === WorkOrderCompanyRole.CLIENT)
    const assignee = (isClient && trip.workOrder.vendor)
      ? trip.workOrder.vendor
      : trip.ccTripContractor

    if (assignee) {
      return {
        id: assignee.id,
        fullName: assignee.name,
        email: assignee.email,
        pictureUrl: vendorPictureUrl(assignee)
      }
    } else {
      return {
        id: tradesmanTrip.assignee.id,
        fullName: tradesmanTrip.assignee.fullName,
        email: tradesmanTrip.assignee.email || undefined,
        pictureUrl: teamMemberPictureUrl(tradesmanTrip.assignee)
      }
    }
  }

  const vendorPictureUrl = (assignee: Company) => {
    return assignee.logo?.versions?.thumb
  }

  const teamMemberPictureUrl = (assignee: Assignee | AssigneeDetails) => {
    return assignee.image?.versions?.thumb
  }

  return {
    getTimecardMenuActions,
    generateTimecardsForTrip,
    verifyService,
    unverifyService,
    newCheckInAllowed,
    generateTimecards,
    timecards,
    getServices,
    canAdjustTimecard
  }
}

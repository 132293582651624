import { apis, api } from '@/api'
import qs from 'qs'
interface GetEventsParams {
  page: number
  q?: any
  include?: any
  perPage?: number
  weatherEventId?: string
  clientId?: string | number
  vendorId?: string | number
  locationIds?: number[]
  workOrderIds?: number[]
}

export default {
  get: ({ include, q, page, perPage }: GetEventsParams) => {
    return apis.$get('/storm_details', {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getInvoiceableWeatherEventsByClient: ({ clientId, locationIds, include, q, page, perPage, workOrderIds }: GetEventsParams) => {
    return api.$get(`clients/${clientId}/event_locations/invoiceables`, {
      params: {
        clientId,
        locationIds,
        workOrderId: workOrderIds,
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getBillableWeatherEventsByVendor: ({ vendorId, locationIds, include, q, page, perPage, workOrderIds }: GetEventsParams) => {
    return api.$get(`vendors/${vendorId}/event_locations/billables`, {
      params: {
        vendorId,
        locationIds,
        workOrderId: workOrderIds,
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}

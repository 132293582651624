import { reactive, ref, h } from 'vue'
import repositories from '@/repositories'
import _, { kebabCase } from 'lodash'
import { useSession } from './session'
import { Vendor, VendorsBulkActionParams } from '@/types/interfaces'
import { DetailsPanelButtonState, DetailsPanelVisibility } from '@/types/enums'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'

import { SUCCESS, ERROR, SHARE, CLOSE, BLOCK_HELPER } from '@/constants/icons'
import { useUsersCompanies } from '@/use/users-companies'
import { useToasts } from './toasts'
import { useListRow } from './list-rows'
import { useFeatureFlags } from './feature-flags'
import { useWorkOrders } from './work-orders'
import { useDetailsPanel } from './details-panel'
import { INVOICE_RESOURCE_TYPE, PO_RESOURCE_TYPE, PROPOSAL_RESOURCE_TYPE, WO_RESOURCE_TYPE } from '@/constants/resource-types'
import { useInboundInvoices } from './invoices/inbound'
import { useInvoices } from './invoices'
import { useApp } from './app'
import { BROKER } from '@/constants/permissions'
import { useInboundProposals } from './proposals/inbound'
import { useOutboundPurchaseOrders } from './purchase-orders/outbound'
import { AttachmentInfo } from '@/use/attachments'
const { addToast } = useToasts()
const { createSubdomainURL, session } = useSession()
const { currentUsersCompany } = useUsersCompanies()
const { generateMenuActions } = useListRow()
const ACTIVE = 'active'
const PENDING = 'pending'
const BLOCKED = 'blocked'
const VENDOR_REJECTED_INVITE = 'vendor_rejected_invite'
const activeVendorsProfileEditModalRef = ref<any>(null)

export const DEFAULT_NEW_VENDOR = {
  name: '',
  legalAddress: {},
  sendPlatformInvite: true,
  otherDetails: {
    businessEmail: '',
    stateOfRegistration: {},
    ein: '',
    logo: null,
    taxClassification: '',
    website: '',
    idNumber: {
      id: null,
      externalId: null
    },
    businessPhone: '',
    businessFax: '',
    mobilePhone: '',
    AutoApproveVendor: true
  },
  inviteDetails: {
    email: '',
    phone: '',
    description: session?.currentCompany?.emailSetting?.emailTemplate?.body || '',
    attachment: null,
    subject: session?.currentCompany?.emailSetting?.emailTemplate?.subject || ''
  }
}

export const DEFAULT_REQUIREMENT = {
  name: '',
  expirationDate: '',
  comment: '',
  isCompliant: false,
  isNonCompliant: true,
  status: false,
  requireExpirationDate: false,
  companyId: '',
  id: ''
}

export const DEFAULT_NOTE_ALERT = {
  id: '',
  comment: '',
  isNote: true,
  isAlert: false,
  startDate: '',
  expirationDate: '',
  noteId: ''
}

const universalActions = [
  {
    value: 'relationship',
    requiresConfirmation: false,
    actionFn: (vendors: any[]) => {
      window.open(`/company/vendors/${vendors[0].id}/profile?tab=active`)
    }
  },
  {
    value: 'view-public-profile',
    updateSelectedResource: false,
    actionFn: (vendor: any) => {
      const { openGlobalDetailsPanel } = useDetailsPanel()
      openGlobalDetailsPanel('vendors/profile', {
        canDoubleClickToEdit: true,
        resourceType: 'vendor',
        visibility: DetailsPanelVisibility.Fullscreen,
        isPublicTab: true,
        actions: getVendorMenuActions()
      }, 'setSelectedResources', vendor[0].otherVendorDetails || vendor) // Pass otherVendorDetails from compliance vendor list
    },
    noSuccessEmit: true
  },
  {
    value: 'edit',
    updateSelectedResource: false,
    actionFn: (vendor: any, { emit }:any) => {
      if (emit) {
        emit('edit')
      } else {
        const { openGlobalDetailsPanel } = useDetailsPanel()
        openGlobalDetailsPanel('vendors/profile', {
          canDoubleClickToEdit: false,
          resourceType: 'vendor',
          actions: getVendorMenuActions()
        }, 'setSelectedResources', vendor[0].otherVendorDetails || vendor) // Pass otherVendorDetails from compliance vendor list
      }
    },
    noSuccessEmit: true
  },
  {
    value: 'save',
    noSuccessEmit: true
  }
]

const genVendorsChipProps = ({
  vendors
}: {
    vendors: any
}) => {
  let state = ''
  let prependIcon = ''
  const { status } = vendors
  let text = _.startCase(status)

  if (status === ACTIVE) {
    text = ACTIVE
    state = 'success'
    prependIcon = SUCCESS
  } else if (status === BLOCKED) {
    text = BLOCKED
    state = 'error'
    prependIcon = ERROR
  }

  return {
    state,
    prependIcon,
    text
  }
}
export const vendorStatuses = [ACTIVE, PENDING, BLOCKED]
const universalActionData: any[] = [
  {
    name: 'Reject',
    textColor: '',
    validStatuses: [PENDING, VENDOR_REJECTED_INVITE],
    primaryAction: []
  },
  {
    name: 'Block',
    textColor: 'error',
    validStatuses: [ACTIVE, PENDING, VENDOR_REJECTED_INVITE],
    primaryAction: []
  }
]
const vendorActionData: any[] = [
  {
    name: '+ Work order',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: [ACTIVE]
  },
  {
    name: 'Accept',
    textColor: 'success',
    validStatuses: [PENDING, VENDOR_REJECTED_INVITE],
    primaryAction: [PENDING]
  },
  {
    name: 'Edit',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE, PENDING, VENDOR_REJECTED_INVITE],
    primaryAction: [],
    detailsPanel: DetailsPanelButtonState.Only
  },
  {
    name: 'Unblock',
    textColor: 'success',
    validStatuses: [BLOCKED],
    primaryAction: [BLOCKED]
  },
  {
    name: '+ Service Request',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: 'Call',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: 'Message',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: 'View Public Profile',
    textColor: '',
    validStatuses: [ACTIVE, PENDING, VENDOR_REJECTED_INVITE, BLOCKED],
    primaryAction: []
  },
  {
    name: 'Resend invitation',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE, PENDING, VENDOR_REJECTED_INVITE],
    primaryAction: []
  },
  {
    name: '+ Invoice',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: '+ Proposal',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: '+ Purchase Order',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: 'Supported trades',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: '+ Serviceable areas',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: '+ Contacts',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: '+ Notes',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: '+ Alerts',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: 'Compliance',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: 'Settings',
    icon: '',
    textColor: '',
    validStatuses: [ACTIVE],
    primaryAction: []
  },
  {
    name: 'Save',
    primaryAction: [],
    validStatuses: [ACTIVE, PENDING, VENDOR_REJECTED_INVITE, BLOCKED],
    detailsPanel: DetailsPanelButtonState.Only,
    eventName: 'save'
  },
  {
    name: 'Share',
    primaryAction: [],
    validStatuses: [ACTIVE, PENDING, BLOCKED],
    detailsPanel: DetailsPanelButtonState.Icon,
    buttonIcon: SHARE
  }
]
const vendorMap = {} as any

const vendorMenuActions: any = generateMenuActions(vendorStatuses, vendorMap, universalActionData, vendorActionData, '', true)

const actionModalProps: any = {
  block: {
    dynamicContentProps: {
      allowedStatuses: universalActionData.find((action: any) => action.name === 'Block').validStatuses,
      resultingStatus: '',
      numberIdKey: 'id'
    },
    modalCardProps: {
      headerText: 'Block Vendor',
      headerIcon: BLOCK_HELPER.split('mdi-')[1],
      size: 'md'
    },
    confirmButtonProps: {
      text: 'BLOCK',
      state: 'error'
    }
  },
  accept: {
    dynamicContentProps: {
      allowedStatuses: vendorActionData.find((action: any) => action.name === 'Accept').validStatuses,
      resultingStatus: '',
      numberIdKey: 'id'
    },
    modalCardProps: {
      headerText: 'Accept Vendor',
      headerIcon: SUCCESS.split('mdi-')[1],
      size: 'md',
      color: 'success'
    },
    confirmButtonProps: {
      text: 'ACCEPT',
      state: 'success'
    }
  },
  reject: {
    dynamicContentProps: {
      allowedStatuses: universalActionData.find((action: any) => action.name === 'Reject').validStatuses,
      resultingStatus: '',
      numberIdKey: 'id'
    },
    modalCardProps: {
      headerText: 'Reject Vendor',
      headerIcon: CLOSE.split('mdi-')[1],
      size: 'md',
      color: 'error'
    },
    confirmButtonProps: {
      text: 'REJECT',
      state: 'error'
    }
  },
  unblock: {
    dynamicContentProps: {
      allowedStatuses: vendorActionData.find((action: any) => action.name === 'Unblock').validStatuses,
      resultingStatus: '',
      numberIdKey: 'id'
    },
    modalCardProps: {
      headerText: 'Unblock Vendor',
      size: 'md'
    },
    confirmButtonProps: {
      text: 'UNBLOCK',
      state: 'success'
    }
  }
}

const genActionableVendors = (actionText: string, vendors: any[]) => {
  let allowedStatuses = vendorActionData.find((action: any) => action.name === actionText).validStatuses
  if ((typeof allowedStatuses === 'boolean') && allowedStatuses) {
    allowedStatuses = vendorStatuses
  }
  return vendors.filter((vendor: any) => {
    return allowedStatuses.includes(vendor.user.status)
  })
}

const getVendorMenuActions = () => {
  return [
    ...universalActions,
    {
      value: 'work-order',
      requiresConfirmation: false,
      actionFn: (_: any, localDetailsPanelComponentProps:any) => {
        const { hideVueWorkOrder } = useFeatureFlags()
        if (hideVueWorkOrder) {
          localDetailsPanelComponentProps.router.push('/company/wizard/work_orders/new?header=false&step=details')
        } else {
          useDetailsPanel().openGlobalDetailsPanel('work-orders/details', {
            outbound: false,
            canDoubleClickToEdit: false,
            resourceType: 'workOrder',
            actions: useWorkOrders().getWorkOrderActions()
          }, 'enterCreateResourceFlow', { WO_RESOURCE_TYPE })
        }
      }
    },
    {
      value: 'service-request',
      requiresConfirmation: false,
      actionFn: (_: any, localDetailsPanelComponentProps:any) => {
        localDetailsPanelComponentProps.router.push('/company/service_requests/site_selections')
      }
    },
    {
      value: 'invoice',
      requiresConfirmation: false,
      actionFn: (vendors: any[]) => {
        const allInboundActions = [
          ...useInboundInvoices().sharedActionsForPayableTab(useApp().companyType.value === BROKER),
          {
            value: 'review',
            bulkConfig: {
              type: 'primary'
            },
            updateSelectedResource: true,
            actionFn: (resources: any[], additionalParams: any) => {
              useInvoices().invoiceActionListenersAndFunctions.review(
                resources,
                additionalParams
              )
            }
          },
          {
            value: 'pay',
            bulkConfig: {
              type: 'primary'
            },
            actionFn: (resources: any[]) => {
              useInvoices().invoiceActionListenersAndFunctions.pay(resources)
            }
          },

          ...useInboundInvoices().basicInvoiceInboundActions
        ]
        useDetailsPanel().openGlobalDetailsPanel('uc/resource-details', {
          outbound: false,
          canDoubleClickToEdit: true,
          resourceType: INVOICE_RESOURCE_TYPE,
          actions: allInboundActions
        }, 'enterCreateResourceFlow', { prefillData: { vendor: vendors[0] } })
      },
      noSuccessEmit: true
    },
    {
      value: 'proposal',
      requiresConfirmation: false,
      actionFn: (vendors: any[]) => {
        const allInboundActions = () => {
          return [
            // Deleting bulkConfig for hiding bulk action for the proposal
            ...useInboundProposals().basicProposalInboundActions.map((item: any) => {
              const temp = Object.assign({}, item)
              delete temp.bulkConfig
              return temp
            }),
            ...useInboundProposals().proposalInboundModalRequiredActions
          ]
        }
        useDetailsPanel().openGlobalDetailsPanel('uc/resource-details', {
          outbound: false,
          canDoubleClickToEdit: true,
          resourceType: PROPOSAL_RESOURCE_TYPE,
          actions: allInboundActions()
        }, 'enterCreateResourceFlow', { prefillData: { vendor: vendors[0] } })
      },
      noSuccessEmit: true
    },
    {
      value: 'purchase-order',
      requiresConfirmation: false,
      actionFn: (vendors: any[]) => {
        const allOutboundActions = () => {
          return [
            // Deleting bulkConfig for hiding bulk action for the purchase order
            ...useOutboundPurchaseOrders().basicPurchaseOrderOutboundActions().map((item: any) => {
              const temp = Object.assign({}, item)
              delete temp.bulkConfig
              return temp
            }),
            ...useOutboundPurchaseOrders().purchaseOrderOutboundModalRequiredActions
          ]
        }
        useDetailsPanel().openGlobalDetailsPanel('uc/resource-details', {
          outbound: true,
          canDoubleClickToEdit: true,
          resourceType: PO_RESOURCE_TYPE,
          actions: allOutboundActions()
        }, 'enterCreateResourceFlow', { prefillData: { vendor: vendors[0] } })
      },
      noSuccessEmit: true
    },
    {
      value: 'contacts',
      updateSelectedResource: false,
      actionFn: (vendor: any, additionalParams: any) => {
        activeVendorsProfileEditModalRef.value.modelDetails.title = `Add contacts for ${vendor[0].name}`
        // pass content table ref for reload as we are showing this in active vendor list
        activeVendorsProfileEditModalRef.value.modelDetails.contentRef = vendorsTable.value
        activeVendorsProfileEditModalRef.value.modelDetails.type = 'AddContacts'
        activeVendorsProfileEditModalRef.value.modelDetails.selectedVendor = vendor[0]
        activeVendorsProfileEditModalRef.value.modelDetails.additionalParams = additionalParams
        activeVendorsProfileEditModalRef.value?.show()
      }
    },
    {
      value: 'settings',
      updateSelectedResource: false,
      actionFn: (vendor: any, { router } :any) => {
        router.push(`/vendors/${vendor[0].id}?tab=settings`)
      }
    },
    {
      value: 'supported-trades',
      updateSelectedResource: false,
      actionFn: (vendor: any, additionalParams: any) => {
        manageTrades(vendor[0], additionalParams)
      }
    },
    {
      value: 'serviceable-areas',
      updateSelectedResource: false,
      actionFn: (vendor: any, additionalParams: any) => {
        activeVendorsProfileEditModalRef.value.modelDetails.title = `Add serviceable area for ${vendor[0].name}`
        activeVendorsProfileEditModalRef.value.modelDetails.type = 'ServiceableAreas'
        activeVendorsProfileEditModalRef.value.modelDetails.selectedVendor = vendor[0]
        activeVendorsProfileEditModalRef.value.modelDetails.additionalParams = additionalParams
        activeVendorsProfileEditModalRef.value?.show()
      }
    },
    {
      value: 'compliance',
      updateSelectedResource: true,
      actionFn: (vendor: any, { router }:any) => {
        router.push(`/vendors/${vendor[0].id}?tab=compliance`)
      }
    },
    {
      value: 'notes',
      updateSelectedResource: false,
      actionFn: (vendor: any, additionalParams: any) => {
        activeVendorsProfileEditModalRef.value.modelDetails.title = `Add a note for ${vendor[0].name}`
        activeVendorsProfileEditModalRef.value.modelDetails.type = 'Note'
        activeVendorsProfileEditModalRef.value.modelDetails.selectedVendor = vendor[0]
        activeVendorsProfileEditModalRef.value.modelDetails.additionalParams = additionalParams
        activeVendorsProfileEditModalRef.value?.show()
      }
    },
    {
      value: 'alerts',
      updateSelectedResource: false,
      actionFn: (vendor: any, additionalParams: any) => {
        activeVendorsProfileEditModalRef.value.modelDetails.title = `Add an alert for ${vendor[0].name}`
        activeVendorsProfileEditModalRef.value.modelDetails.type = 'Alert'
        activeVendorsProfileEditModalRef.value.modelDetails.selectedVendor = vendor[0]
        activeVendorsProfileEditModalRef.value.modelDetails.additionalParams = additionalParams
        activeVendorsProfileEditModalRef.value?.show()
      }
    },
    {
      value: 'share',
      actionFn: async (vendors: any[], additionalParams: any) => {
        await useVendors().actionListenersAndFunctions.share(
          vendors,
          'active',
          additionalParams
        )
      },
      noSuccessEmit: true
    },
    {
      value: 'block',
      requiresConfirmation: true,
      modal: actionModalProps.block,
      actionFn: async (vendors: any[], additionalParams: any) => {
        await useVendors().actionListenersAndFunctions.block(
          vendors,
          additionalParams
        )
      }
    },
    {
      value: 'accept',
      requiresConfirmation: true,
      modal: actionModalProps.accept,
      actionFn: async (vendors: any[], additionalParams: any) => {
        await useVendors().actionListenersAndFunctions.accept(
          vendors,
          additionalParams
        )
      }
    },
    {
      value: 'reject',
      requiresConfirmation: true,
      modal: actionModalProps.reject,
      actionFn: async (vendors: any[]) => {
        await useVendors().actionListenersAndFunctions.reject(
          vendors
        )
      }
    },
    {
      value: 'unblock',
      requiresConfirmation: true,
      modal: actionModalProps.unblock,
      actionFn: async (vendors: any[], additionalParams: any) => {
        await useVendors().actionListenersAndFunctions.unblock(
          vendors,
          additionalParams
        )
      }
    }
  ]
}

const manageTrades = async (vendor: any, additionalParams:any) => {
  const results = await useVendors().getTradesByVendorId({
    id: vendor.id
  })
  activeVendorsProfileEditModalRef.value.modelDetails.title = 'Supported trades'
  activeVendorsProfileEditModalRef.value.modelDetails.type = 'EditTrades'
  activeVendorsProfileEditModalRef.value.modelDetails.selectedVendor = vendor
  activeVendorsProfileEditModalRef.value.modelDetails.additionalParams = additionalParams
  activeVendorsProfileEditModalRef.value?.show(results?.data || [])
}

const vendors: {
  loading: boolean
  list: any
  meta: any
} = reactive({
  loading: false,
  list: [],
  meta: {
    currentPage: null,
    nextPage: null,
    prevPage: null,
    totalPages: null,
    totalCount: null
  }
})
const vendor: {
  loading: boolean
  list: any
} = reactive({
  loading: false,
  list: null
})

const vendorsListView: {
  isRequirementLoading: boolean,
} = reactive({
  isRequirementLoading: false

})
interface VendorUpdateParams {
  vendorId: string,
  include?: string,
  updateContent: Vendor,
}

export interface VendorPhotosParams {
  include?: string
  q?: any
  page?: number
  perPage?: number
}

const vendorsTable = ref<any>(null)
const vendorsProfileModalRef = ref<any>(null)
const detailsRef = ref<any>(null)
const bulkConfirmationRef = ref<any>(null)
const companyComplianceTableRef = ref<any>(null)

export const useVendors = () => {
  const privateComplianceTableRef = ref<any>(null)
  const loadVendors = async (params: any) => {
    try {
      vendors.loading = true
      const res = await repositories.vendors.getVendors(params)
      if (params.page === 1) {
        vendors.list = res.vendors.length === 0 ? [] : [...res.vendors]
      } else {
        const concatVendors = [...vendors.list, ...res.vendors]
        vendors.list = _.uniqBy(concatVendors, 'id')
      }
      vendors.meta = res.meta
    } catch (err) {
      console.log(err)
    } finally {
      vendors.loading = false
    }
  }

  const getVendors = async (params: any) => {
    try {
      const res = await repositories.vendors.getVendors(params)
      return {
        data: res.vendors,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount,
        nextPage: res.meta.nextPage
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  const getCurrentCompanyAndVendors = async (params: any) => {
    try {
      const { session } = useSession()
      const res = await repositories.vendors.getVendors(params)
      const vendorData = res.vendors
      if (params.page === 1) {
        vendorData.unshift({ name: `${session?.currentCompany?.name}`, id: `${session?.currentCompany?.id}` })
      }
      return {
        data: vendorData,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getBillableVendors = async (params: any) => {
    try {
      const res = await repositories.vendors.getBillableVendors(params)
      return {
        data: res.vendors,
        nextPage: res.meta.nextPage
      } as any
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const postVendors = async (params: any, requestBody: any) => {
    try {
      const res = await repositories.vendors.post(params, requestBody)
      return { data: res.vendor }
    } catch (err: any) {
      console.error(err)
      return { data: false }
    }
  }

  const updateVendor = async ({ vendorId, updateContent, include }:VendorUpdateParams) => {
    try {
      const res = await repositories.vendors.update({ vendorId, updateContent, include })
      return { data: res.vendor }
    } catch (err: any) {
      console.error(err)
      return { data: false }
    }
  }

  const getVendorById = async (params: any) => {
    try {
      const res = await repositories.vendors.getById(params)
      return res.vendor
    } catch (err) {
      console.error(err)
    }
  }

  const approveVendor = async (vendorId: number) => {
    try {
      const res = await repositories.vendors.approve(vendorId)
      return res.vendor
    } catch (err) {
      console.error(err)
      return { data: false, error: err }
    }
  }

  const rejectVendor = async (vendorId: number) => {
    try {
      const res = await repositories.vendors.reject(vendorId)
      return res
    } catch (err) {
      console.error(err)
      return { data: false, error: err }
    }
  }

  // everything beneath this should be depcricated
  const loadVendor = async (params: any) => {
    try {
      vendor.loading = true
      const res = await repositories.vendors.getById(params)
      vendor.list = res.vendor
      return vendor.list
    } catch (err) {
      console.log(err)
    } finally {
      vendor.loading = false
    }
  }

  const blockOrUnblockVendor = async (params: any) => {
    try {
      vendors.loading = true
      const res = await repositories.vendors.blockOrUnblockVendor(params)
      if (res && res.vendor) {
        return true
      } else {
        return false
      }
    } catch (err) {
      return false
    } finally {
      vendors.loading = false
    }
  }
  const approveOrRejectVendor = async (params: any) => {
    try {
      vendors.loading = true
      const res = await repositories.vendors.approveOrRejectVendor(params)
      if (res && (res.vendor || res.success)) {
        return true
      } else {
        return false
      }
    } catch (err) {
      return false
    } finally {
      vendors.loading = false
    }
  }

  const saveVendorRequirements = async (params: any, isEdit = false) => {
    try {
      vendorsListView.isRequirementLoading = true
      const res = isEdit ? await repositories.vendors.updateVendorRequirement(params) : await repositories.vendors.saveVendorRequirements(params)
      if (res) { return true } else { return false }
    } catch (err) {
      return false
    } finally {
      vendorsListView.isRequirementLoading = false
    }
  }

  const loadVendorRequirements = async (params: any) => {
    try {
      vendorsListView.isRequirementLoading = true
      const res = await repositories.vendors.getById(params)
      vendor.list = res.vendor
      return vendor.list
    } catch (err) {
      console.log(err)
    } finally {
      vendorsListView.isRequirementLoading = false
    }
  }

  const loadVendorsWithoutSaving = async (params: any) => {
    try {
      const response = await repositories.vendors.getVendors(params)
      return response
    } catch (err) {
      return ''
    }
  }

  const appendVendorItem = (item: any) => {
    if (!vendors.list.find((eachItem: any) => eachItem.id === item.id)) {
      vendors.list = [{ ...item }, ...vendors.list]
    }
  }

  const resendInvitationVendor = async (params: any) => {
    try {
      vendors.loading = true
      const res = await repositories.vendors.resendInvitation(params)
      if (res && res.success) {
        return true
      } else {
        return false
      }
    } catch (err) {
      return false
    } finally {
      vendors.loading = false
    }
  }

  const getVendorNetTerms = async (params: any) => {
    try {
      const res = await repositories.vendors.getNetTerms(params)
      return {
        data: res,
        defaultNetTerm: res.defaultNetTerm,
        totalPages: 1,
        totalCount: res.netTerms.length
      } as any
    } catch (err) {
    }
  }

  const resendInvitationVendorBulk = async (params: VendorsBulkActionParams) => {
    try {
      vendors.loading = true
      const res = await repositories.vendors.resendInvitationBulk(params)
      if (res && res.success) {
        return true
      } else {
        return false
      }
    } catch (err) {
      return false
    } finally {
      vendors.loading = false
    }
  }

  const downloadSampleExcel = async () => {
    try {
      const res = await repositories.vendors.downloadSampleExcel()
      return res
    } catch (err) {
      return {}
    }
  }

  const downloadFilteredExcel = async (params: any) => {
    try {
      const res = await repositories.vendors.downloadFilteredExcel(params)
      return res
    } catch (err) {
      return {}
    }
  }

  const getVendorComplianceHeaders = async () => {
    try {
      const res = await repositories.vendors.getVendorComplianceHeaders()
      return {
        data: res.headers
      } as any
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }
  const inviteVendor = async (companyId: number) => {
    try {
      const res = await repositories.vendors.inviteVendor(companyId)
      return res
    } catch (err) {
      return {}
    }
  }
  const getVendorComplianceDetails = async (param: any, isSharedProfile = false) => {
    try {
      const res = isSharedProfile ? await repositories.vendors.getVendorSharedComplianceDetails(param) : param?.status === 'No Document' || param?.status === 'no_document' ? await repositories.vendors.getVendorComplianceDetailsNoDoc(param) : await repositories.vendors.getVendorComplianceDetails(param)
      return {
        data: res
      } as any
    } catch (err: any) {
      console.log(err)
      return { error: err?.data?.error ? err?.data?.error : err?.data?.errors }
    }
  }

  const postVendorComplianceDetails = async (complianceUpdate:any, param: any) => {
    try {
      const res = await repositories.vendors.postVendorComplianceDetails(complianceUpdate, param)
      return {
        data: res
      } as any
    } catch (err: any) {
      console.log(err)
      return { error: err?.data?.errors }
    }
  }

  const postVendor = async (param: any) => {
    try {
      const res = await repositories.vendors.postVendor(param)
      return res
    } catch (err: any) {
      console.log(err)
      return { error: err?.data?.error ? err?.data?.error : err?.data?.errors }
    }
  }

  const getComplianceList = async (param: any) => {
    try {
      let res = await repositories.vendors.getComplianceList(param)
      res = res.complianceDocumentsStatus.map((item: any, index:number) => {
        return {
          id: index,
          ...item
        }
      })
      return {
        data: res,
        totalPages: 1,
        totalCount: 1
      } as any
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getComplianceRequirementList = async (params: any) => {
    try {
      const res = await repositories.vendors.getComplianceRequirementList(params)
      return {
        data: res.complianceRequirments,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const markVendorCompliant = async (params: VendorsBulkActionParams) => {
    try {
      const res = await repositories.vendors.markVendorCompliant(params)
      return res
    } catch (err) {
      return err
    }
  }

  const markVendorNonCompliant = async (params: VendorsBulkActionParams) => {
    try {
      const res = await repositories.vendors.markVendorNonCompliant(params)
      return res
    } catch (err) {
      return err
    }
  }

  const complianceAcceptReject = async (param: any) => {
    try {
      const res = await repositories.vendors.complianceAcceptReject(param)
      return res
    } catch (err) {
      return err
    }
  }

  const complianceUpdate = async (complianceUpdate:any, param: any) => {
    try {
      const res = await repositories.vendors.complianceUpdate(complianceUpdate, param)
      return { data: res }
    } catch (err) {
      return { data: false }
    }
  }

  const getVendorSharedComplianceList = async (param: any) => {
    try {
      let res = await repositories.vendors.getVendorSharedComplianceList(param)
      res = res?.vendorCompliances?.map((item: any) => {
        return {
          id: item.id,
          name: item.label,
          status: item.statusText,
          documentType: item.surveyTemplate?.surveyType === 'w9_document' ? 'w9' : 'coi'
        }
      })
      return {
        data: res,
        totalPages: 1,
        totalCount: 1
      } as any
    } catch (err: any) {
      console.log(err)
      return { error: err?.data?.errors }
    }
  }

  const getVendorsLicense = async (param: any) => {
    try {
      const res = await repositories.vendors.getVendorsLicense(param)
      return {
        data: res.licenses,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.log(err)
      return {
        data: [],
        totalPages: 0,
        totalCount: 0
      } as any
    }
  }

  const getTradesByVendorId = async (params: any) => {
    try {
      const res = await repositories.vendors.getTradesByVendorId(params)
      return {
        data: res.trades,
        totalPages: res.meta?.totalPages,
        totalCount: res.meta?.totalCount
      }
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const getPublicTradesByVendorId = async (params: any) => {
    try {
      const res = await repositories.vendors.getPublicTradesByVendorId(params)
      return {
        data: res.trades,
        totalPages: res.meta?.totalPages,
        totalCount: res.meta?.totalCount
      }
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const getPrivateTrades = async (params: any) => {
    try {
      const res = await repositories.vendors.getPrivateTrades(params)
      return {
        data: res.trades,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount,
        currentPage: res.meta.currentPage
      } as any
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const vendorTradeEdit = async (param: any) => {
    try {
      const res = await repositories.vendors.vendorTradeEdit(param)
      return { data: res }
    } catch (err) {
      return { data: err }
    }
  }

  const getPrivateRegionByVendorId = async (params: any) => {
    try {
      const res = await repositories.vendors.getPrivateRegionByVendorId(params)
      return {
        data: res.regions,
        totalPages: res.meta?.totalPages,
        totalCount: res.meta?.totalCount
      }
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const getPublicRegionByVendorId = async (params: any) => {
    try {
      const res = await repositories.vendors.getPublicRegionByVendorId(params)
      return {
        data: res.regions,
        totalPages: res.meta?.totalPages,
        totalCount: res.meta?.totalCount
      }
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const getVendorCOIRejectionEmailTemplate = async (params: any) => {
    try {
      const res = await repositories.vendors.getVendorCOIRejectionEmailTemplate(params)
      return res
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const sendComplianceRejectEmail = async (params: any) => {
    try {
      const res = await repositories.vendors.sendComplianceRejectEmail(params)
      return res
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const updateEmailAndSendResendVendorInvite = async (params: any) => {
    try {
      const res = await repositories.vendors.updateEmailAndSendResendVendorInvite(params)
      return res
    } catch (err) {
      console.error(err)
      return err
    }
  }

  const sendCOIExpirationRemainder = async (params: VendorsBulkActionParams) => {
    try {
      const res = await repositories.vendors.sendCOIExpirationRemainder(params)
      return res
    } catch (err) {
      return err
    }
  }

  const updateProfileLogo = async (params: any, id: number) => {
    try {
      const res = await repositories.vendors.updateProfileLogo(params, id)
      return res.vendor
    } catch (err) {
      return err
    }
  }

  const updateProfile = async (params: any, id: number) => {
    try {
      const res = await repositories.vendors.updateProfile(params, id)
      return {
        data: res.vendor
      }
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const addVendorAlert = async (params: any, isEdit = false) => {
    try {
      const res = isEdit ? await repositories.vendors.editVendorAlert(params) : await repositories.vendors.addVendorAlert(params)
      return res
    } catch (err) {
      console.error(err)
      return err
    }
  }

  const addVendorNote = async (params: any, isEdit = false) => {
    try {
      const res = isEdit ? await repositories.vendors.editVendorNote(params) : await repositories.vendors.addVendorNote(params)
      return res
    } catch (err) {
      console.error(err)
      return err
    }
  }

  const deleteVendorNote = async (params: any) => {
    try {
      const res = await repositories.vendors.deleteVendorNote(params)
      return res
    } catch (err) {
      console.error(err)
      return err
    }
  }

  const deleteVendorAlert = async (params: any) => {
    try {
      const res = await repositories.vendors.deleteVendorAlert(params)
      return res
    } catch (err) {
      console.error(err)
      return err
    }
  }

  const getVendorsContact = async (params: any) => {
    try {
      const res = await repositories.vendors.getContacts(params)
      return {
        data: res.contacts,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.log(err)
      return {
        data: [],
        totalPages: 0,
        totalCount: 0
      } as any
    }
  }

  const deleteVendorContact = async (params: any) => {
    try {
      const res = await repositories.vendors.deleteVendorContact(params)
      return res
    } catch (err) {
      console.error(err)
      return err
    }
  }

  const AddVendorContact = async (param: any) => {
    try {
      const res = await repositories.vendors.AddVendorContact(param)
      return {
        data: res
      } as any
    } catch (err: any) {
      console.log(err)
      return { error: err?.data?.errors }
    }
  }

  const updateVendorContact = async (param: any) => {
    try {
      const res = await repositories.vendors.updateVendorContact(param)
      return {
        data: res
      } as any
    } catch (err: any) {
      console.log(err)
      return { error: err?.data?.errors }
    }
  }

  const getVendorRegion = async (params: any) => {
    try {
      const res = await repositories.vendors.getVendorRegion(params)
      return {
        data: res.states,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  const getVendorStateCounty = async (params: any) => {
    try {
      const res = await repositories.vendors.getVendorStateCounty(params)
      return {
        data: res.counties,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  const getVendorCountyZipCodes = async (params: any) => {
    try {
      const res = await repositories.vendors.getVendorCountyZipCodes(params)
      return {
        data: res.zipCodes,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  const addVendorRegion = async (params: any) => {
    try {
      const res = await repositories.vendors.addVendorRegion(params)
      return res
    } catch (err) {
      return err
    }
  }

  const updateVendorRegion = async (params: any) => {
    try {
      const res = await repositories.vendors.updateVendorRegion(params)
      return res
    } catch (err) {
      return err
    }
  }

  const getClaimStatuses = async (params: any) => {
    try {
      const res = await repositories.vendors.getClaimStatuses(params)
      return {
        data: res.statuses,
        totalPages: 1,
        totalCount: res.statuses.length
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  const getVendorsAddresses = async (params: any) => {
    try {
      const res = await repositories.vendors.getAddresses(params)
      return {
        data: res.addresses,
        totalPages: 1,
        totalCount: res.addresses.length
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  const downloadVendorCompliancesDocs = async (params: any) => {
    try {
      const res = await repositories.vendors.downloadVendorCompliancesDocs(params)
      return res
    } catch (err) {
      console.log(err)
    }
  }

  const getComplianceUploadReminderInfo = async (params: any) => {
    try {
      const res = await repositories.vendors.getComplianceUploadReminderInfo(params)
      return res as any
    } catch (err) {
      console.log(err)
    }
  }

  const sendComplianceUploadReminder = async (params: any) => {
    try {
      const res = await repositories.vendors.sendComplianceUploadReminder(params)
      return res as any
    } catch (err) {
      console.log(err)
    }
  }

  const getCoiExpirationReminderInfo = async (params: any) => {
    try {
      const res = await repositories.vendors.getCoiExpirationReminderInfo(params)
      return res as any
    } catch (err) {
      console.log(err)
    }
  }

  const sendCoiExpirationReminder = async (params: any) => {
    try {
      const res = await repositories.vendors.sendCoiExpirationReminder(params)
      return res as any
    } catch (err) {
      console.log(err)
    }
  }

  const getInvitationTemplate = async (vendorName: any) => {
    try {
      const res = await repositories.vendors.getInvitationTemplate(vendorName)
      return res as any
    } catch (err) {
      console.log(err)
    }
  }

  const showToastMessage = (response: any, toastMessage: any) => {
    const toast = {
      timeout: !response ? 2000 : 4000,
      color: response ? 'success' : 'error',
      message: toastMessage,
      prependIcon: response ? SUCCESS : ERROR
    }
    addToast({ ...toast })
  }
  const actionListenersAndFunctions: any = {
    accept: async (
      vendor: any[],
      additionalParams: any
    ) => {
      await approveVendor(vendor[0].id)
      const localVendorId = vendor[0].id
      const localVendorName = vendor[0].name
      const isFromTable = additionalParams?.isFromTable
      addToast({
        color: 'success',
        message: !isFromTable ? `${localVendorName} has been accepted.` : '',
        timeout: 15000,
        prependIcon: SUCCESS,
        component: () => isFromTable
          ? h(
            'div', { style: { fontWeight: 500, fontSize: '1rem' } }, [' ', '',
              h('router-link', { props: { to: `/vendors/${localVendorId}` } }, [`${localVendorName}`]), ' has been accepted'
            ]
          )
          : h()
      })
    },
    reject: async (
      vendor: any[]
    ) => {
      const response = await rejectVendor(vendor[0].id)
      const toastMessage = response.success
        ? `${vendor[0].name} has been rejected.`
        : `Failed to reject ${vendor[0].name || 'selected vendor'}`
      showToastMessage(response, toastMessage)
    },
    block: async (
      vendor: any[],
      additionalParams: any
    ) => {
      const param = { vendorId: vendor[0].id, path: 'block' }
      await blockOrUnblockVendor(param)
      const localVendorId = vendor[0].id
      const localVendorName = vendor[0].name
      addToast({
        color: 'success',
        message: !additionalParams.isFromTable ? `${localVendorName} has been blocked.` : '',
        timeout: 15000,
        prependIcon: SUCCESS,
        component: () =>
          h(
            'div', { style: { fontWeight: 500, fontSize: '1rem' } }, [' ', '',
              h('router-link', { props: { to: `/vendors/${localVendorId}` } }, [`${localVendorName}`]), ' has been blocked'
            ]
          )
      })
    },
    unblock: async (
      vendor: any[],
      additionalParams: any
    ) => {
      const param = { vendorId: vendor[0].id, path: 'unblock' }
      await blockOrUnblockVendor(param)
      const localVendorId = vendor[0].id
      const localVendorName = vendor[0].name
      const isFromTable = additionalParams?.isFromTable
      addToast({
        color: 'success',
        message: !isFromTable ? `${localVendorName} has been unblocked.` : '',
        timeout: 15000,
        prependIcon: SUCCESS,
        component: () =>
          h(
            'div', { style: { fontWeight: 500, fontSize: '1rem' } }, [' ', '',
              h('router-link', { props: { to: `/vendors/${localVendorId}` } }, [`${localVendorName}`]), ' has been unblocked'
            ]
          )
      })
    },
    invite: async (
      vendor: any[],
      additionalParams: any,
      tab:string,
      activeOrPendingVendor: boolean,
      q = {},
      totalVendorsCount = 0
    ) => {
      if (vendor?.length > 1) {
        const response = await resendInvitationVendorBulk({ tab, activeOrPendingVendor, q })
        const toastMessage = response
          ? `Invitation email resent to ${totalVendorsCount} vendors.`
          : 'Failed to send vendors invitations'
        showToastMessage(response, toastMessage)
      } else {
        const localVendorId = vendor[0].id
        const localVendorName = vendor[0]?.props?.vendor?.name ? vendor[0]?.props?.vendor?.name : vendor[0]?.name
        const isFromTable = additionalParams?.isFromTable
        await resendInvitationVendor({ vendorId: vendor[0].id })
        addToast({
          color: 'success',
          message: !isFromTable ? `Invitation email resent to ${localVendorName}` : '',
          timeout: 15000,
          prependIcon: SUCCESS,
          component: () =>
            h(
              'div', { style: { fontWeight: 500, fontSize: '1rem' } }, ['Invitation email resent to ',
                h('router-link', { props: { to: `/vendors/${localVendorId}` } }, [`${localVendorName}`])
              ]
            )
        })
      }
    },
    share: (
      vendor: any[],
      tab:string,
      additionalParams: any
    ) => {
      const isFromTable = additionalParams?.isFromTable
      const url = createSubdomainURL(currentUsersCompany.value.subdomain, `/vendors/${vendor[0].id}`)
      navigator.clipboard.writeText(url).then(function () {
        const localVendorId = vendor[0].id
        const localVendorName = vendor[0].name
        addToast({
          color: 'success',
          message: !isFromTable ? 'Link copied to clipboard' : '',
          timeout: 15000,
          prependIcon: SUCCESS,
          component: () => isFromTable
            ? h(
              'div', { style: { fontWeight: 500, fontSize: '1rem' } }, [' ', 'Link copied to clipboard for ',
                h('router-link', { props: { to: `/vendors/${localVendorId}` } }, [`${localVendorName}`])
              ]
            )
            : h()
        })
      })
    }
  }

  const getVendorCoinsConnections = async (params: any) => {
    try {
      const res = await repositories.vendors.getVendorCoinsConnections(params)
      return {
        data: res.externalConnections,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  const getVendorMarkets = async (params: any) => {
    try {
      const res = await repositories.vendors.getVendorMarkets(params)
      return {
        data: res.markets,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  const getVendorRegions = async (params: any) => {
    try {
      const res = await repositories.vendors.getVendorRegions(params)
      return {
        data: res.territories,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  const getVendorTrades = async (params: any) => {
    try {
      const res = await repositories.vendors.getVendorTrades(params)
      return {
        data: res.trades,
        totalPages: res.totalPages,
        totalCount: res.totalCount
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  const getVendorAddresses = async (params: any) => {
    try {
      const res = await repositories.vendors.getVendorAddresses(params)
      return {
        data: res.addresses,
        totalPages: res.totalPages,
        totalCount: res.totalCount
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  const getVendorsCoverageAmount = async (params: any) => {
    try {
      const res = await repositories.vendors.getVendorsCoverageAmount(params)
      return {
        data: res.observations,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  const vendorsAutoComplete = async (params: AutocompletePayload) => {
    try {
      const res = await repositories.vendors.vendorsAutoComplete(params)
      return {
        data: res.results,
        nextPage: res.nextPage
      } as { data: AutocompleteResponse[], nextPage: number }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const vendorsSearch = async (params: any) => {
    try {
      const res = await repositories.vendors.vendorsSearch(params)
      return {
        data: res.vendors,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  const billableVendorsSearch = async (params: any) => {
    try {
      const res = await repositories.vendors.billableVendorsSearch(params)
      return {
        data: res.vendors,
        nextPage: res.meta.nextPage
      } as any
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getVendorsByWorkOrderId = async (params: any) => {
    try {
      const res = await repositories.vendors.getVendorsByWorkOrderId(params)
      return {
        data: res.companies,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getVendorsPhotos = async (vendorId: number, params: VendorPhotosParams) => {
    try {
      const res = await repositories.vendors.getVendorsPhotos(vendorId, params)
      return {
        data: res.tripPhotos,
        currentPage: res.meta?.currentPage,
        nextPage: res.meta?.nextPage,
        prevPage: res.meta?.prevPage,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getVendorsPhotosCount = async (vendorId: number) => {
    try {
      const res = await repositories.vendors.getVendorsPhotosCount(vendorId)
      return {
        data: res.count
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const photoPreview = async (params: { ids: number[], tab: string }) => {
    try {
      const res: { companyEngagements: AttachmentInfo[] } = await repositories.vendors.photoPreview(params)
      return res
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const additionalDataDetails = [{ fetchFn: photoPreview, responseKey: 'companyEngagements', dataKey: 'attachmentInfo' }]

  return {
    vendorsListView,
    vendors,
    loadVendors,
    vendor,
    postVendors,
    updateVendor,
    getVendors,
    getCurrentCompanyAndVendors,
    loadVendor,
    blockOrUnblockVendor,
    loadVendorsWithoutSaving,
    appendVendorItem,
    saveVendorRequirements,
    loadVendorRequirements,
    approveOrRejectVendor,
    resendInvitationVendor,
    resendInvitationVendorBulk,
    getVendorById,
    getBillableVendors,
    approveVendor,
    rejectVendor,
    downloadSampleExcel,
    downloadFilteredExcel,
    getVendorComplianceHeaders,
    inviteVendor,
    getVendorComplianceDetails,
    postVendor,
    getComplianceList,
    getComplianceRequirementList,
    markVendorCompliant,
    markVendorNonCompliant,
    complianceAcceptReject,
    complianceUpdate,
    vendorsTable,
    vendorsProfileModalRef,
    getVendorSharedComplianceList,
    getTradesByVendorId,
    getPublicTradesByVendorId,
    getVendorsLicense,
    getPrivateTrades,
    vendorTradeEdit,
    getPublicRegionByVendorId,
    getPrivateRegionByVendorId,
    getVendorCOIRejectionEmailTemplate,
    sendComplianceRejectEmail,
    updateEmailAndSendResendVendorInvite,
    sendCOIExpirationRemainder,
    updateProfileLogo,
    updateProfile,
    addVendorAlert,
    addVendorNote,
    deleteVendorNote,
    deleteVendorAlert,
    getVendorsContact,
    postVendorComplianceDetails,
    deleteVendorContact,
    AddVendorContact,
    updateVendorContact,
    detailsRef,
    getVendorRegion,
    getVendorStateCounty,
    getVendorCountyZipCodes,
    addVendorRegion,
    updateVendorRegion,
    getClaimStatuses,
    getVendorsAddresses,
    bulkConfirmationRef,
    downloadVendorCompliancesDocs,
    privateComplianceTableRef,
    companyComplianceTableRef,
    actionListenersAndFunctions,
    universalActions,
    actionModalProps,
    genVendorsChipProps,
    universalActionData,
    vendorActionData,
    vendorMenuActions,
    genActionableVendors,
    getComplianceUploadReminderInfo,
    sendComplianceUploadReminder,
    getCoiExpirationReminderInfo,
    sendCoiExpirationReminder,
    getVendorCoinsConnections,
    getVendorMarkets,
    getVendorRegions,
    getVendorTrades,
    getVendorAddresses,
    getVendorsCoverageAmount,
    getInvitationTemplate,
    getVendorNetTerms,
    vendorsAutoComplete,
    vendorsSearch,
    billableVendorsSearch,
    getVendorsByWorkOrderId,
    getVendorMenuActions,
    activeVendorsProfileEditModalRef,
    getVendorsPhotos,
    getVendorsPhotosCount,
    additionalDataDetails
  }
}

import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('uc-tile-container', {
    directives: [{
      def: Intersect,
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _setup.emitIntersect
      },
      expression: "{\n    handler: emitIntersect,\n  }"
    }],
    staticClass: "d-flex flex-column gap-lg pa-2",
    attrs: {
      "no-margin": true,
      "no-elevation": true,
      "no-padding": true,
      "background-color": '--v-layer-base'
    }
  }, [_c('div', {
    staticClass: "d-flex flex-row align-center gap-lg"
  }, [_c(VSkeletonLoader, {
    attrs: {
      "width": "20px",
      "height": "20px",
      "type": "image"
    }
  }), _vm._l(2, function (index) {
    return _c('div', {
      key: `first-loop-${index}`,
      staticClass: "d-flex flex-1"
    }, [_c(VSkeletonLoader, {
      attrs: {
        "width": "100%",
        "height": "20px",
        "type": "image"
      }
    })], 1);
  }), _c(VSkeletonLoader, {
    attrs: {
      "width": "30px",
      "height": "30px",
      "type": "image"
    }
  })], 2), _c('div', {
    staticClass: "d-flex flex-row align-center gap-lg ml-8"
  }, [_vm._l(3, function (index) {
    return _c(VSkeletonLoader, {
      key: `second-loop-${index}`,
      attrs: {
        "width": "30px",
        "height": "30px",
        "type": "image"
      }
    });
  }), _vm._l(2, function (index) {
    return _c(VSkeletonLoader, {
      key: `third-loop-${index}`,
      attrs: {
        "width": "100px",
        "height": "30px",
        "type": "image"
      }
    });
  })], 2), _c('div', {
    staticClass: "d-flex flex-row align-center gap-lg ml-8 mt-2"
  }, _vm._l(2, function (index) {
    return _c('div', {
      key: `fourth-loop-${index}`,
      staticClass: "d-flex flex-1"
    }, [_c(VSkeletonLoader, {
      attrs: {
        "width": "100%",
        "height": "20px",
        "type": "image"
      }
    })], 1);
  }), 0)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
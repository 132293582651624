import { useOutboundPurchaseOrders } from '@/use/purchase-orders/outbound'
import { useInboundPurchaseOrders } from '@/use/purchase-orders/inbound'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { ref } from 'vue'
import { FilterResources } from '@/types/enums/filter-types'
import { cloneDeep } from 'lodash'
import { CompanyRoleTypes } from '@/types/enums'

const { purchaseOrdersAutoComplete, statuses } = useOutboundPurchaseOrders()
const { inboundPurchaseOrdersAutoComplete } = useInboundPurchaseOrders()
const isOutbound = ref(false)

const purchaseOrderAutoCompleteFetchFn = async (params: AutocompletePayload) => {
  const { data, nextPage } = (isOutbound.value)
    ? await purchaseOrdersAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number }
    : await inboundPurchaseOrdersAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number }

  return {
    data: data.map((item: AutocompleteResponse, index: number) => {
      return {
        id: `${index}-${item.text}`,
        text: item.text,
        value: item.text
      }
    }),
    nextPage
  }
}

// const poStatuses: Record <string, string> = {
//   Draft: 'draft',
//   Sent: 'sent',
//   Accepted: 'accepted',
//   Rejected: 'rejected',
//   Closed: 'closed',
//   Received: 'sent'
// }

interface CalculatedStaus {
  code: number;
  text: string;
}

const poStatuses: {[CompanyRoleTypes.CLIENT]: CalculatedStaus[], [CompanyRoleTypes.VENDOR]: CalculatedStaus[], both: CalculatedStaus[] } = {
  client: [],
  vendor: [],
  both: []
}

const getPoStatuses = async (as: CompanyRoleTypes | 'both') => {
  if (poStatuses[as].length) return poStatuses[as]

  const res = await statuses(as)

  if (res.data) {
    poStatuses[as] = res.data
    return poStatuses[as]
  } else {
    return []
  }
}

const PO_NUMBER = (outbound: boolean, config = {}, params = { field: 'po_number' }, fetchFn: any = purchaseOrderAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'purchase-order-filter',
      headerText: 'Purchase Order Number',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      predicate: '_cont',
      params,
      searchKeys: ['po_number'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.PURCHASE_ORDER,
    ...config
  }
}

const PO_CUSTOM_NUMBER = (outbound: boolean, config = {}, params = { field: 'custom_po_number' }, fetchFn: any = purchaseOrderAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Custom Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'purchase-order-custom-number-filter',
      headerText: 'Purchase Order Custom Number',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['custom_po_number'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.PURCHASE_ORDER,
    ...config
  }
}

const PO_STATUS = (as: CompanyRoleTypes | 'both', config = {}) => {
  return {
    text: 'Status',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'purchase-order-status-filter',
      headerText: 'Purchase Order Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async ({ terms }: { terms?: string }) => {
        const res = await getPoStatuses(as)

        const data = cloneDeep(res).filter((calculatedStaus) => calculatedStaus.text.toLocaleLowerCase().includes(terms?.toLowerCase() || '')).map((calculatedStaus) => {
          return {
            id: calculatedStaus.code,
            text: calculatedStaus.text,
            value: calculatedStaus.code
          }
        })

        return {
          data,
          totalCount: data.length,
          totalPages: 1
        }
      },
      params: { q: { s: 'number asc' } },
      searchKeys: ['number']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.PURCHASE_ORDER,
    ...config
  }
}

const PO_DATE_OF_ISSUE = (outbound: boolean, config = {}) => {
  return {
    text: 'Date of Issue',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'purchase-order-date-of-issue',
      headerText: 'Purchase Order Date of Issue',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.PURCHASE_ORDER,
    ...config
  }
}

export {
  PO_NUMBER,
  PO_CUSTOM_NUMBER,
  PO_STATUS,
  PO_DATE_OF_ISSUE
}

import { api, apis } from '@/api'
import qs from 'qs'
import { transformRansackToEndpointQuery } from '@/utils/api'
import { AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'
import { params } from '@/utils'

interface getLocationsParams {
  vendorId?: string
  withExtendedStatus?: boolean,
  id?: string | number,
  status?: string
  include?: string
  q?: any
  page?: number
  perPage?: number
  tab?: string
  roles?: any
  activeOrPendingClientsLocations?: boolean
  creditHold?: boolean
  contactUser?: boolean
}
interface getInvoiceableResourcesParams {
  locationId?: string
  include?: string
  q?: any
  page?: number
  perPage?: number
  resourceType?: string
  resourceId?: number
  clientId?: number | string
  vendorId?: number | string
  withExtendedStatus?: boolean
  roles: string
  includeResourcesCount?: boolean
}

interface GetInventoryLocationParams {
  q?: any,
  page: number
  perPage: number
}

interface GetLocationEquipmentsParams {
  locationId:number
}

interface GetClientsLocations {
  withExtendedStatus?: boolean,
  include?: string
  q?: any
  page?: number
  perPage?: number,
  clientId?: string | number,
  excludeClosedSites?: true,
  forContractorInvoice?: boolean,
  onlyAssigned?: boolean,
  withNonExistingClients?: false
  roles?:string
}
interface GetClientsLocationsContact {
  id?: string | number,
  q?: any,
  include?: string,
  contactType?: string
}

export const dateTransformed = (q: any) => transformRansackToEndpointQuery(q, [
  {
    queryField: /work_order_creation_date(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_created_at'
  },
  {
    queryField: /work_order_expiration_date(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_expiration_date'
  },
  {
    queryField: /expiration_date(.*)/,
    replaceWith: 'q_trips',
    key: 'expiration_date'
  },
  {
    queryField: /start_date(.*)/,
    replaceWith: 'q_trips',
    key: 'start_date'
  }
])

export default {
  getLocationsNearBy: ({ include, q, page, perPage, tab, roles }: getLocationsParams) => api.$get('/locations/nearby', {
    params: {
      include,
      tab,
      roles,
      q,
      page,
      perPage
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  getLocations: ({ vendorId, withExtendedStatus, status, include, q, page, perPage, tab, roles, activeOrPendingClientsLocations, creditHold }: getLocationsParams) => api.$get('/locations', {
    params: {
      contractorId: vendorId,
      withExtendedStatus,
      status,
      include,
      q,
      page,
      perPage,
      tab,
      roles,
      active_or_pending_clients_locations: activeOrPendingClientsLocations,
      credit_hold: creditHold
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  getLocation: (params:any) => {
    const parameters:getLocationsParams = params
    const { id, include } = parameters
    return api.$get(`/locations/${id}`, {
      params: {
        include
      }
    })
  },
  getById: (params:any) => {
    const parameters:getLocationsParams = params
    const { id, include } = parameters
    return api.$get(`/locations/${id}`, {
      params: {
        include
      }
    })
  },
  getLocationTax: (id:number) => {
    return api.$get(`/locations/${id}/taxes`)
  },
  getInventoryLocation: ({ perPage, page, q }: GetInventoryLocationParams) => apis.$get('/locations', {
    params: {
      include: 'company, address',
      page,
      perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  getLocationEquipmentsURL: ({ locationId } : GetLocationEquipmentsParams) => apis.$get('/locations/equipments', {
    params: {
      location_ids: locationId
    }
  }),
  getInvoiceableLocations: ({ withExtendedStatus, roles, status, include, q, page, perPage }: getLocationsParams) => {
    const transformed = transformRansackToEndpointQuery(q, [
      {
        queryField: /completed_at_date(.*)/,
        replaceWith: 'q_trips',
        key: 'completed_at_date'
      },
      {
        queryField: /work_order_trade_services_id(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_trade_services_id'
      },
      {
        queryField: /work_order_trades_id(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_trades_id'
      },
      {
        queryField: /work_order_work_order_type(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_work_order_type'
      },
      {
        queryField: /work_order_locations_agreements_external_region(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_locations_agreements_external_region'
      },
      {
        queryField: /work_order_extended_status_state(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_extended_status_state'
      },
      {
        queryField: /extended_status_code(.*)/,
        replaceWith: 'q_trips',
        key: 'extended_status_code'
      },
      {
        queryField: /work_order_locations_cc_territory_name(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_locations_cc_territory_name'
      },
      {
        queryField: /storms_start(.*)/,
        replaceWith: 'q_trips',
        key: 'storms_start'
      },
      {
        queryField: /storms_end(.*)/,
        replaceWith: 'q_trips',
        key: 'storms_end'
      },
      {
        queryField: /work_order_created_at(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_created_at'
      },
      {
        queryField: /work_order_status_name(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_work_order_status_name'
      }
    ])

    return api.$get('/locations/invoiceables', {
      params: {
        withExtendedStatus,
        roles,
        status,
        include,
        q,
        page,
        perPage,
        ...transformed,
        ...dateTransformed(q)
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getInvoiceableLocsByClient: ({ clientId, include, q, page, perPage, withExtendedStatus, roles }: getInvoiceableResourcesParams) => {
    const transformed = transformRansackToEndpointQuery(q, [
      {
        queryField: /vendor_invoice_exists(.*)/,
        key: 'vendor_invoice_exists',
        noPredicate: true,
        valueIndex: 0
      },
      {
        queryField: /completed_at_date(.*)/,
        replaceWith: 'q_trips',
        key: 'completed_at_date'
      },
      {
        queryField: /work_order_trade_services_id(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_trade_services_id'
      },
      {
        queryField: /work_order_trades_id(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_trades_id'
      },
      {
        queryField: /work_order_work_order_type(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_work_order_type'
      },
      {
        queryField: /work_order_locations_agreements_external_region(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_locations_agreements_external_region'
      },
      {
        queryField: /work_order_extended_status_state(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_extended_status_state'
      },
      {
        queryField: /extended_status_code(.*)/,
        replaceWith: 'q_trips',
        key: 'extended_status_code'
      },
      {
        queryField: /work_order_locations_cc_territory_name(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_locations_cc_territory_name'
      },
      {
        queryField: /storms_start(.*)/,
        replaceWith: 'q_trips',
        key: 'storms_start'
      },
      {
        queryField: /storms_end(.*)/,
        replaceWith: 'q_trips',
        key: 'storms_end'
      },
      {
        queryField: /work_order_created_at(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_created_at'
      },
      {
        queryField: /work_order_follow_up_at(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_follow_up_at'
      },
      {
        queryField: /work_order_work_order_name(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_work_order_name'
      },
      {
        queryField: /locations_id(.*)/,
        replaceWith: 'q_trips',
        key: 'locations_id'
      },
      {
        queryField: /work_order_trades_name(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_trades_name'
      }
    ])

    return api.$get(`locations/clients/${clientId}/invoiceable_locations`, {
      params: {
        include,
        q,
        page,
        perPage,
        withExtendedStatus,
        roles,
        ...transformed,
        ...dateTransformed(q)
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getClientsLocations: ({ clientId, include, q, page, perPage, withExtendedStatus, roles, excludeClosedSites, forContractorInvoice, onlyAssigned, withNonExistingClients }: GetClientsLocations) => api.$get(`/clients/${clientId}/locations`, {
    params: {
      include,
      q,
      page,
      perPage,
      withExtendedStatus,
      roles,
      excludeClosedSites,
      forContractorInvoice,
      onlyAssigned,
      withNonExistingClients
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  getBillableLocations: ({ vendorId, withExtendedStatus, roles, status, include, q, page, perPage }: getLocationsParams) => {
    const transformed = transformRansackToEndpointQuery(q, [
      {
        queryField: /completed_at_date(.*)/,
        replaceWith: 'q_trips',
        key: 'completed_at_date'
      },
      {
        queryField: /work_order_company_id(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_company_id'
      },
      {
        queryField: /locations_id(.*)/,
        replaceWith: 'q_trips',
        key: 'locations_id'
      },
      {
        queryField: /work_order_work_order_name(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_work_order_name'
      },
      {
        queryField: /trade_services_id(.*)/,
        replaceWith: 'q_trips',
        key: 'trade_services_id'
      },
      {
        queryField: /trades_id(.*)/,
        replaceWith: 'q_trips',
        key: 'trades_id'
      },
      {
        queryField: /work_order_work_order_type(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_work_order_type'
      },
      {
        queryField: /work_order_vendor_client_agreements_external_region(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_vendor_client_agreements_external_region'
      },
      {
        queryField: /work_order_created_at(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_created_at'
      },
      {
        queryField: /extended_status_code(.*)/,
        replaceWith: 'q_trips',
        key: 'extended_status_code'
      },
      {
        queryField: /completed_at(.*)/,
        replaceWith: 'q_trips',
        key: 'completed_at'
      },
      {
        queryField: /work_order_follow_up_at(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_follow_up_at'
      },
      {
        queryField: /work_order_extended_status_state(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_extended_status_state'
      },
      {
        queryField: /work_order_locations_cc_territory_name(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_locations_cc_territory_name'
      },
      {
        queryField: /work_order_trades_name(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_trades_name'
      }
    ])

    return api.$get(`/vendors/${vendorId}/billable_locations`, {
      params: {
        withExtendedStatus,
        status,
        include,
        roles,
        q,
        page,
        perPage,
        ...transformed,
        ...dateTransformed(q)
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getInvoiceableResourcesByLocation: ({ locationId, include, q, page, perPage, resourceType, clientId, includeResourcesCount }: getInvoiceableResourcesParams) => {
    const transformed = transformRansackToEndpointQuery(q, [
      {
        queryField: /completed_at_date(.*)/,
        replaceWith: 'q_trips',
        key: 'completed_at_date'
      },
      {
        queryField: /vendor_invoice_exists(.*)/,
        key: 'vendor_invoice_exists',
        noPredicate: true,
        valueIndex: 0
      },
      {
        queryField: /work_order_trade_services_id(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_trade_services_id'
      },
      {
        queryField: /work_order_trades_id(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_trades_id'
      },
      {
        queryField: /work_order_work_order_type(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_work_order_type'
      },
      {
        queryField: /work_order_locations_agreements_external_region(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_locations_agreements_external_region'
      },
      {
        queryField: /work_order_extended_status_state(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_extended_status_state'
      },
      {
        queryField: /extended_status_code(.*)/,
        replaceWith: 'q_trips',
        key: 'extended_status_code'
      },
      {
        queryField: /work_order_locations_cc_territory_name(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_locations_cc_territory_name'
      },
      {
        queryField: /storms_start(.*)/,
        replaceWith: 'q_trips',
        key: 'storms_start'
      },
      {
        queryField: /storms_end(.*)/,
        replaceWith: 'q_trips',
        key: 'storms_end'
      },
      {
        queryField: /work_order_created_at(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_created_at'
      },
      {
        queryField: /work_order_follow_up_at(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_follow_up_at'
      },
      {
        queryField: /work_order_work_order_name(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_work_order_name'
      },
      {
        queryField: /work_order_locations_id(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_locations_id'
      },
      {
        queryField: /work_order_trades_name(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_trades_name'
      }
    ])
    return api.$get(`locations/${locationId}/invoiceable_resources/${resourceType}`, {
      params: {
        clientId,
        include,
        q,
        page,
        perPage,
        includeResourcesCount,
        ...transformed,
        ...dateTransformed(q)
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getInvoiceableTripsByLocAndResource: ({ locationId, include, q, page, perPage, resourceType, resourceId, clientId, vendorId }: getInvoiceableResourcesParams) => {
    const transformed = transformRansackToEndpointQuery(q, [
      {
        queryField: /completed_at_date(.*)/,
        replaceWith: 'q_trips',
        key: 'completed_at_date'
      },
      {
        queryField: /vendor_invoice_exists(.*)/,
        key: 'vendor_invoice_exists',
        noPredicate: true,
        valueIndex: 0
      },
      {
        queryField: /work_order_trade_services_id(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_trade_services_id'
      },
      {
        queryField: /work_order_trades_id(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_trades_id'
      },
      {
        queryField: /work_order_work_order_type(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_work_order_type'
      },
      {
        queryField: /work_order_locations_agreements_external_region(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_locations_agreements_external_region'
      },
      {
        queryField: /work_order_extended_status_state(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_extended_status_state'
      },
      {
        queryField: /extended_status_code(.*)/,
        replaceWith: 'q_trips',
        key: 'extended_status_code'
      },
      {
        queryField: /work_order_locations_cc_territory_name(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_locations_cc_territory_name'
      },
      {
        queryField: /storms_start(.*)/,
        replaceWith: 'q_trips',
        key: 'storms_start'
      },
      {
        queryField: /storms_end(.*)/,
        replaceWith: 'q_trips',
        key: 'storms_end'
      },
      {
        queryField: /work_order_created_at(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_created_at'
      },
      {
        queryField: /work_order_follow_up_at(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_follow_up_at'
      },
      {
        queryField: /work_order_work_order_name(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_work_order_name'
      },
      {
        queryField: /work_order_locations_id(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_locations_id'
      }
    ])

    return api.$get(`locations/${locationId}/invoiceable_resources/${resourceType}/${resourceId}/trips`, {
      params: {
        clientId,
        vendorId,
        include,
        q,
        page,
        perPage,
        ...transformed,
        ...dateTransformed(q)
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getBillableResourcesByLocation: ({ locationId, vendorId, include, q, page, perPage, resourceType, includeResourcesCount }: getInvoiceableResourcesParams) => {
    const transformed = transformRansackToEndpointQuery(q, [
      {
        queryField: /completed_at_date(.*)/,
        replaceWith: 'q_trips',
        key: 'completed_at_date'
      },
      {
        queryField: /work_order_company_id(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_company_id'
      },
      {
        queryField: /locations_id(.*)/,
        replaceWith: 'q_trips',
        key: 'locations_id'
      },
      {
        queryField: /work_order_work_order_name(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_work_order_name'
      },
      {
        queryField: /trade_services_id(.*)/,
        replaceWith: 'q_trips',
        key: 'trade_services_id'
      },
      {
        queryField: /trades_id(.*)/,
        replaceWith: 'q_trips',
        key: 'trades_id'
      },
      {
        queryField: /work_order_work_order_type(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_work_order_type'
      },
      {
        queryField: /work_order_vendor_client_agreements_external_region(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_vendor_client_agreements_external_region'
      },
      {
        queryField: /work_order_created_at(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_created_at'
      },
      {
        queryField: /extended_status_code(.*)/,
        replaceWith: 'q_trips',
        key: 'extended_status_code'
      },
      {
        queryField: /completed_at(.*)/,
        replaceWith: 'q_trips',
        key: 'completed_at'
      },
      {
        queryField: /work_order_follow_up_at(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_follow_up_at'
      },
      {
        queryField: /work_order_extended_status_state(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_extended_status_state'
      },
      {
        queryField: /work_order_locations_cc_territory_name(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_locations_cc_territory_name'
      },
      {
        queryField: /work_order_trades_name(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_trades_name'
      }
    ])

    return api.$get(`locations/${locationId}/billable_resources/${resourceType}`, {
      params: {
        vendorId,
        include,
        q,
        page,
        perPage,
        includeResourcesCount,
        ...transformed,
        ...dateTransformed(q)
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getBillableTripsByLocAndResource: ({ locationId, vendorId, include, q, page, perPage, resourceType, resourceId }: getInvoiceableResourcesParams) => {
    const transformed = transformRansackToEndpointQuery(q, [
      {
        queryField: /completed_at_date(.*)/,
        replaceWith: 'q_trips',
        key: 'completed_at_date'
      },
      {
        queryField: /work_order_company_id(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_company_id'
      },
      {
        queryField: /trade_services_id(.*)/,
        replaceWith: 'q_trips',
        key: 'trade_services_id'
      },
      {
        queryField: /trades_id(.*)/,
        replaceWith: 'q_trips',
        key: 'trades_id'
      },
      {
        queryField: /work_order_work_order_type(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_work_order_type'
      },
      {
        queryField: /work_order_vendor_client_agreements_external_region(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_vendor_client_agreements_external_region'
      },
      {
        queryField: /work_order_created_at(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_created_at'
      },
      {
        queryField: /extended_status_code(.*)/,
        replaceWith: 'q_trips',
        key: 'extended_status_code'
      },
      {
        queryField: /completed_at(.*)/,
        replaceWith: 'q_trips',
        key: 'completed_at'
      },
      {
        queryField: /work_order_follow_up_at(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_follow_up_at'
      },
      {
        queryField: /work_order_extended_status_state(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_extended_status_state'
      },
      {
        queryField: /work_order_locations_cc_territory_name(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_locations_cc_territory_name'
      },
      {
        queryField: /work_order_work_order_name(.*)/,
        replaceWith: 'q_trips',
        key: 'work_order_work_order_name'
      }
    ])

    return api.$get(`locations/${locationId}/billable_resources/${resourceType}/${resourceId}/trips`, {
      params: {
        vendorId,
        include,
        q,
        page,
        perPage,
        ...transformed,
        ...dateTransformed(q)
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getMaterialMarkupsByLoc: ({ id, include, q } :getLocationsParams) => api.$get(`/locations/${id}/material_markups`, {
    params: {
      include,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  getClientAndLocContactsById: ({ id, include, q } :getLocationsParams) => api.$get(`locations/${id}/client_and_location_contacts`, {
    params: {
      include,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  activateSiteByID: (siteId: number, action: any) => api.$patch(`/locations/${siteId}/${action}`),
  deactivateSiteByID: (param: any, action: any) => api.$patch(`/locations/${param.siteId}/${action}`, { deactivated_by: param.name }),
  getStates: (params: any) => apis.$get('/states', {
    params: {
      ...params
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  getClientSiteContactById: ({ id, contactType, include, q } :GetClientsLocationsContact) => api.$get(`locations/${id}/default_contacts/${contactType}`, {
    params: {
      include,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  locationsAutoComplete: (params: AutocompletePayload) => {
    const { field, terms, predicate, tab, roles, page, invoiceables, perPage } = params
    return apis.$post('/locations/autocomplete', {
      field,
      terms,
      predicate,
      tab,
      roles,
      page,
      invoiceables,
      perPage
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getLocationContacts: ({ id, include, q, contactUser } :getLocationsParams) => api.$get(`locations/${id}/contacts`, {
    params: {
      include,
      q,
      contactUser
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  getLocationUsers: ({ id, include, q }: getLocationsParams) => api.$get(`locations/${id}/users`, {
    params: {
      include,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  })
}

import { ref, computed, ComputedRef } from 'vue'
import repositories from '@/repositories/index'
import _ from 'lodash'

interface Settings {
  data?: Record<any, any>
}

const settings = ref<Settings>({
  data: {}
})
export interface PDFTemplate {
id?: string,
name?: string,
default?:boolean
}
const pdfTemplateRef = ref<PDFTemplate[]>([{}])

const pdfProposalTemplateRef = ref<PDFTemplate[]>([{}])

export const calcPDFTemplateItems = () => {
  const items: {name: string, id: string, default:boolean}[] = []
  if (!settings.value) return items

  const keys = Object.keys?.(settings?.value?.data?.invoiceSetting?.invoiceTemplates) || []
  keys.forEach((key: string) => {
    if (settings.value?.data?.invoiceSetting?.invoiceTemplates?.[key].available === 'true') {
      items.push({ name: _.startCase(key), id: key, default: !!settings.value?.data?.invoiceSetting?.invoiceTemplates?.[key].default || false })
    }
  })
  return items
}

export const calcProposalPDFTemplateItems = () => {
  const items: {name: string, id: string, default:boolean}[] = []
  if (!settings.value) return items

  const keys = Object.keys?.(settings?.value?.data?.proposalSetting?.proposalTemplates) || []
  keys.forEach((key: string) => {
    if (settings.value?.data?.proposalSetting?.proposalTemplates?.[key].available === 'true') {
      items.push({ name: _.startCase(key), id: key, default: !!settings.value?.data?.proposalSetting?.proposalTemplates?.[key].default || false })
    }
  })
  return items
}

const batchPDFOptions:ComputedRef<PDFTemplate[]> = computed(() => {
  if (!pdfTemplateRef?.value?.length) return []
  else return [...pdfTemplateRef.value.filter((template:PDFTemplate) => template.name === 'Batch')]
})

const nonBatchPDFOptions:ComputedRef<PDFTemplate[]> = computed(() => {
  if (!pdfTemplateRef.value?.length) return []
  else return [...pdfTemplateRef.value.filter((template:PDFTemplate) => template.name !== 'Batch')]
})

const vendorInvoiceCustomNumberRequired = ref<boolean>(false)

const clientInvoiceCustomNumberRequired = ref<boolean>(false)

// Define a ref to hold the result of calcPDFTemplates

export type ValidPDFTemplatesByGroupBy = PDFTemplate[][]

export const useCompanySettings = () => {
  const getCompanySettings = async () => {
    try {
      const res = await repositories.companySettings.getCompanySettings()
      settings.value.data = res.company[0]
      if (settings.value?.data?.invoiceSetting) {
        pdfTemplateRef.value = calcPDFTemplateItems()
        vendorInvoiceCustomNumberRequired.value = !!settings.value?.data?.vendorSetting?.customInvoiceNumberFieldMandatory
      }
      if (settings.value?.data?.clientSetting) {
        clientInvoiceCustomNumberRequired.value = settings.value?.data?.clientSetting?.customInvoiceNumberFieldMandatory
      }
      if (settings.value?.data?.proposalSetting) {
        pdfProposalTemplateRef.value = calcProposalPDFTemplateItems()
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getPaymentTerms = async (params: any) => {
    try {
      const res = await repositories.companySettings.getPaymentTerms(params)
      return {
        data: res.paymentTerms,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  const getVendorSetting = async (params: any) => {
    try {
      const res = await repositories.companySettings.getVendorSetting(params)
      return {
        data: res.settingsVendor
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  const updateVendorSetting = async (vendorSettingObject: any, params: any) => {
    try {
      const res = await repositories.companySettings.updateVendorSetting(vendorSettingObject, params)
      return res
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }
  return {
    settings,
    getCompanySettings,
    calcPDFTemplateItems,
    batchPDFOptions,
    nonBatchPDFOptions,
    pdfTemplateRef,
    vendorInvoiceCustomNumberRequired,
    calcProposalPDFTemplateItems,
    pdfProposalTemplateRef,
    getPaymentTerms,
    clientInvoiceCustomNumberRequired,
    getVendorSetting,
    updateVendorSetting
  }
}

import { api } from '@/api'
import { PreviewTemplateRequestBody, PreviewTemplateRequestBodyForInboundInvoice } from '@/use/preview-templates'

export default {
  getPreviewTemplatesForOutboundInvoices: (id: number) => api.$get(`/invoices/${id}/preview_templates`),
  updateDefaultTemplateForOutboundInvoices: (id: number, requestBody: PreviewTemplateRequestBody) => api.$patch(`/invoices/${id}`, {
    ...requestBody
  }),
  getPreviewTemplatesForInboundInvoices: (id: number) => api.$get(`/inbound_invoices/${id}/preview_templates`),
  updateDefaultTemplateForInboundInvoices: (id: number, requestBody: PreviewTemplateRequestBodyForInboundInvoice) => api.$patch(`/inbound_invoices/${id}`, {
    ...requestBody
  }),
  getPreviewTemplatesForProposals: () => api.$get('/proposals/preview_templates'),
  updateDefaultTemplateForProposals: (id: number, requestBody: PreviewTemplateRequestBody) => api.$patch(`/proposals/${id}`, {
    ...requestBody
  }),
  getPreviewTemplatesForPurchaseOrders: () => api.$get('/purchase_orders/preview_templates'),
  updateDefaultTemplateForPurchaseOrders: (id: number, requestBody: PreviewTemplateRequestBody) => api.$patch(`/purchase_orders/${id}`, {
    ...requestBody
  })
}

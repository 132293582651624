import { apis } from '@/api'
import { params } from '@/utils'
import { baseGetParams } from '@/utils/api'
import qs from 'qs'
import { AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'

interface GetPaymentListParams {
  include?: string
  perPage?: number
  page?: number
  q?: any
}

interface DeletePaymentParams {
  id: number
  q?: any
}

export default {
  get: ({ perPage, page, q, include }: baseGetParams) =>
    apis.$get('/payments', {
      params: {
        include,
        page,
        per_page: perPage,
        q
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    }),
  delete: ({ id, q }: DeletePaymentParams) =>
    apis.$delete(`/payments/${id}`, {
      params: {
        q
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    }),
  receivePaymentMethods: () => apis.$get('/receive_payment_methods'),
  receiveInvoicesPayments: (params: any, requestBody: any) =>
    apis.$post('/payments/receive_invoices_payments', requestBody, {
      params,
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    }),
  paymentsAutoComplete: (params: AutocompletePayload) => {
    const { field, terms, predicate, page, perPage } = params
    return apis.$post('/payments/autocomplete', {
      field,
      terms,
      predicate,
      page,
      perPage
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  paymentsSearch: ({ perPage, page, q, include }: baseGetParams) => {
    return apis.$post('/payments/search', {
      include,
      page,
      per_page: perPage,
      q
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}

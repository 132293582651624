import repositories from '@/repositories'
import { computed, ref } from 'vue'
import { BackgroundTask, NewBackgroundTask } from '@/types/interfaces'
import { startCase, toLower } from 'lodash'

const jobsBeingProcessed = ref<any[]>([])

const watchInterval = ref<number>(1)
interface CustomReportHeadersPayload {
  jobType: string,
  type: string,
  reportType: string,
  customReportType: string
}

const currentErrorReport = ref('')
const bgToastRef = ref<any>(null)

const enqueued = ref<BackgroundTask[]>([])
const inProgress = ref<BackgroundTask[]>([])
const completed = ref<BackgroundTask[]>([])

export const SUCCESS_REPORT_JOB_TYPES = ['work_orders_import']

export const INITIATED = 'initiated'
export const PENDING = 'pending'
export const RUNNING = 'running'
export const COMPLETED = 'completed'
export const CLOSED = 'closed'
export const NOT_RUNNING_STATUSES = [CLOSED, COMPLETED]
export const BULK_ACTIONS_JOB = ['bulk_close_wos', 'bulk_add_note', 'bulk_delete_wos', 'bulk_generate_wo_ins_report', 'bulk_mark_wo_ready_for_billing', 'bulk_update_wo_custom_status', 'bulk_dispatch_trips', 'bulk_work_order_download_trip_report', 'bulk_work_order_report_export']

export enum AllowedSorts {
  CREATED_AT_DESC = 'created_at desc',
  CREATED_AT_ASC = 'created_at asc'
}

export const PER_TASK_DEBOUNCE_MS = 5000

export const useBackgroundTasks = () => {
  const defaultSort = AllowedSorts.CREATED_AT_DESC

  const getBackgroundTasks = async (params: any) => {
    try {
      const res = await repositories.backgroundTasks.get(params)
      return {
        data: res.backgroundTasks,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getBackgroundTask = async (id: string | number, include = '') => {
    try {
      const res = await repositories.backgroundTasks.getById(id, include)
      return {
        data: res
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getErrorReport = async (id: string | number) => {
    try {
      const res = await repositories.backgroundTasks.getErrorReportById(id)
      console.error('error report - ', res)
      return {
        data: res
      }
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const patchBackgroundTask = async (requestBody: any, params: any) => {
    try {
      const res = await repositories.backgroundTasks.patch(requestBody, params)
      return {
        data: res.backgroundTask
      }
    } catch (err: any) {
      console.error(err)
      return { data: false }
    }
  }

  const getCustomReportHeaders = async (body: CustomReportHeadersPayload) => {
    try {
      const res = await repositories.backgroundTasks.getCustomReportHeaders(body)
      return res
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const createBackgroundTask = async (body:FormData | NewBackgroundTask) => {
    try {
      const res = await repositories.backgroundTasks.post(body)
      res.backgroundTask.download = true
      addBackgroundTask(res.backgroundTask)
      return {
        data: res.backgroundTask
      }
    } catch (err: any) {
      console.log(err)
      const errorMsg = err.data?.errors?.base[0]?.error || ''
      return { errorMsg }
    }
  }

  /*
  Watch the list of jobs being processsed (just ids)
  This variable changes when the below code runs OR a new job is created
  If it changes (i.e. a job is added or removed), wipe out the set interval
  If there are still jobs being processed, recreate the set interval
  Every 5 seconds we will fetch all jobs being processed (jobsBeingProcessed ref)
  After fetching the latest job data, check if any are done (logic in-progress)
  If any are done remove id from jbos being processed
  */

  const cleanSetInterval = (override = false) => {
    if (!jobsBeingProcessed.value.length || override) {
      inProgress.value = []
      enqueued.value = []
      completed.value = []
    }
  }

  const addBackgroundTask = async (job: BackgroundTask) => {
    if (typeof job.status === 'string') {
      if (job.status === RUNNING) {
        inProgress.value.unshift(job)
      } else if ([INITIATED, PENDING].includes(job.status)) {
        if (!(enqueued.value.some((bgjob: BackgroundTask) => bgjob.id === job.id))) {
          enqueued.value.unshift(job)
        }
      } else {
        completed.value.unshift(job)
      }
    }
  }

  const getSuccessReport = async (id: string | number) => {
    try {
      const res = await repositories.backgroundTasks.getSuccessReportById(id)
      return {
        data: res
      }
    } catch (err) {
      console.error(err)
      return { data: false }
    }
  }

  const availableSorts = computed(() => {
    const sorts = [] as {label: string, value: string}[]
    Object.keys(AllowedSorts).forEach((key: string) => {
      sorts.push({ label: startCase(toLower(key)), value: key })
    })
    return sorts
  })

  return {
    getBackgroundTasks,
    createBackgroundTask,
    jobsBeingProcessed,
    getBackgroundTask,
    getErrorReport,
    addBackgroundTask,
    watchInterval,
    getCustomReportHeaders,
    currentErrorReport,
    bgToastRef,
    cleanSetInterval,
    enqueued,
    inProgress,
    completed,
    patchBackgroundTask,
    getSuccessReport,
    defaultSort,
    availableSorts
  }
}

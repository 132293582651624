import { api } from '@/api'
import qs from 'qs'
import { AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'

interface getWorkOrdersParams {
  scope?: string
  include?: string
  q?: any
  page?: number
  perPage?: number,
  workOrderId?: number
}

export default {
  get: ({ scope, include, q, page, perPage }: getWorkOrdersParams) => {
    return api.$get('/inbound_work_orders', {
      params: {
        scope,
        include,
        q,
        page,
        perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getTripsById: ({ scope, include, q, page, perPage, workOrderId }: getWorkOrdersParams) => {
    return api.$get(`/inbound_work_orders/${workOrderId}/trips`, {
      params: {
        scope,
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  inboundWorkOrdersAutoComplete: (params: AutocompletePayload) => {
    const { field, terms, predicate, page, invoiceables, perPage, allRecords } = params
    return api.$post('/inbound_work_orders/autocomplete', {
      field,
      terms,
      predicate,
      page,
      invoiceables,
      perPage,
      allRecords
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}

import { apis } from '@/api'
import qs from 'qs'

interface Params {
  include: string
  q: any
  page?: number
  perPage?: number
  isWithoutCount?: boolean
  search?: string
  sort?: string
}

export default {
  get: (params: Params, active: boolean) => {
    const { include, q, page, perPage } = params
    return apis.$get('/chat/conversations/', {
      params: {
        include,
        q,
        page,
        per_page: perPage,
        active
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  getById: (params: Params, id: number) => {
    const { include, q, page, perPage } = params
    return apis.$get(`/chat/conversations/${id}`, {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  getAllContacts: (params: Params) => {
    const { include, q, page, perPage, isWithoutCount, search } = params
    return apis.$get('/chat/members', {
      params: {
        include,
        q,
        page,
        per_page: perPage,
        without_count: isWithoutCount,
        search
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  post: (userIds: number[]) => {
    return apis.$post('/chat/conversations', { chat_conversation: { user_ids: userIds } })
  },
  getChatMessages: (params: Params, chatId: number) => {
    const { include, q, page, perPage, sort } = params
    return apis.$get(`/chat/conversations/${chatId}/messages`, {
      params: {
        include,
        q,
        page,
        per_page: perPage,
        sort
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  postReply: (messageObj: {msg: string, chatId: number, typing: boolean}) => {
    return apis.$post(`/chat/conversations/${messageObj.chatId}/reply`, { chat_conversation: { msg: messageObj.msg, chat_id: messageObj.chatId, typing: messageObj.typing } })
  },
  close: (chatId: number) => {
    return apis.$post('/chat/close', { conversation_id: chatId })
  },
  minimize: (chatId: number) => {
    return apis.$post('/chat/minimize', { conversation_id: chatId })
  },
  maximize: (chatId: number) => {
    return apis.$post('/chat/maximize', { conversation_id: chatId })
  }
}

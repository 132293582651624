import { useOutboundPurchaseOrders } from '@/use/purchase-orders/outbound'
import { useBackgroundTasks } from '@/use/background-tasks'
import { SUCCESS, ERROR, REJECTED_ALERT, DRAFT, SEND, RECEIVED, DATE_EXPIRED, CLOSE, PDF_DOWNLOAD, HELP, ICON_PACKAGE_VARIANT_CLOSED, SHARE, PRINT, MAIL_BOX } from '@/constants/icons'
import { PO_RESOURCE_TYPE } from '@/constants/resource-types'
import { outboundActionData } from '@/constants/actions-by-resource/purchase-orders/outbound'
import { inboundActionData } from '@/constants/actions-by-resource/purchase-orders/inbound'
import { useSession } from '@/use/session'
import { useUsersCompanies } from '@/use/users-companies'
import {
  useTransactionResources
} from '@/use/transactional-resources'
import { BackgroundTaskSources, BackgroundTaskFormats, BackgroundTaskReportTypes, BackgroundTaskZipFileTypes, DetailsPanelButtonState } from '@/types/enums'
import { MenuAction, ResourceAction } from '@/types/interfaces'
import { cloneDeep, snakeCase } from 'lodash'
import { DirectionalResourceAction } from '@/types/interfaces/global/resource-actions'
import { useListRow } from '../list-rows'
import { useApp } from '../app'
import { CLIENT, VENDOR } from '@/constants/permissions'
import { useToasts } from '../toasts'
import { PurchaseOrder } from '@/types/interfaces/api-v2/purchase-order'
import { findCompanyType } from '@/utils/company'
import { ref } from 'vue'
import { useDetailsPanel } from '../details-panel'
import { useInboundPurchaseOrders } from './inbound'

const PURCHASE_ORDER_STATUSES = ['unsaved', 'sent', 'draft', 'rejected', 'accepted', 'closed']
const SAVED_PURCHASE_ORDER_STATUSES = PURCHASE_ORDER_STATUSES.filter((status: string) => status !== 'unsaved')

const MODAL_CONTENT_NOTE_COMPONENT = 'uc/resource/modal-content/note'

const statusTransformationMap = (outbound = true) => {
  return {
    accepted: { text: 'Approved', state: 'success', prependIcon: SUCCESS },
    rejected_closed: { text: 'Rejected (Closed)', state: 'error', prependIcon: REJECTED_ALERT },
    sent: { text: outbound ? 'Sent' : 'Received', state: 'warning', prependIcon: outbound ? SEND : RECEIVED },
    rejected: { text: 'Rejected', state: 'error', prependIcon: REJECTED_ALERT },
    expired: { text: 'Expired', state: 'error', prependIcon: DATE_EXPIRED },
    draft: { text: 'Draft', state: 'secondary-base', prependIcon: DRAFT },
    closed: { text: 'Closed', state: 'error', prependIcon: CLOSE },
    unsaved: { text: 'Unsaved', state: 'warning', prependIcon: '' },
    received: { text: 'Received', state: 'warning', prependIcon: RECEIVED },
    open: { text: 'Open', state: 'secondary-base', prependIcon: DRAFT }
  } as any
}

const universalPurchaseOrderActions = [
  {
    name: 'Print',
    primaryAction: {
      outbound: [
        'closed'
      ],
      inbound: [
        'closed'
      ]
    },
    validStatuses: {
      outbound: SAVED_PURCHASE_ORDER_STATUSES,
      inbound: SAVED_PURCHASE_ORDER_STATUSES
    },
    detailsPanel: DetailsPanelButtonState.Icon,
    buttonIcon: PRINT
  },
  {
    name: 'View',
    primaryAction: {
      outbound: [],
      inbound: []
    },
    validStatuses: {
      outbound: SAVED_PURCHASE_ORDER_STATUSES,
      inbound: SAVED_PURCHASE_ORDER_STATUSES
    },
    detailsPanel: DetailsPanelButtonState.Hide
  },
  {
    name: 'Share',
    primaryAction: {
      outbound: [],
      inbound: []
    },
    validStatuses: {
      outbound: SAVED_PURCHASE_ORDER_STATUSES,
      inbound: SAVED_PURCHASE_ORDER_STATUSES
    },
    detailsPanel: DetailsPanelButtonState.Icon,
    buttonIcon: SHARE
  },
  {
    name: 'Download',
    primaryAction: {
      outbound: [
      ],
      inbound: []
    },
    validStatuses: {
      outbound: SAVED_PURCHASE_ORDER_STATUSES,
      inbound: SAVED_PURCHASE_ORDER_STATUSES
    },
    detailsPanel: DetailsPanelButtonState.Icon,
    buttonIcon: PDF_DOWNLOAD
  }
] as DirectionalResourceAction[]

const purchaseOrderMenuActionsMap = {
  outbound: {},
  inbound: {}
} as Record<string, Record<string, MenuAction[]>>

export const PURCHASE_ORDER_WORK_ORDER_ACTIONS = {
  edit: {
    value: 'purchase-order-edit',
    requiresConfirmation: false,
    actionFn: (proposalIds: (string | number)[]) => {
      const proposalId = proposalIds[0]
      // functionality to be added later
      console.log('Edit action for purchase order', proposalId)
    }
  },
  generateInvoice: {
    value: 'purchase-order-generate-invoice',
    requiresConfirmation: false,
    actionFn: (proposalIds: (string | number)[]) => {
      const proposalId = proposalIds[0]
      // functionality to be added later
      console.log('Generate invoice action for purchase order', proposalId)
    }
  },
  approve: {
    value: 'purchase-order-approve',
    requiresConfirmation: false,
    actionFn: (proposalIds: (string | number)[]) => {
      const proposalId = proposalIds[0]
      // functionality to be added later
      console.log('Approve action for purchase order', proposalId)
    }
  }
}

export const usePurchaseOrders = () => {
  const purchaseOrdersTable = ref<any>(null)
  const { companyType } = useApp()
  const {
    sendResources,
    configureDetailsPanel,
    sharedBasicActions
  } = useTransactionResources()
  const { addToast } = useToasts()
  const { createBackgroundTask } = useBackgroundTasks()
  const { createSubdomainURL } = useSession()
  const { currentUsersCompany } = useUsersCompanies()

  const purchaseOrderActionModalProps = (outbound = true) => {
    const actionData = outbound ? outboundActionData : inboundActionData
    return {
      delete: {
        dynamicContentProps: {
          allowedStatuses: actionData.find((action: ResourceAction) => action.name === 'Delete')?.validStatuses || [],
          resultingStatus: '',
          numberIdKey: 'poNumber'
        },
        modalCardProps: {
          headerText: 'Delete',
          headerIcon: 'delete',
          size: 'md'
        },
        modalContentComponent: null,
        confirmButtonProps: {
          text: 'DELETE',
          state: 'error'
        }
      },
      close: {
        dynamicContentProps: {
          allowedStatuses: actionData.find((action: ResourceAction) => action.name === 'Close')?.validStatuses || [],
          resultingStatus: '',
          numberIdKey: 'poNumber'
        },
        modalCardProps: {
          headerText: 'Close',
          headerIcon: CLOSE.split('mdi-')[1],
          size: 'md'
        },
        modalContentComponent: MODAL_CONTENT_NOTE_COMPONENT,
        confirmButtonProps: {
          text: 'CLOSE',
          state: 'error',
          disabled: false
        }
      },
      reject: {
        dynamicContentProps: {
          allowedStatuses: actionData.find((action: ResourceAction) => action.name === 'Reject')?.validStatuses || [],
          numberIdKey: 'poNumber'
        },
        modalCardProps: {
          headerText: 'Reject Purchase Order',
          headerIcon: REJECTED_ALERT.split('mdi-')[1],
          size: 'md'
        },
        modalContentComponent: MODAL_CONTENT_NOTE_COMPONENT,
        confirmButtonProps: {
          text: 'REJECT',
          state: 'error',
          disabled: false
        }
      },
      approve: {
        dynamicContentProps: {
          allowedStatuses: actionData.find((action: ResourceAction) => action.name === 'Approve')?.validStatuses || [],
          numberIdKey: 'poNumber'
        },
        modalCardProps: {
          headerText: 'Approve Purchase Order',
          headerIcon: SUCCESS.split('mdi-')[1],
          size: 'md'
        },
        modalContentComponent: MODAL_CONTENT_NOTE_COMPONENT,
        modalContentComponentProps: {
          isApprove: true
        },
        confirmButtonProps: {
          text: 'APPROVE',
          state: 'success',
          disabled: false
        }
      },
      evaluate: {
        dynamicContentProps: {
          allowedStatuses: actionData.find((action: any) => action.name === 'Evaluate')?.validStatuses,
          numberIdKey: 'poNumber'
        },
        modalCardProps: {
          headerText: 'Evaluate Purchase Order',
          headerIcon: HELP.split('mdi-')[1],
          size: 'md'
        },
        modalContentComponent: MODAL_CONTENT_NOTE_COMPONENT,
        confirmButtonProps: {
          text: 'EVALUATE',
          state: 'primary',
          disabled: false
        }
      },
      assignDepartment: {
        dynamicContentProps: {
          allowedStatuses: outboundActionData.find((action: any) => action.name === 'Assign Department').validStatuses,
          resultingStatus: '',
          numberIdKey: 'poNumber'
        },
        modalCardProps: {
          headerText: 'Assign Department',
          headerIcon: '',
          size: 'md'
        },
        modalContentComponent: 'uc/resource/modal-content/assign-department',
        confirmButtonProps: {
          text: 'ASSIGN DEPARTMENT',
          state: 'primary'
        }
      }
    }
  }

  const generatePurchaseOrderMenuActions = (purchaseOrder: PurchaseOrder, isDetailsPanel = false) => {
    const { generateMenuActions } = useListRow()
    const filteredUniversalActions = universalPurchaseOrderActions.map((action:any) => {
      if (companyType.value === CLIENT && action.name === 'View') {
        action.primaryAction.outbound = ['accepted', 'accepted_wo']
        action.primaryAction.inbound = ['accepted', 'accepted_wo']
      }
      return action
    })
    let outboundActionDataCopy = cloneDeep(outboundActionData)
    if (companyType.value === VENDOR) {
      const actionsToRemove = ['Reject', 'Approve', 'Evaluate']
      outboundActionDataCopy = outboundActionDataCopy.filter(item => !actionsToRemove.includes(item.name))
    }
    purchaseOrderMenuActionsMap.outbound = generateMenuActions(PURCHASE_ORDER_STATUSES, purchaseOrderMenuActionsMap.outbound, filteredUniversalActions, outboundActionDataCopy, 'outbound', isDetailsPanel, findCompanyType(purchaseOrder?.company?.roles || []))
    purchaseOrderMenuActionsMap.inbound = generateMenuActions(PURCHASE_ORDER_STATUSES, purchaseOrderMenuActionsMap.inbound, filteredUniversalActions, inboundActionData, 'inbound', isDetailsPanel, null)
  }

  const purchaseOrderActionListenersAndFunctions: any = {
    review: (
      purchaseOrders: any[],
      { toggleFullScreenView, setSelectedResources }:any
    ) => {
      configureDetailsPanel(purchaseOrders, toggleFullScreenView, setSelectedResources)
    },
    send: (purchaseOrders: any[], { hidePanel }: any) => {
      !purchaseOrders[0].props ? sendResources(purchaseOrders, PO_RESOURCE_TYPE) : sendResources(purchaseOrders.map((purchaseOrder: any) => purchaseOrder.props.purchaseOrder), PO_RESOURCE_TYPE)
      hidePanel()
    },
    edit: (purchaseOrders: any, { emit, rowProps }:any) => {
      if (emit) {
        emit('edit')
      } else {
        const { openGlobalDetailsPanel } = useDetailsPanel()
        let actions
        if (rowProps.outbound) {
          const { basicPurchaseOrderOutboundActions, purchaseOrderOutboundModalRequiredActions } = useOutboundPurchaseOrders()
          actions = [
            // Deleting bulkConfig for hiding bulk action for the purchase order
            ...basicPurchaseOrderOutboundActions().map((item: any) => {
              const temp = Object.assign({}, item)
              delete temp.bulkConfig
              return temp
            }),
            ...purchaseOrderOutboundModalRequiredActions
          ]
        } else {
          const { basicPurchaseOrderInboundActions, purchaseOrderInboundModalRequiredActions } = useInboundPurchaseOrders()
          actions = [
            // Deleting bulkConfig for hiding bulk action for the purchase order
            ...basicPurchaseOrderInboundActions.map((action: any) => {
              const temp = Object.assign({}, action)
              delete temp.bulkConfig
              return temp
            }),
            ...purchaseOrderInboundModalRequiredActions
          ]
        }
        openGlobalDetailsPanel('uc/resource-details', {
          actions,
          selectedResources: purchaseOrders,
          resourceType: PO_RESOURCE_TYPE,
          canDoubleClickToEdit: false,
          outbound: rowProps.outbound
        }, '', { }, { })
      }
    },
    print: async (
      purchaseOrders: any[],
      additionalParams: any,
      outbound = true
    ) => {
      if (purchaseOrders.length === 1) {
        additionalParams.pdfViewerRef.setLoading(true)
        const { getPDFLinkById } = useOutboundPurchaseOrders()
        const url = await getPDFLinkById(purchaseOrders[0].id, outbound)
        additionalParams.pdfViewerRef.setLoading(false)
        if (!url) {
          addToast({ timeout: 3000, color: 'error', message: 'Failed to print PDF', prependIcon: ERROR })
          additionalParams.pdfViewerRef.setLoading(false)
          return
        }
        additionalParams.pdfViewerRef.show(url)
      } else if (purchaseOrders.length > 1) {
        const defaultQ = additionalParams?.exportQuery()
        const handleQuery = () => {
          const idPKey = additionalParams.bulkSelectionData.isBulkExclusive ? 'id_not_in' : 'id_in'
          const q = {
            ...defaultQ,
            [idPKey]: additionalParams.trackedResourceIds
          }
          return q
        }
        await createBackgroundTask({
          jobType: 'purchase_orders_export',
          source: BackgroundTaskSources.QUERY,
          query: JSON.stringify(handleQuery()),
          format: BackgroundTaskFormats.PDF,
          reportType: outbound ? BackgroundTaskReportTypes.OUTBOUND : BackgroundTaskReportTypes.INBOUND,
          zipFileType: BackgroundTaskZipFileTypes.MULTIPLE_PDF
        })
        addToast({ timeout: 3000, color: 'primary', message: 'Your PDFs are being processed', prependIcon: PDF_DOWNLOAD })
      }
    },
    download: async (
      purchaseOrders: any[],
      additionalParams: any,
      outbound = true
    ) => {
      // we can generate PDF for a single purchase order at a time
      if (purchaseOrders.length === 1) {
        additionalParams.pdfViewerRef.setLoading(true)
        const { getPDFLinkById } = useOutboundPurchaseOrders()
        const url = await getPDFLinkById(purchaseOrders[0].id, outbound)
        additionalParams.pdfViewerRef.setLoading(false)
        if (!url) {
          addToast({ timeout: 3000, color: 'error', message: 'Failed to download PDF', prependIcon: ERROR })
          additionalParams.pdfViewerRef.setLoading(false)
          return
        }
        const blob = new Blob([new Uint8Array(url)], {
          type: 'application/pdf'
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${purchaseOrders[0].poNumber}_${outbound ? 'purchase_orders' : 'inbound_purchase_orders'}.pdf`
        link.click()

        addToast({
          timeout: 3000,
          color: 'primary',
          message: 'Your PDF is being downloaded',
          prependIcon: PDF_DOWNLOAD
        })
      }
    },
    share: (
      purchaseOrders: any[],
      additionalParams: any,
      outbound = true
    ) => {
      const url = createSubdomainURL(currentUsersCompany.value.subdomain, `/purchase-orders/${purchaseOrders[0].id}?${outbound ? 'outbound=true' : 'outbound=false'}`)
      navigator.clipboard.writeText(url).then(function () {
        addToast({
          message: 'Link copied to clipboard',
          timeout: 5000,
          prependIcon: 'mdi-share-variant',
          color: 'primary'
        })
      })
    }
  }

  const shared = (outbound = true) => {
    return [
      ...sharedBasicActions(purchaseOrderActionListenersAndFunctions, outbound).filter((action:any) => action.value !== 'export'),
      {
        value: 'download',
        bulkConfig: {
          type: 'icon',
          icon: PDF_DOWNLOAD,
          text: 'Download PDF'
        },
        actionFn: (purchaseOrders: any[], additionalParams: any) => {
          purchaseOrderActionListenersAndFunctions.download(
            purchaseOrders,
            additionalParams,
            outbound || additionalParams?.rowProps?.outbound || !!additionalParams.detailsPanelComponentProps?.outbound

          )
        },
        noSuccessEmit: true
      },
      {
        value: 'edit',
        updateSelectedResource: true,
        bulkConfig: {
        },
        actionFn: (purchaseOrders: any[], additionalParams: any) => {
          purchaseOrderActionListenersAndFunctions.edit(
            purchaseOrders,
            additionalParams,
            outbound || additionalParams?.rowProps?.outbound || !!additionalParams.detailsPanelComponentProps?.outbound
          )
        },
        noSuccessEmit: true
      },
      {
        value: 'skip'
      }
    ]
  }

  const genPurchaseOrderChipProps = ({ purchaseOrder, outbound = true }: { purchaseOrder: any, outbound: boolean | undefined }) => {
    const appendedText = ''
    const { status } = purchaseOrder
    const statusMap = statusTransformationMap(outbound)
    let { text, state, prependIcon } = statusMap[snakeCase(status)]

    if (status === 'accepted') {
      state = 'success'
      prependIcon = SUCCESS
    } else if (status === 'rejected') {
      state = 'error'
      prependIcon = REJECTED_ALERT
    } else if (
      status === 'sent'
    ) {
      state = 'warning'
      prependIcon = SEND
    } else if (
      status === 'draft' || status === 'open'
    ) {
      state = 'secondary-base'
      prependIcon = DRAFT
    } else if (
      status === 'closed'
    ) {
      state = 'layer-secondary'
      prependIcon = ICON_PACKAGE_VARIANT_CLOSED
    }

    return {
      appendedText,
      state,
      prependIcon,
      text
    }
  }
  const allOutboundPurchaseOrderActions = () => {
    const {
      purchaseOrderOutboundModalRequiredActions,
      basicPurchaseOrderOutboundActions
    } = useOutboundPurchaseOrders()
    return [
      ...basicPurchaseOrderOutboundActions().map((item: any) => {
        const temp = Object.assign({}, item)
        delete temp.bulkConfig
        return temp
      }),
      ...purchaseOrderOutboundModalRequiredActions
    ]
  }
  return {
    sharedBasicActions,
    generatePurchaseOrderMenuActions,
    purchaseOrderMenuActionsMap,
    purchaseOrderActionModalProps,
    purchaseOrderActionListenersAndFunctions,
    genPurchaseOrderChipProps,
    SAVED_PURCHASE_ORDER_STATUSES,
    shared,
    allOutboundPurchaseOrderActions,
    purchaseOrdersTable
  }
}

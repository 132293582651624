import { DetailsPanelButtonState } from '@/types/enums'

export const inboundActionData: any[] = [
  {
    name: 'Review',
    validStatuses: [
      'sent', 'approval_requested'
    ],
    primaryAction: [
      'sent', 'approval_requested'
    ],
    detailsPanel: DetailsPanelButtonState.Hide
  },
  {
    name: 'Approve',
    textColor: 'success',
    validStatuses: [
      'sent', 'approval_requested'
    ],
    primaryAction: [
    ],
    detailsPanel: DetailsPanelButtonState.Expose
  },
  {
    name: 'Pay',
    validStatuses: [
      'approved',
      'partially_paid'
    ],
    primaryAction: [
      'approved',
      'partially_paid'
    ],
    detailsPanel: DetailsPanelButtonState.Expose
  },
  {
    name: 'Forward to Client',
    textColor: 'success',
    validStatuses: [
      'approved',
      'partially_paid',
      'paid'
    ],
    primaryAction: [
    ]
  },
  {
    name: 'Reject',
    textColor: 'error',
    validStatuses: [
      'sent', 'approval_requested'
    ],
    primaryAction: [
    ],
    detailsPanel: DetailsPanelButtonState.Expose
  },
  {
    name: 'Edit',
    validStatuses: [
      'sent', 'approval_requested', 'approved', 'rejected'
    ],
    primaryAction: []
  },
  {
    name: 'Save',
    validStatuses: ['sent', 'approval_requested', 'approved'],
    primaryAction: [],
    detailsPanel: DetailsPanelButtonState.Only
  },
  {
    name: 'Save And Approve',
    validStatuses: ['unsaved'],
    primaryAction: []
  },
  {
    name: 'Save And Receive',
    validStatuses: ['unsaved', 'rejected'],
    primaryAction: []
  },
  {
    name: 'Skip',
    validStatuses: true,
    primaryAction: [
      'Skip'
    ],
    textColor: 'error',
    detailsPanel: DetailsPanelButtonState.Only
  }
]

export const actionsToShowNonCompliantVendorAlertFor: string[] = ['approve']

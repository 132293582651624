import { api, apis } from '@/api'
import { InvoiceParams, baseGetParams } from '@/utils/api'
import qs from 'qs'
import { AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'

interface GetPurchaseOrderListParams {
  perPage: number
  page: number
  q?: any
  include?: any
  withNonExistingClients?: boolean
}

interface PurchaseOrderUpdateParams {
  purchaseOrderId: string,
  include?: string,
  updateContent: any
}

export default {
  get: ({ perPage, page, q, include, withNonExistingClients = false }: GetPurchaseOrderListParams) => api.$get('/purchase_orders', {
    params: {
      include,
      page,
      per_page: perPage,
      q,
      with_non_existing_clients: withNonExistingClients
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  post: (params: any, requestBody: any) => {
    return api.$post('/purchase_orders', {
      ...requestBody
    }, {
      params,
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  getPreviewPdfById: (purchaseOrderId:string) => {
    return api.$getBuffer(`/purchase_orders/${purchaseOrderId}/preview_pdf`, {
      params: {
        download: true
      },
      responseType: 'arraybuffer',
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  getPurchaseOrderById: (id: number, { include }: baseGetParams) => api.$get(`/purchase_orders/${id}`, {
    params: {
      include
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  updatePurchaseOrderById: (id: number, updatedPurchaseOrder: any) => {
    return api.$patch(`/purchase_orders/${id}`, {
      include: 'vendor,department,purchase_order_assignment',
      params: {
        id
      },
      purchaseOrder: updatedPurchaseOrder
    })
  },

  // Newly added Functions
  getPreviewPdfByResource: (purchaseOrderId: string | number, resourceType: string) => {
    return api.$getBuffer(`/${resourceType}/${purchaseOrderId}/preview_pdf`, {
      params: {
        download: true
      },
      responseType: 'arraybuffer',
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  postSendEmail: async (body: any) => {
    const res = await apis.$post(`/purchase_orders/${body.purchase_order_email.ids[0]}/send_email`, body, {})
    return res
  },
  delete: (purchaseOrderId: string) => api.$delete(`/purchase_orders/${purchaseOrderId}`),
  bulkDelete: async (ids: any) => {
    const res = await api.$delete('/purchase_orders/bulk_destroy', {
      params: {
        ids
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
    return res
  },
  update: ({ purchaseOrderId, updateContent, include }:PurchaseOrderUpdateParams) => {
    return api.$patch(`/purchase_orders/${purchaseOrderId}`, { purchaseOrder: updateContent }, {
      params: {
        include
      },
      paramsSerializer: (params:any) => {
        return qs.stringify(params)
      }
    })
  },
  updateStatus: async (purchaseOrderId: number, updatedStatus: string) => {
    const payload = {
      purchase_order: {
        status: updatedStatus
      }
    }
    return await api.$patch(`/purchase_orders/${purchaseOrderId}/update_status`, payload)
  },
  getPOPrefix: ({ perPage, page, q, include }: GetPurchaseOrderListParams) => api.$get('/po_prefixes', {
    params: {
      include,
      page,
      per_page: perPage,
      q
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  postPOPrefix: (params: any, requestBody: any) => {
    return api.$post('/po_prefixes', {
      ...requestBody
    }, {
      params,
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  purchaseOrdersAutoComplete: (params: AutocompletePayload) => {
    const { field, terms, predicate, page, finderParams, perPage } = params
    return api.$post('/purchase_orders/autocomplete', {
      field,
      terms,
      predicate,
      page,
      finderParams,
      perPage
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  purchaseOrdersSearch: ({ perPage, page, q, include }: GetPurchaseOrderListParams) => {
    return api.$post('/purchase_orders/search', {
      include,
      page,
      per_page: perPage,
      q
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getAnnexedPurchaseOrderStatuses: () => apis.$get('/annexed_purchase_orders/statuses', {}),
  purchaseOrderStatuses: (as = 'both') => apis.$get('/purchase_orders/statuses', { params: { as } })

}

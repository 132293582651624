import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('uc-input', _vm._b({
    ref: "numberInputRef",
    attrs: {
      "decimals": _vm.decimal,
      "readonly": _vm.readonly,
      "disabled": _vm.disabled,
      "tab-index": _vm.localTabIndex,
      "read-mode-empty-state": _vm.readModeEmptyState,
      "clearable": _vm.clearable
    },
    on: {
      "blur": function ($event) {
        return _vm.formatNumbers($event);
      },
      "click": function ($event) {
        return _vm.$emit('click');
      },
      "focus": function ($event) {
        return _vm.$emit('focus');
      }
    },
    scopedSlots: _vm._u([_vm.showControls ? {
      key: "append",
      fn: function () {
        return [_c('div', {
          class: `${!_vm.$vuetify.theme.dark && 'light'} number-stepper d-flex flex-column align-center justify-center ml-1`
        }, [_c('div', {
          staticClass: "d-flex align-center justify-center"
        }, [_c(VIcon, {
          attrs: {
            "size": 14,
            "data-cy": "click-upward"
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              _vm.formatNumbers(_vm.$refs.numberInputRef.value, _vm.$refs.numberInputRef.increment, 1);
              _vm.$emit('click:upward');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.DRAWER_OPENED) + " ")])], 1), _c(VDivider, {
          staticClass: "divider"
        }), _c('div', {
          staticClass: "d-flex align-center justify-center"
        }, [_c(VIcon, {
          attrs: {
            "size": 14,
            "data-cy": "click-downward"
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              _vm.formatNumbers(_vm.$refs.numberInputRef.value, _vm.$refs.numberInputRef.increment, -1);
              _vm.$emit('click:downward');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.DRAWER_CLOSED) + " ")])], 1)], 1)];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  }, 'uc-input', {
    ..._vm.$attrs,
    type: 'number'
  }, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };
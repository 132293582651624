import moment from 'moment-timezone'
import { INVOICE_RESOURCE_TYPE, PO_RESOURCE_TYPE, PROPOSAL_RESOURCE_TYPE } from '@/constants/resource-types'
import { uniqBy, cloneDeep, snakeCase, intersectionWith } from 'lodash'
import { useClients } from '@/use/clients'
import { useCompanies } from '@/use/companies'
import { useLocations } from '@/use/locations'
import { useTaxes } from '@/use/taxes'
import { useCompanySettings } from '@/use/company-settings'
import { VENDOR, BROKER, CLIENT, companyRoles } from '@/constants/permissions'
import { useApp } from '@/use/app'
import { useSession } from '@/use/session'
import { CLIENT_INCLUDE } from '@/constants/include'
import { useInvoices } from '@/use/invoices'
import { NewLocation, NewAddress, NewInvoice, NewProposal, NewWorkOrder, StormDetail, NewDerivedItem, NewItem, NewMeasureUnit, WorkOrder, Location, Invoice, NewPurchaseOrder, NewBatchInvoice, InvoiceResource } from '@/types/interfaces'
import { useLineItems } from '@/use/line-items'
import { MarkupTypes } from '@/types/enums/markup-types'

// this file supports dynamic resource handling functionality of the uc-resource-details component.
export const tempResourceMap:any = {
  invoice: {
    id: null,
    attachments: [],
    invoiceItems: [],
    invoiceResources: [],
    toEmails: [''],
    emailBcc: '',
    emailCc: '',
    payableBalance: 0,
    companyId: null,
    company: {},
    statusTxt: 'Unsaved',
    status: 'unsaved',
    dateOfIssue: moment().format('YYYY-MM-DD'),
    dueDate: moment().format('YYYY-MM-DD'),
    terms: '',
    total: 0,
    discount: 0,
    customInvoiceNumber: '',
    billingAddress: '',
    statementMemo: '',
    locations: [{}],
    workOrders: [{}],
    weatherEvents: [{}],
    vendor: { id: null },
    vendorId: null,
    trips: [],
    department: {},
    pdfTemplate: '',
    version: 'current',
    clientContact: { id: null, fullName: '' },
    invoiceClient: false,
    requiredTerms: true,
    taxExempt: false,
    taxExemptLabel: 'Tax Exempt',
    customIdRequired: false
  } as any,
  proposal: {
    id: null,
    proposalItems: [],
    proposalNotes: [],
    proposalDocuments: [],
    clientSignature: true,
    disableLiPriceOnPdf: false,
    termsAndConditions: false,
    emailTo: '',
    emailBcc: '',
    emailCc: '',
    companyId: null,
    companyName: '',
    company: {},

    status: 'unsaved',
    dateOfIssue: moment().format('YYYY-MM-DD'),
    proposalDate: moment().format('YYYY-MM-DD'),
    terms: '',
    total: 0,
    discount: 0,
    discountPercentage: 0,
    discountEnabled: false,
    salesTax: 0,
    customProposalName: '',
    annexedPoNumber: '',
    estimateNumber: '',
    proposalNumber: '',
    billingAddress: '',
    statementMemo: '',
    locationId: '',
    locationName: '',
    workOrderId: null,
    vendorId: null,
    invoiceId: null,
    locations: [{}],
    workOrders: [{}],
    vendor: { id: null },
    trips: [],
    contactUser: { id: null, fullName: '' },
    clientContact: { id: null, fullName: '' }
  },
  'purchase-order': {
    addressId: null,
    addressType: '',
    amount: 0,
    billingAddress: '',
    company: {},
    companyId: null,
    customPoNumber: '',
    dateOfIssue: moment().format('YYYY-MM-DD'),
    deliveryLocation: '',
    deliveryClient: null,
    deliveryClientId: null,
    servicedClient: null,
    serviceLocation: null,
    discount: 0,
    emailTo: '',
    emailBcc: '',
    emailCc: '',
    eta: moment().format('YYYY-MM-DD'),
    headquarterAddress: '',
    id: null,
    message: null,
    locations: [{}],
    poNumber: '',
    prefix: '',
    purchaseOrderAssignment: null,
    purchaseOrderEmails: '',
    purchaseOrderItems: [],
    siteAddress: '',
    statementMemo: '',
    status: 'unsaved',
    taxExempt: false,
    taxExemptLabel: 'Tax Exempt',
    trips: [],
    tripIds: [],
    terms: '',
    total: 0,
    vendor: { id: null },
    vendorId: null,
    workOrder: {},
    workOrderId: null
  }
}

export interface ResourceItemTaxes {
  id?: null | number
  name?: string
  tax?: number
  amount?: number
}

interface invoiceItemPoMaterials {
  id?: null | number
  resresourceType?: string
  resourceId?: number
  invoiceItemType?: string
}
export interface VueResourceLineItem {
  id?: null | number
  type: string
  quantity: number
  price: number
  originalPrice: number
  tax: number
  baseTax: number
  description: string
  markup?: number
  multiplier?: number
  resourceType: string
  resourceId: number | null | string
  tripIds: number[]
  location: string | number
  teamMembersCount: number
  measureUnit: NewMeasureUnit
  derivedItem: NewDerivedItem | null
  derivedItemId: number | null
  itemId: number | null
  itemTypeId: number | null
  itemTypeName: string
  itemName: string
  item: NewItem | null | { id: null | number, itemType: null, name?: string}
  _destroy?: number
  transformed: true
  selectedTrip?: { id: number}
  markedUp?: boolean
  position: number
  lineItemTaxes?: ResourceItemTaxes[]
  invoiceItemTaxes?: ResourceItemTaxes[]
  proposalItemTaxes?: ResourceItemTaxes[]
  purchaseOrderItemTaxes?: ResourceItemTaxes[]
  invoiceItemPoMaterials?: invoiceItemPoMaterials[]
}

type VueLocationResources = NewWorkOrder | StormDetail // union type meaning it can be either one or other. interfaces do not have this functionality/property

interface VueNestedResourceAdditionalProperties {
  expanded: boolean
  nteColor: string
  formattedNte: number | null
  resourceType: string
  rowType: string
  tripIds: number[],
  lineItems: VueResourceLineItem[]
}

export type ExtendedVueLocationResources = VueLocationResources & VueNestedResourceAdditionalProperties

export interface NestedBatchLineItemsByLocation extends NewLocation {
  expanded: boolean
  nteColor: string
  nteExceed: number
  resources: ExtendedVueLocationResources[]
}

const { settings } = useCompanySettings()

export const extractAndMapTripsFromResourceId = async (resourceId: number, fetchFn:any, params:any, givenTripIds:any = []) => {
  if (resourceId && givenTripIds.length) {
    try {
      const res = await fetchFn(params)
      if (res.data && res.data?.trips?.length) {
        return givenTripIds.map((trip:number) => { return { id: trip, position: res.data.trips.find((t: {id: number}) => t.id === trip).position } })
      }
    } catch (err) {
      console.error(err, 'resourceId or givenTripIds are invalid params')
    }
  } else {
    return givenTripIds
  }
}

export const getResourceClientOrVendorId = (resource:NewInvoice|NewProposal|NewPurchaseOrder|NewBatchInvoice, outbound:boolean, subject:string) => {
  const { session } = useSession()
  if (subject === 'client') {
    if (outbound) {
      // if we dont have a client id, return -1 (control for parent functions)
      return resource?.company?.id || resource?.companyId || -1
    } else if (!outbound && !resource?.id) {
      return session?.loggedUser?.company_id
    } else return resource?.companyId
  } else {
    if (outbound && !resource?.id) {
      return session?.loggedUser?.company_id
    } else {
    // if we dont have a vendor id chosen yet, return -1
      return resource?.vendor?.id || resource?.vendorId || -1
    }
  }
}

export const findLocationSpecificMaterialMarkups = async (locationId:string|number, outbound:boolean, resource:NewInvoice|NewProposal|NewPurchaseOrder) => {
  const { companyType } = useApp()
  const receiverRole = findResourceReceiverRoleByCompany(resource, outbound)
  if (locationId === -1 || companyType.value !== BROKER || (!outbound && resource.id) || (companyType.value === BROKER && receiverRole === BROKER) || locationId === 'no-location') return []
  const { getMaterialMarkupsByLoc } = useLocations()
  const res = await getMaterialMarkupsByLoc({ id: locationId })
  if (!res?.data) {
    console.error(res)
    return []
  }

  return [...res.data]
}
const inboundTaxExemptCalculation = async (state = '', taxExemptedStateIds: number[]) => {
  const { getStates } = useLocations()
  if (!state || !taxExemptedStateIds?.length) return false
  const stateId = await getStates({ q: { code_eq: state } })
  if (!stateId.data) return false
  return !!(taxExemptedStateIds.find((item: number) => stateId?.data?.[0]?.id === item))
}
// Making API call to get CompanySettings>VendorTaxExempt and VendorTaxExemptedState
const getTaxExemptionByState = async (state = '', clientId: any) => {
  const params = { clientId }
  const { getTaxExemptByClient } = useClients()
  const res = await getTaxExemptByClient(params)
  const states = res.data?.states || []
  if (res.data?.allStatesTaxExempt) {
    // returning true if Company Setting > Vendor > Invoice All Vendor TaxExempt = true
    return { taxExempt: true, taxExemptLabel: 'Tax Exempt based on Vendor Settings' }
  } else if (state) {
    if (states.find((item: { code: string }) => item.code === state)) {
      // if location is under Company Setting > Vendor > Invice > TaxExempted States Array
      return { taxExempt: true, taxExemptLabel: 'Tax Exempt based on Site Settings' }
    }
    // if location doesn't has state returning false
  }
  return { taxExempt: false, taxExemptLabel: 'Tax Exempt' }
}

export const setEmailMetaData = (email:string | string[], expectedFormat: 'Array' | 'string') => {
  if (!email || !email.length) return expectedFormat === 'Array' ? [] : ''
  if (expectedFormat === 'Array') {
    return Array.isArray(email) ? email : email.split(',')
  } else if (expectedFormat === 'string') {
    return Array.isArray(email) ? email.join(',') : email
  }
}
export const getBillingContact = (persons:any) => {
  if (persons && persons.length) {
    return persons.filter((p:any) => p.isBillingPerson)
  } else return null
}

type Contact = {
  id?: number,
  name?: string
}

export interface ClientConfig {
  billingAddress?: string
  email?: string|Array<string>
  contactObj?: any
  contacts?: Contact[]
  state?: string
  taxExempt: boolean
  requireCustomInvoiceNumber?: boolean
}

export interface LocConfigById {
  id: number
  billingAddress?: string
  email?: string|Array<string>
  contactObj?: any
  contacts?: Contact[]
  state?: string
  taxExempt?: boolean
}

export interface CompanySiteResourceData {
  client: ClientConfig
  locations: LocConfigById[]
}
type StateObj = Pick<NewAddress, 'state'>

type LocIdByTaxExempt = Pick<NewLocation, 'taxExempt' |'stateTaxExempt' | 'id'>

type DefaultExistingLocationsData = {
  id: LocIdByTaxExempt['id'],
  taxExempt: LocIdByTaxExempt['taxExempt'] | LocIdByTaxExempt['stateTaxExempt'] | boolean,
  state: StateObj['state'] | ''
}
type DefaultClientData = Pick<ClientConfig, 'taxExempt'>

export interface DefaultExistingCompanySiteResourceData {
  client: DefaultClientData
  locations: DefaultExistingLocationsData[]
}
// CARLY TO DO: CREATE PROPOSAL BASED FUNCTION
// will need to create a similar funciton for proposal business logic and then dynamically map for our loc api call

export const genCompanySiteApiDerivedInfo = async (resource:NewInvoice|NewProposal|NewPurchaseOrder, outbound:boolean, resourceType:string, locationId: any = -1, skipClientAPI = false) => {
  const { companyType } = useApp()
  const { getClientById } = useClients()
  const { vendorInvoiceCustomNumberRequired } = useCompanySettings()
  const { getInvoiceLocationsFetchDataByWorkFlow } = useInvoices()
  const returnObj:CompanySiteResourceData = {
    client: {
      billingAddress: '',
      email: resourceType === INVOICE_RESOURCE_TYPE ? [''] : '',
      contactObj: {},
      contacts: [],
      taxExempt: false,
      state: '',
      requireCustomInvoiceNumber: false
    },
    locations: [
      {
        id: -1,
        billingAddress: '',
        email: resourceType === INVOICE_RESOURCE_TYPE ? [''] : '',
        contactObj: {},
        contacts: [],
        taxExempt: false,
        state: ''

      }
    ]
  }

  const clientId = getResourceClientOrVendorId(resource, outbound, 'client')
  let clientRes:any
  let locRes:any
  let tempEmail = ''

  if (clientId !== -1 && !skipClientAPI && companyType.value !== CLIENT) {
    clientRes = await getClientById({ id: clientId, include: CLIENT_INCLUDE })
    if (returnObj.client) {
      if (!resource.id) {
        returnObj.client.contacts = clientRes?.data?.contacts || []
        returnObj.client.taxExempt = clientRes?.data?.taxExempt

        const found = getBillingContact(clientRes.data?.contacts)

        // if (resourceType === PROPOSAL_RESOURCE_TYPE) {
        //   returnObj.client.contactObj = found[0] || {}
        // }
        tempEmail = found ? found.map?.((contact:any) => contact.email) : clientRes.data?.email
        returnObj.client.email = setEmailMetaData(tempEmail, resourceType === INVOICE_RESOURCE_TYPE ? 'Array' : 'string')
        returnObj.client.billingAddress = clientRes.data?.billingAddress ? (clientRes.data?.billingAddress?.formattedAddressWithCareOfAndSuite || clientRes.data?.billingAddress?.formattedAddress) : ''
        returnObj.client.state = clientRes.data?.headquarterAddress?.state
      }
      if (!outbound) {
        returnObj.client.requireCustomInvoiceNumber = vendorInvoiceCustomNumberRequired.value
      } else if (outbound && (companyType.value === VENDOR || companyType.value === BROKER)) {
        returnObj.client.requireCustomInvoiceNumber = resourceType === INVOICE_RESOURCE_TYPE && !!clientRes?.data.customInvoiceNumberFieldMandatory
      }
    }
  }

  if (locationId !== -1 && !resource.id && locationId !== 'no-location') {
    const { fetchFn, params } = getInvoiceLocationsFetchDataByWorkFlow(resource, outbound, locationId)
    // proposal will break here until we revisit them but I like keeping there resource specific functionality/apis separate
    if (typeof fetchFn === 'function') {
      locRes = await fetchFn(params)
      // TO DO: change locFetchfn once we know how to filter for clientId
      if (locRes.data) {
        locRes.data = locRes.data.filter((loc: {id: number}) => loc.id === locationId)
      }
    }

    if (locRes?.data?.length) {
      const found = getBillingContact(locRes.data?.[0]?.contacts || [])
      tempEmail = found ? found.map?.((contact:any) => contact.email) : ''
      let tempBillingAddress = ''
      let tempTaxExempt = false
      if (findResourceReceiverRoleByCompany(resource, outbound) === BROKER) {
        // broker to broker relationship - locbilling address should not be relevant so set to '' to ensure that client billing address is the only address being used
        tempBillingAddress = ''
        tempTaxExempt = locRes?.data?.[0]?.taxExempt // we only want to look at the state taxExempt value if we are creating a vendor invoice. Broker -> Client should not consider this as it is a VENDOR specific company setting
      } else {
        tempBillingAddress = locRes?.data?.[0]?.billingAddress ? (locRes?.data?.[0]?.billingAddress.formattedAddressWithCareOfAndSuite || locRes?.data?.[0]?.billingAddress.formattedAddress) : ''
        tempTaxExempt = locRes?.data?.[0]?.taxExempt || (locRes?.data?.[0]?.stateTaxExempt && !(resourceType === INVOICE_RESOURCE_TYPE && outbound))
      }

      if (returnObj.locations[0].id === -1) {
        returnObj.locations.pop()
      }

      // index 0 is default -1 loc
      returnObj.locations.push({
        id: locationId,
        contacts: locRes?.data?.[0]?.contacts,
        email: setEmailMetaData(tempEmail, resourceType === INVOICE_RESOURCE_TYPE ? 'Array' : 'string'),
        billingAddress: tempBillingAddress,
        state: locRes?.data?.[0]?.address?.state,
        taxExempt: tempTaxExempt

      })
    } else {
      returnObj.locations.push({
        id: locationId,
        contacts: [],
        email: setEmailMetaData('', resourceType === INVOICE_RESOURCE_TYPE ? 'Array' : 'string'),
        billingAddress: '',
        state: '',
        taxExempt: false
      })
    }
  }

  return returnObj
}

export const findResourceReceiverRoleByCompany = (resource:NewInvoice|NewProposal|NewPurchaseOrder, outbound:boolean) => {
  const { companyType } = useApp()
  if ((!outbound && companyType.value === BROKER) || (outbound && companyType.value === VENDOR)) {
    return BROKER
  } else if (companyType.value === CLIENT) return CLIENT

  // when we are transformed for vue (proposals will eventually be standardized to use locations arr for ease of typing ** TODO when AR/AP is done )

  // if we dont have either company or location, we cannot determine if receiver is the owner of the manager of the location being associated to the resource
  // default to client
  if (resource.company?.id === undefined && !resource.locations?.[0]?.id) return CLIENT

  // we have a selected client
  if (resource.company !== undefined && companyType.value === BROKER) {
    if (Object.keys(resource.company).includes('roles') && resource.company.roles.length > 1) {
      return BROKER
    } else return CLIENT
    //   } else {

  //     // if we are non batch, then check the resource company id against the locations company id => BROKER and manager of site.
  // if (resource.locations.length === 1) {
  //   return resource.company?.id !== resource.locations?.[0]?.companyId ? BROKER : CLIENT
  // } else {
  //   // if we are batch, and we find that at least one of the resources selected locations is a managed site vs all owned sites, this client is also a broker, but only for that specific location being invoiced.
  //   // we will still ignore location specific summary data, but we will conditionally fetch for preset email addresses that only brokers can set
  //   return resource.locations?.some((loc: { companyId: number}) => (loc.companyId !== resource.company?.id)) ? BROKER : CLIENT
  // }
  } else return CLIENT
}

// function only applies to invoicing
export const setTeamMemberEmails = async (resource:NewInvoice|NewProposal, outbound:boolean) => {
  const clientId = getResourceClientOrVendorId(resource, outbound, 'client')
  const { getUsersByCompany } = useCompanies()
  if ((clientId === -1 || !resource?.locations?.[0]?.id) && outbound) {
    return ['']
  }

  const receiverCompanyType: companyRoles = findResourceReceiverRoleByCompany(resource, outbound)

  // if the relationship between sender and receiver of the invoice includes a broker and a vendor (and not a client), we need to fetch for assigned company users via api
  // this is a preset company setting  = highest prirority of conditionals for setting email addresses for invoices ONLY

  if (receiverCompanyType === BROKER) {
    const params = { with_vendor_invoice_review: true }
    const res = await getUsersByCompany(clientId, params)
    if (!res.data) return ['']
    return [res.data?.map((user: any) => user.email).join(', ')]
  } else return ['']
}
// this will most likely break for proposals right now until we map the correct attributes per resource
export const genCompanySiteBillingAndContactInfo = (resource:NewInvoice|NewProposal, outbound:boolean, resourceType:string, apiFetchData:CompanySiteResourceData, locationId: number) => {
  const { session } = useSession()
  const billingContactInfo:any = {
    email: resourceType === INVOICE_RESOURCE_TYPE ? [''] : '',
    billingAddress: '',
    clientContact: {},
    clientOrSiteContacts: []
  }

  let contactEmail: string | string[] | undefined
  let tempBillingAddress = ''
  let allContacts = [] as any

  if (!resource.id) {
    if (!outbound) {
      // we get the contact email from setTeamMemberEmails so default to ''
      contactEmail = setEmailMetaData('', resourceType === INVOICE_RESOURCE_TYPE ? 'Array' : 'string')
      tempBillingAddress = session.currentCompany?.billingAddress?.fullAddress || session.currentCompany?.billingAddress?.formattedAddress || ''
      billingContactInfo.clientContact = session.currentCompany?.contacts?.[0]
    }
    // we only want to overwrite the client billing address and email address if the resource selected has a single location and the
    const receiverCompanyType: companyRoles = findResourceReceiverRoleByCompany(resource, outbound)
    if ((locationId !== -1 && receiverCompanyType === CLIENT) && (resource?.locations?.length === 1 || resourceType === PROPOSAL_RESOURCE_TYPE)) {
      const locIndex = apiFetchData.locations.findIndex((loc: {id:number}) => loc.id === locationId)
      if (locIndex > -1) {
        contactEmail = ((Array.isArray(apiFetchData.locations?.[locIndex]?.email) && apiFetchData.locations?.[locIndex].email?.[0]?.length) || (typeof apiFetchData.locations?.[locIndex]?.email === 'string' && apiFetchData.locations?.[locIndex]?.email)) ? apiFetchData.locations?.[locIndex]?.email : apiFetchData.client?.email
        tempBillingAddress = apiFetchData.locations[locIndex].billingAddress || apiFetchData?.client?.billingAddress || ''
        if (apiFetchData.locations?.[locIndex]?.contacts?.[0]?.id) {
          allContacts = allContacts.concat(apiFetchData.locations?.[locIndex]?.contacts)
        }
      }
    } else if ((resource?.locations && resource.locations.length > 1) || receiverCompanyType === BROKER || locationId === -1) {
      // always use client email and billing address if we are in batch or receiverComapnyType is broker
      contactEmail = apiFetchData?.client?.email
      tempBillingAddress = apiFetchData?.client?.billingAddress || ''
      billingContactInfo.clientContact = apiFetchData?.client?.contactObj
    }
  }

  // we need to set contact info in the case that the user is editing an existing resource and changes the contact info
  if (apiFetchData.client?.contacts?.[0]) {
    allContacts = allContacts.concat(apiFetchData.client.contacts)
  }

  const uniqueKey = 'name'
  const uniqueContactsByNameKey = [...new Map(allContacts.map((contact: Contact) => [contact[uniqueKey], contact])).values()]
  billingContactInfo.clientOrSiteContacts = uniqueContactsByNameKey

  billingContactInfo.email = contactEmail
  billingContactInfo.billingAddress = tempBillingAddress

  if (resourceType === INVOICE_RESOURCE_TYPE) {
    delete billingContactInfo.clientOrSiteContacts
    delete billingContactInfo.clientContact
  }

  return billingContactInfo
}

export const findLineItemTaxExemptionStatus = async (resource:NewInvoice|NewProposal|NewPurchaseOrder|NewBatchInvoice, outbound:boolean, resourceType:string, apiFetchData:CompanySiteResourceData | DefaultExistingCompanySiteResourceData, locationId:number) => {
  let taxExempt = !!resource.taxExempt
  let taxExemptLabel = resource.taxExemptLabel || 'Tax Exempt'
  if (taxExempt || resource.id) return { taxExempt, taxExemptLabel }

  const clientId = getResourceClientOrVendorId(resource, outbound, 'client')
  // CHECK-POINT:1 if we are inbound, and there is no clientid or locationid, we are calling this function in summary panel as a broker to check company setting for vendor invoicing
  const locIndex = apiFetchData.locations.length ? apiFetchData.locations.findIndex((loc:any) => loc.id === locationId) : -1
  if (locIndex !== -1) {
    let invTaxExempt = false
    if (resourceType === INVOICE_RESOURCE_TYPE) {
      if ((resource as NewBatchInvoice)?.grouped && (resource as NewBatchInvoice)?.subInvoices?.length) {
        invTaxExempt = Boolean((resource as NewBatchInvoice)?.subInvoices?.every(ins => ins.taxExempt))
      } else {
        invTaxExempt = Boolean(resource.taxExempt)
      }
    }
    if (!outbound && !resource.id) {
      // if inbound and create flow take my vendor settings
      if (resourceType === INVOICE_RESOURCE_TYPE && invTaxExempt && taxExemptLabel !== 'Tax Exempt') {
        taxExempt = true
      } else if (resourceType === INVOICE_RESOURCE_TYPE && settings.value.data?.vendorSetting?.invoiceTaxExempt) {
        // check if VendorSettings.invoiceTaxExempt
        taxExempt = true
        taxExemptLabel = 'Tax Exempt based on Global Vendor Settings'
      } else if (resourceType === PROPOSAL_RESOURCE_TYPE) {
        if (settings.value.data?.proposalSetting?.id !== undefined && settings.value.data?.proposalSetting?.id !== null) {
          if (!settings.value.data?.proposalSetting?.addSalesTaxForItem) {
            taxExempt = true
            taxExemptLabel = 'Tax Exempt based on Proposal Settings'
          }
        }
        if (resource.company?.proposalSetting?.id !== undefined && resource.company?.proposalSetting?.id !== null) {
          if (!resource.company?.proposalSetting?.addSalesTaxForItem) {
            taxExempt = true
            taxExemptLabel = 'Tax Exempt based on Proposal Settings'
          }
        }
      } else if (resourceType !== PO_RESOURCE_TYPE && resource.vendor?.taxExempt) {
        // check if Vendor.taxExempt
        taxExempt = true
        taxExemptLabel = 'Tax Exempt based on Vendor Settings'
      } else if (locationId !== -1) {
        // if locId > 1 (not bill to client) then get state of location and check if the state is tax exempt
        if (resourceType === PO_RESOURCE_TYPE) {
          if (resource.addressType?.id === 'work_order_location') {
            taxExempt = resource.workOrder?.locations?.[0]?.taxExempt || false
            if (taxExempt) taxExemptLabel = 'Tax Exempt based on Site Settings'
          } else if (apiFetchData.locations?.[locIndex]?.taxExempt) {
            taxExempt = true
            taxExemptLabel = 'Tax Exempt based on Site Settings'
          }
        }
        if (resourceType === PROPOSAL_RESOURCE_TYPE && apiFetchData.locations?.[locIndex]?.taxExempt) {
          taxExempt = true
          taxExemptLabel = 'Tax Exempt based on Site Settings'
        }
        if (resourceType === INVOICE_RESOURCE_TYPE && apiFetchData.locations?.[locIndex]?.state) {
          taxExempt = await inboundTaxExemptCalculation(apiFetchData.locations?.[locIndex]?.state, settings.value.data?.vendorSetting.taxExemptedStateIds)
          if (taxExempt) {
            // check if location.state is tax exempt according to my clients settings (check all_states_tax_exempt or if state is in array)
            taxExemptLabel = 'Tax Exempt based on Site Settings'
          }
        }
      }
    } else if (outbound) { // THIS IS OK
      if (resourceType === INVOICE_RESOURCE_TYPE && invTaxExempt && taxExemptLabel !== 'Tax Exempt') {
        // check if invoice.taxExempt
        taxExempt = true
      } else if (resourceType === PROPOSAL_RESOURCE_TYPE) {
        if (settings.value.data?.proposalSetting?.id !== undefined && settings.value.data?.proposalSetting?.id !== null) {
          if (!settings.value.data?.proposalSetting?.addSalesTaxForItem) {
            taxExempt = true
            taxExemptLabel = 'Tax Exempt based on Proposal Settings'
          }
        }
        if (resource.company?.proposalSetting?.id !== undefined && resource.company?.proposalSetting?.id !== null) {
          if (!resource.company?.proposalSetting?.addSalesTaxForItem) {
            taxExempt = true
            taxExemptLabel = 'Tax Exempt based on Proposal Settings'
          }
        }
      } else if (apiFetchData.locations?.[locIndex]?.taxExempt) {
        // check if location.taxExempt
        taxExempt = true
        taxExemptLabel = 'Tax Exempt based on Site Settings'
        if (apiFetchData.client.taxExempt) {
          // check if location.taxExempt and client.taxExempt
          taxExemptLabel = 'Tax Exempt based on Site and Client Settings'
        }
      } else if (apiFetchData.client.taxExempt) {
        taxExempt = true
        taxExemptLabel = 'Tax Exempt based on Client Settings'
      } else {
        // check if location.state is tax exempt according to my clients settings (check all_states_tax_exempt or if state is in array)
        const resp = await getTaxExemptionByState(apiFetchData.locations?.[locIndex]?.state, clientId)
        taxExempt = resp.taxExempt
        taxExemptLabel = resp.taxExemptLabel
      }
    }
  } else if (apiFetchData.client.taxExempt) {
    taxExempt = true
    taxExemptLabel = 'Tax Exempt based on Client Settings'
  } else if (resourceType === PROPOSAL_RESOURCE_TYPE && resource.vendor?.taxExempt) {
    // check if Vendor.taxExempt in case of Proposal
    taxExempt = true
    taxExemptLabel = 'Tax Exempt based on Vendor Settings'
  }
  return { taxExempt, taxExemptLabel }
}

export const fetchAndTransformTax = async (params:any, resourceType:string) => {
  const { getTaxes } = useTaxes()
  if (resourceType === PO_RESOURCE_TYPE && !params.addressId) {
    return 0
  } else if (resourceType !== PO_RESOURCE_TYPE && (params.locationId === -1 || !params.locationId || params.locationId === 'no-location')) {
    return 0
  }
  const tax:any = await getTaxes(params)
  if (Object.keys(tax.data) && Object.keys(tax.data).includes('taxRate')) {
    return tax.data.taxRate
  } else return tax.data || 0
}

export const preprocessEmails = (emails: string | string[]) => {
  if (Array.isArray(emails)) return emails
  const emailList: string[] = emails?.split(',').map((s:string) => s?.trim())
  return emailList
}

export const getDefaultLineItemTaxByLocOrClient = async (params:any, outbound:boolean, resourceType: string) => {
  // we only run this function for batching resources in create mode.
  // goal is to provide either a location based default tax value or a client tax value if we choose to bill to client address
  // the func call and setup are the same. however, with a locationId, we can get a more specific tax value that !== client tax value
  const copyParams = cloneDeep(params)
  copyParams.clientId = copyParams.companyId
  const keysToDelete = ['departmentId', 'tripIds', 'companyId']
  if (resourceType === PO_RESOURCE_TYPE) {
    copyParams.addressId = params.addressId
    copyParams.clientId = copyParams.vendorId
    keysToDelete.push('vendorId')
    keysToDelete.push('locationId')
  }
  keysToDelete.forEach((k:string) => {
    delete copyParams[k]
  })

  if (copyParams.invoiceId && !outbound) {
    copyParams.tab = 'inbound'
  }

  return await fetchAndTransformTax(copyParams, resourceType)
}

// depending on our selected resource type, we need to fetch for locationId differently
// invoice locations are returned as an array that we index off of and then get the id properly
// proposal locations are found in a location obj key where we directly get the id property
// transform loc ids to always be in array form for agnostic application
export const getResourceTypeLocationIds = (resource:any, selectedResourceType:string) => {
  let locs = cloneDeep(resource?.locations)
  // we dont haver any loc ids we have created a resource that is billed directly to client with no loc assigned - set locid to 1 to represent this for already existing resources
  if (Array.isArray(locs)) {
    locs = locs?.[0]?.id === undefined ? [-1] : locs.map((loc:any) => loc?.id)
  } else {
    locs = locs?.id ? [locs.id] : [-1]
  }
  if (selectedResourceType === PO_RESOURCE_TYPE && locs[0] === -1 && resource?.workOrder?.locations !== undefined) {
    locs = resource?.workOrder?.locations?.map((location:Location) => location.id)
  }
  return locs
}

const returnInvoiceId = (resource:any, resourceType:string) => {
  switch (resourceType) {
    case INVOICE_RESOURCE_TYPE :
      return resource.id as number | undefined
    case PROPOSAL_RESOURCE_TYPE :
      return resource.invoiceId as number | undefined
    case PO_RESOURCE_TYPE :
      return resource.invoiceId as number | undefined
    default:
      return resource.id as number | undefined
  }
}

export const computeLineItemMetaDataFetchParams = async (resource:NewInvoice|NewProposal|NewPurchaseOrder, outbound:boolean, selectedResourceType:string) => {
  const { session } = useSession()
  const invoiceId = returnInvoiceId(resource, selectedResourceType)
  let companyId:number | string
  let vendorId:number |string
  // our meta data map will store the default tax & per line item (+ tax exemption status), materialMarkups array, + other lineItemMetaDataFetchParams we will need
  const lineItemMetaDataMap:any = {
    '-1': {
      lineItemMetaData: { taxExemptLabel: 'Tax Exempt', taxExempt: false, fetchParams: {} },
      materialMarkups: []
    }
  }
  if (resource) {
    // step 1 is to manage the company/vendor pov and return correct id per role
    // if PO outbound is revered bcoz the client is sending to Vendor
    // for example broker sends to vendor, broker is client, vendor is vendor
    const isOutbound = selectedResourceType === PO_RESOURCE_TYPE ? !outbound : outbound
    companyId = getResourceClientOrVendorId(resource, isOutbound, 'client')
    vendorId = getResourceClientOrVendorId(resource, isOutbound, 'vendor')
    const params:any = {
      vendorId,
      companyId,
      objectScope: 'both'
    }
    if (selectedResourceType === PO_RESOURCE_TYPE) {
      params.addressId = resource.addressId
    }
    // step 2: layer in invoiceId params which is found on the resource parameter
    if (resource?.id !== undefined) {
      params.invoiceId = invoiceId
    }
    // step 3: loop thru locations on resource and for each loc, map its default tax and materialMarkup values
    const locIds = getResourceTypeLocationIds(resource, selectedResourceType)

    let clientTaxExempt = false
    let apiFetchData:CompanySiteResourceData | DefaultExistingCompanySiteResourceData
    const locArrData = [] as any

    for (let i = 0; i < locIds.length; i++) {
      // we need to determine tax exemption by loc (or by broker company settings if we are !outbound and increateflow)
      params.locationId = locIds[i]
      // we only want to call client api once, irrespective of # of location calls. The only time we should be skipping client call is if we have multiplie locations and I have already called the client info api at i = 0
      if (!resource.id) {
        apiFetchData = await genCompanySiteApiDerivedInfo(resource, isOutbound, selectedResourceType, locIds[i], (locIds.length > 1 && i >= 1))
        locArrData.push(...apiFetchData.locations)
      } else if (resource.locations !== undefined && resource.locations?.length) {
        // map resource.locations and return the only props we care about to assess taxexemption - id, state, taxExempt
        const mappedLocs = resource.locations.map((loc:NewLocation) => {
          const locData = {
            id: loc.id,
            state: loc?.address?.state || '',
            taxExempt: loc.taxExempt || loc.stateTaxExempt || false,
            taxExemptLabel: 'Tax Exempt'
          }
          if (locData.taxExempt) locData.taxExemptLabel = 'Tax Exempt based on Site Settings'
          return locData
        })

        // JEREMY NOTE: we need taxExempt included on invoice.company obj

        apiFetchData = { client: { taxExempt: false }, locations: [...mappedLocs] }
      } else {
        apiFetchData = { client: { taxExempt: false }, locations: [{ id: -1, state: '', taxExempt: false }] }
      }

      if (i === 0) {
        // this line of code breaks when were inbound because the current client is me. use logged user company tax exempt
        if (!resource.id) {
          clientTaxExempt = apiFetchData?.client?.taxExempt || false
        } else if (resource.id && outbound && Array.isArray(resource?.locations)) {
          // if we are viewing an existing resource and we are outbound (we are the sender of the resource and aim to receive payment, we can utilize the taxExempt prop on the resource.company obj bc its included in the parent components api call)
          if (resource?.locations.length > 1) {
            clientTaxExempt = resource?.company?.taxExempt || false
          } else if (resource?.taxExempt !== undefined) {
            clientTaxExempt = resource?.taxExempt || false
          } else {
          // we are inbound reviewing an invoice. the receiver of the resource (client) is the current user and aim is to pay resource. we do not have the company obj included on the resource derived from the parent components api call - because we are the client company. get the session. logged_user.company.taxExempt
          // @JEREMY - carly reminding you here that we will later need to understand how to expose client's broker's tax exemption status for accurate ux handling of resource tax exempt - multi resource. currentCompanyObj does not have a taxExemptkey
            clientTaxExempt = session.loggedUser?.currentCompany?.taxExempt || false
          }
        }
      }
      if ((i >= 1 && !resource.id) || (i === 0 && resource.id)) {
        // if we are at loc 2 for creating resource or we are at loc 1 and we are reviewing/editing resource, set the taxExempt key on client object nested in apiFetchData
        apiFetchData.client.taxExempt = clientTaxExempt
      }

      if (!lineItemMetaDataMap[locIds[i]]) {
        lineItemMetaDataMap[locIds[i]] = {
          lineItemMetaData: {
            taxExemptLabel: 'Tax Exempt', taxExempt: false, fetchParams: {}, defaultLocationTax: [{ tax: 0, name: '' }]
          },
          materialMarkups: []

        }
      }
      if (!resource.id) {
        apiFetchData.locations = locArrData
      }
      // params are complete (less tradeService which is computed in the line item component). store the fetchParams per locId
      lineItemMetaDataMap[locIds[i]].lineItemMetaData.fetchParams = params
      // determine loc tax exemption status, find materialMArkups per loc, and get default location (orclient if billing only to client) tax value
      const promises = [
        findLineItemTaxExemptionStatus(resource, isOutbound, selectedResourceType, apiFetchData, locIds[i]),
        findLocationSpecificMaterialMarkups(locIds[i], isOutbound, resource),
        getDefaultLineItemTaxByLocOrClient(params, isOutbound, selectedResourceType)
      ]
      const vals = await Promise.all(promises)
      lineItemMetaDataMap[locIds[i]].lineItemMetaData.taxExempt = vals[0].taxExempt
      lineItemMetaDataMap[locIds[i]].lineItemMetaData.taxExemptLabel = vals[0].taxExemptLabel
      // lastly, get the materialmarkups array per loc
      lineItemMetaDataMap[locIds[i]].materialMarkups = vals[1]

      // Modified Taxes Api, which going to return taxes in different responses
      // 1. Old response only tax_rate (this is for all us states)
      // 2. New response this will be object of tax rate and tax type example (pst, gst, hst, etc.) (this is for canadian states)
      if (vals[2] !== 0) lineItemMetaDataMap[locIds[i]].lineItemMetaData.defaultLocationTax = vals[2]
    }
  }

  return lineItemMetaDataMap
}

// This function is responsible to preprocess the each lineItem and return the API specific object

const cleanLineItemForPostPatchDB = (item:any, resourceType:string, resourceTypeToPropsMap:any, outbound:boolean, post:boolean, resourceMultiplierType: string) => {
  // item.markupType = MARKUP
  const { getMarkupOrMarginPrice } = useLineItems()

  if ((item.type && (item.type === 'custom' || item.type === 'new')) || !item.id || post) delete item.id
  if (resourceType === INVOICE_RESOURCE_TYPE) {
    item.measureUnitId = item.measureUnit?.id || null
  } else if (resourceType === PROPOSAL_RESOURCE_TYPE) {
    item.unit = item.measureUnit?.name
    !outbound && !('multiplier' in item) && (item.multiplier = 0)
    delete item.teamMembersCount
  } else if (resourceType === PO_RESOURCE_TYPE) {
    item.unitOfMeasure = item.measureUnit?.name || null
  }
  delete item.measureUnit
  const markUpKey = resourceTypeToPropsMap?.markupType === MarkupTypes.MARKUP ? MarkupTypes.MARKUP : MarkupTypes.MULTIPLIER

  const keys = ['quantity', 'price', 'tax', MarkupTypes.MARKUP, 'teamMembersCount']
  keys.forEach((key:string) => {
    item[key] = parseFloat(item[key])
    if (key === MarkupTypes.MARKUP) {
      if (!outbound && post && resourceType === INVOICE_RESOURCE_TYPE) {
        const markupOrMarginPrice = getMarkupOrMarginPrice(item, markUpKey)
        item.price = markupOrMarginPrice
        delete item.markup
        if (item.multiplier) delete item.multiplier
      } else {
        if (markUpKey === MarkupTypes.MULTIPLIER) delete item.markup
      }
    } else if (key === 'tax') {
      if (resourceType === INVOICE_RESOURCE_TYPE) delete item.baseTax
    } else if (key === 'price' && resourceType === PROPOSAL_RESOURCE_TYPE) {
      if (item.price !== item.originalPrice) {
        item.baseTax = 0
      } else {
        item.price -= item.price * (item.baseTax / 100)
      }
    }
  })
  if (resourceType === PROPOSAL_RESOURCE_TYPE && resourceMultiplierType === MarkupTypes.MARKUP) {
    item.multiplier = 100 / (100 - item.multiplier)
  }

  delete item.item
  delete item.itemTypeId
  delete item.derivedItem
  delete item.lineTotal

  if (item.itemId && item.derivedItemId) {
    delete item.itemId
  } else if (!item.itemId) {
    delete item.itemId
  }
  if (resourceType === PROPOSAL_RESOURCE_TYPE) {
    item.proposalItemTaxesAttributes = item.lineItemTaxes
  } else if (resourceType === PO_RESOURCE_TYPE) {
    item.purchaseOrderItemTaxesAttributes = item.lineItemTaxes
  } else {
    item.invoiceItemTaxesAttributes = item.lineItemTaxes
  }
  if (outbound && resourceType === INVOICE_RESOURCE_TYPE) {
    item.invoiceItemPoMaterialsAttributes = item.invoiceItemPoMaterials?.[0]
  }
  delete item.lineItemTaxes
  delete item.invoiceItemTaxes
  return item
}

interface BatchInvoiceLocation extends Location {
  resources: WorkOrder[]
}

/*
This function is responsible to preprocess the each BatchInvoice object and return the API specific object
*/
export const cleanResourceForBatchInvoicePostPatchDB = (
  resource:any,
  inCreateResourceFlow: boolean,
  resourceTypeToPropsMap: any,
  clientId: number,
  vendorId: number,
  post:boolean,
  lineItemTree: any
) => {
  const invoiceKeys = ['receivableBalance', 'payableBalance', 'locations', 'department', 'total', 'invoiceItems', 'company', 'vendor', 'invoiceResources', 'invoice_resources']
  const createOnlyKeys = ['trips', 'id']
  const updateObj = cloneDeep(resource)

  const subInvoices:Invoice[] = []

  updateObj.grouped = true
  lineItemTree?.forEach((location:BatchInvoiceLocation) => {
    const updateSubInvoiceObj = cloneDeep(resource)
    const updatedLineItems:VueResourceLineItem[] = []
    const invoiceResources = []
    updateSubInvoiceObj.locationIds = [location.id]
    updateSubInvoiceObj.generatedDescriptionForResources = {}
    location.resources?.forEach((locationResource:any) => {
      updateSubInvoiceObj.generatedDescriptionForResources[`${locationResource.id}-${locationResource.resourceType}`] = locationResource.generatedDescriptionForResource
      const allLineItems = [...locationResource.lineItems, ...locationResource.removedLineItems]
      const cleanedItems = allLineItems.map((item:VueResourceLineItem, index:number) => {
        if (index === 0) {
          invoiceResources.push({ resource_id: item.resourceId, resource_type: item.resourceType })
          if (!post) {
            updateSubInvoiceObj.id = resource.subInvoices.find((subInvoice:Invoice) => subInvoice.locationIds[0] === location.id)?.id
          }
        }
        return cleanLineItemForPostPatchDB(item, 'invoice', resourceTypeToPropsMap, true, post, resource.multiplierType)
      })
      updatedLineItems.push(...cleanedItems)
    })
    if (!post) delete updateSubInvoiceObj.tripIds
    updateSubInvoiceObj[resourceTypeToPropsMap?.lineItemDataMap.updateContentKey] = updatedLineItems
    invoiceKeys.forEach((key:string) => {
      const invoiceValue = updateSubInvoiceObj[key]

      if (invoiceValue !== undefined) {
        switch (key) {
          case 'department':
            updateSubInvoiceObj.departmentId = invoiceValue?.id || null
            break
          case 'company':
          case 'vendor':
            updateSubInvoiceObj.companyId = clientId
            updateSubInvoiceObj.vendorId = vendorId
            break
          case 'invoiceResources':
          case 'invoice_resources':
            // eslint-disable-next-line no-case-declarations
            const invResAttrib: any = updateSubInvoiceObj[resourceTypeToPropsMap?.lineItemDataMap.updateContentKey]?.map((lineItem:any) => {
              return { resource_id: lineItem.resourceId, resource_type: lineItem.resourceType }
            })
            // eslint-disable-next-line no-case-declarations
            const invoiceResourcesByLineItems = uniqBy(invResAttrib, (e: any) => { return `${e.resource_id}-${e.resource_type}` })
            if (post) {
              invoiceResourcesByLineItems.forEach((ir: { resource_id: number, resource_type: string, generated_description_for_resource: string }) => {
                ir.generated_description_for_resource = updateSubInvoiceObj.generatedDescriptionForResources[`${ir.resource_id}-${ir.resource_type}`]
              })
              updateSubInvoiceObj.invoice_resources_attributes = invoiceResourcesByLineItems
            } else {
              const invoiceResources: InvoiceResource[] = intersectionWith(updateSubInvoiceObj.invoiceResources, invoiceResourcesByLineItems, (obj1:any, obj2:any) => {
                return obj2.resource_id === obj1.resourceId && obj2.resource_type === obj1.resourceType
              })
              invoiceResources.forEach((ir: InvoiceResource) => {
                const resourceKey = `${ir.resourceId}-${ir.resourceType}`
                ir.generatedDescriptionForResource = updateSubInvoiceObj.generatedDescriptionForResources[resourceKey]
              })
              updateSubInvoiceObj.invoice_resources_attributes = invoiceResources
            }
            delete updateSubInvoiceObj.invoice_resources
            delete updateSubInvoiceObj.invoiceResources
            break
        }
      }
      delete updateSubInvoiceObj[key]
    })

    if (post) {
      createOnlyKeys.forEach((key) => {
        const invoiceValue = updateSubInvoiceObj[key]

        if (invoiceValue !== undefined) {
          switch (key) {
            case 'trips':
              updateSubInvoiceObj.tripIds = updateSubInvoiceObj[resourceTypeToPropsMap?.lineItemDataMap.updateContentKey]?.map((lineItem:any) => lineItem.tripIds)?.flat()
              break
          }
          delete updateSubInvoiceObj[key]
        }
      })
    }
    subInvoices.push(updateSubInvoiceObj)
  })
  updateObj.sub_invoices_attributes = subInvoices
  invoiceKeys.forEach((key:string) => {
    const invoiceValue = updateObj[key]
    if (invoiceValue !== undefined) {
      switch (key) {
        case 'department':
          updateObj.departmentId = invoiceValue?.id || null
          break
        case 'company':
        case 'vendor':
          updateObj.companyId = clientId
          updateObj.vendorId = vendorId
          break
      }
    }
    delete updateObj[key]
  })

  const formattedPDFValue = snakeCase(updateObj.pdfTemplate)
  updateObj.pdfTemplate = formattedPDFValue
  updateObj.toEmails = preprocessEmails(updateObj.toEmails)
  return updateObj
}

/**
     * REFERENCE FOR VUE DEVS - THESE ARE TH ATTRIBUTES ROR EXPECTS US TO RETURN WHEN WE SAVE OR CREATE AN INVOICE
     *     def invoice_items_attributes
       %i[
         id
         item_id
         item_name
         item_type_name
         derived_item_id
         measure_unit_id
         team_members_count,
         trip_ids,
         description
         markup
         price
         quantity
         tax
         line_total
         _destroy
         resource_id
         resource_type
       ]
     */

// The invoice object we have in vue doesn't align with the invoice object required by the RoR API
// So We have this “cleanResourceForPostPatchDB” function that will convert the vue objects to be “ready” for RoR APIs
export const cleanResourceForPostPatchDB = (resource:any, lineItemsObj: {original: VueResourceLineItem[], updated: VueResourceLineItem[]}, resourceType: string, outbound: boolean, inCreateResourceFlow: boolean, resourceTypeToPropsMap: any, clientId: number, vendorId: number, post:boolean, lineItemTree:any) => {
  const sharedKeysByResource:any = {
    invoice: ['receivableBalance', 'payableBalance', 'locations', 'department', 'total', 'invoiceItems', 'company', 'vendor', 'invoiceResources', 'invoice_resources', 'clientContact', 'clientDepartment'],
    proposal: ['total', 'locations', 'proposalItems', 'location', 'department', 'proposalDocuments', 'clientContact', 'contactUser', 'company', 'vendor', 'termsAndConditions', 'proposalAssignment', 'disableLiPriceOnPdf', 'annexedPoNumber'],
    'purchase-order': ['company', 'discount', 'vendor', 'department', 'purchaseOrderItems', 'purchaseOrderAssignment', 'deliveryClient', 'deliveryLocation', 'deliveryAddressType', 'headquarterAddress', 'locationAddress', 'workOrder', 'workOrderId', 'tripIds', 'servicedClient', 'purchaseOrderEmails', 'locations', 'total', 'location_id', 'statusTxt', 'amount']
  }
  const createOnlyKeys = ['trips', 'workOrders', 'weatherEvents', 'id']
  const originalLineItems = lineItemsObj.original
  let updateObj:any
  let updatedLineItems = lineItemsObj?.updated || []
  // if we are on the summary tab, and want to save our changes, our locallineItems ref will be undefined and not initialized (until we open this tab),
  // so default the selected invoice line items to be its initial values

  // create local "revert" deep clone of our line items before transforming for db in case we fail to save or create - this will ensure we keep our "vuetransformed" lineitems intact and all computed line item functionality and values are maintained
  const inCaseOfErrorLineItemsCopy = cloneDeep(updatedLineItems)
  if (resource?.locations?.length > 1) {
    updateObj = cleanResourceForBatchInvoicePostPatchDB(resource, inCreateResourceFlow, resourceTypeToPropsMap, clientId, vendorId, post, lineItemTree)
  } else {
    updateObj = cloneDeep(resource)
    // Get line items ids from the local list
    const lineItemIds = updatedLineItems.map((item: VueResourceLineItem) => item.id)
    // find the list of line items from the original one that were not in the local list so they can be marked as deleted (_destroy = 1)
    const deletedLineItems = originalLineItems?.filter((item: VueResourceLineItem) => !lineItemIds.includes(item.id)) || []
    deletedLineItems.forEach((element:VueResourceLineItem) => {
      element._destroy = 1
    })
    // clean invoice items
    // This is temp fix for draggable line items.
    // Once reactivity of all the components involved is fixed then we will move this to uc-draggable.
    // Here we update the position of the line items as per the position/index in the updated list from draggable.
    updatedLineItems = updatedLineItems.map((item, index) => {
      const newItem = cleanLineItemForPostPatchDB(item, resourceType, resourceTypeToPropsMap, outbound, post, updateObj.multiplierType)
      return { ...newItem, position: index + 1 }
    })
    // before we delete keys from the updateObj, we need to map certain key value
    updateObj[resourceTypeToPropsMap?.lineItemDataMap.updateContentKey] = [...updatedLineItems, ...deletedLineItems]

    if (updateObj?.locations && updateObj.locations[0]?.id === 'no-location') {
      updateObj.locations = []
    }
    sharedKeysByResource[resourceType].forEach((key:string) => {
      // CARLY TO DO: think thru a solution to make this code cleaner -> a way to provide rules of transformation per resourcetype if possible?
      // or we make separate cleanfordb functions for resource -> this may get more verbose though -> also tough to functionalize because it uses a lot of locally scoped vars
      if (updateObj[key] !== undefined) {
        if (key === 'department') {
          updateObj.departmentId = updateObj[key]?.id || null
        } else if (key === 'clientDepartment') {
          updateObj.clientDepartmentId = updateObj[key]?.id || null
        } else if (key === 'annexedPoNumber') {
          updateObj.annexed_po_number = updateObj[key]
        } else if (key === 'locations' && post) {
          if (resourceType === INVOICE_RESOURCE_TYPE) {
            // before we preprocess location and company specific data for our payload obj, we need to check if the relationship between sender and receiver is broker to broker
            // if relationship = broker to broker, we need to exclude location_ids from being passed. the loc is not associated to the client bc its a managed location on behalf of a broker
            // we validate by checking if any of the locations.company.id !== the resource.company.id

            // const companyId = outbound ? updateObj.company?.id : updateObj.companyId
            updateObj.locationIds = updateObj[key]?.map((val: {id: number}) => val.id)
          } else if (resourceType === PROPOSAL_RESOURCE_TYPE) {
            updateObj.locationId = updateObj[key]?.[0]?.id || updateObj?.locationId
            updateObj.locationName = updateObj[key]?.[0]?.name || updateObj?.locationName
          } else if (resourceType === PO_RESOURCE_TYPE) {
            updateObj.locations = updateObj[key]
            updateObj.locationId = updateObj[key]?.[0]?.id
            updateObj.serviceLocationId = updateObj[key]?.[0]?.id
          }
        } else if (key === 'deliveryAddressType') {
          updateObj.addressType = updateObj.deliveryAddressType?.id
        } else if (key === 'proposalDocuments') {
          updateObj.proposal_document_attributes = updateObj[key]
        } else if (key === 'proposalAssignment') {
          updateObj.proposal_assignment_attributes = updateObj[key]
        } else if (key === 'purchaseOrderAssignment') {
          const assignmentObj = {
            work_order_id: updateObj?.workOrderId,
            trip_id: updateObj?.trips?.map((trip:any) => trip.id)?.[0]
          }
          if (!post) {
            Object.assign(assignmentObj, { id: updateObj?.purchaseOrderAssignment?.id })
          }
          updateObj.purchase_order_assignment_attributes = assignmentObj
        } else if (key === 'locations' && resourceType === PO_RESOURCE_TYPE) {
          updateObj.serviceLocationId = updateObj[key]?.[0]?.id
        } else if (key === 'termsAndConditions') {
          updateObj.terms_and_conditions = updateObj[key]
        } else if (key === 'disableLiPriceOnPdf') {
          updateObj.disable_li_price_on_pdf = updateObj[key]
        } else if (key === 'contactUser') {
          updateObj.contactUserId = updateObj[key]?.id
        } else if (key === 'clientContact') {
          updateObj.clientContactId = updateObj[key]?.id
        } else if (key === 'company' || key === 'vendor') {
          if (resourceType !== PO_RESOURCE_TYPE) {
            updateObj.companyId = clientId
            updateObj.vendorId = vendorId
          }
          if (resourceType === PROPOSAL_RESOURCE_TYPE && outbound) {
            updateObj.companyName = updateObj.company?.name
          }
        } else if ((key === 'message' || key === 'invoiceMessage') && resourceType === PO_RESOURCE_TYPE) {
          updateObj.message = updateObj[key]
        } else if (key === 'invoiceResources') {
          const resources:any = cloneDeep(updateObj[key])
          const indexedInvoiceResourceDescriptions:any = {}
          lineItemTree?.forEach((location:BatchInvoiceLocation) => {
            location.resources?.forEach((locationResource:any) => {
              indexedInvoiceResourceDescriptions[`${locationResource.id}-${locationResource.resourceType}`] = locationResource.generatedDescriptionForResource
            })
          })
          const invResAttrib = resources.map((r:any) => {
            r.generatedDescriptionForResource = indexedInvoiceResourceDescriptions[`${r.resourceId}-${r.resourceType}`]
            return r
          })
          updateObj.invoice_resources_attributes = uniqBy(invResAttrib, (e: any) => { return `${e.resourceId}-${e.resourceType}` })
          delete updateObj.invoice_resources
        }
        delete updateObj[key]
      }
    })

    if (resourceType === INVOICE_RESOURCE_TYPE) {
      const formattedPDFValue = snakeCase(updateObj.pdfTemplate)
      updateObj.pdfTemplate = formattedPDFValue
      updateObj.toEmails = preprocessEmails(updateObj.toEmails)
    } else if (resourceType === PROPOSAL_RESOURCE_TYPE) {
      const formattedProposalPDFValue = snakeCase(updateObj.pdfTemplate)
      updateObj.pdfTemplate = formattedProposalPDFValue
      updateObj.discountInPercentage = true
    } else if (resourceType === PO_RESOURCE_TYPE) {
      updateObj?.purchase_order_items_attributes?.map((item: any) => {
        item.type = ''
        return item
      })
      updateObj.purchaseOrderEmailsAttributes = [
        {
          emailTo: updateObj.emailTo,
          emailCc: updateObj.emailCc,
          emailBcc: updateObj.emailBcc
        }
      ]
    }

    if (post) {
      createOnlyKeys.forEach((key:string) => {
        // proposal may not have trip ids exposed to obj
        if ((updateObj[key] !== undefined)) {
          if (key === 'trips') {
            updateObj.tripIds = updateObj[key].map((trip:any) => trip.id)
          } else if (key === 'workOrders' && resourceType === PROPOSAL_RESOURCE_TYPE) {
            updateObj.workOrderId = updateObj[key]?.[0]?.id
          }
          delete updateObj[key]
        }
      })
    }
  }

  return [updateObj, inCaseOfErrorLineItemsCopy]
}

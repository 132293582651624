import {
  VENDOR_NAME,
  VENDOR_EMAIL,
  VENDOR_PHONE,
  VENDOR_COINS_ID,
  VENDOR_PLATFORM_ID,
  VENDOR_MARKETS,
  VENDOR_REGIONS,
  VENDOR_ONBOARDED,
  VENDOR_COI_EXPIRATION,
  VENDOR_COVERAGE_AMOUNT,
  VENDOR_COI_DOCUMENTS,
  VENDOR_COMPLIANCE,
  VENDOR_TRADE_NAME,
  VENDOR_SIC_CODE,
  VENDOR_CITY,
  VENDOR_STATE,
  VENDOR_COUNTRY,
  VENDOR_ZIP_CODE,
  VENDOR_ADDRESS,
  VENDOR_CLAIM_STATUS_FILTER,
  VENDOR_STATUS,
  VendorFilterTabs
} from '@/constants/filters/resources/vendor'

import { BROKER } from '@/constants/permissions'
import { useSession } from '@/use/session'

export const vendorsFilters = (tab: VendorFilterTabs) => {
  const { session } = useSession()

  const availableVendorFilters = () => {
    const baseFilters: any = [
      VENDOR_NAME({ pinned: isPinned('name', tab), isPrimary: true }),
      VENDOR_STATUS({ pinned: isPinned('status', tab), isPrimary: true }, applicableStatuses(tab)),
      VENDOR_EMAIL({ pinned: isPinned('email', tab), isPrimary: true }),
      VENDOR_PHONE({ pinned: isPinned('phone', tab), isPrimary: true }),
      VENDOR_COINS_ID({ pinned: false, isPrimary: true }),
      VENDOR_PLATFORM_ID({ pinned: false, isPrimary: true }),
      VENDOR_MARKETS({ pinned: false, isPrimary: true }),
      VENDOR_REGIONS({ pinned: false, isPrimary: true }),
      VENDOR_ONBOARDED({ pinned: false, isPrimary: true }),
      VENDOR_TRADE_NAME({ pinned: isPinned('trade', tab), isPrimary: true }, tradeParams(tab)),
      VENDOR_SIC_CODE({ pinned: false, isPrimary: true }),
      VENDOR_CITY({ pinned: false, isPrimary: true }),
      VENDOR_STATE({ pinned: isPinned('state', tab), isPrimary: true }),
      VENDOR_COUNTRY({ pinned: false, isPrimary: true }),
      VENDOR_ZIP_CODE({ pinned: isPinned('zip_code', tab), isPrimary: true }, zipCodeParams(tab)),
      VENDOR_ADDRESS({ pinned: false, isPrimary: true }),
      VENDOR_CLAIM_STATUS_FILTER({ pinned: isPinned('claim_status', tab), isPrimary: true })
    ]
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(
        VENDOR_COI_EXPIRATION({ pinned: false, isPrimary: true }),
        VENDOR_COI_DOCUMENTS({ pinned: isPinned('coi_documents', tab), isPrimary: true }),
        VENDOR_COMPLIANCE({ pinned: isPinned('compliance', tab), isPrimary: true }),
        VENDOR_COVERAGE_AMOUNT({ pinned: false, isPrimary: true })
      )
    }
    return baseFilters
  }

  return {
    filters: [
      ...availableVendorFilters()
    ],
    sortBy: {
      value: 'name',
      direction: {
        text: 'Ascending',
        value: 'asc'
      },
      items: [
        {
          label: 'Name',
          value: 'name'
        }
      ]
    },
    fieldMapping: {
      'vendor-name': {
        name: 'vendor_name',
        field: 'text'
      },
      'vendor-status': {
        name: 'status',
        field: 'value'
      },
      'vendor-email': {
        name: 'vendor_email',
        field: 'text'
      },
      'vendor-phone-number': {
        name: 'vendor_phone',
        field: 'text'
      },
      'vendor-platform-id': {
        name: 'vendor_id',
        field: 'text'
      },
      'vendor-markets': {
        name: 'vendor_markets_label',
        field: 'text'
      },
      'vendor-trade-name': {
        name: tradeFilterName(tab),
        field: 'value'
      },
      'vendor-coins-id': {
        name: 'vendor_vendor_coins_connection_external_id',
        field: 'value'
      },
      'vendor-regions': {
        name: 'vendor_territories_name',
        field: 'value'
      },
      'vendor-on-boarded': {
        name: 'vendor_subdomain',
        field: 'value',
        operator: 'not_null'
      },
      'vendor-coi-expiration': {
        name: 'vendor_local_coi_documents_expiration_date',
        field: 'value',
        operator: 'days_between'
      },
      'vendor-coverage-amount': {
        name: 'vendor_local_coi_documents_coverage',
        field: 'value'
      },
      'vendor-coi-documents': {
        name: 'vendor_local_coi_documents_coi_label',
        field: 'value'
      },
      'vendor-compliance': {
        name: 'vendor_cc_compliance_percentage_status',
        field: 'value'
      },
      'vendor-sic-code': {
        name: 'vendor_root_trades_sic_code',
        field: 'value'
      },
      'vendor-city': {
        name: 'vendor_headquarter_address_city',
        field: 'value'
      },
      'vendor-state': {
        name: 'vendor_headquarter_address_state',
        field: 'value'
      },
      'vendor-country': {
        name: 'vendor_headquarter_address_country',
        field: 'value'
      },
      'vendor-zip-code': {
        name: zipCodeFilterName(tab),
        field: 'value'
      },
      'vendor-address': {
        name: 'vendor_headquarter_address_address1',
        field: 'value'
      },
      'vendor-claim-status': {
        name: 'vendor_cc_vendor_claim_status_status',
        field: 'value'
      }
    }
  }
}

function isPinned (filterName: string, tab: VendorFilterTabs) {
  if (tab === VendorFilterTabs.VENDOR) {
    return ['name', 'status', 'email', 'phone', 'coi_documents', 'trade', 'state', 'zip_code', 'claim_status'].includes(filterName)
  } else if ([VendorFilterTabs.ADVANCED_PUBLIC, VendorFilterTabs.ADVANCED_PRIVATE].includes(tab)) {
    return ['name', 'status', 'trade', 'compliance', 'zip_code'].includes(filterName)
  }
}

function tradeFilterName (tab: VendorFilterTabs) {
  switch (tab) {
    case VendorFilterTabs.VENDOR:
      return 'vendor_root_trades_name'
    case VendorFilterTabs.ADVANCED_PUBLIC:
      return 'vendor_public_trades_name'
    case VendorFilterTabs.ADVANCED_PRIVATE:
      return 'vendor_private_trades_name'
  }
}

function zipCodeFilterName (tab: VendorFilterTabs) {
  switch (tab) {
    case VendorFilterTabs.VENDOR:
      return 'vendor_headquarter_address_zip_code'
    case VendorFilterTabs.ADVANCED_PUBLIC:
      return 'vendor_public_regions_zip_codes_code'
    case VendorFilterTabs.ADVANCED_PRIVATE:
      return 'vendor_private_regions_zip_codes_code'
  }
}

function zipCodeParams (tab: VendorFilterTabs) {
  switch (tab) {
    case VendorFilterTabs.VENDOR:
      return { field: 'zip_code', tab: 'active' }
    case VendorFilterTabs.ADVANCED_PUBLIC:
      return { field: 'public_zip_code', tab: 'active' }
    case VendorFilterTabs.ADVANCED_PRIVATE:
      return { field: 'private_zip_code', tab: 'active' }
  }
}

function tradeParams (tab: VendorFilterTabs) {
  switch (tab) {
    case VendorFilterTabs.VENDOR:
      return { field: 'trade', tab: 'active' }
    case VendorFilterTabs.ADVANCED_PUBLIC:
      return { field: 'public_trade', tab: 'active' }
    case VendorFilterTabs.ADVANCED_PRIVATE:
      return { field: 'private_trade', tab: 'active' }
  }
}

function applicableStatuses (tab: VendorFilterTabs) {
  if (tab === VendorFilterTabs.VENDOR) return undefined

  return ['active', 'pending']
}

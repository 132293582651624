import { SEND } from '@/constants/icons'
import { DetailsPanelButtonState } from '@/types/enums'

export const outboundActionData: any[] = [
  {
    name: 'Send',
    validStatuses: [
      'approved_to_send',
      'draft'
    ],
    primaryAction: [
      'approved_to_send'
    ],
    detailsPanel: DetailsPanelButtonState.Icon,
    buttonIcon: SEND
  },
  {
    name: 'Edit',
    validStatuses: [
      'approved_to_send',
      'draft',
      'rejected'
    ],
    primaryAction: [
      'draft'
    ]
  },
  {
    name: 'Approve',
    textColor: 'success',
    validStatuses: [
      'sent', 'approval_requested'
    ],
    primaryAction: [
    ],
    detailsPanel: DetailsPanelButtonState.Expose
  },
  {
    name: 'Receive Payment',
    validStatuses: [
      'sent',
      'approved',
      'partially_paid'
    ],
    primaryAction: [
      'partially_paid'
    ]
  },
  {
    name: 'Assign Department',
    validStatuses: ['draft', 'approved_to_send'],
    primaryAction: [
    ]
  },
  {
    name: 'Approve To Send',
    validStatuses: [
      'draft'
    ],
    primaryAction: [
    ]
  },
  {
    name: 'Mark Sent',
    validStatuses: [
      'approved_to_send',
      'draft'
    ],
    primaryAction: [
    ]
  },
  {
    name: 'Void',
    textColor: 'error',
    validStatuses: [
      'approved_to_send',
      'draft',
      'sent',
      'approved',
      'partially_paid'
    ],
    primaryAction: [
    ]
  },
  {
    name: 'Delete',
    validStatuses: [
      'draft',
      'rejected'
    ],
    primaryAction: [
    ]
  },
  {
    name: 'Save And Send',
    validStatuses: ['unsaved'],
    primaryAction: [
    ]
  },
  {
    name: 'Save And Mark Sent',
    validStatuses: ['unsaved'],
    primaryAction: [
    ]
  },
  {
    name: 'Save And Approve To Send',
    validStatuses: ['unsaved'],
    primaryAction: [
    ]
  },
  {
    name: 'Save As Draft',
    validStatuses: ['unsaved'],
    primaryAction: [
    ]
  },
  {
    name: 'Skip',
    validStatuses: true,
    primaryAction: [
      'Skip'
    ],
    textColor: 'error',
    detailsPanel: DetailsPanelButtonState.Only
  },
  {
    name: 'Save',
    primaryAction: [
      'approved_to_send',
      'draft'
    ],
    validStatuses: [],
    detailsPanel: DetailsPanelButtonState.Only
  },
  {
    name: 'Reject',
    textColor: 'error',
    validStatuses: [
      'sent', 'approval_requested'
    ],
    primaryAction: [
    ],
    detailsPanel: DetailsPanelButtonState.Expose
  }
]

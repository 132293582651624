import { useOutboundProposals } from '@/use/proposals/outbound'
import { useInboundProposals } from '@/use/proposals/inbound'
import { useSession } from '@/use/session'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { ref } from 'vue'
import { FilterResources } from '@/types/enums/filter-types'
import { cloneDeep } from 'lodash'
import { CompanyRoleTypes } from '@/types/enums'

const { getProposals, proposalsAutoComplete, statuses } = useOutboundProposals()
const { inboundProposalsAutoComplete } = useInboundProposals()
const { getCompanySetValues } = useSession()

getCompanySetValues()

const isOutbound = ref(false)
const proposalAutoCompleteFetchFn = async (params: AutocompletePayload) => {
  const { data, nextPage } =
    isOutbound.value
      ? await proposalsAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number }
      : await inboundProposalsAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number }
  return {
    data: data.map((item: AutocompleteResponse, index: number) => {
      return {
        id: `${index}-${item.text}`,
        text: item.text,
        value: item.text
      }
    }),
    nextPage
  }
}

// const proposalStatuses: Record <string, string> = {
//   Draft: 'draft',
//   Sent: 'sent',
//   'Accepted (WO)': 'accepted_wo',
//   'Accepted (Invoiced)': 'accepted_invoiced',
//   Approved: 'accepted',
//   Rejected: 'rejected',
//   'Rejected (Closed)': 'rejected_closed',
//   Received: 'sent',
//   Evaluating: 'evaluating'
// }

interface CalculatedStaus {
  code: number;
  text: string;
}
const proposalStatuses: {[CompanyRoleTypes.CLIENT]: CalculatedStaus[], [CompanyRoleTypes.VENDOR]: CalculatedStaus[], both: CalculatedStaus[] } = {
  client: [],
  vendor: [],
  both: []
}

const getProposalStatuses = async (as: CompanyRoleTypes | 'both') => {
  if (proposalStatuses[as].length) return proposalStatuses[as]

  const res = await statuses(as)

  if (res.data) {
    proposalStatuses[as] = res.data
    return proposalStatuses[as]
  } else {
    return []
  }
}

/**
 * PROPOSALS
 */
const PROPOSAL_NUMBER = (outbound = false, config = {}, params = { field: 'proposal_number' }, fetchFn: any = proposalAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'proposal-number-filter',
      headerText: 'Proposal Number',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['proposal_number'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.PROPOSAL,
    ...config
  }
}

const PROPOSAL_CUSTOM_NAME = (outbound = false, config = {}, params = { field: 'custom_proposal_name' }, fetchFn: any = proposalAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Custom Proposal Name',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'proposal-custom-name-filter',
      headerText: 'Proposal Custom Name',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['custom_proposal_name']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.PROPOSAL,
    ...config
  }
}

const PROPOSAL_STATUS = (as: CompanyRoleTypes | 'both', config = {}) => {
  return {
    text: 'Status',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'proposal-status-filter',
      headerText: 'Proposal Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async ({ terms }: { terms?: string }) => {
        const res = await getProposalStatuses(as)

        const data = cloneDeep(res).filter((calculatedStaus) => calculatedStaus.text.toLocaleLowerCase().includes(terms?.toLowerCase() || '')).map((calculatedStaus) => {
          return {
            id: calculatedStaus.code,
            text: calculatedStaus.text,
            value: calculatedStaus.code
          }
        })

        return {
          data,
          totalCount: data.length,
          totalPages: 1
        }
      },
      params: { q: { s: 'number asc' } },
      searchKeys: ['number']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.PROPOSAL,
    ...config
  }
}

const PROPOSAL_DATE_OF_ISSUE = (config = {}) => {
  return {
    text: 'Date of Issue',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'proposal-date-of-issue-filter',
      headerText: 'Proposal Date of Issue',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.PROPOSAL,
    ...config
  }
}

const PROPOSAL_EXPIRATION_DATE = (config = {}) => {
  return {
    text: 'Expiration Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'proposal-expiration-date-filter',
      headerText: 'Proposal Expiration Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.PROPOSAL,
    ...config
  }
}

// Not in use (This was build for autocomplete stuff)
const PROPOSAL_CLIENT_NAME = (config = {}) => {
  return {
    text: 'Client Name',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'proposal-client-name-filter',
      headerText: 'Proposal Client Name',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async (params: any) => {
        const { data, totalPages, totalCount } = await getProposals({ ...params }) as any
        return {
          data: data.map((item: any) => {
            return {
              id: item.id,
              text: item.companyName,
              value: item.companyName
            }
          }),
          totalCount,
          totalPages
        }
      },
      params: { q: { s: 'company_name asc' } },
      searchKeys: ['company_name']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.PROPOSAL,
    ...config
  }
}

const PROPOSAL_SITE_NAME = (outbound = false, config = {}, params = { field: 'site', invoiceables: false }, fetchFn: any = proposalAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Site Name',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'proposal-site-name-filter',
      headerText: 'Proposal Site Name',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['site']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.PROPOSAL,
    ...config
  }
}

const PROPOSAL_PO_NUMBER = (outbound = false, config = {}, params = { field: 'po_number' }, fetchFn: any = proposalAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'PO Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'po-number-filter',
      headerText: 'PO Number',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['po_number'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.PROPOSAL,
    ...config
  }
}

const PROPOSAL_DEPARTMENT = (outbound = false, config = {}, params = { field: 'department' }, fetchFn: any = proposalAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Department',
    canSelect: false,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'proposal-department-filter',
      headerText: 'Department',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['department']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.PROPOSAL,
    ...config
  }
}

export {
  PROPOSAL_NUMBER,
  PROPOSAL_CUSTOM_NAME,
  PROPOSAL_STATUS,
  PROPOSAL_DATE_OF_ISSUE,
  PROPOSAL_EXPIRATION_DATE,
  PROPOSAL_CLIENT_NAME,
  PROPOSAL_SITE_NAME,
  PROPOSAL_PO_NUMBER,
  PROPOSAL_DEPARTMENT
}

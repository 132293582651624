import {
  SITE_REGION,
  SITE_NAME,
  SITE_STATE
} from '@/constants/filters/resources/site'

import {
  CLIENT_NAME
} from '@/constants/filters/resources/client'

import {
  PROPOSAL_NUMBER,
  PROPOSAL_CUSTOM_NAME,
  PROPOSAL_STATUS,
  PROPOSAL_DATE_OF_ISSUE,
  PROPOSAL_EXPIRATION_DATE,
  PROPOSAL_PO_NUMBER,
  PROPOSAL_DEPARTMENT
} from '@/constants/filters/resources/proposal'

import { BROKER } from '@/constants/permissions'
import { useOutboundProposals } from '@/use/proposals/outbound'
import { AutocompleteResponse, AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'
import { CompanyRoleTypes } from '@/types/enums'

export const outboundCovertProposalFilters = () => {
  const { proposalsAutoComplete } = useOutboundProposals()

  const proposalsAutoCompleteFetchFn = async (params: AutocompletePayload) => {
    params.finderParams = { status_in: ['accepted'] }
    const { data, nextPage } = await proposalsAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number }
    return {
      data: data.map((item: AutocompleteResponse, index: number) => {
        return {
          id: `${index}-${item.text}`,
          text: item.text,
          value: item.text
        }
      }),
      nextPage
    }
  }

  return {
    filters: [
      CLIENT_NAME({ pinned: true, isPrimary: true }, { field: 'client', tab: 'active' }, proposalsAutoCompleteFetchFn),
      SITE_NAME({ pinned: true, isPrimary: true }, { field: 'site', invoiceables: false }, proposalsAutoCompleteFetchFn),
      SITE_REGION({ pinned: false, isPrimary: false }, { field: 'site_region', invoiceables: false }, proposalsAutoCompleteFetchFn),
      SITE_STATE({ pinned: false, isPrimary: false }, { field: 'site_state', tab: 'active' }, proposalsAutoCompleteFetchFn),
      PROPOSAL_NUMBER(true, { pinned: true, isPrimary: true }, { field: 'proposal_number' }, proposalsAutoCompleteFetchFn),
      PROPOSAL_CUSTOM_NAME(true, { pinned: false, isPrimary: false }, { field: 'custom_proposal_name' }, proposalsAutoCompleteFetchFn),
      PROPOSAL_STATUS(CompanyRoleTypes.VENDOR, { pinned: true, isPrimary: true }),
      PROPOSAL_DATE_OF_ISSUE({ pinned: false, isPrimary: false }),
      PROPOSAL_EXPIRATION_DATE({ pinned: false, isPrimary: false }),
      PROPOSAL_DEPARTMENT(true, { pinned: false, isPrimary: false }, { field: 'department' }, proposalsAutoCompleteFetchFn)
    ],
    sortBy: {
      value: 'date_of_issue',
      direction: {
        text: 'Descending',
        value: 'desc'
      },
      items: [
        {
          label: 'Status',
          value: 'status'
        },
        {
          label: 'Proposal Date',
          value: 'proposal_date'
        },
        {
          label: 'Date of Issue',
          value: 'date_of_issue'
        },
        {
          label: 'Proposal #',
          value: 'estimate_number'
        },
        {
          label: 'Amount',
          value: 'total_cents'
        }
      ]
    },
    fieldMapping: {
      'client-name': {
        name: 'company_name',
        field: 'value'
      },
      'site-name': {
        name: 'location_name',
        field: 'value'
      },
      'site-region': {
        name: 'location_cc_territory_name',
        field: 'text'
      },
      'site-state': {
        name: 'location_address_state',
        field: 'value'
      },
      'proposal-number': {
        name: 'estimate_number',
        field: 'value'
      },
      'proposal-custom-name': {
        name: 'custom_proposal_name',
        field: 'value'
      },
      'proposal-status': {
        name: 'calculated_status_code',
        field: 'value'
      },
      'proposal-date-of-issue': {
        name: 'date_of_issue',
        field: 'value'
      },
      'proposal-expiration-date': {
        name: 'proposal_date',
        field: 'value'
      },
      'proposal-client-name': {
        name: 'company_id',
        field: 'value'
      },
      'proposal-site-name': {
        name: 'location_id',
        field: 'value'
      },
      'po-number': {
        name: 'annexed_po_number',
        field: 'value'
      },
      department: {
        name: 'department_name',
        field: 'value'
      }
    }
  }
}

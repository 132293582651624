import moment from 'moment-timezone'
import { useSession } from '@/use/session'

export const roundToDecimal = (value: number | string, decimals: number): number => {
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value
  const roundedValue = parseFloat(parsedValue.toFixed(decimals))
  return roundedValue
}

export const roundFloor = (value: number, decimalPlaces = 3): number => {
  const factor = Math.pow(10, decimalPlaces)
  return Math.floor(value * factor) / factor
}
export const DEFAULT_CURRENCY_CODE = 'USD'

export const formatNumber = (
  price: number | string,
  options?: {
    style?: 'decimal' | 'currency' | 'percent' | 'unit',
    styleOptions?: Record<string, string | number>,
    commaSeparated?: boolean,
    decimals?: number
  }
) => {
  let style = 'decimal'
  let styleOptions: Record<string, string | number> = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }
  let commaSeparated = false
  if (options) {
    if (options.style) {
      style = options.style
    }
    if (options.styleOptions) {
      styleOptions = options.styleOptions
    }
    if (typeof options.commaSeparated === 'boolean') {
      commaSeparated = options.commaSeparated
    }
    if (options.decimals) {
      styleOptions.maximumFractionDigits = options.decimals
    }
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style,
    ...styleOptions
  })
  const priceArg = (typeof price === 'string') ? parseFloat(price) : price
  const retVal = commaSeparated ? formatter.format(priceArg) : formatter.format(priceArg).replaceAll(',', '')
  return retVal
}

export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm A z'
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
export const DEFAULT_TIME_FORMAT = 'hh:mm:ss A'
export const DEFAULT_HUMAN_READABLE_DATETIME_FORMAT = 'dddd, MMMM DD, YYYY [at] hh:mm A z'
export const DEFAULT_TIME_FORMAT_WITH_TIMEZONE = 'hh:mm A z'
export const DEFAULT_DATE_FILTER_FORMAT = 'MM/DD/YYYY'

// format = 'l' -> 5/10/2022
export const formatDate = (value: string | moment.Moment | Date, format = 'YYYY-MM-DD', tz = '') => {
  const { session } = useSession()
  const timezone = tz || session.loggedUser.timezone
  return moment.tz(value, timezone).format(format)
}

/**
 * Display a count in a limited capacity, with an upper limit to how big we're going to display
 * @param sets The array or count we want to display
 * @param maxCount the maximum allowed count we can display (defaulted to 9)
 */
export const boundedSizeCount = (sets: number | object[], maxCount = 9) => {
  if (!sets) return '0'

  if (Array.isArray(sets)) {
    return sets.length > maxCount ? `${maxCount}+` : `${sets.length}`
  } else {
    return (sets > maxCount ? `${maxCount}+` : sets.toString())
  }
}

/**
 * Display the formatted duration - x Hours y Minutes z Seconds.
 * @param durationInSeconds pass Duration value in seconds.
 * @param delimeter to specify the boundary between retuned string.
 * @param withSeconds pass true if want to have seconds as well in the returned string.
 * NOTE: If the value is less than a minute, by default this method will return
 * ${duration} Seconds, even if @param withSeconds is not set.
 * Units(H/M/S) will only be added if the value is present for the same.
 */
export const formatDuration = (
  durationInSeconds: string | number, delimeter = ' ', withSeconds = false
): string => {
  if (!durationInSeconds) return '-'

  const val = Number(durationInSeconds)
  let formattedString = ''
  const hours = Math.floor(val / 3600)
  const minutes = Math.floor(val % 3600 / 60)
  if (hours) formattedString = `${hours} Hours`
  if (minutes) formattedString = formattedString.concat(`${delimeter}${minutes} Minutes`)
  const seconds = Math.floor(val % 3600 % 60)
  if (seconds && (withSeconds || (!hours && !minutes))) {
    formattedString = formattedString.concat(`${delimeter}${seconds} Seconds`)
  }

  return formattedString
}

export const workOrderSorting = {
  value: 'id',
  direction: {
    text: 'Descending',
    value: 'desc'
  },
  items: [
    {
      label: 'Priority',
      value: 'priority'
    },
    {
      label: 'Work Order Status',
      value: 'extended_status_state'
    },
    {
      label: 'Custom Status',
      value: 'custom_status'
    },
    {
      label: 'Trip Status',
      value: 'trip_extended_status'
    },
    {
      label: 'Creation Date',
      value: 'created'
    },
    {
      label: 'Due Date',
      value: 'due_date'
    },
    {
      label: 'Generation Date',
      value: 'creation_date'
    },
    {
      label: 'Close Date',
      value: 'close_date'
    },
    {
      label: 'Expiration Date',
      value: 'expiration_date'
    },
    {
      label: 'Follow Up At',
      value: 'follow_up_at'
    },
    {
      label: 'Work Order #',
      value: 'id'
    }
  ]
}

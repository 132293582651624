import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "uc-attachments ma-1",
    class: _vm.isDraging ? 'uc-attachments--dragging' : 'uc-attachments--non-dragging',
    on: {
      "dragenter": function ($event) {
        $event.preventDefault();
      },
      "dragover": function ($event) {
        $event.preventDefault();
      },
      "dragleave": function ($event) {
        $event.preventDefault();
      },
      "drop": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.onDrop($event);
      }
    }
  }, [_vm._t("additional-button"), _c('div', {
    staticClass: "attachment-top-header"
  }, [!_vm.disabled ? _c('uc-button', {
    attrs: {
      "state": "primary",
      "text": "Upload local file"
    },
    on: {
      "click": function ($event) {
        return _vm.fileInput.click();
      }
    }
  }) : _vm._e(), _c('uc-modal', {
    ref: "attachmentSettingModalRef",
    attrs: {
      "closable": true,
      "persistent": false,
      "modal-card-props": {
        headerText: 'Attachment Settings',
        size: 'lg'
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex flex-column"
        }, [_c('uc-text', {
          attrs: {
            "type": "body",
            "size": 1,
            "text": "Control who has access to the attachment, as well as provide information of its contents to the user who has access to it."
          }
        }), _c('uc-input', {
          staticClass: "mt-4",
          attrs: {
            "label": "Name",
            "label-variant": true,
            "hide-details": true
          },
          model: {
            value: _vm.newUploadObj.data.name,
            callback: function ($$v) {
              _vm.$set(_vm.newUploadObj.data, "name", $$v);
            },
            expression: "newUploadObj.data.name"
          }
        }), _c('uc-input', {
          staticClass: "mt-4",
          attrs: {
            "label": "Description",
            "label-variant": true,
            "hide-details": true
          },
          model: {
            value: _vm.newUploadObj.data.description,
            callback: function ($$v) {
              _vm.$set(_vm.newUploadObj.data, "description", $$v);
            },
            expression: "newUploadObj.data.description"
          }
        })], 1), _c('div', {
          staticClass: "d-flex flex-1"
        }, _vm._l(_vm.visibilityKeys, function (key, index) {
          return _c('div', {
            key: index,
            staticClass: "mt-4"
          }, [_c('uc-checkbox-group', {
            attrs: {
              "hide-details": true,
              "can-select-all": false,
              "checkboxes": [{
                label: _vm.upperFirst(_vm.lowerCase(key))
              }]
            },
            on: {
              "change": function ($event) {
                _vm.newUploadObj.data[key] = !_vm.newUploadObj[key];
              }
            },
            model: {
              value: _vm.newUploadObj.data[key],
              callback: function ($$v) {
                _vm.$set(_vm.newUploadObj.data, key, $$v);
              },
              expression: "newUploadObj.data[key]"
            }
          })], 1);
        }), 0), _vm.resourceType === _vm.PROPOSAL_RESOURCE_TYPE && _vm.imageMimeTypes.includes(_vm.fileInput.files[0].type) ? _c('div', {
          staticClass: "d-flex flex-column"
        }, [_c('uc-radio-group', {
          staticClass: "mt-4",
          attrs: {
            "buttons": _vm.imageDisplayOptionsForPDF,
            "label": 'Image Display'
          },
          model: {
            value: _vm.newUploadObj.data.attachmentFullWidth,
            callback: function ($$v) {
              _vm.$set(_vm.newUploadObj.data, "attachmentFullWidth", $$v);
            },
            expression: "newUploadObj.data.attachmentFullWidth"
          }
        })], 1) : _vm._e(), _c('div', {
          staticClass: "mt-4"
        }, [_c('uc-input', {
          attrs: {
            "label": "Attached File",
            "readonly": true,
            "label-variant": true,
            "hide-details": true
          },
          scopedSlots: _vm._u([{
            key: "append",
            fn: function () {
              return [_c('a', {
                staticClass: "pl-3",
                on: {
                  "click": function ($event) {
                    _vm.fileInput.removeAttribute('multiple');
                    _vm.fileInput.click();
                    _vm.fileInput.setAttribute('multiple', 'multiple');
                  }
                }
              }, [_c(VIcon, {
                attrs: {
                  "color": "primary"
                }
              }, [_vm._v(" mdi-folder-outline ")])], 1)];
            },
            proxy: true
          }]),
          model: {
            value: _vm.newUploadObj.fileName,
            callback: function ($$v) {
              _vm.$set(_vm.newUploadObj, "fileName", $$v);
            },
            expression: "newUploadObj.fileName"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('uc-button-group', {
          attrs: {
            "buttons": [{
              type: 'outlined',
              text: 'CANCEL'
            }, {
              type: 'primary',
              text: 'SAVE',
              eventName: 'confirm'
            }]
          },
          on: {
            "confirm": function ($event) {
              _vm.attachmentSettingModalRef.close();
              _vm.handlePostAttachment(null, false);
            },
            "cancel": function ($event) {
              return _vm.attachmentSettingModalRef.close();
            }
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.attachmentsData.rows.length,
      expression: "!attachmentsData.rows.length"
    }],
    staticClass: "uc-attachments-view"
  }, [!_vm.disabled ? _c(VIcon, {
    attrs: {
      "x-large": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.fileInput.click();
      }
    }
  }, [_vm._v(" mdi-upload ")]) : _vm._e(), !_vm.disabled ? _c('uc-text', {
    staticClass: "ma-2",
    attrs: {
      "size": 1,
      "type": 'body',
      "text": 'Drag your files here',
      "bold": true
    }
  }) : _vm._e(), !_vm.disabled ? _c('div', {
    staticClass: "d-flex flex-row justify-space-around align-center"
  }, [_c('uc-text', {
    attrs: {
      "size": 1,
      "type": 'body',
      "text": 'or ',
      "bold": true
    }
  }), _c('uc-button', {
    attrs: {
      "type": 'text',
      "size": 'lg',
      "state": 'primary',
      "text": 'Browse from your local files'
    },
    on: {
      "click": function ($event) {
        return _vm.fileInput.click();
      }
    }
  }), !_vm.disabled ? _c('input', {
    ref: "fileInput",
    attrs: {
      "type": "file",
      "multiple": "",
      "hidden": "",
      "accept": _vm.accept
    },
    on: {
      "change": function ($event) {
        return _vm.onClickUpload($event.target);
      },
      "click": function ($event) {
        _vm.fileInput.value = null;
      }
    }
  }) : _vm._e()], 1) : _vm._e()], 1), _c(VDataIterator, {
    staticClass: "attachment-items",
    attrs: {
      "disabled": _vm.disabled,
      "items": _vm.attachmentsData.rows,
      "disable-filtering": true,
      "disable-pagination": true,
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex flex-1 justify-center ma-2"
        }, [_vm.loading ? _c('uc-list-row-loading', {
          attrs: {
            "first-row": true,
            "last-row": true,
            "size": 'md'
          },
          on: {
            "intersect": _vm.generateLoadingState
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (attachmentItem) {
        return [_c('uc-list-row-attachments', {
          attrs: {
            "disabled": _vm.disabled,
            "attachment": attachmentItem.item.props.attachment,
            "is-expanded": true,
            "visibility-keys": _vm.visibilityKeys,
            "resource-type": _vm.resourceType
          },
          on: {
            "attachment:deleted": function ($event) {
              return _vm.deleteSelectedAttachment($event);
            },
            "save": function ($event) {
              return _vm.saveAttachment($event);
            },
            "update": function ($event) {
              return _vm.handleAttachmentChange($event);
            }
          }
        })];
      }
    }, _vm.attachmentsData.rows.length && _vm.attachmentsData.page !== _vm.attachmentsData.totalPages ? {
      key: "footer",
      fn: function () {
        return [_c('uc-list-row-loading', {
          attrs: {
            "first-row": false,
            "last-row": false
          },
          on: {
            "intersect": _vm.getAttachmentData
          }
        }), _c('uc-list-row-loading', {
          attrs: {
            "first-row": false,
            "last-row": true
          }
        })];
      },
      proxy: true
    } : null], null, true)
  }), _vm.disabled && !_vm.attachmentsData.rows.length && !_vm.loading ? _c('div', {
    staticClass: "no-attachment mt-8"
  }, [_c('uc-text', {
    attrs: {
      "size": 1,
      "text": 'No Attachments',
      "type": 'body',
      "bold": true
    }
  })], 1) : _vm._e(), _c('uc-modal', {
    ref: "attachmentsActionModal",
    attrs: {
      "closable": true,
      "persistent": false,
      "modal-card-props": {
        headerText: 'Delete Attachment',
        size: 'md',
        headerIcon: 'delete'
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('uc-text', {
          attrs: {
            "size": 1,
            "type": 'body',
            "text": `Are you sure you want to delete ${_vm.selectedAttachmentName}?`
          }
        })];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('uc-button-group', {
          attrs: {
            "buttons": _vm.modalFooterButtons
          },
          on: {
            "cancel": function ($event) {
              _vm.attachmentsActionModal.close();
              _vm.selectedAttachment = null;
            },
            "delete": function ($event) {
              return _vm.confirmDeleteAttachment();
            }
          }
        })];
      },
      proxy: true
    }])
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };
var render = function render() {
  var _vm$planDetails, _vm$proposedSubscript, _vm$proposedSubscript2, _vm$proposedSubscript3, _vm$proposedSubscript4, _vm$planDetails2;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('uc-tile-container', {
    staticClass: "d-flex flex-column flex-start",
    attrs: {
      "no-margin": false,
      "no-elevation": true,
      "background-color": "--v-layer-secondary-base"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-start",
    staticStyle: {
      "overflow-y": "auto"
    }
  }, [!_vm.isStoreModal && (_vm$planDetails = _vm.planDetails) !== null && _vm$planDetails !== void 0 && _vm$planDetails.buyMoreUsageEnabled && _vm.usage ? _c('uc-tile-container', {
    staticClass: "d-flex align-start px-5 py-4 flex-column",
    attrs: {
      "no-margin": false,
      "no-elevation": true
    }
  }, [_c('package-builder-subscription-manager-header-title', {
    attrs: {
      "title": (_vm$proposedSubscript = _vm.proposedSubscriptionData) !== null && _vm$proposedSubscript !== void 0 && _vm$proposedSubscript.proposedUsage ? 'Your Included Usage!' : 'Please Select Your Usage',
      "description": (_vm$proposedSubscript2 = _vm.proposedSubscriptionData) !== null && _vm$proposedSubscript2 !== void 0 && _vm$proposedSubscript2.proposedUsage ? 'Usage fee is determined by the dollar amount of invoices you estimate to process on UtilizeCore in a given year.' : 'Usage fee is determined by the dollar amount of invoices you estimate to process on UtilizeCore in a given year. Each plan comes with $1,000,000 of free invoicing volume.'
    }
  }), _vm.usage && !((_vm$proposedSubscript3 = _vm.proposedSubscriptionData) !== null && _vm$proposedSubscript3 !== void 0 && _vm$proposedSubscript3.proposedUsage) ? _c('uc-number-input', {
    staticClass: "mt-4 d-flex",
    attrs: {
      "label": 'Annual invoicing volume:',
      "label-variant": "",
      "hide-details": "",
      "disabled": false,
      "readonly": "",
      "prefix": _vm.$,
      "increment": _vm.INVOICE_INCREMENTAL_VOLUME,
      "min": _vm.minimumInvoicingVolume(),
      "can-double-click-to-edit": false,
      "data-cy": "select-invoice-volume"
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('select-invoice-volume', _vm.usage);
      }
    },
    model: {
      value: _vm.usage,
      callback: function ($$v) {
        _vm.usage = $$v;
      },
      expression: "usage"
    }
  }) : _vm._e(), (_vm$proposedSubscript4 = _vm.proposedSubscriptionData) !== null && _vm$proposedSubscript4 !== void 0 && _vm$proposedSubscript4.proposedUsage ? _c('div', {
    staticClass: "d-flex flex-column mt-2"
  }, [_c('uc-text', {
    attrs: {
      "size": 2,
      "type": 'body',
      "text": 'Annual invoicing volume:',
      "bold": false
    }
  }), _c('uc-text', {
    staticClass: "mt-1 ml-1",
    attrs: {
      "size": 1,
      "type": 'body',
      "text": _vm.formatNumber(_vm.proposedSubscriptionData.proposedUsage, {
        style: 'currency',
        styleOptions: {
          currency: 'USD'
        },
        commaSeparated: true
      }),
      "bold": false
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.productServices.length ? _c('div', [_c('package-builder-integration-apps-header', {
    attrs: {
      "services": _vm.addOns,
      "service-type": 'Product Add-ons',
      "available-services-count": _vm.productServices.length
    }
  }), _c('div', {
    staticClass: "d-flex flex-column flex-start"
  }, [_c('uc-table', {
    ref: "addOnsTableRef",
    attrs: {
      "columns": _vm.ADDONS_HEADER(),
      "items": _vm.productServices,
      "hide-header": _vm.hideHeader,
      "actions": [],
      "row-type": 'integrations',
      "row-props": {
        hasBorder: false,
        canExpand: false,
        showActions: false,
        canSelect: true,
        isExpanded: false,
        height: '35px'
      },
      "resource-update-fn": () => null
    },
    on: {
      "item-selected": function ($event) {
        return _vm.saveSelectedIntegrations($event, 'addon');
      }
    }
  })], 1)], 1) : _vm._e(), _vm.integrationsArray.length ? _c('div', [_c('package-builder-integration-apps-header', {
    attrs: {
      "services": _vm.integrationApps,
      "service-type": 'Integrations',
      "available-services-count": _vm.integrationsArray.length,
      "allowed-integrations-count": ((_vm$planDetails2 = _vm.planDetails) === null || _vm$planDetails2 === void 0 ? void 0 : _vm$planDetails2.numberOfIntegrations) || 0
    }
  }), _c('div', {
    staticClass: "d-flex flex-column flex-start"
  }, [_c('uc-table', {
    ref: "integrationsTableRef",
    attrs: {
      "columns": _vm.INTEGRATIONS_HEADER(),
      "items": _vm.integrationsArray,
      "hide-header": _vm.hideHeader,
      "actions": [],
      "row-type": 'integrations',
      "row-props": {
        hasBorder: false,
        canExpand: false,
        showActions: false,
        canSelect: true,
        isExpanded: false,
        height: '35px'
      },
      "resource-update-fn": () => null
    },
    on: {
      "item-selected": function ($event) {
        return _vm.saveSelectedIntegrations($event, 'Integration');
      }
    }
  })], 1)], 1) : _vm._e(), _vm.professionalServices.length ? _c('div', [_c('package-builder-integration-apps-header', {
    attrs: {
      "services": _vm.services,
      "service-type": 'Professional Services',
      "available-services-count": _vm.professionalServices.length
    }
  }), _c('div', {
    staticClass: "d-flex flex-column flex-start"
  }, [_c('uc-table', {
    ref: "servicesTableRef",
    attrs: {
      "columns": _vm.SERVICES_HEADER(),
      "items": _vm.professionalServices,
      "hide-header": _vm.hideHeader,
      "actions": [],
      "row-type": 'integrations',
      "row-props": {
        hasBorder: false,
        canExpand: false,
        showActions: false,
        canSelect: true,
        isExpanded: false,
        height: '35px'
      },
      "resource-update-fn": () => null
    },
    on: {
      "item-selected": function ($event) {
        return _vm.saveSelectedIntegrations($event, 'service');
      }
    }
  })], 1)], 1) : _vm._e()], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import { api } from '@/api'
import qs from 'qs'

export default {
  get: () => {
    return api.$get('settings/invoice')
  },
  update: (params: any) => {
    return api.$patch('settings/invoice', { settings_invoice: params },
      {
        paramsSerializer: (params: any) => {
          return qs.stringify(params)
        }
      }
    )
  }
}

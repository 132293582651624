import { api } from '@/api'
import qs from 'qs'

export interface baseGetParams {
  page?: number
  perPage?: number
  q?: Record<string, string | number>
  include?: string
  scope?: string
  id?: number
  timecardDetailsRequired?: boolean
}

export default {
  verifyService: (tripId: number, serviceId: number, verifyTrip: boolean) => {
    return api.$patch(`/trips/${tripId}/work_order_services/${serviceId}/verify`,
      { verifyTrip }
    )
  },
  unverifyService: (tripId: number, serviceId: number) => {
    return api.$patch(`/trips/${tripId}/work_order_services/${serviceId}/unverify`
    )
  },
  getServices: async ({ scope, timecardDetailsRequired, include, q, page, perPage, id }: baseGetParams) => {
    return api.$get(`/trips/${id}/work_order_services`, {
      params: {
        scope,
        timecard_details_required: timecardDetailsRequired,
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}

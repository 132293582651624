import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('uc-tile-container', _vm._g({
    staticClass: "flex-column rounded-0",
    class: [_vm.$vuetify.theme.dark && 'uc-list-row-base--dark row--dark extended-row--dark', {
      'last-row': _vm.isTile || _vm.lastRow
    }, {
      'first-row': _vm.isTile || _vm.firstRow
    }, _vm.backgroundColor, {
      [`${_vm.backgroundColor}-hover`]: _setup.hovering || _vm.isExpanded && !_vm.isTile
    }, {
      [`${_vm.backgroundColor}-selected`]: _vm.isSelected
    }, {
      [`${_vm.backgroundColor}-active`]: _vm.isSelected && (_vm.isExpanded && !_vm.isTile || _setup.hovering)
    }, _vm.size === 'card' ? 'mb-1 rounded' : ''],
    attrs: {
      "no-padding": "",
      "no-elevation": "",
      "no-margin": ""
    },
    on: {
      "mouseenter": function ($event) {
        _vm.hasHover && (_setup.hovering = true);
      },
      "mouseleave": function ($event) {
        _setup.hovering = false;
      }
    }
  }, _vm.$listeners), [_vm.size !== 'card' ? _c(VRow, {
    class: 'flex-nowrap',
    style: `width: 100%; height: ${_vm.height};`,
    attrs: {
      "align": 'center'
    }
  }, [_vm.canSelect ? _c(VCol, {
    staticClass: "v-col-sm-auto v-col-lg-auto v-col-md-auto v-col-xl-auto v-col-auto"
  }, [_c(VCheckbox, {
    staticStyle: {
      "margin-top": "0px",
      "padding-top": "0px"
    },
    attrs: {
      "input-value": _vm.isSelected,
      "disabled": _vm.selectionDisabled,
      "hide-details": ""
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('toggle-select');
      },
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  })], 1) : _vm._e(), _vm._l(_vm.columnDefs.headers, function (column, index) {
    return _c(VCol, {
      key: index,
      class: _setup.getHeaderClass(column),
      on: {
        "dblclick": function ($event) {
          $event.stopPropagation();
          return _vm.$emit('row-dblclick');
        },
        "click": function ($event) {
          $event.stopPropagation();
          return _setup.emitRowClick();
        }
      }
    }, [_vm._t(column.templateName)], 2);
  }), _vm.showActions ? _c(VCol, {
    staticClass: "v-col-xl v-col-sm v-col-lg v-col-md v-col",
    class: _vm.additionalActionClass
  }, [_c('div', {
    staticClass: "d-flex justify-end",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _setup.emitRowClick();
      }
    }
  }, [_vm._t(_setup.ACTIONS_COL.templateName)], 2)]) : _vm._e(), _vm.canExpand ? _c(VCol, {
    staticClass: "v-col-sm-auto v-col-lg-auto v-col-md-auto v-col-xl-auto v-col-auto"
  }, [_c('div', {
    attrs: {
      "data-cy": "list-row-drawer-expander"
    }
  }, [_c('uc-icon', {
    class: ['transition3Sec', _vm.isExpanded ? 'transformRotate180' : ''],
    attrs: {
      "value": _setup.DRAWER_CLOSED,
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        !_vm.isExpanded;
        _vm.$emit('toggle-expand');
      }
    }
  })], 1)]) : _vm._e(), _vm.canRemove ? _c(VCol, {
    staticClass: "v-col-sm-auto v-col-lg-auto v-col-md-auto v-col-xl-auto v-col-auto"
  }, [_c('div', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _setup.emitRowClick();
      }
    }
  }, [_c('uc-icon', {
    attrs: {
      "value": 'mdi-close'
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('remove');
      }
    }
  })], 1)]) : _vm._e(), _vm.canAdd ? _c(VCol, {
    staticClass: "v-col-sm-auto v-col-lg-auto v-col-md-auto v-col-xl-auto v-col-auto"
  }, [_c('div', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _setup.emitRowClick();
      }
    }
  }, [_c('uc-icon', {
    attrs: {
      "value": 'mdi-plus'
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('add');
      }
    }
  })], 1)]) : _vm._e()], 2) : _c('div', {
    class: ['overflow-hidden fill-width']
  }, [_c('uc-list-row-base-card', _vm._b({
    on: {
      "row-click": _setup.emitRowClick,
      "toggle-select": function ($event) {
        return _vm.$emit('toggle-select');
      },
      "row-dblclick": function ($event) {
        return _vm.$emit('row-dblclick');
      },
      "toggle-expand": function ($event) {
        !_vm.isExpanded;
        _vm.$emit('toggle-expand');
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.columnDefs.headers, function (column) {
      return {
        key: column.templateName,
        fn: function () {
          return [_vm._t(column.templateName)];
        },
        proxy: true
      };
    }), _vm.showActions ? {
      key: _setup.ACTIONS_COL.templateName,
      fn: function () {
        return [_vm._t(_setup.ACTIONS_COL.templateName)];
      },
      proxy: true
    } : null], null, true)
  }, 'uc-list-row-base-card', _vm.$props, false))], 1), _c(VExpandTransition, [_vm.isExpanded ? _c('div', {
    staticClass: "d-flex flex-column fill-width overflow-hidden"
  }, [_vm._t("drawer-content")], 2) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };